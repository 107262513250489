// @flow

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import type { Node } from 'react'

import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import {
  article as articleSchema,
  newsArticle as newsArticleSchema,
} from '@raywhite/helpers-utils/lib/helpers/structuredData'

import {
  format as formatUrl,
  parse as parseUrl,
} from '../../utils/url'
import { getItemImage } from '../../utils/wordpress/content'
import JsonLD from './JsonLD.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import OpenGraph from './OpenGraph.jsx'

type Props = {
  content: Object,
  children: ?Node,
  typeName?: string,
  typeSlug?: string,
  primaryDomain: string,
  protocol: string,
  twitter?: string,
  className?: string,
};

class ContentItem extends Component<Props> {
  render() {
    const {
      content,
      typeSlug,
      typeName,
      primaryDomain,
      protocol,
      twitter,
      className,
      children,
    } = this.props
    const image = getItemImage(content)
    const body = content.content ? content.content.rendered : ''
    const parts = parseUrl(content.link)
    const url = formatUrl({ ...parts, protocol, hostname: primaryDomain })
    const schema = typeSlug === 'news' ? newsArticleSchema : articleSchema

    const rawTitle = content.title && content.title.rendered
    const safeTitle = rawTitle && plainText(rawTitle)
    const seoTitle = content.seo_title || safeTitle

    const structuredData = schema({
      headline: seoTitle,
      articleSection: typeName || 'Content',
      articleBody: body && plainText(body),
      url,
      image: image && `${image.url}?maxwidth=1280`,
      datePublished: content.date,
      dateModified: content.modified,
    })
    const metaDescription = content.excerpt
      ? plainText(content.excerpt.rendered)
      : structuredData.articleBody

    // TODO: markup sanitization will continue.
    return (
      <div
        className={classNames('contentpg_raw', className, {
          [`${content.slug.replace(/-/g, '_')}_raw_pg`]: true,
        })}
      >
        <Helmet>
          <title>{typeName ? `${seoTitle} - ${typeName}` : seoTitle}</title>
          <meta name="description" content={excerptify(metaDescription, 160)} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          title={safeTitle}
          description={metaDescription}
          image={structuredData.image}
          site={twitter}
        />
        <OpenGraph
          mayCropImage
          type="article"
          url={url}
          image={image}
          title={safeTitle}
          description={metaDescription}
          article={{
            modifiedTime: structuredData.datePublished,
            publishedTime: structuredData.dateModified,
            section: structuredData.articleSection,
          }}
        />
        <div className="contentpg_raw_header">
          <h1
            dangerouslySetInnerHTML={{
              __html: rawTitle,
            }}
          />
        </div>
        <div
          className="contentpg_raw_content"
          dangerouslySetInnerHTML={{
            __html: content.content && content.content.rendered,
          }}
        />
        {!!children && (
          <div
            className="contentpg_raw_children"
          >
            {children}
          </div>
        )}
      </div>
    )
  }
}

export default ContentItem
