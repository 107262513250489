import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import * as Joi from 'joi'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSectionHeading,
  FormSection,
  FormTextArea,
  FormSubmit,
  FormSelect,
  FormRow,
  labelifyFormField,
} from '../../presentational/formComponents'
import ContentForm from './ContentForm.jsx'

const FORM_NAME = 'maintenance'
const accessOptions = [
  '',
  "Use the agency's key",
  'Call to arrange access',
]
const schema = schemafy(
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.allow('').empty('')),
  schemaItem('number', 'Your Phone Number', types.phone.allow('').empty('')),
  schemaItem('street', 'Street Address', types.freeText.required()),
  schemaItem('suburb', 'Suburb', types.freeText.required()),
  schemaItem('state', 'State / Region', types.freeText.allow('')),
  schemaItem('postCode', 'Postcode', types.postCode.required()),
  schemaItem(
    'access',
    'Tradesperson Access',
    Joi.string().only(accessOptions.slice(1)).required(),
    ''
  ),
  schemaItem('details', 'Repairs Required', types.freeText.required()),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const initialValues = schemaInitialValues(schema)
const FormField = labelifyFormField(labels)
export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)
export const process = (values, { send, referrer }) => stockFormEmail(
  send,
  'Maintenance Request',
  fields,
  labels,
  values,
  {
    prefix: `A maintenance request has been received from ${referrer || 'your office website'}.`,
    referrer,
  }
)


const MaintenanceForm = props => {
  const {
    fields: { name, email, number, street, suburb, state, postCode, access, details },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Renter Details</FormSectionHeading>
      <FormSection>
        <FormField field={name} placeholder="e.g. Joe Bloggs" required />
        <FormRow>
          <FormField
            field={number}
            placeholder="e.g. 04xx xxx xxx"
            required={!email.value}
            className="one_half"
          />
          <FormField
            field={email}
            placeholder="e.g. j.bloggs@gmail.com"
            required={!number.value}
            className="one_half"
          />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Property Details</FormSectionHeading>
      <FormSection>
        <FormField field={street} placeholder="e.g. 123 Example Street" required />
        <FormField field={suburb} className="one_half" required />
        <FormRow>
          <FormField field={state} className="one_half" />
          <FormField field={postCode} className="one_half" required />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Maintenance Details</FormSectionHeading>
      <FormSection>
        <FormField
          field={access}
          input={FormSelect}
          options={accessOptions}
          className="select_form_field"
        />
        <FormField field={details} input={FormTextArea} required />
      </FormSection>
      <FormSubmit submitting={submitting} label="Request Maintenance" />
    </ContentForm>
  )
}

MaintenanceForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(MaintenanceForm)
