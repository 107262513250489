import React from 'react'
import PropTypes from 'prop-types'

import { Marker } from '.'

const BGImageMarker = ({
  className = 'mb-marker',
  latitude,
  longitude,
  // Required only for compatibility with react-mapbox-gl's cluster
  coordinates,
  dataUri,
  width = '25px',
  height,
  lineHeight,
  anchor = 'center',
  onClick,
  children,
}) => (
  <Marker
    coordinates={coordinates || [longitude, latitude]}
    className={className}
    anchor={anchor}
    onClick={onClick}
  >
    <div
      style={{
        backgroundImage: `url(${dataUri})`,
        backgroundSize: 'cover',
        textAlign: 'center',
        fontWeight: 700,
        width,
        height: height || width,
        lineHeight: lineHeight || width,
      }}
    >
      {children}
    </div>
  </Marker>
)
BGImageMarker.propTypes = {
  className: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  coordinates: PropTypes.array,
  dataUri: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  lineHeight: PropTypes.string,
  anchor: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default BGImageMarker
