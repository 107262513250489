import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  getInvestmentDisclaimers,
} from '@raywhite/data-utils/lib/data/listing/commercial'

const format = amount => `$${Math.round(amount).toLocaleString()}`
const formatTenancy = tenancyCode => (tenancyCode === 'TEN' ? 'Currently Tenanted' : 'Currently Vacant')
const formatYield = amount => `${(amount * 100).toFixed(2)} % p.a. *`
const formatEstimatedYield = amount => (amount ? formatYield(amount) : 'N/A (Marketed Without Price)')
const formatValue = value => `${format(value)} *`
const formatLandTax = landTax => `${format(landTax)} **`
const formatTaxDepreciation = (taxDepreciationMin, taxDepreciationMax) => (
  (taxDepreciationMin && taxDepreciationMax)
    ? `${format(taxDepreciationMin)} - ${format(taxDepreciationMax)} ***`
    : null
)
const formatTenancyPercent = percent => {
  if (percent === undefined) return 'Not Specified'
  if (percent === 0) return 'Untenanted'
  if (percent === 100) return 'Fully Tenanted'
  return 'Partially Tenanted'
}

const metadataFields = {
  tenancyStatus: { label: 'Tenancy Status', formatter: formatTenancy },
  currentWeeklyRent: { label: 'Current Weekly Rent', formatter: formatValue },
  currentAnnualGrossIncome: { label: 'Current Annual Gross Income', formatter: formatValue },
  currentAnnualNettIncome: { label: 'Current Annual Nett Income', formatter: formatValue },
  currentAnnualOtherOutgoings: { label: 'Current Annual Other Outgoings', formatter: formatValue },
  currentAnnualTotalOutgoings: { label: 'Current Annual Total Outgoings', formatter: formatValue },
  expectedWeeklyRent: { label: 'Expected Weekly Rent', formatter: formatValue },
  expectedAnnualGrossIncome: { label: 'Expected Annual Gross Income', formatter: formatValue },
  expectedAnnualNettIncome: { label: 'Expected Annual Nett Income', formatter: formatValue },
  expectedAnnualOtherOutgoings: { label: 'Expected Annual Other Outgoings', formatter: formatValue },
  expectedAnnualTotalOutgoings: { label: 'Expected Annual Total Outgoings', formatter: formatValue },
  estimatedGrossYield: { label: 'Estimated Gross Yield', formatter: formatEstimatedYield },
  estimatedNettYield: { label: 'Estimated Nett Yield', formatter: formatEstimatedYield },
  yearBuilt: { label: 'Built in' },
  yearRenovated: { label: 'Renovated in' },
  shireOrCouncil: { label: 'Council' },
  titleScheme: { label: 'Title Scheme' },
  lotsInComplex: { label: 'Lots in Complex' },
  landTax: { label: 'Land Tax (Anually)', formatter: formatLandTax },
  taxDepreciation: { label: 'Tax Depreciation Allowance' },
}

const extractMetadata = listing => {
  const { investment, tenancyCode, typeCode } = listing

  return Object.entries(metadataFields).reduce((result, [field, { label, formatter }]) => {
    const value = investment[field]

    switch (field) {
      case 'tenancyStatus':
        if (typeCode !== 'COM') {
          if (tenancyCode) result.push({ label, value: formatter(tenancyCode) })
        } else if (investment.tenantedPercent !== undefined) {
          result.push({ label, value: formatTenancyPercent(investment.tenantedPercent) })
        }
        break
      case 'estimatedGrossYield':
      case 'estimatedNettYield':
        result.push({ label, value: formatter(value) })
        break
      case 'taxDepreciation': {
        const taxDepreciation = formatTaxDepreciation(
          investment.taxDepreciationMin,
          investment.taxDepreciationMax,
        )
        if (taxDepreciation) result.push({ label, value: taxDepreciation })
        break
      }
      default:
        if (!value) return result
        result.push({ label, value: formatter ? formatter(value) : value })
    }

    return result
  }, [])
}

export default class InvestmentMetadata extends Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
  }

  static defaultProps = {
    listing: {},
  }

  render() {
    const { listing } = this.props

    if (!listing.investment) return null

    const metadata = extractMetadata(listing)

    if (!metadata.length) return null

    const investmentDisclaimers = getInvestmentDisclaimers(listing)

    return (
      <div className="pdp_investment_data_wrap">
        <div className="inner_smadium">
          <h3 className="charlie centered_text">Investment Information</h3>
          {!!metadata.length && (
            <div className="tbl pdp_investment_data">
              {metadata.map(({ label, value }) => (
                <div key={label} className="tbr">
                  <div className="tbc top">
                    <span className="muted">
                      {label}
                      :
                    </span>
                  </div>
                  <div className="tbc top">
                    <p>{value}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!!investmentDisclaimers.length && (
            <div className="pdp_price_disclaimer muted show_bravo">
              {investmentDisclaimers.map(investmentDisclaimer => (
                <p key={investmentDisclaimer}>
                  <em
                    dangerouslySetInnerHTML={{ __html: investmentDisclaimer }}
                  />
                </p>
              ))}
            </div>
          )}
          {!!investmentDisclaimers.length && (
            <div className="pdp_price_disclaimer muted hide_bravo">
              {investmentDisclaimers.map(investmentDisclaimer => (
                <p key={investmentDisclaimer}>
                  <em
                    dangerouslySetInnerHTML={{ __html: investmentDisclaimer }}
                  />
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}
