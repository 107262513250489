import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import classNames from 'classnames'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { depressify, plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { newsArticle as newsArticleSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import { createTimeSince } from '@raywhite/helpers-utils/lib/helpers/time'
import {
  format as formatUrl,
  parse as parseUrl,
} from '../../utils/url'
import DeferredImage from './DeferredImage.jsx'
import { getItemImage, getItemAuthor } from '../../utils/wordpress/content'
import ShareLinks from './ShareLinks.jsx'
import ContentCardList from './ContentCardList.jsx'
import JsonLD from './JsonLD.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import OpenGraph from './OpenGraph.jsx'
import AgentList from './AgentList.jsx'
import ListingCard from './ListingCard.jsx'

export default class CaseStudyContentItem extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    showSharing: PropTypes.bool.isRequired,
    showDate: PropTypes.bool.isRequired,
    children: PropTypes.node,
    typeName: PropTypes.string,
    primaryDomain: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
    twitter: PropTypes.string,
    className: PropTypes.string,
    recentStudies: PropTypes.object.isRequired,
    relatedAgents: PropTypes.array,
    relatedListing: PropTypes.object,
    nextItem: PropTypes.object,
    prevItem: PropTypes.object,
  }

  static defaultProps = {
    showSharing: false,
    showDate: false,
  }

  render() {
    const {
      content,
      showSharing,
      showDate,
      typeName,
      primaryDomain,
      protocol,
      twitter,
      className,
      recentStudies,
      relatedAgents,
      relatedListing,
      nextItem,
      prevItem,
    } = this.props

    const image = getItemImage(content)
      || (relatedListing && relatedListing.loaded && relatedListing.images[0])
    const author = getItemAuthor(content)
    const _content = content.content ? content.content.rendered : ''
    const body = depressify(_content)
    const parts = parseUrl(content.link)
    const url = formatUrl({ ...parts, protocol, hostname: primaryDomain })
    const structuredData = newsArticleSchema({
      headline: content.title && plainText(content.title.rendered),
      articleSection: typeName,
      articleBody: body && plainText(body.__html),
      url,
      image: image && `${image.url}?maxwidth=1280`,
      datePublished: content.date,
      dateModified: content.modified,
    })
    const metaDescription = content.excerpt
      ? plainText(content.excerpt.rendered)
      : structuredData.articleBody

    const title = content.title && content.title.rendered
    // Recent news: the two most recent items that aren't the current item
    const recents = recentStudies.entities
      .filter(item => item.id !== content.id)
      .slice(0, 2)

    return (
      <div
        className={classNames('sgl_case_study', className, {
          [`${content.slug.replace(/-/g, '_')}_pg`]: true,
        })}
      >
        <Helmet>
          <title>{typeName ? `${plainText(title)} - ${typeName}` : title}</title>
          <meta name="description" content={excerptify(metaDescription, 160)} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          title={structuredData.headline}
          description={metaDescription}
          image={structuredData.image}
          site={twitter}
        />
        <OpenGraph
          mayCropImage
          type="article"
          url={url}
          image={image}
          title={structuredData.headline}
          description={metaDescription}
          article={{
            modifiedTime: structuredData.datePublished,
            publishedTime: structuredData.dateModified,
            section: structuredData.articleSection,
          }}
        />
        <div className={`pg_header sgl_case_study_header ${image ? 'has_feature' : ''}`}>
          <div className="inner_md">
            {!!typeName && (
              <span className="mini muted preheading">{typeName}</span>
            )}
            <h1
              className="charlie centered_text"
              dangerouslySetInnerHTML={{
                __html: content.title && content.title.rendered,
              }}
            />
            {showDate && !!content.date_gmt && (
              <div className="sgl_case_study_meta mini muted">
                <time>{createTimeSince(new Date().getTime(), content.date_gmt)}</time>
                {!!author && ([
                  <span key="sep" className="sgl_case_study_meta_bull">&bull;</span>,
                  <span key="author" className="sgl_case_study_author">
                    {`By ${author}`}
                  </span>,
                ])}
              </div>
            )}
            {showSharing && (
              <div className="sgl_case_study_share primary centered_text show_charlie">
                <ShareLinks
                  id={content.id}
                  url={url}
                  linkedIn
                />
              </div>
            )}
          </div>
        </div>
        {image && (
          <div className="inner_md">
            <div className="sgl_case_study_feature">
              <div
                className="responsive_image_wrapper_wrapper"
                style={{ maxWidth: image.width }}
              >
                <DeferredImage
                  image={image}
                  attrs={{
                    width: 750,
                    scale: 'both',
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="inner_smadium sgl_case_study_content_wrap">
          <div
            className="sgl_news_content"
            dangerouslySetInnerHTML={body}
          />
        </div>
        {!!relatedAgents && relatedAgents.length && (
          <div className="pdp_agents">
            <div className="inner">
              <span className="muted mini preheading">Contact</span>
              <h3 className="centered_text">Agents</h3>
              <AgentList members={relatedAgents} />
            </div>
          </div>
        )}
        {!!relatedListing && (
          <div className="pdp_related case_study_related">
            <div className="inner_lg">
              <span className="muted mini preheading">See More</span>
              <h3 className="charlie centered_text">View This Listing</h3>
              {relatedListing && relatedListing.loaded
                && (
                <div className="proplist" data-proplist-itemnum="1">
                  <ul>
                    <li className="proplist_item_wrap">
                      <ListingCard
                        listing={relatedListing}
                        imageWidth={414}
                        imageHeight={275}
                      />
                    </li>
                  </ul>
                </div>
                )}
            </div>
          </div>
        )}
        {!!(nextItem || prevItem) && (
          <div className="inner sgl_case_study_pagination_wrap">
            <div className="sgl_case_study_pagination">
              {!!prevItem && (
                <div className="prev">
                  <Link to={parseUrl(prevItem.link).path}>
                    <span className="muted">Prev Study</span>
                    <h4
                      className="echo"
                      dangerouslySetInnerHTML={{
                        __html: prevItem.title && prevItem.title.rendered,
                      }}
                    />
                  </Link>
                </div>
              )}
              {!!nextItem && (
                <div className="next">
                  <Link to={parseUrl(nextItem.link).path}>
                    <span className="muted">Next Study</span>
                    <h4
                      className="echo"
                      dangerouslySetInnerHTML={{
                        __html: nextItem.title && nextItem.title.rendered,
                      }}
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="centered_text">
              <Link to="/case-studies" className="anchor">Back to all case studies</Link>
            </div>
          </div>
        )}
        {!!recents.length && (
          <section className="dark_bg centered_text sgl_case_study_latest">
            <span className="muted mini preheading">Up to Date</span>
            <h2 className="charlie">Latest Case Studies</h2>
            <div className="home_content_wrap">
              <ContentCardList
                typeName="casestudy"
                className="sgl_case_study_latest_list_wrap"
                listClassName="inner_lg"
                loaded
                entities={recents}
                cardOptions={{ showImage: false, typeName: 'Study' }}
              />
            </div>
          </section>
        )}
      </div>
    )
  }
}
