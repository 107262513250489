import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import {
  loadContentForType,
  getContentForType,
  getContentForPath,
} from '../../redux/modules/content'
import withContext from '../hocs/withContext'
import ContentPage from './ContentPage.jsx'
import NewsContentItem from '../presentational/NewsContentItem.jsx'

class NewsContentPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    getContentForType: PropTypes.func.isRequired,
    itemOptions: PropTypes.object.isRequired,
    recentNews: PropTypes.object.isRequired,
    allNews: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }

  static fetchData(dispatch, { splat }, data) {
    // @todo fetch other data requirements
    return unlazyAll([
      ContentPage.fetchData(dispatch, { splat: `news/${splat}` }, data),
      dispatch(loadContentForType(data.getContentForType, 'news', 1, 30)),
    ])
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    this.loadData()
  }

  loadData() {
    const { dispatch, allNews, getContentForType: getContent, content } = this.props

    // Load recent news
    const itemIndex = allNews.entities.indexOf(content)
    const page = (itemIndex === -1 || (itemIndex - 1) % 30 === 0)
      ? Math.floor(allNews.entities.length / 30) + 1
      : 1

    dispatch(loadContentForType(getContent, 'news', page, 30))
  }

  render() {
    const { itemOptions, recentNews, allNews, content } = this.props
    const itemIndex = allNews.entities.indexOf(content)
    return (
      <ContentPage
        {...this.props}
        itemOptions={{
          ...itemOptions,
          recentNews,
          nextItem: itemIndex !== -1 ? allNews.entities[itemIndex + 1] : undefined,
          prevItem: (itemIndex !== -1 && itemIndex !== 0)
            ? allNews.entities[itemIndex - 1]
            : undefined,
        }}
      />
    )
  }
}

function mapStateToProps(state, props) {
  // Avoid duplicate loading
  const news = getContentForType(state.content, 'news', -1, 30)
  const recentNews = {
    ...news,
    entities: news.entities.slice(0, 3),
  }
  const splat = props.location.pathname.replace(/(^\/|\/$)/g, '')

  return {
    shouldSetAppHeading: false,
    itemComponent: NewsContentItem,
    itemOptions: {
      showDate: true,
      showSharing: true,
      showNavigation: true,
    },
    params: {
      ...props.params,
      splat,
    },
    // Note: loaded by ContentPage container
    content: getContentForPath(state.content, splat),
    allNews: news,
    recentNews,
  }
}

export default compose(
  withContext('getContentForType'),
  connect(mapStateToProps),
)(NewsContentPage)
