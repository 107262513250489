// @flow
import React from 'react'

type Props = {
  user: {
    firstName: string,
    lastName: string,
    displayName: string,
    phoneNumber: string,
  },
};

const Account = ({ user: { firstName, lastName, displayName, phoneNumber } }: Props) => (
  <div className="inner_lg centered_text">
    <h1>Your Account</h1>
    <ul>
      <li>
        First Name:
        {firstName}
      </li>
      <li>
        Last Name:
        {lastName}
      </li>
      <li>
        Display Name:
        {displayName}
      </li>
      <li>
        Phone:
        {phoneNumber}
      </li>
    </ul>
    <a className="btn large" href="/api/auth/signout">Sign Out</a>
  </div>
)

export default Account
