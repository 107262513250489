/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Button = ({
  children,
  className: _className,
  icon,
  isLoading,
  onClick,
  outline,
  plain,
  theme,
  type,
  ...rest
}) => (
  <button
    className={
      classNames(
        'Button', {
          [`Button--${theme}`]: theme,
          'Button--loading': isLoading,
          'Button--outline': outline,
          'Button--plain': plain,
        },
        _className,
      )
    }
    onClick={onClick}
    type={type}
    {...rest}
  >
    {children}
    {/* TODO: Replace with new icon component */}
    {!!icon && <span className="Button__icon">{icon}</span>}
  </button>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  plain: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light', 'brand']),
  type: PropTypes.oneOf(['button', 'reset', 'submit'])
}

// TODO: Add icon to default props
Button.defaultProps = {
  className: '',
  isLoading: false,
  onClick: () => {},
  outline: false,
  plain: false,
  theme: 'dark',
  type: 'button',
}

export default Button
