import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dimensions from 'react-dimensions'

import DeferredImage from './DeferredImage.jsx'
import { yellow } from '../../../assets/icon_colors.json'
import { mapboxToken } from '../../constants'

class ListingMapImage extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    cdn: PropTypes.string,
    icon: PropTypes.string,
    alt: PropTypes.string,
    containerWidth: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
  }

  render() {
    const {
      onClick,
      latitude,
      longitude,
      cdn,
      icon,
      containerWidth,
      zoom,
      alt,
    } = this.props
    const width = Math.min(containerWidth, 1080)
    const height = Math.round(width / (16 / 9))

    return (
      <DeferredImage
        external
        onClick={onClick}
        alt={alt}
        image={{
          url: [
            'https://api.mapbox.com/styles/v1/mapbox/light-v10/static',
            cdn
              ? `url-${encodeURIComponent(cdn + icon)}(${longitude},${latitude})`
              : `pin-s-d+${yellow.replace('#', '')}(${longitude},${latitude})`,
            `${longitude},${latitude},${zoom},0,0`,
            `${width}x${height}@2x?access_token=${mapboxToken}`,
          ].join('/'),
          width,
          height,
        }}
      />
    )
  }
}

export default Dimensions({
  debounce: 100,
  containerStyle: {
    width: '100%',
  },
})(ListingMapImage)
