import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'

import {
  getContentType,
  loadContentForType,
} from '../../redux/modules/content'
import ContentArchive from '../presentational/ContentArchive.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import withContentSearch from '../hocs/withContentSearch'
import withContext from '../hocs/withContext'

class CaseStudyContentArchive extends React.Component {
  render() {
    const {
      search: { loaded, loading, total, entities, error },
      type: { notFound },
    } = this.props.contentSearch

    // If still loading or we have results, load a generic archive
    if (error) {
      return <ErrorPage error={error} />
    }

    if (notFound) {
      return <NotFoundPage />
    }

    const { primaryOffice, baseUrl } = this.props

    return (
      <ContentArchive
        {...this.props}
        heading="Latest Case Studies"
        cardOptions={{ showDate: true, typeName: 'Study' }}
        entities={entities}
        typeName="Case Studies"
        loading={loading}
        total={total}
        loaded={loaded}
        load={this.props.contentSearchLoad}
        url={`${baseUrl}/case-studies`}
        primaryOffice={primaryOffice}
      />
    )
  }
}

CaseStudyContentArchive.propTypes = {
  contentSearch: PropTypes.object.isRequired,
  contentSearchLoad: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  primaryOrganisationId: PropTypes.number.isRequired,
  primaryOffice: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
}

CaseStudyContentArchive.fetchData = (dispatch, params, settings) => {
  const {
    getContentForType: getContent,
    primaryOrganisationId,
    getOrganisations,
  } = settings

  return unlazyAll([
    dispatch(loadContentForType(getContent, 'news', 1, 10)),
    dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])),
  ])
}

function mapStateToProps(state) {
  return {
    contentSearchType: 'casestudy',
    // Used to force a refresh after types are loaded
    type: getContentType(state.content, 'casestudy'),
    baseUrl: state.config.baseUrl,
    primaryOrganisationId: state.config.primaryOrganisationId,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
  }
}

export default compose(
  withContext('getContentForType', 'getOrganisations'),
  connect(mapStateToProps),
  withContentSearch,
)(CaseStudyContentArchive)
