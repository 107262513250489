import React from 'react'
import PropTypes from 'prop-types'

import { telLink } from '@raywhite/helpers-utils/lib/helpers/link'

import DeferredImage from '../DeferredImage.jsx'
import HeadingGroup from '../HeadingGroup/HeadingGroup.jsx'
import Button from '../Button/Button.jsx'

import withLink from '../../hocs/withLink.js'

const getSrPhone = phone => phone.split('').join(' ')

const PlaceholderImage = () => (
  <div className="Placeholder">
    <picture className="Placeholder__image" />
  </div>
)

const HeadingGroupWithLink = withLink(HeadingGroup)
const DeferredImageWithLink = withLink(DeferredImage)
const PlaceholderImageWithLink = withLink(PlaceholderImage)

const BusinessCard = ({
  alternateName,
  email,
  enquiryMeta,
  image,
  isLink,
  isStatic,
  licenceCode,
  name,
  onClickEmail,
  phone,
  profileUrl,
  showContacts,
  title,
}) => (
  <div className="BusinessCard">
    <div className="BusinessCard__content">
      <HeadingGroupWithLink
        heading={name}
        headingLevel="h3"
        headingSuffix={alternateName}
        isLink={isLink}
        isStatic={isStatic}
        showSubheading={!showContacts}
        subheading={title}
        url={profileUrl}
      />
      <div className="BusinessCard__body">
        {showContacts && (
          <address className="BusinessCard__contacts">
            {isStatic
              ? <div>{phone}</div>
              : telLink(phone, { 'data-ev-label': name, 'aria-label': getSrPhone(phone) })}
            <Button
              onClick={onClickEmail}
              plain
              theme="light"
              type="button"
              {...enquiryMeta}
            >
              {email}
            </Button>
          </address>
        )}
        {(licenceCode && showContacts) && <footer className="BusinessCard__footer">{licenceCode}></footer>}
      </div>
    </div>
    {image.data.url
      ? (
        <div className="BusinessCard__image-container">
          <DeferredImageWithLink
            alt={`Portrait of ${name}`}
            attrs={image.attrs}
            image={image.data}
            isLink={isLink}
            isStatic={isStatic}
            url={profileUrl}
          />
        </div>
      )
      : (
        <div className="BusinessCard__image-container--placeholder">
          <PlaceholderImageWithLink
            alt=""
            isLink={isLink}
            isStatic={isStatic}
            url={profileUrl}
          />
        </div>
      )}
  </div>
)

BusinessCard.propTypes = {
  alternateName: PropTypes.string,
  email: PropTypes.string,
  enquiryMeta: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
  }),
  image: PropTypes.shape({
    attrs: PropTypes.object,
    data: PropTypes.object,
  }),
  isLink: PropTypes.bool,
  isStatic: PropTypes.bool,
  licenceCode: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClickEmail: PropTypes.func,
  phone: PropTypes.string,
  profileUrl: PropTypes.string,
  showContacts: PropTypes.bool,
  title: PropTypes.string,
}

BusinessCard.defaultProps = {
  alternateName: '',
  email: '',
  enquiryMeta: {},
  image: {},
  isLink: false,
  isStatic: false,
  licenceCode: '',
  onClickEmail: () => { },
  phone: '',
  profileUrl: '',
  showContacts: false,
  title: '',
}

export default BusinessCard
