import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { mayShowStatusDescription, mayShowUnderOffer } from '@raywhite/helpers-utils/lib/helpers/rules'
import { createRelativeTimeString, parseTimeStamp, startOfDay } from '@raywhite/helpers-utils/lib/helpers/time'
import { getListingUrl, listingLinkMeta } from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyAddress, createPriceString } from '@raywhite/data-utils/lib/data/listing//formatting'
import ListingDescriptor from './ListingDescriptor.jsx'
import DeferredImage from './DeferredImage.jsx'
import Price from './Price.jsx'
import ListingDetail from './ListingDetail.jsx'
import { auctionsPlusLogo } from '../../constants'
import { inferEventsOffset } from '../../utils/events'

const defaultListingImage = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/default_listing_image.svg')
  : undefined

export const badgeFieldOptions = ['underOffer', 'status', 'inspection', 'auction', 'keyAuction', 'available']

export default class ListingCard extends Component { // eslint-disable-line
  static propTypes = {
    listing: PropTypes.object.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    describeOptions: PropTypes.object,
    addressOptions: PropTypes.object,
    badgeFormatter: PropTypes.func,
    badgeField: PropTypes.oneOfType([
      PropTypes.oneOf(badgeFieldOptions),
      PropTypes.arrayOf(PropTypes.oneOf(badgeFieldOptions)),
    ]).isRequired,
    context: PropTypes.object,
    addressComponent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
  }

  static defaultProps = {
    badgeField: ['status', 'inspection', 'keyAuction', 'available'],
    imageWidth: 414,
    imageHeight: 275,
    describeOptions: {
      withLocality: false,
    },
    addressOptions: {
      withLocality: true,
    },
    context: {},
    addressComponent: 'h3',
  }

  constructor(props) {
    super(props)
    this.state = {
      tzOffset: inferEventsOffset(props.listing),
    }
  }

  componentDidMount() {
    // Adjust to local client TZ offset on mount, avoids inconsistent server side
    // and client side render, although the displayed data will generally be the
    // same and so no visual change will occur
    this.setState((state) => {
      const tzOffset = -(new Date().getTimezoneOffset() * 60 * 1000)
      return state.tzOffset === tzOffset ? null : { tzOffset }
    })
  }

  shouldComponentUpdate(props, state) {
    return (
      this.props.listing.id !== props.listing.id
      || this.state.tzOffset !== state.tzOffset
    )
  }

  computeTzOffset() {
    return -(new Date().getTimezoneOffset() * 60 * 1000)
  }

  render() {
    const {
      listing,
      listing: {
        availableDate,
        title,
        address,
        images = [],
        inspections,
        auction,
        status,
        statusCode,
        typeCode,
        underOffer,
        address: {
          countryCode,
        },
      },
      imageWidth,
      describeOptions,
      addressOptions,
      badgeField,
      badgeFormatter,
      context,
      addressComponent: AddressComponent,
    } = this.props

    const displayAddress = stringifyAddress(address, addressOptions)
    const url = getListingUrl(listing)
    const linkMeta = listingLinkMeta(listing)
    const [image] = images
    const eventsFrom = startOfDay(+new Date() + this.state.tzOffset)
    const now = new Date().getTime() + this.state.tzOffset

    const badgeFields = Array.isArray(badgeField) ? badgeField : [badgeField]
    const badgeData = badgeFormatter
      ? badgeFormatter(listing, context)
      : badgeFields.reduce((data, field) => {
        if (data) return data
        switch (field) {
          case 'auction':
            return parseTimeStamp(auction.date) < eventsFrom
              ? undefined
              : createRelativeTimeString(now, auction.date, 'Auction')
          case 'status':
            return mayShowStatusDescription(statusCode) ? status : undefined
          case 'inspection':
            return inspections.reduce((time, inspection) => {
              if (time) return time
              if (parseTimeStamp(inspection.start) < eventsFrom) return undefined
              return createRelativeTimeString(now, inspection.start, 'Open')
            }, undefined)
          case 'underOffer':
            return mayShowUnderOffer(underOffer, statusCode, countryCode)
              ? 'Under Offer'
              : undefined
          case 'keyAuction':
            // Show auctions where they're critical information for a listing type
            if (typeCode !== 'CLR' || !auction) return undefined
            return parseTimeStamp(auction.date) < eventsFrom
              ? undefined
              : createRelativeTimeString(now, auction.date, 'Auction')
          case 'available':
            if (statusCode !== 'CUR' || !availableDate) return undefined
            return parseTimeStamp(availableDate) <= eventsFrom
              ? 'Available Now'
              : createRelativeTimeString(now, availableDate, 'Available')
          default:
            return undefined
        }
      }, undefined)

    return (
      <div className="proplist_item">
        <Link
          to={url}
          className="proplist_item_image_wrap"
          {...linkMeta}
        >
          <div className="proplist_item_image">
            {!!image ? (
              <DeferredImage
                image={image}
                alt={displayAddress}
                attrs={{
                  mode: 'crop',
                  anchor: 'middlecenter',
                  scale: 'both',
                  width: imageWidth,
                  height: imageWidth && Math.round(imageWidth / 16 * 9),
                }}
              />
            ) : <img src={defaultListingImage} alt="Listing" />}
            {badgeData && (
              <span>{badgeData}</span>
            )}
          </div>
        </Link>
        <div className="proplist_item_header">
          <div className="tbl">
            <div className="tbr">
              <div className="tbc">
                <div className="proplist_item_proptype">
                  <ListingDescriptor
                    className="proplist_item_descriptor muted"
                    listing={listing}
                    describeOptions={describeOptions}
                  />
                </div>
              </div>
              <div className="tbc">
                {listing.sourceId.match(/^APLUS/)
                  ? (
                    <img
                      alt={createPriceString(listing)}
                      className="proplist_item_price_image"
                      src={auctionsPlusLogo}
                    />
                  )
                  : (
                    <Price
                      className="proplist_item_price"
                      listing={listing}
                      showOfferTag={false}
                    />
                  )}
              </div>
            </div>
          </div>
          <AddressComponent className="gamma">
            {(typeCode === 'LVS' || typeCode === 'LVW')
              ? <Link to={url} {...linkMeta}>{title}</Link>
              : (
                <Link to={url} {...linkMeta}>
                  {displayAddress}
                </Link>
              )}
          </AddressComponent>
          <ListingDetail
            {...listing}
            className="alt"
            countryCode={address.countryCode}
          />
        </div>
      </div>
    )
  }
}
