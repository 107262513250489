import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { timestampToDate } from '@raywhite/helpers-utils/lib/helpers/time'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import {
  extractTypeSlug,
  describeSearch,
} from '@raywhite/data-utils/lib/data/listing/listings'
import { defaultHeaderImage } from '../../utils/data/listing/listings'
import { fetchListings } from '../../redux/modules/listings'
import EventsPage from './EventsPage.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import { badgeFieldOptions } from '../presentational/ListingCard.jsx'

// Approx TZ offset
const TZ_OFFSET = 1000 * 60 * 60 * 10

const noop = () => {}

export class EventsPageContainer extends React.Component {
  static propTypes = {
    eventType: PropTypes.string.isRequired,
    eventsSince: PropTypes.string.isRequired,
    routeParams: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    badgeField: PropTypes.oneOfType([
      PropTypes.oneOf(badgeFieldOptions),
      PropTypes.arrayOf(PropTypes.oneOf(badgeFieldOptions)),
    ]),
    headerImage: PropTypes.string,
    isValid: PropTypes.bool.isRequired,
    heading: PropTypes.string.isRequired,
    superScript: PropTypes.string,
    search: PropTypes.object.isRequired,
    resourceUrl: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    primaryOffice: PropTypes.object,
  }

  static fetchData(dispatch, params, settings) {
    const { slug } = params
    const {
      getState,
      getListings,
      getOrganisations,
      primaryOrganisationId,
      location,
    } = settings

    const type = location.pathname.split('/').pop()

    const state = getState()
    const eventsSince = timestampToDate(state.config.requestTime + TZ_OFFSET)
    const {
      statusCode,
      typeCode,
      subTypeCode,
      notSubTypeCode,
      custom,
    } = extractTypeSlug(slug)
    const eventType = type === 'auctions' ? 'auctionDate' : 'inspectionDate'
    const search = {
      ...custom,
      [eventType]: {
        gte: eventsSince,
      },
      statusCode,
      typeCode,
      size: 200,
      sort: [`${eventType} asc`, 'id asc'],
      organisationId: state.config.organisationIds,
    }
    if (subTypeCode || notSubTypeCode) {
      search.subTypeCode = {}
      if (subTypeCode) search.subTypeCode.in = subTypeCode
      if (notSubTypeCode) search.subTypeCode.not = notSubTypeCode
    }

    return Promise.all([
      dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])).catch(noop),
      dispatch(fetchListings(getListings, search, 1)).catch(noop),
    ])
  }

  render() {
    const {
      eventsSince,
      eventType,
      badgeField,
      headerImage,
      search,
      heading,
      superScript,
      isValid,
      resourceUrl,
      baseUrl,
      location,
      primaryOffice,
    } = this.props

    if (!isValid || search.statusCode !== 'CUR') {
      return <NotFoundPage />
    }

    return (
      <EventsPage
        eventsSince={eventsSince}
        eventType={eventType}
        search={search}
        badgeField={badgeField}
        headerImage={headerImage}
        heading={heading}
        superScript={superScript}
        resourceUrl={resourceUrl}
        baseUrl={baseUrl}
        location={location}
        primaryOffice={primaryOffice}
      />
    )
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, props) {
  const eventsSince = timestampToDate(state.config.requestTime + TZ_OFFSET)
  const {
    routeParams: { slug },
    eventType,
  } = props
  const {
    statusCode,
    typeCode,
    subTypeCode,
    notSubTypeCode,
    isValid,
    custom,
  } = extractTypeSlug(slug)
  const headerImage = defaultHeaderImage(
    state.config.options.search.headers, typeCode
  )

  const search = {
    ...custom,
    [props.eventType]: {
      gte: eventsSince,
    },
    statusCode,
    typeCode,
    size: 200,
    sort: [`${props.eventType} asc`, 'id asc'],
  }
  if (subTypeCode || notSubTypeCode) {
    search.subTypeCode = {}
    if (subTypeCode) search.subTypeCode.in = subTypeCode
    if (notSubTypeCode) search.subTypeCode.not = notSubTypeCode
  }

  const heading = eventType === 'inspectionDate'
    ? 'Open For Inspection'
    : 'Upcoming Auctions'

  const type = Array.isArray(typeCode) ? typeCode : [typeCode]
  const superScript = describeSearch({
    status: 'CUR',
    type: type.length === 1 ? type[0] : type,
  })

  return {
    eventsSince,
    headerImage,
    statusCode,
    isValid,
    search,
    heading,
    superScript: superScript === 'properties' ? undefined : superScript,
    resourceUrl: state.config.env.cdn || '',
    baseUrl: state.config.baseUrl,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
  }
}

export default connect(mapStateToProps)(withRouter(EventsPageContainer))
