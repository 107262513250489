import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import flagged from '@raywhite/helpers-utils/lib/helpers/flags'
import AppHeader from '../presentational/AppHeader.jsx'
import withMenus from '../hocs/withMenus'
import withOrgs from '../hocs/withOrgs'
import developmentMenu from '../../../dev.menu.json'

const stripServicePages = items => items.reduce((result, item) => {
  switch (item.path) {
    case '/buy':
    case '/rent':
    case '/property-management':
    case '/sell':
      result.push({ ...item, children: [] })
      break
    case '/lease':
      result.push({ ...item, children: [], path: '/property-management' })
      break
    default:
      result.push(item)
  }
  return result
}, [])

class ConnectedAppHeader extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    useServicePages: PropTypes.bool.isRequired,
    primaryOrg: PropTypes.object.isRequired,
    menuTitle: PropTypes.string,
  }

  render() {
    const {
      menu: {
        menus,
        loaded,
      },
      useServicePages,
      primaryOrg,
      menuTitle,
      ...rest
    } = this.props

    const devMenu = process.env.NODE_ENV === 'production' ? [] : [{
      label: 'DEVELOPMENT',
      children: developmentMenu,
      path: '/',
    }]

    const secondary = menus.header_right
      ? menus.header_right.concat(devMenu)
      : devMenu

    const primaryMenu = useServicePages
      ? stripServicePages(menus.primary || [])
      : (menus.primary || [])

    return (
      <AppHeader
        {...rest}
        menuTitle={menuTitle || primaryOrg.name || 'Ray White'}
        countryCode={primaryOrg?.address?.countryCode}
        orgSubTypeCode={primaryOrg?.subTypeCode}
        primaryOrg={primaryOrg}
        menuLoaded={loaded}
        primaryMenu={primaryMenu}
        secondaryMenu={secondary || []}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    menuTitle: state.config.options.menuTitle,
    useServicePages: flagged('servicePages', state),
  }
}

export default compose(
  connect(mapStateToProps),
  withMenus,
  withOrgs,
)(ConnectedAppHeader)
