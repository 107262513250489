import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button.jsx'

const INITIAL_STATE = {
  showMore: false
}

class Testimonial extends React.Component {
  static propTypes = {
    testimonial: PropTypes.shape({
      company: PropTypes.string,
      detail: PropTypes.string,
      id: PropTypes.number,
      person: PropTypes.string,
      position: PropTypes.string,
    }).isRequired,
    truncatedTestimonial: PropTypes.shape({
      isTruncated: PropTypes.bool,
      testimonial: PropTypes.string,
    }).isRequired,
  }

  state = INITIAL_STATE

  handleOnClick = () => this.setState(state => ({ showMore: !state.showMore }))

  render() {
    const {
      props: {
        testimonial: { detail: originalTestimonial, person },
        truncatedTestimonial: { isTruncated, testimonial },
      },
      state: {
        showMore
      }
    } = this

    return (
      <div className="Testimonial">
        <div className="Testimonial__body">
          <div className="Testimonial__content">
            {!showMore && isTruncated
              ? testimonial
              : originalTestimonial}
          </div>
          {isTruncated
            && (
              <Button
                aria-pressed={showMore}
                className="Testimonial__button"
                onClick={this.handleOnClick}
                plain
                theme="light"
                type="button"
              >
                {!showMore ? 'Show More' : 'Show Less'}
              </Button>
            )}
          <div className="Testimonial__author">
            &ndash;
            {' '}
            {person}
          </div>
        </div>
      </div>
    )
  }
}

export default Testimonial
