import React from 'react'
import { stringify } from 'query-string'
import {
  YOUTUBE,
  VIMEO,
  MATTERPORT,
  LITTLEHINGES,
  KUULA,
  SCANN3D,
  CLOUDPANO,
  ISTAGING,
  VIRTUALTOURS,
  CAPTUR3D,
  DIAKRIT_FURNISH,
  DIAKRIT_PANORAMA,
  IGUIDE,
  PLAT_HD,
  MP4,
  IMAGE,
  VIDEO,
  FLOORPLAN,
  VIRTUAL_TOUR,
  parseLink,
} from '@raywhite/media-utils/lib/media/media'
import Image from '../../components/presentational/Image.jsx'
import ResponsiveIframe from '../../components/presentational/ResponsiveIframe.jsx'

// Safe default attributes for iframes.
const IFRAME_DEFAULT_ATTRS = {
  type: 'text/html',
  allowFullScreen: true,
  allow: 'fullscreen',
}

// Not exaustive... see `https://developers.google.com/youtube/player_parameters`.
const YOUTUBE_DEFAULT_PARAMS = {
  autoplay: 1, // Autoplay video
  cc_load_policy: 0, // Don't show closed captions (our videos don't have them).
  color: 'white', // Control colors - disables modest video branding.
  // controls: 1, // Display controls (default).
  disablekb: 1, // Respond to keyboard controls.
  // ennablejsapi: 0, // Disable iframe js control (default).
  // fs: 1, // Display the fullscreen button in controls (default).
  hl: 'en', // ISO 639.2 2 letter code (http://www.loc.gov/standards/iso639-2/php/code_list.php)
  iv_load_policy: 3, // Disable annotations.
  // loop: 0 // Don't loop the video after playing (defualt).
  // modestbranding: 0 // Display youtube logo (dafault).
  rel: 0, // Don't show related videos when playbsack finishes.
  // playsinline: 0 // iOS fulllscreen bahaviour (default).
  showinfo: 0, // Don't display info before the video begins.
}

// See `https://developer.vimeo.com/player/embedding`.
const VIMEO_DEFAULT_PARAMS = {
  // autopause: 1, // Pause if another video plays (default).
  // autoplay: 0, // Autoplay the video (default).
  // badge: 0, // Disable badge on the video (default).
  byline: 0, // Disable byline on the video.
  color: 'ffffff', // Video control color.
  // loop: 0, // Disable looping (default).
  // player_id: 'x', // Player id for JS API responses (use the links `hash` where needed).
  portrait: 0, // Don't show the uploaders portrait.
  title: 0, // Don't show the title on the video.
}

/**
 * Liam's platinum contact; <nicholas.tod@platinumhd.tv>
 * Videos hosted by platinum themselves are using JWPlayer 6. The following
 * attributes can be set in the iframes qeury string:
 * share_btn: defaults to 'true'
 * sharecode: defaults to 'true'
 * phone_btn: defaults to 'true'
 * email_btn: defaults to 'true'
 * playerskin: URL To JWPlayer 6 Skin XML requires crossdomain settings
 */
const PLAT_HD_DEFAULT_PARAMS = {
  autostart: false,
  related_mode: 'disabled',
  share_btn: 'disabled',
  sharecode: 'disabled',
  phone_btn: 'disabled',
  email_btn: 'disabled',
}

const stripProtocols = str => str
  .replace(/^https?:(?:\/{2})?/, '//')
  .replace(/^\/{3,}/, '/')

// Pure renderers for videos
const videoTypes = {
  [YOUTUBE]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...YOUTUBE_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
      allow: 'fullscreen; autoplay',
    }
    return (
      <ResponsiveIframe
        title="Video"
        {..._attrs}
        src={`//www.youtube.com/embed/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [VIMEO]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...VIMEO_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="Video"
        {..._attrs}
        type="text/html"
        src={`//player.vimeo.com/video/${id}?${stringify(_params)}`}
        allowFullScreen
        outerWrap={outerWrap}
      />
    )
  },

  [PLAT_HD]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...PLAT_HD_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="Video"
        {..._attrs}
        src={`//platinumhd.tv/embed/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  // These are generally replicates of AutoPlay videos hosted in S3.
  [MP4]: (link, attrs, params, outerWrap = true) => {
    const { url: _url } = link
    const _attrs = {
      preload: 'auto',
      controls: true,
      ...attrs,
    }
    const inner = (
      <div className="iframe_wrap">
        <video
          {..._attrs}
          src={stripProtocols(_url)}
        />
      </div>
    )
    return outerWrap
      ? <div className="iframe_outer_wrap">{inner}</div>
      : inner
  },
}

/**
 * Takes a `VID` link object.
 *
 * @param {Object} link
 *
 * @return {Object} react vdom node.
 */
export const renderVideo = (link, attrs = {}, params = {}) => (
  videoTypes[link.type](link, attrs, params)
)

// Can't find any docs
const CLOUDPANO_DEFAULT_PARAMS = {
}

// See `https://www.scann3d.com.au/support/`.
const SCANN3D_DEFAULT_PARAMS = {
  noplayer: null,
  nosplash: null,
  showcaseoverlay: null,
  autoplay: null,
}

// See `https://support.matterport.com/hc/en-us/articles/209980967-How-can-I-customize-viewing-of-my-embedded-models-`
const MATTERPORT_DEFAULT_PARAMS = {
  brand: 0, // Disables matterport branding.
  play: 1, // Don't show the `3D showcase` button while loading.
  ts: 3, // Display the tour immediately after fly in.
}

const KUULA_DEFAULT_PARAMS = {
  fs: 1,
  vr: 0,
  zoom: 1,
  thumbs: 1,
  chromeless: 0,
  logo: 0,
}

// Pure renderers for 3d floor plans.
const tourTypes = {
  [MATTERPORT]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...{ m: id },
      ...MATTERPORT_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
      allow: 'vr *; autoplay *; fullscreen *; gyroscope *; accelerometer *; xr-spatial-tracking *',
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`//my.matterport.com/show/?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [LITTLEHINGES]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
      allow: 'vr *; autoplay *; fullscreen *; gyroscope *; accelerometer *; xr-spatial-tracking *',
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`//openhouse.littlehinges.com.au/${id}`}
        outerWrap={outerWrap}
      />
    )
  },

  [KUULA]: (link, attrs = {}, params = {}, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...KUULA_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      allow: 'vr,gyroscope,accelerometer,fullscreen',
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://kuula.co/share/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [CLOUDPANO]: (link, attrs = {}, params = {}, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...CLOUDPANO_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }

    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://app.cloudpano.com/tours/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [SCANN3D]: (link, attrs = {}, params = {}) => {
    const { id } = link
    const _params = {
      ...SCANN3D_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <div className="iframe_wrap">
        <iframe
          {..._attrs}
          title="3D Tour"
          src={`//www.scann3d.com.au/view/v1/?${id}${stringify(_params)}`}
        />
      </div>
    )
  },

  [ISTAGING]: (link, attrs = {}, params = {}, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <React.Fragment>
        <ResponsiveIframe
          title="3D Tour"
          {..._attrs}
          src={`https://livetour.istaging.com/${id}?${stringify(params)}`}
          outerWrap={outerWrap}
        />
        <script src="https://www.istaging.com/src/iframe-enable-gyro.min.js" />
      </React.Fragment>
    )
  },

  // eslint-disable-next-line no-unused-vars
  [VIRTUALTOURS]: (link, attrs = {}, params = {}, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://vtc.virtualtourscreator.com.au/share/${id}`}
        outerWrap={outerWrap}
      />
    )
  },

  [IGUIDE]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    const _params = {
      autostart: 1,
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://youriguide.com/embed/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  // It's really a Matterport one
  [CAPTUR3D]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _params = {
      ...MATTERPORT_DEFAULT_PARAMS,
      ...params,
    }
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
      allow: 'vr *; autoplay *; fullscreen *; gyroscope *; accelerometer *; xr-spatial-tracking *',
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`//captur3d.io/view/${id}?${stringify(_params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [DIAKRIT_PANORAMA]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://cdn.diakrit.com/product/panorama/${id}?${stringify(params)}`}
        outerWrap={outerWrap}
      />
    )
  },

  [DIAKRIT_FURNISH]: (link, attrs, params, outerWrap = true) => {
    const { id } = link
    const _attrs = {
      ...IFRAME_DEFAULT_ATTRS,
      ...attrs,
    }
    return (
      <ResponsiveIframe
        title="3D Tour"
        {..._attrs}
        src={`https://cdn.diakrit.com/product/furnish/${id}?${stringify(params)}`}
        outerWrap={outerWrap}
      />
    )
  },
}

/**
 * Takes a `3FP` link object.
 *
 * @param {Object} link
 *
 * @return {Object} react vdom node.
 */
export const renderTour = (link, attrs = {}, params = {}) => (
  tourTypes[link.type](link, attrs, params)
)

/**
 * Takes an `IMG` link object.
 *
 * @param {Object} link
 *
 * @return {Object} react vdom node.
 */
export function renderImage(image, attrs, props) {
  return <Image {...props} image={image} attrs={attrs} />
}

const mediaTypes = {
  [VIDEO]: renderVideo,
  [VIRTUAL_TOUR]: renderTour,
  [FLOORPLAN]: (m, attrs, props) => renderImage(m, attrs, props),
  [IMAGE]: (m, attrs, props) => renderImage(m, attrs, props),
}

/**
 * Takes a links and renders them based on their `code` and `type` properties.
 *
 * @param {Object} link
 *
 * @return {Object} react vdom node.
 */
export const renderMedia = (m, attrs, params) => (
  mediaTypes[m.code] && mediaTypes[m.code](m, attrs, params)
)

export const renderLink = (url, attrs, params) => (
  renderMedia(parseLink({ url }), attrs, params)
)

export const getCdnImage = url => (
  url && url.replace(/rw-media.s3.amazonaws.com/, 'cdn6.ep.dynamics.net/s3/rw-media')
)
