// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const noundef = obj => {
  if (obj === undefined || obj === null) {
    return undefined
  }

  if (/string|number|boolean/.test(typeof obj)) {
    return obj
  }

  return Object.keys(obj).reduce((res, prop) => {
    const val = obj[prop]
    if (val === undefined || val === null) {
      return res
    }

    if (Array.isArray(val)) {
      const _val = val
        .map(x => noundef(x))
        .filter(x => x !== undefined)

      if (!_val.length) {
        // Drop empty arrays
        return res
      }

      res[prop] = _val // eslint-disable-line no-param-reassign
      return res
    }

    // eslint-disable-next-line no-param-reassign
    res[prop] = /string|number|boolean/.test(typeof val)
      ? val
      : noundef(val)
    return res
  }, {})
}

type Props = {
  children?: Object,
  context?: string,
};

const JsonLD = ({ children = {}, context = 'https://schema.org' }: Props) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({ '@context': context, ...noundef(children) }, null, '  ')}
    </script>
  </Helmet>
)
JsonLD.propTypes = {
  children: PropTypes.object.isRequired,
  context: PropTypes.string,
}

export default JsonLD
