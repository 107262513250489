// @flow
import React, { Component } from 'react'

type Props = {
  name: string,
  type: string,
  value: string,
  className?: string,
  onChange: Object => void,
};

type Event = {
  target: {
    value: any,
  },
};

type State = {
  value: ?string,
};

// A form input that only triggers the given onChange when focus is lost
export default class FormInput extends Component<Props, State> {
  static defaultProps = {
    type: 'text',
  }

  state = {
    value: undefined,
  }

  getValue() {
    return this.state.value === undefined
      ? this.props.value
      : this.state.value
  }

  onBlur = () => {
    this.notifyChange()
  }

  onChange = (event: Event) => {
    this.setState({ value: event.target.value })
  }

  notifyChange = () => {
    this.props.onChange({ target: { value: this.getValue() } })
    this.setState({ value: undefined })
  }

  onKeyDown = (event: SyntheticKeyboardEvent<*>) => {
    if (event.key === 'Enter') this.notifyChange()
  }

  render() {
    const { name, type, className } = this.props
    return (
      <input
        className={className}
        name={name}
        type={type}
        value={this.getValue()}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
      />
    )
  }
}
