import EventEmitter from 'events'
import { pack, isExpired } from '../cache'

const NUM_RESERVED_ITEMS = 1
const VERSION = 8

/**
 * Wraps a localForage instance (or compatible interface) with additional
 * logic to
 *  - handle expiration
 *  - handle over quota problems (badly at the moment)
 *
 * @param {localForage} store The local forage instance to use.
 */
export default function localForage(store) {
  const emitter = new EventEmitter()

  // Allows versions to be bumped to purge the local cache, e.g. if we change
  // the structure of the store
  async function init() {
    try {
      if (await store.getItem('_version') === VERSION) return
      await store.clear()
      await store.setItem('_version', VERSION)
    } catch (error) {
      const _err = new Error(`Unable to init client cache: ${error.message}`)
      error._originalError = error
      emitter.emit('error', error)
      throw _err
    }
  }

  // Promise to await on to know that the store is ready
  let initialised = init()

  /**
   * Trim any expired items, returns true if found, otherwise false.
   */
  async function trim() {
    await initialised

    const now = new Date().getTime()
    const trims = []

    // Find expired items
    store.iterate(({ expires }, key) => {
      if (!expires || expires > now) return
      trims.push(store.removeItem(key))
    })

    return Promise.all(trims).then(() => !!trims.length)
  }

  async function get(key) {
    await initialised

    const result = await store.getItem(key)
    if (!result) return result

    // Only return it if it's valid
    if (!isExpired(result)) return result.v

    // Drop the expired item
    await store.removeItem(key)

    return null
  }

  async function set(key, value, { expire } = {}) {
    await initialised

    try {
      await store.setItem(key, pack(value, expire))
    } catch (e) {
      // Agressively assume that we're failing due to limited space
      const error = new Error(`Failed to set localForage ${key}.`)
      error._originalError = e
      error._level = 'info'
      emitter.emit('error', error)

      // TODO: only clear on actual space errors; unclear how to determine this

      // Store is empty already, nothing to do here.
      if (await store.length() <= NUM_RESERVED_ITEMS) return

      // Free space by dropping expired items
      if (!await trim()) {
        // No space freed by trim, just purge
        // TODO: clear more intelligently (e.g. LRU)
        await store.clear()
        initialised = init()
      }

      // Try to set again after freeing space
      set(key, pack(value, expire))
    }
  }

  async function getTags(tags) {
    const data = await get('__tags')
    return tags.reduce((result, tag) => {
      // eslint-disable-next-line no-param-reassign
      result[tag] = data[tag] || 0
      return result
    }, {})
  }

  async function invalidateTags(tags) {
    const data = await get('__tags')
    tags.forEach(tag => {
      // eslint-disable-next-line no-param-reassign
      data[tag] = (data[tag] || 0) + 1
    })
    return set('__tags', data, { expire: false })
  }

  return { get, set, getTags, invalidateTags }
}
