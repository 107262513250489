import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeferredImage from './DeferredImage.jsx'

const imageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
})

export default class Image extends Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    image: imageShape.isRequired,
    responsive: PropTypes.bool.isRequired,
    galleryItem: PropTypes.bool,
    attrs: PropTypes.object,
    style: PropTypes.object,
    sizes: PropTypes.string,
    immediate: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    responsive: true,
    showLoader: false,
    immediate: false,
  }

  render() {
    return <DeferredImage deferred={false} {...this.props} />
  }
}
