import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import ContentForm from './ContentForm.jsx'
import {
  FormSectionHeading,
  FormSection,
  FormTextArea,
  FormSubmit,
  FormRow,
  labelifyFormField,
} from '../../presentational/formComponents'

const FORM_NAME = 'contact'
const schema = schemafy(
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.allow('').empty('')),
  schemaItem('number', 'Your Phone Number', types.phone.allow('').empty('')),
  schemaItem('message', 'Message', types.freeText.required('')),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)
export const process = (values, { send, referrer }) => stockFormEmail(
  send,
  'Enquiry',
  fields,
  labels,
  values,
  {
    prefix: `An enquiry has been received from ${referrer || 'your office website'}.`,
    referrer,
  }
)

const ContactForm = props => {
  const {
    fields: { name, email, number, message },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Your Contact Details</FormSectionHeading>
      <FormSection>
        <FormField field={name} placeholder="e.g. Joe Bloggs" required />
        <FormRow>
          <FormField
            field={number}
            placeholder="e.g. 04xx xxx xxx"
            required={!email.value}
            className="one_half"
          />
          <FormField
            field={email}
            placeholder="e.g. j.bloggs@gmail.com"
            required={!number.value}
            className="one_half"
          />
          <FormField field={message} input={FormTextArea} required />
        </FormRow>
      </FormSection>
      <FormSubmit submitting={submitting} label="Send Enquiry" />
    </ContentForm>
  )
}

ContactForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(ContactForm)
