// @flow
import React, { Component } from 'react'
import classNames from 'classnames'
import deepEqual from 'deep-equal'
import type { ElementOptions } from '../../utils/functionalities/search'

type Element = {
  key: string,
  field: Object,
} & ElementOptions;

type Props = {
  form: Object,
  toggleFilter: () => Object,
  resetFilters: () => void,
  activateFilter: () => void,
  activateSort: () => void,
  activateTimeFrame: () => void,
  className: string,
  filterGroup?: string,
  onKeyDown?: SyntheticKeyboardEvent<*> => void,
  applicableElements: Array<string>,
};

const displayFormElements = (elements: Array<Element>) => (
  elements.map(({
    component: Input, key, label, options, defaultValue,
  }) => (
    <div
      className={`map_filter_item map_filter_${key}`}
      key={key}
    >
      {elements.length > 1
        ? (
          <span className="map_filter_item_label">
            {label}
            :
          </span>
        )
        : ''}
      <Input
        options={options}
        defaultValue={defaultValue}
      />
    </div>
  ))
)

// Prevent event propagation
const stopPropagation = e => e.stopPropagation()

export default class FilterSlideInModal extends Component<Props> {
  wrapper: ?HTMLElement

  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      this.props.className !== nextProps.className
      || this.props.filterGroup !== nextProps.filterGroup
      || !deepEqual(this.props.applicableElements, nextProps.applicableElements)
    )
  }

  focus() {
    if (this.wrapper) this.wrapper.focus()
  }

  setWrapper = (el: ?HTMLElement) => {
    this.wrapper = el
  }

  render() {
    const {
      form,
      className,
      toggleFilter,
      resetFilters,
      onKeyDown: handleKeyDown,
      activateFilter,
      activateSort,
      activateTimeFrame,
      filterGroup,
      applicableElements,
    } = this.props
    const { filter, sortRadio, dateFilter } = form

    return (
      <div
        className={classNames('map_filter', className)}
        onClick={toggleFilter}
        onKeyDown={handleKeyDown}
        ref={this.setWrapper}
        tabIndex="0"
        data-ev-on="click"
        data-ev-category="Filter Panel"
        data-ev-action="Close Filter Panel"
      >
        <div
          className="map_filter_form"
          onClick={stopPropagation}
        >
          <div className="map_filter_header">
            <span
              className="map_filter_close"
              onClick={toggleFilter}
              data-ev-on="click"
              data-ev-category="Filter Panel"
              data-ev-action="Close Filter Panel"
            />
            <h3 className="mini">Filter &amp; Sort</h3>
          </div>
          <div className="map_filter_body">
            <div
              className={classNames('map_filter_group filter', { active: filterGroup === 'filter' })}
              onClick={activateFilter}
            >
              <div className="map_filter_group_header">
                <h3 className="foxtrot secondary_font">Filter Properties</h3>
              </div>
              <div
                className="map_filter_group_items"
                onClick={stopPropagation}
              >
                {filter.map(({ label: groupLabel, elements }) => {
                  const _elements = elements.filter(
                    ({ key }) => applicableElements.indexOf(key) !== -1
                  )

                  // Skip rendering the group if no renderable elements
                  if (!_elements.length) return null

                  return [
                    <p key="label">
                      {groupLabel}
                      :
                    </p>,
                    ...displayFormElements(_elements),
                  ]
                })}
                <div className="centered_text map_filter_clear">
                  <span
                    onClick={resetFilters}
                    data-ev-on="click"
                    data-ev-category="Filter"
                    data-ev-action="Clear Filter"
                  >
                    Clear Filters
                  </span>
                </div>
              </div>
            </div>
            <div
              className={classNames('map_filter_group sort', { active: filterGroup === 'sort' })}
              onClick={activateSort}
            >
              <div className="map_filter_group_header">
                <h3 className="foxtrot secondary_font">Sort By</h3>
              </div>
              <div
                className="map_filter_group_items"
                onClick={stopPropagation}
              >
                {displayFormElements([sortRadio])}
              </div>
            </div>
            {!!dateFilter && (
              <div
                className={classNames('map_filter_group timeframe', { active: filterGroup === 'timeframe' })}
                onClick={activateTimeFrame}
              >
                <div className="map_filter_group_header">
                  <h3 className="foxtrot secondary_font">Date Range</h3>
                </div>
                <div
                  className="map_filter_group_items"
                  onClick={stopPropagation}
                >
                  {displayFormElements([dateFilter])}
                </div>
              </div>
            )}
          </div>
          <div className="map_filter_footer centered_text">
            <span
              className="map_filter_done brand_btn btn no_arrow"
              onClick={toggleFilter}
              data-ev-on="click"
              data-ev-category="Filter Panel"
              data-ev-action="Close Filter Panel"
            >
              Done
            </span>
          </div>
        </div>
      </div>
    )
  }
}
