import { stringify } from 'query-string'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'

const defaultListingImage = process.env.WEBPACKED
  ? require('../../assets/icons/dist/default_listing_image.svg')
  : ''

const logo = process.env.WEBPACKED
  ? require('../../assets/icons/dist/rw_logo.svg')
  : ''

const prefix = params => Object.entries(params).reduce((acc, [k, v]) => ({
  ...acc,
  [`ta_${k}`]: v,
}), {})

const normalisePrice = (price, code) => {
  switch (code) {
    case 'WKL':
      return price
    case 'MTH':
      return price * 12 / 365 * 7
    case 'ANN':
      return price / 365 * 7
    case 'DAY':
      return price * 7
    case 'FTN':
      return price / 2
    default:
      return null
  }
}

export default function tenappUrl(refid, baseUrl, listing, referrer, agent, office, resourceUrl) {
  if (!refid || !baseUrl || !office) return null

  const {
    id,
    bond,
    availableDate,
    rentPrice,
    rentFrequencyCode,
    address,
    address: {
      suburb,
      stateCode,
      postCode,
    },
    carSpaces,
    bedrooms,
    bathrooms,
    type,
    images: [image] = [],
  } = listing

  // Cannot do it for listings missing price or bond info
  if (!rentPrice || !rentFrequencyCode) return null

  // Cannot do it for non public addresses
  const streetAddress = stringifyAddress(address)
  if (!streetAddress) return null

  const query = stringify(prefix({
    refid,
    ppid: id,
    ppbond: bond || 0,
    ppavail: availableDate ? availableDate.slice(0, 10) : undefined,
    ppprice: normalisePrice(rentPrice, rentFrequencyCode),
    ppaddr: streetAddress,
    ppsuburb: suburb,
    ppstate: stateCode,
    pppcode: postCode,
    ppdisplay: 'Y',
    ppcar: carSpaces || 0,
    ppbed: bedrooms || 0,
    ppbath: bathrooms || 0,
    pptype: type,
    pplink: referrer,
    rename: office.fullName,
    reagent: agent?.fullName || office.fullName,
    reemail: agent?.email || office.email,
    relogo: `${resourceUrl}${logo}`,
    ppimages: image ? `${image.url}?maxwidth=1280` : `${resourceUrl}${defaultListingImage}`,
  }))

  return `${baseUrl}?${query}`
}
