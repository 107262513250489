import PropTypes from 'prop-types'
import { createContextConsumer } from './providers'

const types = {
  fetch: PropTypes.func,
  _window: PropTypes.object,
  getListings: PropTypes.func,
  getListingsMeta: PropTypes.func,
  getSuburbs: PropTypes.func,
  getMembers: PropTypes.func,
  getOrganisations: PropTypes.func,
  getOrganisationsMetadata: PropTypes.func,
  getAgentMetadata: PropTypes.func,
  getContentTypes: PropTypes.func,
  getContentForType: PropTypes.func,
  getContentForPath: PropTypes.func,
  getSyndicatedNews: PropTypes.func,
  getMenus: PropTypes.func,
  getBrokers: PropTypes.func,
  getUser: PropTypes.func,
  store: PropTypes.object,
}

const withContext = (...contexts) => Wrapee => {
  const selected = contexts.reduce((all, name) => {
    if (types[name]) {
      all.push({ name, type: types[name] })
    }
    return all
  }, [])

  if (!selected.length) return Wrapee

  return createContextConsumer(`withContext(${contexts.join(',')})`, selected)(Wrapee)
}

export default withContext
