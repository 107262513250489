import SellAppraisalForm, {
  validate as validateSellAppraisalForm,
  process as processSellAppraisalFormResponse,
} from './SellAppraisalForm.jsx'

import RentAppraisalForm, {
  validate as validateRentAppraisalForm,
  process as processRentAppraisalFormResponse,
} from './RentAppraisalForm.jsx'

import ContactForm, {
  validate as validateContactForm,
  process as processContactFormResponse,
} from './ContactForm.jsx'

import MaintenanceForm, {
  validate as validateMaintenanceForm,
  process as processMaintenanceFormResponse,
} from './MaintenanceForm.jsx'

import VacatingForm, {
  validate as validateVacatingForm,
  process as processVacatingFormResponse,
} from './VacatingForm.jsx'

import RentAlertForm, {
  validate as validateRentAlertForm,
  process as processRentAlertFormResponse,
} from './RentAlertForm.jsx'

import PropertySaleAlertForm, {
  validate as validatePropertySaleAlertForm,
  process as processPropertySaleAlertFormResponse,
} from './PropertySaleAlertForm.jsx'

import EnquiryForm, {
  validate as validateEnquiryForm,
  process as processEnquiryFormResponse,
} from './EnquiryForm.jsx'

import CommercialSellAppraisalForm, {
  validate as validateCommercialSellAppraisalForm,
  process as processCommercialSellAppraisalFormResponse,
} from './CommercialSellAppraisalForm.jsx'

import CommercialLeaseAppraisalForm, {
  validate as validateCommercialLeaseAppraisalForm,
  process as processCommercialLeaseAppraisalFormResponse,
} from './CommercialLeaseAppraisalForm.jsx'

import NzRentalApplicationForm, {
  validate as validateNzRentalApplicationForm,
  process as processNzRentalApplicationFormResponse,
} from './NzRentalApplicationForm.jsx'

export default {
  SellAppraisalForm: {
    component: SellAppraisalForm,
    validate: validateSellAppraisalForm,
    process: processSellAppraisalFormResponse,
    label: 'Appraisal',
  },
  RentAppraisalForm: {
    component: RentAppraisalForm,
    validate: validateRentAppraisalForm,
    process: processRentAppraisalFormResponse,
    label: 'Rent Appraisal',
  },
  ContactForm: {
    component: ContactForm,
    validate: validateContactForm,
    process: processContactFormResponse,
    label: 'Ask Question',
  },
  MaintenanceForm: {
    component: MaintenanceForm,
    validate: validateMaintenanceForm,
    process: processMaintenanceFormResponse,
    label: 'Mantenance',
  },
  VacatingForm: {
    component: VacatingForm,
    validate: validateVacatingForm,
    process: processVacatingFormResponse,
    label: 'Vacating',
  },
  RentAlertForm: {
    component: RentAlertForm,
    validate: validateRentAlertForm,
    process: processRentAlertFormResponse,
    label: 'Rent Alert',
  },
  PropertySaleAlertForm: {
    component: PropertySaleAlertForm,
    validate: validatePropertySaleAlertForm,
    process: processPropertySaleAlertFormResponse,
    label: 'Sale Alert',
  },
  EnquiryForm: {
    component: EnquiryForm,
    validate: validateEnquiryForm,
    process: processEnquiryFormResponse,
    label: 'Ask Question',
  },
  CommercialSellAppraisalForm: {
    component: CommercialSellAppraisalForm,
    validate: validateCommercialSellAppraisalForm,
    process: processCommercialSellAppraisalFormResponse,
    label: 'Appraisal',
  },
  CommercialLeaseAppraisalForm: {
    component: CommercialLeaseAppraisalForm,
    validate: validateCommercialLeaseAppraisalForm,
    process: processCommercialLeaseAppraisalFormResponse,
    label: 'Lease Appraisal',
  },
  NzRentalApplicationForm: {
    component: NzRentalApplicationForm,
    validate: validateNzRentalApplicationForm,
    process: processNzRentalApplicationFormResponse,
    label: 'Rental Application',
  },
}
