import React from 'react'
import PropTypes from 'prop-types'

import {
  agentUrl,
  getMemberPhones,
} from '@raywhite/data-utils/lib/data/member/agent'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { getAgentLicenceCode } from '@raywhite/helpers-utils/lib/helpers/rules'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

import BusinessCard from '../BusinessCard/BusinessCard.jsx'
import CardGrid from '../CardGrid/CardGrid.jsx'
import EnquiryForm from '../../container/EnquiryForm.jsx'
import HeadingGroup from '../HeadingGroup/HeadingGroup.jsx'
import Modal from '../Modal.jsx'
import ModalInner from '../ModalInner.jsx'

import withContext from '../../hocs/withContext'

const INITIAL_STATE = {
  selectedMember: {},
  showModal: false,
}

const RESIZE_PARAMS = {
  height: 624,
  width: 624,
  scale: 'both',
}

const enquiryMeta = eventMeta({
  category: 'Ask Question',
  action: 'Open',
})

const getLicenceCode = (address, licenceCode, showLicenceCode) => {
  const { countryCode, stateCode } = address

  if (!showLicenceCode || licenceCode) return undefined

  return getAgentLicenceCode(countryCode, stateCode, licenceCode)
}

const getMemberImageData = imageUrl => (
  {
    data: {
      height: RESIZE_PARAMS.height,
      width: RESIZE_PARAMS.width,
      url: imageUrl,
    },
    attrs: RESIZE_PARAMS,
  }
)

const getPhoneNumber = (member, organisationIds, parentOrganisationId, phones) => {
  const { mobilePhone, officePhone, promoPhone } = {
    ...member,
    ...getMemberPhones(organisationIds, phones, [parentOrganisationId])
  }

  if (promoPhone) return promoPhone
  if (mobilePhone) return mobilePhone
  return officePhone
}

class MembersGridWithHeader extends React.Component {
  static propTypes = {
    address: PropTypes.object,
    fetch: PropTypes.func.isRequired,
    heading: PropTypes.string,
    licenceCode: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
    organisationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    parentOrganisationId: PropTypes.number,
    showLicenceCode: PropTypes.bool,
    _window: PropTypes.object,
  }

  state = INITIAL_STATE

  handleCloseModal = () => {
    this.setState(INITIAL_STATE)
  }

  handleShowModal = member => {
    this.setState({ selectedMember: member, showModal: true })
  }

  handleSubmitEnquiryForm = data => {
    const {
      props: { fetch, name, _window },
      state: { selectedMember },
    } = this

    return submitForm(
      fetch,
      _window && _window.dataLayer,
      'Ask a question',
      `/api/contact/agent/${selectedMember.id}`,
      data,
      'Unable to send enquiry.',
      name,
    )
  }

  render() {
    const {
      props: {
        address,
        heading,
        licenceCode,
        members = [],
        organisationIds,
        parentOrganisationId,
        showLicenceCode,
      },
      state: {
        selectedMember: { alternateName, fullName },
        showModal
      },
    } = this

    return (
      <section className="MembersGridWithHeader">
        <HeadingGroup
          heading={heading}
          headingLevel="h2"
        />
        <CardGrid>
          {members.map((member) => (
            <BusinessCard
              key={member.id}
              alternateName={member.alternateName}
              email={member.email}
              enquiryMeta={enquiryMeta}
              image={getMemberImageData(member.imageHeadshot)}
              isLink={member.organisationIds.includes(parentOrganisationId)}
              licenceCode={getLicenceCode(address, licenceCode, showLicenceCode)}
              name={member.fullName}
              onClickEmail={() => this.handleShowModal(member)}
              phone={getPhoneNumber(
                member,
                organisationIds,
                parentOrganisationId,
                member.phones,
              )}
              profileUrl={agentUrl(member)}
              showContacts
            />
          ))}
        </CardGrid>
        {showModal && (
          <Modal handleClose={this.handleCloseModal}>
            <ModalInner
              className="modal_form"
              subtitle={fullName || alternateName}
              title="Get in touch"
            >
              <EnquiryForm
                handleClose={this.handleCloseModal}
                sendForm={this.handleSubmitEnquiryForm}
                submittedMessage="Thanks, we'll be in touch!"
              />
            </ModalInner>
          </Modal>
        )}
      </section>
    )
  }
}

export default withContext('fetch', '_window')(MembersGridWithHeader)
