import stdurl from 'url'

export const parse = stdurl.parse

export function format(parts, options) {
  const params = { ...parts }

  // host beats out hostname in formatting previously parsed urls
  if (params.hostname) delete params.host
  // Ensure that standard ports are ignored
  if (params.port && [80, 443].indexOf(params.port) !== -1) delete params.port

  return stdurl.format(params, options)
}
