// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { Link as InternalLink } from 'react-router'

import type { Node } from 'react'

type Props = {
  to?: string,
  children: ?Node,
};

/**
 * A wrapper for the react-router link that handles external links
 * as well.  External links are always rendered with a blank target.
 */
const Link = ({ to = '/', children, ...rest }: Props) => (
  to[0] === '/'
    ? <InternalLink to={to} {...rest}>{children}</InternalLink>
    : <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>{children}</a>
)
Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Link
