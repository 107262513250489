import React, { Component } from 'react'
import PropTypes from 'prop-types'
import useScroll from 'react-router-scroll/lib/useScroll'
import {
  Router,
  browserHistory,
  applyRouterMiddleware,
} from 'react-router'
import AppContextProvider from './container/AppContextProvider.jsx'

const agentRe = /^\/agents\/[\w-]+\/\d+/
/**
* Check whether or not the route transition that is occuring is one that should
* trigger a reset of the scroll position (this is the case for almost all routes)
* so a `blacklist` of transitions that shouldn't trigger a scroll is an effective
* strategy.
*
* @param {Object} the prvious router props
* @param {Object} the new router props
* @return {Boolean} whether or not to restore scroll
*/
const shouldScrollUpdate = (prevRouterProps, { location: { pathname } }) => {
  if (prevRouterProps) {
    const {
      location: {
        pathname: _pathname,
      },
    } = prevRouterProps

    // Path hasn't changed, don't scroll (query vars may have)
    if (_pathname === pathname) {
      return false
    }

    // Agent page.
    if (agentRe.test(_pathname) && agentRe.test(pathname)) return false
  }

  return true
}

export default class Site extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    unifiedClient: PropTypes.object.isRequired,
    _window: PropTypes.object,
    routes: PropTypes.any,
  }

  render() {
    const { store, unifiedClient, _window, routes } = this.props

    return (
      <AppContextProvider {...{ store, ...unifiedClient, _window }}>
        <Router
          history={browserHistory}
          render={applyRouterMiddleware(useScroll(shouldScrollUpdate))}
        >
          {routes}
        </Router>
      </AppContextProvider>
    )
  }
}
