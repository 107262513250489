/**
 * Any actions dealing with fetching data for / updating the 'brokers'
 * key in state, and their associated reducers, should be handled
 * in this file.
 */
import { missingKeys } from '@raywhite/helpers-utils/lib/helpers/misc'
import { makeMappedReducer, DEFAULT_LOADABLE } from '../utils'

const LOADING_BROKERS = 'RW_OFFICE/BROKERS/LOADING_BROKERS'
const LOADED_BROKERS = 'RW_OFFICE/BROKERS/LOADED_BROKERS'

const DEFAULT_BROKER = DEFAULT_LOADABLE

function reduceLoadingBrokers(state, action) {
  const loading = action.brokerIds.reduce((result, brokerId) => {
    // eslint-disable-next-line no-param-reassign
    result[brokerId] = result[brokerId] || {
      ...DEFAULT_BROKER,
      loading: true,
      brokerId,
    }
    return result
  }, {})

  return { ...state, ...loading }
}

function reduceLoadedBrokers(state, action) {
  const loaded = action.brokerIds.reduce((result, brokerId) => {
    const broker = action.brokers[brokerId] || {
      ...DEFAULT_BROKER,
      // Note: notFound if there was no error, otherwise we consider it an error
      notFound: !action.error,
    }
    result[brokerId] = { // eslint-disable-line no-param-reassign
      ...broker,
      loaded: true,
      loading: false,
      error: action.error,
    }
    return result
  }, {})

  return { ...state, ...loaded }
}

const reducer = makeMappedReducer({
  [LOADING_BROKERS]: reduceLoadingBrokers,
  [LOADED_BROKERS]: reduceLoadedBrokers,
}, {})
export default reducer

/**
 * The only way that brokers data makes it into the state object
 * is through this syncronous action creator
 *
 * @param {Object} brokers - normalised brokers to add to state
 */
function loadingBrokers(brokerIds) {
  return { type: LOADING_BROKERS, brokerIds }
}

function loadedBrokers(brokerIds, brokers, { error = false } = {}) {
  return { type: LOADED_BROKERS, brokerIds, brokers, error }
}

export function getBroker(state, brokerId) {
  return state[brokerId] || DEFAULT_BROKER
}

export function getBrokers(state, brokerIds = []) {
  const brokers = brokerIds.reduce((result, id) => {
    result.push(state[id] || { // eslint-disable-line no-param-reassign
      ...DEFAULT_BROKER,
      brokerId: id,
    })
    return result
  }, [])

  const loaded = brokers.reduce((result, broker) => (
    result && broker.loaded
  ), true)

  const loading = brokers.reduce((result, broker) => (
    result && broker.loading
  ), false)

  const error = brokers.reduce((result, broker) => (
    result || broker.error
  ), false)

  return {
    loaded,
    loading,
    error,
    entities: brokers,
  }
}

export function loadBrokers(api, countryCode, brokerIds = []) {
  if (!Array.isArray(brokerIds)) {
    throw new Error('brokerIds must be an Array.')
  }

  /**
   * @param {Function} store.dispatch.
   * @param {Function} store.getState.
   *
   * @return {Promise} should be used for error handling / compostion
   */
  return function dispatchLoadBrokers(dispatch, getState) {
    const { brokers } = getState()
    const missingIds = missingKeys(brokerIds, brokers)

    if (!missingIds.length) {
      // No brokers to load
      return Promise.resolve(getBrokers(getState().brokers, brokerIds))
    }

    dispatch(loadingBrokers(missingIds))

    // api = getBrokers from src/lmClient
    return api({ countryCode, ids: missingIds })
      .then(data => {
        const mapped = data
          .reduce((results, broker) => {
            results[broker.brokerId] = broker // eslint-disable-line no-param-reassign
            return results
          }, {})

        return dispatch(loadedBrokers(missingIds, mapped))
      })
      .catch(error => dispatch(loadedBrokers(missingIds, {}, { error })))
      .then(() => getBrokers(getState().brokers, brokerIds))
  }
}
