import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import orgs from '@raywhite/redux/lib/org'
import members from '@raywhite/redux/lib/member'
import listings from '@raywhite/redux/lib/listing'
import meta from '@raywhite/redux/lib/meta'
import suburbs from '@raywhite/redux/lib/suburb'
import config from './config'
import content from './content'
import menu from './menu'
import brokers from './brokers'
import user from './user'
import app from './app'
import search from './search'

const reducer = combineReducers({
  config,
  orgs,
  members,
  suburbs,
  listings,
  content,
  menu,
  meta,
  brokers,
  form,
  user,
  app,
  search,
})
export default reducer
