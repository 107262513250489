import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import memoize from 'memoizee'
import { compose } from 'redux'
import { createTime, createTimeRange } from '@raywhite/helpers-utils/lib/helpers/time'
import { searchResultsPage as searchResultsPageSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import { getHeaderImage } from '../../utils/data/listing/listings'
import Loader from '../presentational/Loader.jsx'
import withSearch from '../hocs/withSearch'
import ListingCardList from '../presentational/ListingCardList.jsx'
import { badgeFieldOptions } from '../presentational/ListingCard.jsx'
import OpenGraph from '../presentational/OpenGraph.jsx'
import TwitterSummaryCard from '../presentational/TwitterSummaryCard.jsx'
import JsonLD from '../presentational/JsonLD.jsx'

/**
 * Takes a time string returned from the listings API and normalizes
 * it so that all times on the same day are identical. Used to sort
 * events into buckets based on the date they occur.
 *
 * @param {String} the original time string
 *
 * @return {String} the normalized time string
 */
function normalizeDate(str) {
  return `${str.split('T')[0]}T00:00:00`
}

const formatRange = (start, finish) => {
  const {
    start: {
      time,
      meridiem,
    },
    end: {
      time: _time,
      meridiem: _meridiem,
    },
  } = createTimeRange(start, finish)
  return `${time}${meridiem} - ${_time}${_meridiem}`
}

const resortListings = listings => (
  listings
    .sort(([la, ta], [lb, tb]) => (
      ta === tb
        ? (la.id - lb.id)
        : ta - tb
    ))
    .map(([l]) => l)
)

const formatTime = (start) => {
  const { time, meridiem } = createTime(start)
  return `${time}${meridiem}`
}

const formatAuctionTime = () => ({ auction: { date } }) => formatTime(date)
const formatInspectionTime = date => ({ id, inspections = [] }, context) => {
  const sliced = date.slice(0, 10)
  const { previousListItems = [] } = context
  const today = inspections.filter(i => sliced === i.start.slice(0, 10))
  const occurrences = previousListItems.filter(l => l.id === id)

  // No inspections on this date
  if (!today.length) return undefined

  // Pick out the next inspection, if any have already been shown for this listing
  const inspection = today[occurrences.length]
  return inspection ? formatRange(inspection.start, inspection.finish) : undefined
}

/**
 * Accepts the return value from `parseEventDates()` and constructs
 * an object that can be used to render each days events and links
 * to them if they are within the next week. It is composed into
 * `parseDates()` which is passed as a prop to the `<Dates>` component.
 *
 * @param {Object} obj - the parsed inspection dates
 *
 * @returns {Array} contains an array of each day in the next week with inspections.
 */
function parseDateKeyedObject(obj) {
  const dates = []
  Object.keys(obj).sort().forEach(date => {
    const { day, date: _date, month, year } = createTime(date)
    const listings = obj[date]
    const dateString = `${day} ${_date} ${month}, ${year}`

    dates.push({
      date,
      listings,
      dateString,
    })
  })
  return dates
}

/**
 * Takes an array of listings containing inspection dates and returns
 * and object whose keys are dates and values are arrays of listings
 * with ispections on the corresponding day.
 *
 * @param {Array} listings
 *
 * @return {Object} dates object
 */
/* eslint-disable func-names, no-param-reassign */
function parseInspectionDates(listings, eventsSince) {
  return listings.reduce((previous, current) => {
    const { inspections } = current
    inspections.forEach(inspection => {
      const { start } = inspection
      const date = normalizeDate(start)
      if (date < eventsSince) return
      const dateKey = previous[date] = previous[date] || []
      if (dateKey.indexOf(current) === -1) dateKey.push([current, new Date(start).getTime()])
    })
    return previous
  }, {})
}

/**
 * Takes an array of listings containing auction dates and returns
 * and object whose keys are dates and values are arrays of listings
 * with auctions on the corresponding day.
 *
 * @param {Array} listings
 *
 * @return {Object} dates object
 */
function parseAuctionDates(listings, eventsSince) {
  return listings.reduce((previous, current) => {
    const date = normalizeDate(current.auction.date)
    if (date < eventsSince) return previous
    const dateKey = previous[date] = previous[date] || []
    dateKey.push([current, new Date(current.auction.date).getTime()])
    return previous
  }, {})
}
/* eslint-enable func-names, no-param-reassign */

// Merge back date split listings into one set
const dateListings = dates => dates.reduce(
  (result, date) => result.concat(date.listings.map(([l]) => l)),
  []
)

export class Dates extends Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    eventsSince: PropTypes.string.isRequired,
    listings: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    parseDates: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    badgeField: PropTypes.oneOfType([
      PropTypes.oneOf(badgeFieldOptions),
      PropTypes.arrayOf(PropTypes.oneOf(badgeFieldOptions)),
    ]),
    badgeFormatter: PropTypes.func,
    superScript: PropTypes.string,
    headerImage: PropTypes.string,
    resourceUrl: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    primaryOffice: PropTypes.object.isRequired,
  }

  static defaultProps = {
    superScript: "What's On",
  }

  shouldComponentUpdate(props) {
    if (
      this.props.loaded !== props.loaded
      || this.props.heading !== props.heading
      || this.props.listings.length !== props.listings.length
    ) return true

    // Rerender if any listings have changed
    for (let i = 0; i < props.listings.length; i++) {
      if (this.props.listings[0] !== props.listings[0]) return true
    }
    return false
  }

  render() {
    const {
      eventsSince,
      listings: _listings,
      loading,
      loaded,
      parseDates,
      heading,
      superScript,
      badgeField,
      badgeFormatter: formatter,
      headerImage,
      resourceUrl,
      baseUrl,
      location,
      primaryOffice,
    } = this.props

    if (loading || !loaded) return <Loader />

    const dates = parseDates(_listings, eventsSince)
    const image = getHeaderImage(
      headerImage,
      { loaded, items: dateListings(dates) },
      resourceUrl,
    )

    const listingCount = new Set(
      dates
        .map(date => date.listings)
        .flat(2)
        .map(listing => listing.id)
    ).size

    const metaTitle = heading
    const metaDescription = [
      listingCount,
      heading.toLowerCase(),
    ].join(' ').replace(/s$/, listingCount === 1 ? '' : 's')
    const structuredData = searchResultsPageSchema({
      name: metaTitle,
      description: metaDescription,
      url: `${baseUrl}${location.pathname}${location.search}`,
      image: image ? `${image}?width=1280` : undefined,
    })

    return (
      <div className="pg_upcomingevent">
        <Helmet>
          <title>{heading}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <TwitterSummaryCard
          title={metaTitle}
          site={primaryOffice.twitter}
          description={metaDescription}
          image={structuredData.image}
        />
        <JsonLD>{structuredData}</JsonLD>
        <OpenGraph
          type="article"
          title={structuredData.name}
          description={structuredData.description}
          image={structuredData.image && {
            url: structuredData.image,
            resizable: false,
            height: 1200,
            width: 630,
          }}
          url={structuredData.url}
        />
        <div
          className="listings_header"
          style={{ backgroundImage: `url(${image}?width=1280)` }}
        >
          <div className="tbl centered_text">
            <div className="listings_header_content tbc middle">
              <div className="inner">
                <span className="mini">{superScript}</span>
                <h1>{heading}</h1>
              </div>
            </div>
          </div>
        </div>
        {dates.length ? dates.map(({ date, listings, dateString }) => (
          <div
            className="upcomingevent_date_block"
            key={dateString}
          >
            <div className="upcomingevent_date_wrap centered_text">
              <div>
                <span
                  className="delta upcomingevent_date"
                >
                  {dateString}
                </span>
              </div>
            </div>
            <div className="inner_lg">
              <ListingCardList
                loaded
                listings={resortListings(listings)}
                className="upcomingevent_list_wrap proplist"
                cardOptions={{
                  badgeField,
                  badgeFormatter: formatter ? formatter(date) : undefined,
                }}
              />
            </div>
          </div>
        )) : (
          <ListingCardList
            loaded
            listings={[]}
            className="upcomingevent_list_wrap proplist"
          />
        )}
      </div>
    )
  }
}

// Get a dates component without building a new one for each unique search
const getDates = memoize(search => withSearch(search, 1)(Dates), {
  max: 10,
  normalizer: args => JSON.stringify(args[0]),
})

const getInspectionDates = compose(parseDateKeyedObject, parseInspectionDates)
const getAuctionDates = compose(parseDateKeyedObject, parseAuctionDates)

export default class EventsPage extends Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    eventsSince: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    badgeField: PropTypes.oneOfType([
      PropTypes.oneOf(badgeFieldOptions),
      PropTypes.arrayOf(PropTypes.oneOf(badgeFieldOptions)),
    ]),
    badgeFormatter: PropTypes.func,
    headerImage: PropTypes.string,
    heading: PropTypes.string.isRequired,
    superScript: PropTypes.string,
    search: PropTypes.object.isRequired,
    resourceUrl: PropTypes.string.isRequired,
    baseUrl: PropTypes.string,
    location: PropTypes.object.isRequired,
    primaryOffice: PropTypes.object,
  }

  render() {
    const {
      eventsSince,
      eventType,
      search,
      badgeField,
      headerImage,
      heading,
      superScript,
      resourceUrl,
      baseUrl,
      location,
      primaryOffice,
    } = this.props

    const EventDates = getDates(search)
    const parseDates = eventType === 'inspectionDate'
      ? getInspectionDates
      : getAuctionDates
    const badgeFormatter = eventType === 'inspectionDate'
      ? formatInspectionTime
      : formatAuctionTime

    return (
      <EventDates
        eventsSince={eventsSince}
        parseDates={parseDates}
        heading={heading}
        superScript={superScript}
        badgeField={badgeField}
        badgeFormatter={badgeFormatter}
        headerImage={headerImage}
        resourceUrl={resourceUrl}
        baseUrl={baseUrl}
        location={location}
        primaryOffice={primaryOffice}
      />
    )
  }
}
