import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button.jsx'
import CardGrid from '../CardGrid/CardGrid.jsx'
import Testimonial from '../Testimonial/Testimonial.jsx'

const INCREMENT = 4
const INITIAL_TESTIMONIALS_VISIBLE = 4
const TRUNCATE_LIMIT = 50

const INITIAL_STATE = {
  testimonialsVisible: INITIAL_TESTIMONIALS_VISIBLE,
}

class Testimonials extends React.Component {
  static propTypes = {
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        company: PropTypes.string,
        id: PropTypes.number,
        detail: PropTypes.string,
        person: PropTypes.string,
        position: PropTypes.string,
      }),
    )
  }

  state = INITIAL_STATE

  getTestimonials() {
    const { props: { testimonials }, state: { testimonialsVisible } } = this

    return testimonials.slice(0, testimonialsVisible)
  }

  getTruncatedTestimonial(testimonial) {
    if (!testimonial || testimonial.length < TRUNCATE_LIMIT) {
      return { isTruncated: false, testimonial }
    }

    const truncatedTestimonial = testimonial
      .split(' ')
      .slice(0, TRUNCATE_LIMIT)
      .join(' ')
      .concat('...')

    return { isTruncated: true, testimonial: truncatedTestimonial }
  }

  handleOnClick = () => {
    const { props: { testimonials }, state: { testimonialsVisible } } = this

    if (testimonials.length <= testimonialsVisible) {
      return this.setState(INITIAL_STATE)
    }

    return this.setState(state => ({ testimonialsVisible: state.testimonialsVisible + INCREMENT }))
  }

  render() {
    const {
      props: { testimonials },
      state: { testimonialsVisible }
    } = this

    const _testimonials = this.getTestimonials()
    const isButtonVisible = testimonials.length > INITIAL_TESTIMONIALS_VISIBLE
      || testimonials.length > INCREMENT
    const moreTestimonials = testimonialsVisible < testimonials.length

    return (
      <section className="Testimonials">
        <header className="Testimonials__header inner_lg centered_text">
          <h2 className="Testimonials__heading">Testimonials</h2>
        </header>
        <CardGrid>
          {testimonials && _testimonials
            .map((testimonial) => (
              <Testimonial
                key={testimonial.id}
                testimonial={testimonial}
                truncatedTestimonial={this.getTruncatedTestimonial(testimonial.detail)}
              />
            ))}
        </CardGrid>
        {isButtonVisible && (
          <Button
            className="Testimonials__button"
            onClick={this.handleOnClick}
            theme="dark"
            type="button"
          >
            {moreTestimonials ? 'Show More' : 'Show Less'}
          </Button>
        )}
      </section>
    )
  }
}

export default Testimonials
