import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { stringify } from 'query-string'
import memoize from 'memoizee'
import classNames from 'classnames'
import {
  getSearch,
  keystringify,
} from '@raywhite/redux/lib/listing'
import { loadFilteredSuburbs } from '@raywhite/redux/lib/meta'
import { getCategories } from '@raywhite/data-utils/lib/data/meta/categories'
import { mapMetaTypeCodes } from '@raywhite/data-utils/lib/data/meta/helpers'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { searchResultsPage as searchResultsPageSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import { prepareQuery as _prepareQuery, listingsToPoints } from '@raywhite/data-utils/lib/data/listing/listings'
import {
  describeType,
  describeStatus,
  describeTypeStatus,
  describeSubTypeStatus,
  buildTypeDescription,
} from '@raywhite/data-utils/lib/data/listing/codes'
import {
  shouldShowMap,
  clusterPoints,
} from '@raywhite/media-utils/lib/media/geo'

import JsonLD from '../presentational/JsonLD.jsx'
import OpenGraph from '../presentational/OpenGraph.jsx'
import TwitterSummaryCard from '../presentational/TwitterSummaryCard.jsx'
import SearchMap from '../presentational/Mapbox/SearchMap.jsx'
import { defaultHeaderImage, getHeaderImage } from '../../utils/data/listing/listings'
import { fetchListings } from '../../redux/modules/listings'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import LoadMoreListingList from '../presentational/LoadMoreListingList.jsx'
import FilterSlideInModal from '../presentational/FilterSlideInModal.jsx'
import FilterStatusRow from '../presentational/FilterStatusRow.jsx'
import withContext from '../hocs/withContext'
import withSiteMetadata from '../hocs/withSiteMetadata'
import { loadOfficeSiteMetadata } from '../../redux/modules/meta'
import {
  getApplicableElements,
  getForm as getSearchForm,
  processFilters as processSearchFilters,
  processQuery as processSearchQuery,
} from '../../utils/functionalities/search'
import { getFilters, updateFilters } from '../../redux/modules/search'
import ListingCard from '../presentational/ListingCard.jsx'

// Force "under offer" to be shown as the priority badge on listings; will
// obey other restrictions re: status and country as well, so fall back to
// the existing defaults in those cases
const RESULT_LIST_OPTIONS = {
  cardOptions: {
    ...ListingCard.defaultProps,
    badgeField: ['underOffer', ...ListingCard.defaultProps.badgeField],
    addressComponent: 'h2',
  },
}

// Minimum number of listings required before we show filters
const MIN_FILTERABLE_LISTINGS = 10

// Memoize query preparation, we do it a lot
const prepareQuery = memoize(
  _prepareQuery,
  {
    max: 2,
    normalizer: args => JSON.stringify(args[0]),
  },
)

const clusterMerge = (a, b) => { a.data.push(...b.data); return a }
export const preparePoints = memoize(
  points => clusterPoints(listingsToPoints(points), { merge: clusterMerge }),
  {
    normalizer: args => JSON.stringify(args[0]),
  }
)

const listPageSize = 50
const mapPageSize = 1000
const mapSearch = params => ({ ...params, size: mapPageSize })
const allSearch = ({ typeCode, subTypeCode, statusCode }) => {
  const params = { typeCode, subTypeCode, statusCode, size: 0 }
  if (!typeCode) delete params.typeCode
  if (!subTypeCode) delete params.subTypeCode
  if (!statusCode) delete params.statusCode
  return params
}

const recase = value => (
  value
    ?.toLowerCase()
    .replace(/ +/g, ' ')
    .replace(/\b[a-z]/g, letter => letter.toUpperCase())
)

const ucfirst = str => (str ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` : '')

const commaList = (list, join = ' and ') => {
  if (!list) return ''
  if (list.length < 3) return list.join(' and ')

  return [
    list.slice(0, list.length - 1).join(', '),
    list[list.length - 1],
  ].join(join)
}

const defaultSuburbs = (params, metadata, count = 1) => {
  if (!metadata?.loaded || !metadata?.typeKey) return ''

  const { statusCode, typeCode, subTypeCode } = params
  const typeMap = mapMetaTypeCodes(typeCode, subTypeCode)

  const merged = Object.keys(typeMap).reduce((res, code) => {
    const suburbs = metadata.typeKey[code]?.statusCode[statusCode]?.suburb
    if (!suburbs) return res

    return Object.entries(suburbs).reduce((acc, [name, data]) => {
      if (acc[name]) acc[name].total += data.total
      else acc[name] = { name, total: data.total }
      return acc
    }, res)
  }, {})

  const sorted = Object.values(merged).sort((a, b) => b.total - a.total)
  // Handle multiple top results
  const counts = new Set(sorted.slice(0, count).map(burb => burb.total))
  const top = sorted.filter(burb => counts.has(burb.total))
  const names = top.map(burb => recase(burb.name))

  return commaList([...names, 'nearby'])
}

const PLURALS = {
  Property: 'Properties',
  Retail: 'Retail properties',
}
const describePropertyTypes = (params, metadata) => {
  const {
    categoryCode = [],
    statusCode,
    subTypeCode,
    typeCode,
  } = params
  if (!categoryCode?.length || !metadata?.loaded) return undefined

  const categories = getCategories(metadata.typeKey, typeCode, subTypeCode, statusCode)

  const labels = categoryCode
    .map(code => categories.find(cat => cat.value === code)?.label)
    .map(label => (PLURALS[label] || `${label}s`.replace(/ss$/, 's')))
    .filter(Boolean)

  if (!labels.length) return undefined

  return ucfirst(commaList(labels))
}

const normPipeValue = val => (val?.split('|').sort().join('|') || '')

const canonicalUrl = (baseUrl, location) => {
  const {
    query: {
      category = '',
      suburbPostCode = '',
    } = {},
    pathname,
  } = location
  const query = stringify({
    category: normPipeValue(category),
    suburbPostCode: normPipeValue(suburbPostCode),
  })

  return `${baseUrl}${pathname}?${query}`
}

/**
 * Displays a set of listings with the ability to filter.
 *
 * Filters are based partly on the path (e.g. /properties/residential-for-sale)
 * and partly based on selected form filters (which are mapped on to the query
 * string in the URL so that the results are always linkable).
 *
 * The filters available are dependant on the detected type of listings from
 * the URL, e.g. sold listings allow sorting by sold date, while current listings
 * do not, residential listings allow filtering by the number of beds, commercial
 * listings do not, etc.
 */
export class ListingsFilter extends Component {
  static propTypes = {
    query: PropTypes.object.isRequired,
    listings: PropTypes.object.isRequired,
    mapListings: PropTypes.object.isRequired,
    getListings: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    hideFilters: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    filterInitiallyOpen: PropTypes.bool.isRequired,
    suburbs: PropTypes.array,
    categories: PropTypes.array,
    prices: PropTypes.array,
    getOrganisations: PropTypes.func.isRequired,
    primaryOffice: PropTypes.object.isRequired,
    organisationId: PropTypes.number.isRequired,
    typedMetadata: PropTypes.object,
    suburbParams: PropTypes.object.isRequired,
    headerImage: PropTypes.string,
    _window: PropTypes.object,
    siteMetadata: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    filterCount: PropTypes.number.isRequired,
    timeframe: PropTypes.string.isRequired,
    noPossibleResults: PropTypes.bool.isRequired,
    baseUrl: PropTypes.string.isRequired,
    resourceUrl: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    siteTitle: PropTypes.string,
    theme: PropTypes.string,
    applicableElements: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  static defaultProps = {
    filterInitiallyOpen: false,
  }

  static contextTypes = {
    store: PropTypes.object,
  }

  static fetchData(dispatch, params, settings) {
    const {
      getState,
      getListings,
      getOrganisations,
      getOrganisationsMetadata,
      organisationIds,
      primaryOrganisationId,
      location,
      theme,
    } = settings

    const query = prepareQuery(params)
    const form = getSearchForm(query.params, { theme })

    // Apply valid query filters to the state
    dispatch(updateFilters(form.key, {
      ...form.defaults,
      ...processSearchQuery(form, location.query),
    }))

    // Build search params from the URL + state filters
    const filters = getFilters(getState().search, form.key)
    const search = {
      ...query.params,
      ...processSearchFilters(form, filters),
      size: listPageSize,
      _formKey: undefined,
    }

    return unlazyAll([
      dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])),
      dispatch(loadOfficeSiteMetadata(getOrganisationsMetadata, organisationIds)),
      dispatch(fetchListings(getListings, search, 1)),
      dispatch(fetchListings(getListings, mapSearch(search), 1)),
      dispatch(fetchListings(getListings, allSearch(search), 1)),
    ])
  }

  constructor(props) {
    super(props)
    const isMobileView = props._window && props._window.innerWidth < 1081
    this.state = {
      filterActive: false,
      isListView: true,
      filterGroup: 'filter',
      isMobileView,
      renderMap: false,
    }
    this.state.renderMap = !this.state.filterActive && (
      !isMobileView || !this.state.isListView
    )
  }

  componentDidMount() {
    const {
      dispatch,
      getOrganisations,
      organisationId,
      params,
      location,
      _window,
      theme,
    } = this.props

    this.loadSuburbs()

    // Apply valid query filters to the state when first mounted, allows linking
    // to filtered searches within the app
    const query = prepareQuery(params)
    const form = getSearchForm(query.params, { _window, theme })
    dispatch(updateFilters(form.key, {
      ...form.defaults,
      ...processSearchQuery(form, location.query),
    }))

    dispatch(loadOrganisations(getOrganisations, [organisationId]))
    this.loadMap()
    this.maybeDisableDateFilter()

    this.maybeTrackEvent()
  }

  componentDidUpdate(prevProps) {
    const {
      filters: prevFilters,
      location: { query: prevQuery },
    } = prevProps
    const {
      form,
      dispatch,
      location: { pathname, query },
      filters,
      router,
    } = this.props

    // Apply the query to the filters if it has changed
    if (query !== prevQuery) {
      const merged = {
        ...filters,
        ...processSearchQuery(form, query),
      }

      if (stringify(merged) !== stringify(filters)) {
        dispatch(updateFilters(form.key, merged))
      }
    } else if (filters !== prevFilters) {
      // Apply the filters to the query if it has changed
      if (stringify(filters) !== stringify(query)) {
        router.replace({ pathname, query: filters })
      }
    }

    this.maybeTrackEvent()

    // Maybe disable date filter if timeframe has been removed
    if (prevProps.timeframe && !this.props.timeframe) this.maybeDisableDateFilter()
    this.loadSuburbs()
    this.loadMap()
  }

  componentWillUnmount() {
    const { _window } = this.props
    _window?.dataLayer?.push({ // eslint-disable-line no-unused-expressions
      searchFilters: undefined,
      searchResult: undefined,
    })
  }

  maybeTrackEvent() {
    const {
      _window,
      filters,
      listings,
    } = this.props

    if (listings.loaded && !listings.error && _window?.dataLayer) {
      // Push search metadata into the datalayer
      const lastResult = _window.dataLayer.findLast(dl => 'searchResult' in dl)
      const newLoad = JSON.stringify(lastResult?.searchFilters) !== JSON.stringify(filters)
      if (newLoad) {
        // eslint-disable-next-line no-unused-expressions
        _window?.dataLayer?.push({
          event: 'listingSearch',
          searchFilters: filters,
          searchResult: listings.items.map(listing => ({
            id: listing.id,
            address: listing.address,
            type: listing.type,
          })),
        })
      }
    }
  }

  maybeDisableDateFilter() {
    const {
      dispatch,
      form,
      hideFilters,
      timeframe,
    } = this.props

    if (
      form.dateFilter?.queryKey
      && (!timeframe || hideFilters)
    ) {
      dispatch(updateFilters(form.key, {
        [form.dateFilter.queryKey]: 'all',
      }))
    }
  }

  pushEvent = (_window, action) => {
    if (_window.dataLayer) {
      _window.dataLayer.push({
        event: 'rwCustom',
        rwCustomData: {
          category: 'Map',
          action: `${action} Marker`,
          label: undefined,
        },
      })
    }
  }

  loadSuburbs() {
    const {
      dispatch,
      query,
      suburbParams: { statusCode, typeCode } = {},
    } = this.props

    if (query.isValid) {
      dispatch(loadFilteredSuburbs(statusCode, typeCode))
    }
  }

  loadMap() {
    const { dispatch, query, getListings } = this.props

    // Don't search invalid queries
    if (!query.isValid) return

    // Don't search if the map and listings are not displayed
    if (this.state.filterActive) return

    dispatch(fetchListings(getListings, mapSearch(query.params), 1))
    dispatch(fetchListings(getListings, allSearch(query.params), 1))
  }

  setFilterSlideIn = el => {
    this.filterSlideIn = el
  }

  toggleFilter = () => {
    const filterActive = !this.state.filterActive
    this.setState(state => ({
      filterActive,
      renderMap: state.renderMap || (!filterActive && !state.isListView),
    }), () => {
      if (this.state.filterActive) {
        if (this.filterSlideIn) this.filterSlideIn.focus()
        const win = this.props._window
        if (!win) return
        const { documentElement: doc, body } = win.document
        const { scrollTop } = body
        doc.classList.add('showing_modal')
        this.restoreBodyScroll = function restoreBodyScroll() {
          doc.classList.remove('showing_modal')
          body.scrollTop = scrollTop
        }
      } else if (this.restoreBodyScroll) {
        this.restoreBodyScroll()
        delete this.restoreBodyScroll
      }
    })
  }

  resetFilters = () => {
    const win = this.props._window
    if (!win) return
    const { documentElement: doc } = win.document
    const { dispatch, form } = this.props

    // Sort order and timeframe should not be cleared
    const _defaults = { ...form.defaults }
    delete _defaults.sort
    delete _defaults.soldDate
    delete _defaults.updatedDate

    dispatch(updateFilters(form.key, _defaults))
    if (doc.classList.contains('showing_modal')) {
      this.toggleFilter()
    }
  }

  load = page => {
    const { query, getListings, dispatch } = this.props
    if (!query.isValid) return
    if (this.state.filterActive) return

    dispatch(fetchListings(getListings, query.params, page))
  }

  toggleResultsView = () => {
    const isListView = !this.state.isListView
    this.setState(state => ({
      isListView,
      renderMap: state.renderMap || (!isListView && !state.filterActive),
    }))
    document.body.classList.toggle('results_map_active')
  }

  handleKeyDown = e => {
    if (e.key === 'Escape') this.toggleFilter()
  }

  // Three-way toggle function for filter modal sections, also allows setting
  // none to active.  If activating a filter group it will also open the modal
  // if the modal is not currently open.
  setFilterGroup(filterGroup) {
    const { filterGroup: currentFilterGroup, filterActive } = this.state
    const isSame = filterGroup === currentFilterGroup

    if (filterActive) {
      // Toggle active filter group

      this.setState({ filterGroup: isSame ? undefined : filterGroup })
      return
    }

    if (!isSame) this.setState({ filterGroup })
    this.toggleFilter()
  }

  activateFilter = () => this.setFilterGroup('filter')

  activateSort = () => this.setFilterGroup('sort')

  activateTimeFrame = () => this.setFilterGroup('timeframe')

  render() {
    if (!this.props.query.isValid) {
      return <NotFoundPage />
    }

    const {
      query,
      query: { params: qp },
      listings,
      listings: { totalResults },
      siteMetadata,
      mapListings,
      filters,
      primaryOffice,
      location,
      primaryOffice: {
        address: officeAddress = {},
        fullName,
      },
      form,
      applicableElements,
      filterCount,
      timeframe,
      hideFilters,
      noPossibleResults,
      baseUrl,
      resourceUrl,
      siteTitle,
      _window,
    } = this.props

    const headerImage = getHeaderImage(this.props.headerImage, listings, resourceUrl)
    const miniDescription = describeStatus(qp.statusCode)
      || describeTypeStatus(qp.typeCode, qp.statusCode)
      || describeSubTypeStatus(
        qp.subTypeCode && (qp.subTypeCode.in || qp.subTypeCode),
        qp.statusCode,
        true
      )
      || ''
    const suburbs = (
      filters.suburbPostCode
        ?.replace(/\|/g, ', ')
        .replace(/,([^,]*)$/, ' and$1')
        .replace(/ +\d{4}/g, '')
    ) || defaultSuburbs(qp, siteMetadata)
    const propertyTypeDescription = describePropertyTypes(qp, siteMetadata)
    const typeDescription = (
      propertyTypeDescription
      || describeType(qp.typeCode, false)
      || 'Properties'
    )
    const metaTitle = [
      `${typeDescription} ${miniDescription?.toLowerCase() || ''}`.trim(),
      suburbs,
    ].filter(Boolean).join(' in ')
    const metaBBC = [
      parseInt(filters.minBeds, 10) && `${filters.minBeds} or more bedrooms`,
      parseInt(filters.minBaths, 10) && `${filters.minBaths} or more bathrooms`,
      parseInt(filters.minCars, 10) && `${filters.minCars} or more car spaces`,
    ].filter(x => x)
    const description = buildTypeDescription(
      siteTitle || fullName, totalResults, qp.statusCode, qp.typeCode,
      qp.subTypeCode, propertyTypeDescription?.toLowerCase(),
    )

    const metaDescription = ([ // eslint-disable-line prefer-template
      description,
      metaBBC.length && 'with',
      metaBBC.length === 1 && metaBBC[0],
      metaBBC.length > 1 && metaBBC.slice(0, -1).join(', '),
      metaBBC.length > 1 && `and ${metaBBC.slice(-1)[0]}`,
      suburbs && `in ${suburbs}`,
    ].filter(x => x).join(' ') + '.').replace(/^([a-z])/, first => first.toUpperCase())

    const showMap = !noPossibleResults && shouldShowMap(qp.typeCode)
    const { filter, bar, sort } = form
    const points = preparePoints(mapListings && mapListings.items)
    const structuredData = searchResultsPageSchema({
      name: metaTitle,
      description: metaDescription,
      url: `${baseUrl}${location.pathname}${location.search}`,
      image: headerImage ? `${headerImage}?width=1280` : undefined,
    })

    return (
      <article className="pg_listings">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href={canonicalUrl(baseUrl, location)} />
        </Helmet>
        <TwitterSummaryCard
          title={metaTitle}
          site={primaryOffice.twitter}
          description={metaDescription}
          image={structuredData.image}
        />
        <JsonLD>{structuredData}</JsonLD>
        <OpenGraph
          type="article"
          title={structuredData.name}
          description={structuredData.description}
          image={showMap && structuredData.image
            && { url: structuredData.image,
              resizable: false,
              height: 1200,
              width: 630,
            }}
          url={structuredData.url}
        />
        {!showMap && (
          <div
            className="listings_header"
            style={{ backgroundImage: headerImage && `url(${headerImage}?width=1280)` }}
          >
            <div className="tbl centered_text">
              <div className="listings_header_content tbc middle">
                <div className="inner">
                  <span className="mini">{miniDescription}</span>
                  <h1>{describeType(qp.typeCode, false) || 'Properties'}</h1>
                </div>
              </div>
            </div>
          </div>
        )}
        {!hideFilters && !showMap && !!filter.length && (
          <div className="listings_nomap_form">
            {filter.map(({ elements }) => ([
              ...elements
                .filter(({ key }) => applicableElements.indexOf(key) !== -1)
                .map(({
                  component: Input, key, label, options, defaultValue,
                }) => (
                  <div
                    className={`mini_map_filter_${key}`}
                    key={key}
                  >
                    <span>{label}</span>
                    <Input
                      options={options}
                      defaultValue={defaultValue}
                    />
                  </div>
                )),
            ]))}

          </div>
        )}
        {showMap && (
          <div
            className={classNames('listings_map_outer', { active: !this.state.isListView })}
          >
            <div className="listings_map_wrap">
              <SearchMap
                identifier="search"
                loaded={mapListings.loaded}
                points={points}
                center={[
                  officeAddress.longitude || 140.9131117,
                  officeAddress.latitude || -28.9524163,
                ]}
                zoom={12}
                _window={_window}
                active={!this.state.filterActive && (
                  !this.state.isMobileView || !this.state.isListView
                )}
                controlPadding={this.state.isMobileView ? 10 : 131}
                renderMap={this.state.renderMap}
                interactive
              />
              {!hideFilters && (
                <div className="show_charlie mini_map_filter_wrap">
                  <div className="mini_map_filter listings_map_overlay">
                    {bar
                      .filter(({ key }) => applicableElements.indexOf(key) !== -1)
                      .map(({ component: Input, key, label, options, defaultValue }) => (
                        <div
                          key={key}
                          className={`mini_map_filter_${key}`}
                        >
                          <span>
                            {label}
                            :
                          </span>
                          <Input
                            store={this.context.store}
                            prefix="bar_"
                            options={options}
                            defaultValue={defaultValue}
                          />
                        </div>
                      ))}
                    <div className="mini_map_filter_more">
                      <span
                        onClick={this.activateFilter}
                        data-ev-on="click"
                        data-ev-category="Filter Panel"
                        data-ev-action="Open Filter"
                      >
                        Edit Filters
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames('listing_map_results', { active: this.state.isListView })}
        >
          {!noPossibleResults
            && (
            <FilterStatusRow
              listings={listings}
              sort={sort}
              hideFilters={hideFilters}
              filterCount={filterCount}
              showMap={showMap}
              timeframe={timeframe}
              activateFilter={this.activateFilter}
              resetFilters={this.resetFilters}
              activateTimeFrame={this.activateTimeFrame}
            />
            )}
          <div className="inner_lg listings_page_proplist">
            <LoadMoreListingList
              key={keystringify(query.params)}
              listings={listings}
              loadMoreLabel="Load more properties"
              load={this.load}
              listOptions={RESULT_LIST_OPTIONS}
            />
          </div>
        </div>
        {!hideFilters
          && (
          <FilterSlideInModal
            className={classNames({ active: this.state.filterActive })}
            form={form}
            toggleFilter={this.toggleFilter}
            onKeyDown={this.handleKeyDown}
            ref={this.setFilterSlideIn}
            resetFilters={this.resetFilters}
            activateFilter={this.activateFilter}
            activateSort={this.activateSort}
            activateTimeFrame={this.activateTimeFrame}
            filterGroup={this.state.filterGroup}
            applicableElements={applicableElements}
          />
          )}
        <div className="mobile_listings_view_switcher hide_charlie">
          {showMap && (
            <span
              className={classNames('mobile_listings_view_switcher_button', this.state.isListView ? 'map' : 'list', 'micro')}
              onClick={this.toggleResultsView}
              data-ev-on="click"
              data-ev-category="Search Results"
              data-ev-action={`View ${this.state.isListView ? 'Map' : 'List'}`}
            >
              {this.state.isListView ? 'Map' : 'List'}
            </span>
          )}
          {!hideFilters && (
            <span
              className="mobile_listings_view_switcher_button filter micro"
              onClick={this.toggleFilter}
              data-ev-on="click"
              data-ev-category="Filter Panel"
              data-ev-action="Open Filter"
            >
              Filter
              {!!filterCount && (
                <span className="mobile_listings_view_switcher_filtercount">
                  {filterCount}
                </span>
              )}
            </span>
          )}
        </div>
      </article>
    )
  }
}

function mapStateToProps(state, props) {
  const { siteTitle } = state.config.options
  const _query = prepareQuery(props.params)
  const form = getSearchForm(_query.params, {
    _window: props._window,
    theme: state.config.theme,
  })
  const headerImages = state.config.options.search.headers
  const filters = {
    ...form.defaults,
    ...getFilters(state.search, form.key),
  }

  const query = {
    ..._query,
    params: {
      ..._query.params,
      ...processSearchFilters(form, filters),
      size: listPageSize,
      _formKey: undefined,
    },
  }
  const listings = getSearch(state.listings, query.params, -1)
  const mapListings = getSearch(state.listings, mapSearch(query.params), -1)
  const allListings = getSearch(state.listings, allSearch(query.params), -1)

  // Count filters that differ from their default value, excluding pairs
  const paired = { minFloor: 'maxFloor', minLand: 'maxLand' }
  const filterCount = Object
    .entries(filters)
    // 'sort', 'soldDate' or 'updatedDate' isn't a filter
    .filter(([name]) => name !== 'sort' && name !== 'soldDate' && name !== 'updatedDate')
    .reduce((sum, [name]) => {
      // If the pair was also modified, ignore it so that they count as one
      const pair = paired[name]
      if (pair !== undefined) {
        if (form.defaults[pair] !== filters[pair]) return sum
      }

      return sum + Number(form.defaults[name] !== filters[name])
    }, 0)

  const timeframeValue = form.dateFilter
    ? filters[form.dateFilter.queryKey]
    : undefined
  const timeframe = form.dateFilter
    ? form.dateFilter.options.filter(({ value }) => value === timeframeValue)[0].label.toLowerCase()
    : ''

  return {
    hideFilters: (
      allListings.loaded
      && allListings.totalResults < MIN_FILTERABLE_LISTINGS
      && listings.loaded
      && listings.totalResults >= allListings.totalResults
    ),
    noPossibleResults: allListings.loaded && !allListings.totalResults,
    siteTitle,
    form,
    applicableElements: getApplicableElements(
      form.elements.category
        ? form.elements.category.process(filters, {}).categoryCode
        : Object.keys(form.elements),
      form.elements
    ),
    filterCount,
    // No point forcing date filtering for small numbers of results
    timeframe: allListings.loaded && allListings.totalResults < 300
      ? ''
      : timeframe,
    query,
    listings,
    mapListings,
    filters,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    organisationId: state.config.primaryOrganisationId,
    suburbParams: _query.params,
    headerImage: defaultHeaderImage(headerImages, query.params.typeCode),
    baseUrl: state.config.baseUrl,
    resourceUrl: state.config.env.cdn || state.config.baseUrl,
    theme: state.config.theme,
  }
}

const ConnectedListingsFilter = compose(
  withSiteMetadata,
  withRouter,
  withContext('getListings', 'getOrganisations', '_window', 'getOrganisationsMetadata'),
  connect(mapStateToProps),
)(ListingsFilter)
ConnectedListingsFilter.displayName = 'ConnectedListingsFilter'
export default ConnectedListingsFilter
