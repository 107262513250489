import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import {
  getSearch,
} from '@raywhite/redux/lib/listing'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { processCategories } from '@raywhite/functionalities-utils/lib/functionalities/services'

import { fetchListings } from '../../redux/modules/listings'
import withSiteMetadata from '../hocs/withSiteMetadata'
import ServicePage, { typeShape } from '../presentational/ServicePage.jsx'
import Loader from '../presentational/Loader.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import withContext from '../hocs/withContext'
import { setShowingModal } from '../../redux/modules/app'
import {
  loadContentForType,
  getContentForType,
} from '../../redux/modules/content'
import { loadOfficeSiteMetadata } from '../../redux/modules/meta'

const contentParams = {
  orderby: 'menu_order',
  order: 'asc',
}

const formHeadings = {
  buy: 'Resources for Buyers',
  rent: 'Resources for Renters',
  sell: 'Resources for Sellers',
  'property-management': 'Resources for Investors',
}

const categoryTitles = {
  sell: "What We've Sold",
  'property-management': "What We've Leased",
}

/**
 * Determine the data required to display the service page.
 *
 * @param {Object} meta The site metadata loaded via unifiedClient.
 * @param {String} category [ buy | rent | sell | property-management ] The service
 *                 page category being shown.
 * @param {String} slug The type of data being shown within the service page, e.g.
 *                 residential, commercial, etc.
 * @param {Array[Number]} organisationIds The IDs of the organisations that the
 *                 site is for.
 *
 * @return {Object} An object with keys indicating the currently selected type,
 *                  a query to load listings for that type, and all of the available
 *                  types within the given category.
 */
const prepareLoadingData = (meta, category, slug, organisationIds) => {
  // Load appropriate types
  const types = meta ? processCategories(meta)[category] : []
  const type = types.reduce((result, data) => {
    if (result) return result
    return data.slug === slug ? data : undefined
  }, false) || types[0]

  const query = !type ? undefined : {
    typeCode: type.typeCode,
    statusCode: type.statusCode,
    subTypeCode: type.subTypeCode,
    sort: type.sort,
    organisationId: organisationIds,
    size: 500,
  }

  return { type, types, query }
}

/**
 * Container component for ServicePage; loads required data to display the page.
 */
class ServicePageContainer extends React.Component {
  static propTypes = {
    type: typeShape,
    types: PropTypes.arrayOf(typeShape).isRequired,
    path: PropTypes.string.isRequired,
    category: PropTypes.oneOf(['buy', 'rent', 'sell', 'property-management']),
    siteMetadata: PropTypes.object.isRequired,
    routeParams: PropTypes.shape({
      slug: PropTypes.string,
    }),
    routes: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string,
    })),
    query: PropTypes.object,
    getListings: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    listings: PropTypes.object,
    _window: PropTypes.object,
    getContentForType: PropTypes.func,
    content: PropTypes.object,
    baseUrl: PropTypes.string,
    resourceUrl: PropTypes.string,
    office: PropTypes.object,
    organisationId: PropTypes.number.isRequired,
    getOrganisations: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    showBuyRentEnquiryModal: PropTypes.func.isRequired,
    showMaintenanceModal: PropTypes.func.isRequired,
    showVacatingModal: PropTypes.func.isRequired,
    showAppraisalModal: PropTypes.func.isRequired,
    showNzRentalApplicationModal: PropTypes.func.isRequired,
    showEnquiryModal: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    siteTitle: PropTypes.string,
  }

  static fetchData(dispatch, { slug }, settings) {
    const {
      getListings,
      getOrganisations,
      primaryOrganisationId,
      organisationIds,
      getOrganisationsMetadata,
      location: { pathname },
      getContentForType: getContent,
    } = settings

    const [, category] = pathname.match(/^\/([a-z-]+)(?:$|\/)/) || []

    return dispatch(loadOfficeSiteMetadata(getOrganisationsMetadata, organisationIds))
      .then(meta => {
        const { query } = prepareLoadingData(
          meta.typeKey, category, slug, organisationIds
        )

        return unlazyAll([
          dispatch(fetchListings(getListings, query, 1)),
          dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])),
          dispatch(loadContentForType(getContent, category, 1, 20, contentParams)),
        ])
      })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate() {
    this.load()
  }

  load() {
    const {
      dispatch,
      query,
      getListings,
      getContentForType: getContent,
      category,
      getOrganisations,
      organisationId,
    } = this.props

    if (query) dispatch(fetchListings(getListings, query, 1))
    dispatch(loadContentForType(getContent, category, 1, 20, contentParams))
    dispatch(loadOrganisations(getOrganisations, [organisationId]))
  }

  render() {
    const {
      type,
      types,
      siteMetadata: { loaded },
      path,
      listings,
      content,
      _window,
      category,
      baseUrl,
      resourceUrl,
      office,
      router,
      siteTitle,
    } = this.props

    if (!loaded) {
      return <Loader />
    }

    if (!types.length) {
      return <NotFoundPage />
    }

    const _content = content
    if (content) {
      _content.entities = _content.entities.filter(item => item.content && item.content.rendered)
      _content.entities.total = _content.entities.lenth
    }

    const serviceForms = {
      buy: [{
        label: 'Enquire about Properties',
        onClick: this.props.showBuyRentEnquiryModal,
        className: 'servicepg_form_enquire',
      }],
      rent: [
        {
          label: 'Enquire about Rentals',
          onClick: this.props.showBuyRentEnquiryModal,
          className: 'servicepg_form_enquire',
        },
        {
          label: 'Request Maintenance',
          onClick: this.props.showMaintenanceModal,
          className: 'servicepg_form_maintenance',
        },
        {
          label: 'Notice to Vacate',
          onClick: this.props.showVacatingModal,
          className: 'servicepg_form_vacate',
        },
      ],
      sell: [{
        label: 'Request an Appraisal',
        onClick: this.props.showAppraisalModal,
        className: 'servicepg_form_appraisal',
      }],
      'property-management': [
        {
          label: 'Enquire about Properties',
          onClick: this.props.showBuyRentEnquiryModal,
          className: 'servicepg_form_enquire',
        },
        {
          label: 'Request an Appraisal',
          onClick: this.props.showAppraisalModal,
          className: 'servicepg_form_appraisal',
        },
      ],
    }
    if (office.countryCode === 'NZ') {
      serviceForms.rent.push({
        label: 'Rental Application',
        onClick: this.props.showNzRentalApplicationModal,
        className: 'servicepg_form_application',
      })
    }

    return (
      <ServicePage
        type={type}
        category={category}
        types={types}
        path={path}
        listings={listings}
        content={_content}
        _window={_window}
        title={categoryTitles[category]}
        baseUrl={baseUrl}
        resourceUrl={resourceUrl}
        office={office}
        formsHeading={formHeadings[category]}
        forms={serviceForms[category]}
        router={router}
        siteTitle={siteTitle}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const {
    siteMetadata: { loaded, typeKey: meta },
    category,
    routeParams: { slug },
    routes,
  } = props

  const { types, type, query } = prepareLoadingData(
    loaded && meta, category, slug, state.config.organisationIds
  )
  const listings = type ? getSearch(state.listings, query, 1) : undefined
  const content = getContentForType(state.content, category, 1, 20, contentParams)
  const { siteTitle } = state.config.options

  return {
    siteTitle,
    type,
    types,
    path: routes.slice(0, 2).map(({ path }) => path).join(''),
    listings,
    query,
    content,
    baseUrl: state.config.baseUrl,
    resourceUrl: state.config.env.cdn || state.config.baseUrl,
    office: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    organisationId: state.config.primaryOrganisationId,
    organisationIds: state.config.organisationIds,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    hideModal: () => dispatch(setShowingModal(undefined)),
    showBuyRentEnquiryModal: () => dispatch(setShowingModal('buyRentEnquiry')),
    showEnquiryModal: () => dispatch(setShowingModal('enquiry')),
    showAppraisalModal: () => dispatch(setShowingModal('appraisal')),
    showMaintenanceModal: () => dispatch(setShowingModal('maintenance')),
    showVacatingModal: () => dispatch(setShowingModal('vacating')),
    showNzRentalApplicationModal: () => dispatch(setShowingModal('nzRentalApplication')),
  }
}

export default compose(
  withSiteMetadata,
  withRouter,
  withContext(
    'getListings',
    '_window',
    'getContentForType',
    'getOrganisations',
    'getOrganisationsMetadata',
  ),
  connect(mapStateToProps, mapDispatchToProps),
)(ServicePageContainer)
