// From MDN
export function getJitter(min, max) {
  const _min = Math.ceil(min)
  const _max = Math.floor(max)
  return Math.floor(Math.random() * (_max - _min)) + _min
}

/**
 * Provides a method which wraps a handler funtion with a cached version
 * of itself, e.g.
 *
 * const listings = getListings(args);
 * // or
 * const cache = getMyCache(); // which implements .fetch(key, function)
 * const getCachedListings = cacheify(cache, getListings);
 * const listings = getCachedListings(args);
 *
 * Key generation is automatic, but a `keyifier` function may be provided
 * to generate different keys if required.
 *
 * @param {Object} cache The cache to use, must implement .fetch.
 * @param {Function} handler The method to use to load data if result is not cached.
 * @param {Options} options Options to be passed through to the cache, e.g.
 * @param {Number} options.expire The number of ms the result should be cached.
 * @param {Function or String} keyified Function to generate a key from the args
 *        if the default key generation is not appropriate. If a string is given
 *        it will replace the method name.
 */
export const cacheify = (cache, handler, options, keyifier) => (...args) => {
  const key = typeof keyifier === 'function'
    ? keyifier(args)
    : `${keyifier || handler.name}:${JSON.stringify(args)}`

  const fetcher = () => handler(...args)

  return cache.fetch(key, fetcher, options)
}
