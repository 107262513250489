import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { getListingUrl } from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyResizerParams } from '@raywhite/media-utils/lib/media/images'
import { mayShowStatusDescription } from '@raywhite/helpers-utils/lib/helpers/rules'

const historyListItem = (listing, i) => {
  const {
    address: {
      suburb,
    },
    status,
    statusCode,
    images = [],
  } = listing
  const url = getListingUrl(listing)

  const _img = images[0]
  const img = _img && (
    <img
      src={
        `${_img.url}?${stringifyResizerParams({
          width: 480,
          height: 420,
          mode: 'crop',
          anchor: 'middlecenter',
          quality: 60,
        })}`
      }
      alt="Property"
    />
  )

  return (
    <li key={i} className="grid_proplist_item_wrap">
      <div className="grid_proplist_item">
        <Link to={url} className="grid_proplist_item_link">
          <div className="grid_proplist_item_image">
            {img}
            {mayShowStatusDescription(statusCode) && (
              <span>{status}</span>
            )}
          </div>
          <div className="grid_proplist_item_header">
            <h2 className="charlie">
              {suburb}
            </h2>
          </div>
        </Link>
      </div>
    </li>
  )
}

export default class ListingGridList extends Component {
  render() {
    const { loaded, listings = [], error } = this.props

    if (loaded && !listings.length) {
      return (
        <div className="proplist_no_results centered_text">
          <div className="inner">
            {!!error ? (
              <em>Something went wrong loading listings :(</em>
            ) : (
              <em>No properties found.</em>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="agent_section_sold">
        <ul className="grid_proplist">
          {listings.map(historyListItem)}
        </ul>
        {this.props.children}
      </div>
    )
  }
}

ListingGridList.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.object).isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node,
}
ListingGridList.defaultProps = {
  error: false,
}
