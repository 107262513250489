const LABELS = {
  strata: 'Request a Strata Report',
  bpi: 'Request a Building & Pest Inspection Report',
  mixed: 'Request an Inspection Report',
}

const CONFIG = {
  NSW: {
    UNT: LABELS.strata,
    APT: LABELS.strata,
    BOU: LABELS.strata,
    STD: LABELS.strata,
    SAP: LABELS.strata,
    FLT: LABELS.strata,

    // Commercial
    OFF: LABELS.strata,
    MED: LABELS.strata,
  },

  HSE: LABELS.bpi,
  UNT: LABELS.bpi,
  APT: LABELS.bpi,
  THS: LABELS.mixed,
  OTH: LABELS.bpi,
  ASR: LABELS.bpi,
  VIL: LABELS.bpi,
  BOU: LABELS.bpi,
  STD: LABELS.bpi,
  SDC: LABELS.bpi,
  TCE: LABELS.bpi,
  WHS: LABELS.bpi,
  SAP: LABELS.bpi,
  FLT: LABELS.bpi,

  // Commercial
  OFF: LABELS.bpi,
  MED: LABELS.bpi,
}

const beforeYouBidLabel = (countryCode, stateCode, typeCode, categories) => {
  const [category] = categories
  const code = category?.code

  if (countryCode !== 'AU') return undefined
  if (!stateCode) return undefined

  if (!(typeCode === 'COM' || typeCode === 'SAL')) return undefined

  return CONFIG[stateCode]?.[code] || CONFIG[code]
}

const beforeYouBidLink = (member, listing, beforeYouBidId) => {
  if (!beforeYouBidId) return undefined
  if (!member) return undefined

  const formatted = listing.address.formatted.replace(/\//g, 'BYBFS').replace(/\s\s/g, ', ').replace(/\n/g, ', ')
  const agentEmail = member.email
  const agentNumber = (
    member.mobilePhoneLocal
    || Object.values(member?.phones || {}).find(ph => ph.mobilePhoneLocal)?.mobilePhoneLocal
  )
  if (!agentEmail || !agentNumber) return undefined

  const suffix = [
    'address-map-action',
    formatted,
    beforeYouBidId,
    member.fullName,
    agentNumber,
    agentEmail,
  ].map(encodeURI).join('/')
  return `https://beforeyoubid.com.au/${suffix}`
}

export const labelLink = (
  countryCode,
  stateCode,
  typeCode,
  categories,
  member,
  listing,
  bidId,
) => {
  // Short circuit calculations
  if (!bidId) return undefined

  const label = beforeYouBidLabel(countryCode, stateCode, typeCode, categories)
  const link = beforeYouBidLink(member, listing, bidId)

  if (!label || !link) return undefined

  return { label, link }
}
