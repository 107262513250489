import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { loadOrganisations } from '@raywhite/redux/lib/org'

import HomeLoans from '../presentational/HomeLoans.jsx'
import { getBrokers, loadBrokers } from '../../redux/modules/brokers'
import ErrorPage from '../presentational/ErrorPage.jsx'
import Loader from '../presentational/Loader.jsx'
import withContext from '../hocs/withContext'
import withOrgs from '../hocs/withOrgs'

class HomeLoansContainer extends React.Component {
  componentDidMount() {
    const { dispatch, brokers, countryCode = 'AU', getBrokers: api } = this.props
    const brokerIds = brokers.map(({ brokerId }) => brokerId)
    if (countryCode) {
      dispatch(loadBrokers(api, countryCode.toLowerCase(), brokerIds))
    }
  }

  shouldComponentUpdate({ loaded, error, countryCode }) {
    return loaded !== this.props.loaded
      || error !== this.props.error
      || countryCode !== this.props.countryCode
  }

  render() {
    const {
      loaded,
      loading,
      error,
      brokers,
      countryCode,
    } = this.props

    if (error) return <ErrorPage error={error} />

    if (!loaded || !countryCode || loading) return <Loader />


    return (
      <HomeLoans
        brokers={brokers}
        countryCode={countryCode}
      />
    )
  }
}

HomeLoansContainer.fetchData = (
  dispatch, params, {
    getBrokers: api,
    getOrganisations,
    primaryOrganisationId,
    options: {
      brokers: {
        ids: brokerIds = [],
      },
    },
  }
) => {
  if (!brokerIds.length) return undefined
  return dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId]))
    .then(orgs => {
      const org = orgs.entities[primaryOrganisationId]
      if (!org) return undefined
      return dispatch(loadBrokers(api, (org.countryCode || 'AU').toLowerCase(), brokerIds))
    })
}

HomeLoansContainer.propTypes = {
  brokers: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  dispatch: PropTypes.func.isRequired,
  getBrokers: PropTypes.func.isRequired,
  countryCode: PropTypes.string,
  primaryOrg: PropTypes.object,
}

const mapStateToProps = ({ brokers, config }, { primaryOrg }) => {
  const {
    loaded,
    loading,
    error,
    entities,
  } = getBrokers(brokers, config.options.brokers.ids)

  const countryCode = primaryOrg?.address?.countryCode

  return {
    loaded,
    loading,
    error,
    brokers: entities.filter(broker => !broker.notFound),
    countryCode,
  }
}

export default compose(
  withContext('getBrokers'),
  withOrgs,
  connect(mapStateToProps),
)(HomeLoansContainer)
