import React from 'react'
import PropTypes from 'prop-types'

import { ZoomControl as InnerZoomControl } from '.'

const ZoomControl = ({
  className = 'mb-control mb-control-zoom',
  ...rest
}) => (
  <InnerZoomControl {...{ className, ...rest }} />
)
ZoomControl.propTypes = {
  className: PropTypes.string,
}

export default ZoomControl
