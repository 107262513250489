const siteFocusConfig = {
  residential: {
    appraisalLabel: 'Book a Free Appraisal',
    currentSlug: 'for-sale',
    transactionTitle: 'Recently Sold',
    recentSlug: 'sold',
    recentDescription: 'sold properties',
    recentStatusCode: 'SLD',
    recentSort: 'soldDate desc',
    showInvestments: false,
    searchParams: {
      typeCode: ['BUS', 'COM', 'RUR', 'SAL', 'REN'],
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    headerTypeCode: ['SAL', 'RUR'],
    showAppraisalCTA: true,
  },
  sales: {
    appraisalLabel: 'Book a Free Appraisal',
    currentSlug: 'for-sale',
    transactionTitle: 'Recently Sold',
    recentSlug: 'sold',
    recentDescription: 'sold properties',
    recentStatusCode: 'SLD',
    recentSort: 'soldDate desc',
    showInvestments: false,
    searchParams: {
      typeCode: ['BUS', 'COM', 'RUR', 'SAL'],
      subTypeCode: { not: ['LSE'] },
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    headerTypeCode: ['SAL', 'RUR'],
    showAppraisalCTA: true,
  },
  propertyManagement: {
    appraisalLabel: 'Book a Free Rental Appraisal',
    currentSlug: 'for-rent',
    transactionTitle: 'Recently Leased',
    recentSlug: 'leased',
    recentDescription: 'leased properties',
    recentStatusCode: 'LSE',
    recentSort: ['updatedAt desc', 'id desc'],
    showInvestments: false,
    searchParams: {
      typeCode: ['REN'],
      subTypeCode: { not: ['LSE'] },
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    headerTypeCode: ['REN'],
    showAppraisalCTA: true,
  },
  rural: {
    appraisalLabel: 'Book a Free Appraisal',
    currentSlug: 'for-sale',
    transactionTitle: 'Recently Sold',
    recentSlug: 'sold',
    recentDescription: 'sold properties',
    recentStatusCode: 'SLD',
    recentSort: 'soldDate desc',
    showInvestments: false,
    searchParams: {
      typeCode: ['RUR'],
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    headerTypeCode: ['RUR'],
    showAppraisalCTA: true,
  },
  commercial: {
    currentSlug: 'commercial',
    transactionTitle: 'Recent Transactions',
    recentSlug: 'transactions',
    recentDescription: 'recent transactions',
    recentStatusCode: ['SLD', 'LSE'],
    recentSort: ['updatedAt desc', 'id desc'],
    showInvestments: true,
    searchParams: {
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    showAppraisalCTA: false,
  },
  commercialSales: {
    currentSlug: 'commercial-for-sale',
    transactionTitle: 'Recently Sold',
    recentSlug: 'sold',
    recentDescription: 'sold properties',
    recentStatusCode: 'SLD',
    recentSort: 'soldDate desc',
    showInvestments: true,
    searchParams: {
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
      subTypeCode: { not: ['LSE'] },
    },
    showAppraisalCTA: false,
  },
  commercialPropertyManagement: {
    currentSlug: 'commercial-for-lease',
    transactionTitle: 'Recently Leased',
    recentSlug: 'leased',
    recentDescription: 'leased properties',
    recentStatusCode: 'LSE',
    recentSort: ['updatedAt desc', 'id desc'],
    showInvestments: true,
    searchParams: {
      typeCode: ['COM', 'BUS'],
      subTypeCode: { not: ['SAL'] },
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    showAppraisalCTA: false,
  },
  business: {
    currentSlug: 'business',
    transactionTitle: 'Recent Transactions',
    recentSlug: 'transactions',
    recentDescription: 'recent transactions',
    recentStatusCode: ['SLD', 'LSE'],
    recentSort: ['updatedAt desc', 'id desc'],
    showInvestments: true,
    searchParams: {
      typeCode: ['BUS'],
      size: 5,
      sort: ['updatedAt desc', 'id desc'],
    },
    showAppraisalCTA: false,
  },
}

export default siteFocusConfig
