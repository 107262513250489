import React from 'react'
import PropTypes from 'prop-types'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

const formMeta = eventMeta({
  on: 'submit',
  category: 'Outbound',
  action: 'Submit',
  label: 'Apply (1Form)',
})

const logo = 'https://www.raywhite.com/resources/logos/rwlogo-grey-145.png'

// Permitted: Acreage/Semi-Rural,Block of Units,House,Townhouse,Unit/Apartment
const categoryMap = {
  Unit: 'Unit/Apartment',
  Apartment: 'Unit/Apartment',
  Other: 'House',
}

export default class OneFormLink extends React.Component {
  shouldComponentUpdate(props) {
    return (
      props.listing.id !== this.props.listing.id
      || props.listing.loaded !== this.props.listing.loaded
      || props.agentName !== this.props.agentName
      || props.agentEmail !== this.props.agentEmail
      || props.officeName !== this.props.officeName
    )
  }

  setForm = form => {
    this.form = form
  }

  submit = () => {
    if (this.form) this.form.submit()
  }

  render() {
    const {
      id,
      listing,
      listing: {
        images: [image],
        address,
        categories: [{ category }],
      },
      agentName,
      agentEmail,
      officeName,
      className,
      children,
      ...rest
    } = this.props

    if (!listing.loaded) {
      return null
    }

    return (
      <form
        action="https://www.1form.com/au/tenant/application/start/"
        method="post"
        target="_blank"
        rel="noopener noreferrer"
        ref={this.setForm}
        {...formMeta}
      >
        <input type="hidden" name="tagid" value={id} />
        <input type="hidden" name="papf_realestateco" value={officeName} />
        <input type="hidden" name="papf_realestateag" value={agentName} />
        <input type="hidden" name="papf_realestatem" value={agentEmail} />
        <input type="hidden" name="papf_logo" value={logo} />
        <input
          type="hidden"
          name="papf_propid"
          value={(
            listing.address.countryCode === 'AU' ? listing.id : listing.sourceId
          )}
        />
        <input type="hidden" name="papf_propadd" value={stringifyAddress(address)} />
        <input type="hidden" name="papf_propsub" value={address.suburb} />
        <input type="hidden" name="papf_proppc" value={address.postCode} />
        <input type="hidden" name="papf_propstat" value={address.stateCode || address.state} />
        <input type="hidden" name="papf_available" value={listing.availableDate} />
        <input
          type="hidden"
          name="papf_rent"
          value={/^\d+(\.\d+)?$/.test(listing.displayPrice) ? listing.displayPrice : ''}
        />
        <input type="hidden" name="papf_bond" value={listing.bond} />
        <input
          type="hidden"
          name="papf_proptype"
          value={category && (categoryMap[category] || category)}
        />
        <input type="hidden" name="papf_propnobed" value={listing.bedrooms} />
        <input type="hidden" name="papf_propnobath" value={listing.bathrooms} />
        <input type="hidden" name="papf_propnocar" value={listing.carSpaces} />
        <input type="hidden" name="papf_image" value={image && `${image.url}?maxwidth=1200`} />
        <span
          className={className}
          onClick={this.submit}
          role="button"
          tabIndex={0}
          {...rest}
        >
          {children}
        </span>
      </form>
    )
  }
}

OneFormLink.propTypes = {
  id: PropTypes.string.isRequired,
  listing: PropTypes.object.isRequired,
  officeName: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
  agentEmail: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

OneFormLink.defaultProps = {
  agentName: 'Property Manager',
  officeName: 'Ray White',
  agentEmail: '',
}
