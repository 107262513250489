// @flow

/**
 * Provides utilties for building search forms based on the status, type and
 * subtype of a search.
 *
 * The core functionality is in `getForm`, which builds up a form configuration
 * using the `forms` and `elements` data.  In building each form it looks up the
 * configuration for each element included in the form, applies any overrides
 * specified in the form config, then uses react-redux's `connect` to connect
 * each element to the appropriate bit of the redux store.
 *
 * Each element provides three core methods
 *  - `apply`, which is used as an `onChange` handler to dispatch an update to
 *    the filter values (see redux/modules/search's `updateFilters`).  It just
 *    returns an object which will be merged into the existing filters for the
 *    form that the element is included in.
 *  - `select`, which is used to extract the current value from the state.  It
 *    looks like a normal `mapStateToProps`, but the state that it gets passed
 *    is the state _for the specific form that it's in_; if more state is needed
 *    the complete state is passed as the third param and the search params as
 *    the fourth (see category, suburbPostCode, and the price inputs for examples
 *    of how this might be used to extract things like options from the state).
 *    Like `mapStateToProps` it just returns an object which will be applied as
 *    props to the underlying component.
 *  - `process`, which converts the filter value into a query object to use to
 *    find the listings from the API.  It gets passed the state (and the current
 *    query as the second arg, not normally needed but see minFloor et al for
 *    examples of when it might be needed) and should return an object which will
 *    then get merged back into the query object.  Return an empty object if the
 *    query does not need to be modified.
 */
import React from 'react'
import * as Joi from 'joi'
import { connect } from 'react-redux'
import { getFilteredSuburbs, getSiteMetadata } from '@raywhite/redux/lib/meta'
import { compressPrice } from '@raywhite/helpers-utils/lib/helpers/string'
import { formatNumber } from '@raywhite/data-utils/lib/data/listing/formatting'
import { getCategories } from '@raywhite/data-utils/lib/data/meta/categories'
import { invertSubTypes } from '@raywhite/data-utils/lib/data/meta/helpers'
import { getPrices } from '@raywhite/data-utils/lib/data/meta/prices'

import type { Dispatch } from 'redux'

import { getFilters, updateFilters } from '../../redux/modules/search'
import {
  FormSelect,
  FormRadio,
} from '../../components/presentational/formComponents'
import Clicker from '../../components/presentational/Clicker.jsx'
import PriceBar from '../../components/presentational/PriceBar.jsx'
import MultiSelect from '../../components/presentational/MultiSelect.jsx'
import FormInput from '../../components/presentational/FormInput.jsx'
import Loader from '../../components/presentational/Loader.jsx'
import { PRICE_INTERVAL, RENT_PRICE_INTERVAL } from '../../constants'

type Option = {
  label: string,
  value: string,
};
type OptionArray = Array<Option>;

export type ElementOptions = {
  // The component to connect() to the react state
  component: any, // react component
  // The label to show for the component
  label: string,
  // For multi option components, the default list of options; can override in
  // select below if dynamic
  options?: OptionArray,
  // Like mapStateToProps, selects the props from the form state to pass down
  // (filterState, props, fullState, searchParams) => { /* props to pass down */ }
  select: (Object, Object, Object, Object) => Object,
  // Used as an onChange handler for the component, maps the event value to a new
  // object which will be merged into the filter state
  apply: ({ target: { value: string } }) => Object,
  // Converts the current filter state into a listings API query
  // (filterState, currentQuery) => { /* object to merge into currentQuery */ }
  process: (Object, Object) => Object,
  // The default value for the filter
  defaultValue: string,
  // A validation rule for the data, used to discard invalid values server side
  rule: OptionArray => Object,
  // An optional key to store this value into the filters/URL query string, the
  // default is whatever key is used to store the element in the `elements` list
  // but this is useful if multiple elements represent the same data in different
  // ways
  queryKey?: string,
  // An optional function which will be called with the theme (if present) to allow
  // settings to be customised for specific themes
  themeify?: (ElementOptions, string) => ElementOptions,
  // An optional list of category codes for which this element should be ignored
  excludeForCategories?: Array<string>,
}
;type FilterGroup = {
  // Label to show when in the filter slide in
  label: string,
  // Names of the elements to use in the filter group, displayed in order
  elements: Array<string>,
};

type FormOptions = {
  // Names of the elements to use in the filter bar, displayed in order
  bar: Array<string>,
  // List of filter groups or elements to display in the filter; if an element
  // name it will be displayed in a filter group of its own
  filter: Array<FilterGroup | string>,
  // Custom options for any elements to override their defaults
  options?: { [string]: OptionArray },
  // CUstom labels for any elements to override defaults
  labels?: { [string]: string },
  // Custom defaultValues to override their defaults
  defaultValue?: { [string]: string },
  dateFilter?: string,
  themeable: boolean,
};

type FormConfig = {
  // The unique key for the form, generated from the query params, used to access
  // the appropriate bit of redux state for the filters
  key: string,
  // A list of all fields included in the form
  fields: Array<string>,
  // A keyed list of all elements included in the form
  elements: { [string]: ElementOptions },
  // A list of elements, in order, to display in the filter bar
  bar: Array<ElementOptions>,
  // A list of FilterGroups, in order, to display in the filter slide in
  filter: Array<FilterGroup>,
  // The sort element, always included for separate display
  sort: ElementOptions,
  // The default values for all elements included in the form
  defaults: { [string]: string },
  // The date filter field to use for timeline
  dateFilter?: string,
};

const DATE_FILTER_KEY = 'dateFilter'
const FIFTY_PER_WEEK = 2607
const valPlus = (x: string) => `${x}+`

const soldSortOptions = [
  { label: 'Sold Date', value: 'soldDate desc' },
  { label: 'Price: Lowest', value: 'price asc' },
  { label: 'Price: Highest', value: 'price desc' },
]

const defaultSoldConfig = {
  options: {
    sort: soldSortOptions,
    sortRadio: soldSortOptions,
  },
  defaultValue: {
    sort: 'soldDate desc',
    sortRadio: 'soldDate desc',
  },
  dateFilter: 'soldDate',
}

const landSelectOptions = [
  { label: 'None', value: '0' },
  { label: '5ha', value: '50000' },
  { label: '10ha', value: '100000' },
  { label: '25ha', value: '250000' },
  { label: '50ha', value: '500000' },
  { label: '100ha', value: '1000000' },
  { label: '200ha', value: '2000000' },
  { label: '400ha', value: '4000000' },
  { label: '600ha', value: '6000000' },
  { label: '800ha', value: '8000000' },
  { label: '1000ha', value: '10000000' },
  { label: '1500ha', value: '15000000' },
  { label: '2000ha', value: '20000000' },
  { label: '2500ha', value: '25000000' },
  { label: '3000ha', value: '30000000' },
  { label: '3500ha', value: '35000000' },
  { label: '4000ha', value: '40000000' },
  { label: '5000ha', value: '50000000' },
]
const minLandSelectOptions = [
  { label: 'No Min', value: '0' },
  ...landSelectOptions.slice(1),
]
const maxLandSelectOptions = [
  { label: 'No Max', value: '0' },
  ...landSelectOptions.slice(1),
]
const commercialFloorSizes = [
  { label: 'No Min', value: '0' },
  { label: '50m²', value: '50' },
  { label: '100m²', value: '100' },
  { label: '150m²', value: '150' },
  { label: '200m²', value: '200' },
  { label: '250m²', value: '250' },
  { label: '300m²', value: '300' },
  { label: '350m²', value: '350' },
  { label: '400m²', value: '400' },
  { label: '500m²', value: '500' },
  { label: '750m²', value: '750' },
  { label: '1,000m²', value: '1000' },
  { label: '1,500m²', value: '1500' },
  { label: '2,000m²', value: '2000' },
  { label: '3,000m²', value: '3000' },
  { label: '5,000m²', value: '5000' },
  { label: '10,000m²', value: '10000' },
  { label: '20,000m²', value: '20000' },
]

type SorterProps = {
  className: ?string,
  value: string,
  options: Array<Option>,
  onChange: Object => void,
};
// Component to show sort selection
const Sorter = ({ className, value: fvalue, options, onChange }: SorterProps) => (
  <div className={className}>
    {options.map(({ value, label }) => (
      <span
        key={value}
        className={value === fvalue ? 'active' : undefined}
        onClick={() => onChange({ target: { value } })}
        onKeyPress={() => onChange({ target: { value } })}
      >
        {label}
      </span>
    ))}
  </div>
)

// Rule for enforcing static options
const optval = opt => `${opt.value === undefined ? opt : opt.value}`
const onlyOptions = options => {
  const opts = options.map(optval)
  const rule = Joi.string().only(opts)
  return opts.indexOf('') !== -1
    ? rule.allow('')
    : rule
}

const formatWeeklyRent = rent => `$${formatNumber(Math.round(rent / 365 * 7 / 10) * 10)}/wk`
const formatAnnualRent = rent => `${compressPrice(rent)}/year`

const getSearchDate = () => {
  const today = new Date()
  today.setFullYear(today.getFullYear() - 1)

  return today.toISOString().slice(0, 10)
}


const bbcGroup = {
  label: 'Amenities',
  elements: ['minBedsClicker', 'minBathsClicker', 'minCarsClicker'],
}

const landGroup = {
  label: 'Land Size',
  elements: ['minLand', 'maxLand'],
}

const floorGroup = {
  label: 'Building Size',
  elements: ['minFloor', 'maxFloor'],
}

// Process a paired min/max value into a search value
const processMinMax = val => {
  const [_min, _max] = (val || '').split('-')
  const min = _min && +_min
  const max = _max && +_max
  if (!min && !max) return undefined
  const range = {}
  if (min) range.gte = min
  if (max) range.lte = max
  return range
}

// Apply a min/max pair value to a state value
const applyMinMax = e => {
  if (typeof e.target.value !== 'object') return ''
  const { min, max, rangeMin, rangeMax } = e.target.value
  const _min = min === rangeMin ? 0 : min
  const _max = max === rangeMax ? 0 : max

  return (_min || _max)
    ? `${_min}-${_max}`
    : ''
}

// Process sort settings to modify for commercial theme
const themeifySort = (settings, theme) => {
  if (theme !== 'commercial') return settings
  const { options, process, defaultValue } = settings
  return {
    ...settings,
    options: [{ label: 'Relevance', value: 'relevance' }, ...(options || [])],
    defaultValue: 'relevance',
    process: (state, query) => {
      const { sort } = state
      if (sort !== 'relevance') return process(state, query)
      return {
        sort: [defaultValue, 'id desc'],
        presort: ['elite'],
      }
    },
  }
}

// Conceal real min/max prices for commercial
const themeifyPrice = (settings, theme) => {
  if (theme !== 'commercial') return settings
  return {
    ...settings,
    select: (...args) => ({
      ...settings.select(...args),
      minLabel: 'Min',
      maxLabel: 'Max',
    }),
  }
}

// Floor size filter for commercial theme
const themeifyFloorSize = (settings, theme) => {
  if (theme !== 'commercial') return settings
  return {
    ...settings,
    options: settings.options
      ? [settings.options[0], ...commercialFloorSizes.slice(1)]
      : commercialFloorSizes,
  }
}

const elements: { [string]: ElementOptions } = {
  keywords: {
    component: FormInput,
    label: 'Keywords',
    defaultValue: '',
    options: [],
    select: (state, props) => ({
      value: state.keywords || props.defaultValue,
      type: 'text',
    }),
    apply: e => (
      e.target.value && e.target.value.trim()
        ? { keywords: e.target.value.trim() }
        : {}
    ),
    process: ({ keywords }) => (
      keywords && keywords.trim()
        ? { match: { field: 'text', value: keywords.trim(), minimum: '100%' } }
        : {}
    ),
    rule: () => Joi.string().regex(/(^(?=[^\s]).*|^$)$/).allow(''),
  },
  sort: {
    component: Sorter,
    label: 'Sort By',
    options: [
      { label: 'Recent', value: 'updatedAt desc' },
      { label: 'Price: Lowest', value: 'price asc' },
      { label: 'Price: Highest', value: 'price desc' },
    ],
    defaultValue: 'updatedAt desc',
    select: (state, props) => ({ value: state.sort || props.defaultValue }),
    apply: e => ({ sort: e.target.value }),
    process: ({ sort }) => (sort ? { sort: [sort, 'id desc', '_score desc'] } : {}),
    rule: onlyOptions,
    themeify: themeifySort,
  },
  soldDate: {
    component: FormRadio,
    label: 'Sold Within', // note: unused
    options: [
      { label: 'The Last 12 Months', value: 'recent' },
      { label: 'Any Time', value: 'all' },
    ],
    defaultValue: 'recent',
    select: (state, props) => ({ value: state[DATE_FILTER_KEY] || props.defaultValue }),
    apply: e => ({ [DATE_FILTER_KEY]: e.target.value }),
    process: filters => {
      const soldDate = filters[DATE_FILTER_KEY]
      if (soldDate === 'all') return {}

      return { soldDate: { gte: getSearchDate() } }
    },
    rule: onlyOptions,
    queryKey: DATE_FILTER_KEY,
  },
  updatedDate: {
    component: FormRadio,
    label: 'Leased Within', // note: unused
    options: [
      { label: 'The Last 12 Months', value: 'recent' },
      { label: 'Any Time', value: 'all' },
    ],
    defaultValue: 'recent',
    select: (state, props) => ({ value: state[DATE_FILTER_KEY] || props.defaultValue }),
    apply: e => ({ [DATE_FILTER_KEY]: e.target.value }),
    process: filters => {
      const updatedDate = filters[DATE_FILTER_KEY]
      if (updatedDate === 'all') return {}

      return { updatedAt: { gte: getSearchDate() } }
    },
    rule: onlyOptions,
    queryKey: DATE_FILTER_KEY,
  },
  sortRadio: {
    component: FormRadio,
    label: 'Sort By',
    options: [
      { label: 'Recent', value: 'updatedAt desc' },
      { label: 'Price: Lowest', value: 'price asc' },
      { label: 'Price: Highest', value: 'price desc' },
    ],
    defaultValue: 'updatedAt desc',
    select: (state, props) => ({
      value: state.sort || props.defaultValue,
      className: 'radio_group_vertical',
    }),
    apply: e => ({ sort: e.target.value }),
    process: ({ sort }) => (sort ? { sort: [sort, 'id desc', '_score desc'] } : {}),
    rule: onlyOptions,
    queryKey: 'sort',
    themeify: themeifySort,
  },
  suburbPostCode: {
    component: MultiSelect,
    label: 'Suburb',
    defaultValue: '',
    select: (state, props, fullState, searchParams) => {
      const value = state.suburbPostCode || props.defaultValue
      const { statusCode, typeCode, subTypeCode } = searchParams
      const suburbs = getFilteredSuburbs(fullState.meta, statusCode, typeCode, subTypeCode)
      const options = (suburbs || []).map(suburb => ({ value: suburb, label: suburb }))
      const className = value !== props.defaultValue ? 'filter_active' : undefined

      return { value, options, className }
    },
    apply: e => ({ suburbPostCode: e.target.value }),
    process: ({ suburbPostCode }) => {
      if (!suburbPostCode) return {}
      const suburb = []
      const postCode = []

      suburbPostCode.split('|').forEach(entry => {
        const [, _suburb, _postCode] = entry.match(/(.*)\s(\d+)$/) || ['', '']
        if (!(_suburb && _postCode)) return
        suburb.push(_suburb)
        postCode.push(_postCode)
      })

      return suburb.length && postCode.length
        ? { suburb, postCode }
        : {}
    },
    rule: () => Joi.string().allow('').regex(/\s\d+(\|.*\s\d+)*$/),
  },
  price: {
    component: props => {
      // eslint-disable-next-line react/prop-types
      if (!props.loaded) return <Loader />
      return <PriceBar {...props} />
    },
    label: 'Price',
    defaultValue: '',
    process: ({ price }) => {
      if (!price) return {}
      const range = processMinMax(price)
      if (!range) return {}
      return { price: range }
    },
    apply: e => ({ price: applyMinMax(e) }),
    select: (state, props, fullState, searchParams) => {
      const value = state.price || props.defaultValue
      const { typeCode, subTypeCode, statusCode } = searchParams
      const metadata = getSiteMetadata(fullState.meta)
      const prices = getPrices(metadata.typeKey, typeCode, subTypeCode, statusCode,
        PRICE_INTERVAL, RENT_PRICE_INTERVAL)
      const minPrice = prices[0] ? prices[0].price : 0
      const maxPrice = prices.length ? prices[prices.length - 1].price : 1
      const [min, max] = value.split('-').map(v => +v)

      return {
        min: min || minPrice,
        max: max || maxPrice,
        prices,
        step: PRICE_INTERVAL,
        loaded: metadata.loaded,
        rangeMin: minPrice,
        rangeMax: maxPrice,
        disabled: !prices.length,
      }
    },
    rule: () => Joi.string().allow('').regex(/^\d+-\d+$/),
    themeify: themeifyPrice,
  },
  rentPrice: {
    component: props => {
      // eslint-disable-next-line react/prop-types
      if (!props.loaded) return <Loader />
      return <PriceBar {...props} />
    },
    label: 'Price',
    defaultValue: '',
    process: ({ rentPrice }) => {
      const range = processMinMax(rentPrice)
      if (!range) return {}
      return { annualRentPrice: range }
    },
    apply: e => ({ rentPrice: applyMinMax(e) }),
    select: (state, props, fullState, searchParams) => {
      const value = state.rentPrice || props.defaultValue
      const { typeCode, subTypeCode, statusCode } = searchParams
      const metadata = getSiteMetadata(fullState.meta)
      const prices = getPrices(metadata.typeKey, typeCode, subTypeCode, statusCode,
        PRICE_INTERVAL, RENT_PRICE_INTERVAL, true)
      const minPrice = prices[0] ? prices[0].price : 0
      const maxPrice = prices.length ? prices[prices.length - 1].price : 1
      const [min, max] = value.split('-').map(v => +v)
      const _typeCode = Array.isArray(typeCode) ? typeCode : [typeCode]
      const weeklyRent = _typeCode.indexOf('REN') !== -1 || _typeCode.indexOf('HOL') !== -1

      const rangeMax = weeklyRent
        ? Math.ceil((maxPrice + RENT_PRICE_INTERVAL) / FIFTY_PER_WEEK) * FIFTY_PER_WEEK
        : maxPrice + RENT_PRICE_INTERVAL
      const rangeMin = weeklyRent
        ? Math.floor(minPrice / FIFTY_PER_WEEK) * FIFTY_PER_WEEK
        : minPrice

      return {
        min: min || rangeMin,
        max: max || rangeMax,
        prices,
        loaded: metadata.loaded,
        rangeMin,
        rangeMax,
        step: weeklyRent ? FIFTY_PER_WEEK : RENT_PRICE_INTERVAL,
        disabled: !prices.length,
        formatLabel: weeklyRent ? formatWeeklyRent : formatAnnualRent,
      }
    },
    rule: () => Joi.string().allow('').regex(/^\d+-\d+$/),
  },
  category: {
    component: MultiSelect,
    label: 'Property Types',
    defaultValue: '',
    select: (state, props, fullState, searchParams) => {
      const value = state.category || props.defaultValue
      const { typeCode, subTypeCode, statusCode } = searchParams
      const metadata = getSiteMetadata(fullState.meta)
      const categories = getCategories(metadata.typeKey, typeCode, subTypeCode, statusCode)
      const options = categories || []
      const className = value !== props.defaultValue ? 'filter_active' : undefined

      return { value, options, className }
    },
    apply: e => ({ category: e.target.value }),
    process: ({ category }) => (category ? { categoryCode: category.split('|') } : {}),
    rule: () => Joi.string().allow('').regex(/^[a-zA-Z \-']+(\|[a-zA-Z \-']+)*$/),
  },
  minFloor: {
    component: FormSelect,
    label: 'Min Floor Size',
    defaultValue: '0',
    select: (state, props) => {
      const maxFloor = state.maxFloor && +state.maxFloor
      const options = maxFloor
        ? props.options.map(opt => ({
          ...opt,
          attrs: (maxFloor <= +opt.value && +opt.value)
            ? { disabled: true }
            : {},
        }))
        : props.options

      return {
        value: state.minFloor || props.defaultValue,
        className: state.minFloor && state.minFloor !== props.defaultValue
          ? 'filter_active'
          : undefined,
        options,
      }
    },
    apply: e => ({ minFloor: e.target.value }),
    process: ({ minFloor }, current) => (+minFloor ? {
      buildingSize: {
        ...current.buildingSize,
        gte: +minFloor,
      },
    } : {}),
    options: [
      { label: 'No Min', value: '0' },
      { label: '20m²', value: '20' },
      { label: '40m²', value: '40' },
      { label: '80m²', value: '80' },
      { label: '100m²', value: '100' },
      { label: '150m²', value: '150' },
      { label: '200m²', value: '200' },
      { label: '300m²', value: '300' },
      { label: '400m²', value: '400' },
      { label: '500m²', value: '500' },
      { label: '750m²', value: '750' },
      { label: '1,000m²', value: '1000' },
    ],
    rule: onlyOptions,
    excludeForCategories: ['FARM', 'DEV', 'CLD', 'IDL', 'LAN', 'SEC', 'LSS'],
    themeify: themeifyFloorSize,
  },
  maxFloor: {
    component: FormSelect,
    label: 'Max Floor Size',
    defaultValue: '0',
    select: (state, props) => {
      const minFloor = state.minFloor && +state.minFloor
      const options = minFloor
        ? props.options.map(opt => ({
          ...opt,
          attrs: (minFloor >= +opt.value && +opt.value)
            ? { disabled: true }
            : {},
        }))
        : props.options

      return {
        value: state.maxFloor || props.defaultValue,
        className: state.maxFloor && state.maxFloor !== props.defaultValue
          ? 'filter_active'
          : undefined,
        options,
      }
    },
    apply: e => ({ maxFloor: e.target.value }),
    process: ({ maxFloor }, current) => (+maxFloor ? {
      buildingSize: {
        ...current.buildingSize,
        lte: +maxFloor,
      },
    } : {}),
    options: [
      { label: 'No Max', value: '0' },
      { label: '20m²', value: '20' },
      { label: '40m²', value: '40' },
      { label: '80m²', value: '80' },
      { label: '100m²', value: '100' },
      { label: '150m²', value: '150' },
      { label: '200m²', value: '200' },
      { label: '300m²', value: '300' },
      { label: '400m²', value: '400' },
      { label: '500m²', value: '500' },
      { label: '750m²', value: '750' },
      { label: '1,000m²', value: '1000' },
    ],
    rule: onlyOptions,
    excludeForCategories: ['FARM', 'DEV', 'CLD', 'IDL', 'LAN', 'SEC', 'LSS'],
    themeify: themeifyFloorSize,
  },
  minLand: {
    component: FormSelect,
    label: 'Min Land Size',
    defaultValue: '0',
    select: (state, props) => {
      const maxLand = state.maxLand && +state.maxLand
      const options = maxLand
        ? props.options.map(opt => ({
          ...opt,
          attrs: (maxLand <= +opt.value && !opt.value)
            ? { disabled: true }
            : {},
        }))
        : props.options
      return {
        value: state.minLand || props.defaultValue,
        className: state.minLand && state.minLand !== props.defaultValue
          ? 'filter_active'
          : undefined,
        options,
      }
    },
    apply: e => ({ minLand: e.target.value }),
    process: ({ minLand }, current) => (+minLand ? {
      landSize: {
        ...current.landSize,
        gte: +minLand,
      },
    } : {}),
    options: [
      { label: 'No Min', value: '0' },
      { label: '250m²', value: '250' },
      { label: '500m²', value: '500' },
      { label: '750m²', value: '750' },
      { label: '1,000m²', value: '1000' },
      { label: '1,500m²', value: '1500' },
      { label: '2,000m²', value: '2000' },
      { label: '2,500m²', value: '2500' },
      { label: '3,000m²', value: '3000' },
      { label: '4,000m²', value: '4000' },
      { label: '5,000m²', value: '5000' },
      { label: '7,500m²', value: '7500' },
      { label: '1ha', value: '10000' },
      { label: '2ha', value: '20000' },
      { label: '3ha', value: '30000' },
      { label: '4ha', value: '40000' },
      { label: '5ha', value: '50000' },
      { label: '7.5ha', value: '75000' },
      { label: '10ha', value: '100000' },
      { label: '15ha', value: '150000' },
      { label: '20ha', value: '200000' },
      { label: '50ha', value: '500000' },
    ],
    rule: onlyOptions,
    // Not shown on non-land commercial
    excludeForCategories: ['HOTEL', 'HOT', 'IBL', 'IND', 'MED', 'OBL', 'OFF', 'RET', 'RPY', 'SAL'],
  },
  maxLand: {
    component: FormSelect,
    label: 'Max Land Size',
    defaultValue: '0',
    select: (state, props) => {
      const minLand = state.minLand && +state.minLand
      const options = minLand
        ? props.options.map(opt => ({
          ...opt,
          attrs: (minLand >= +opt.value && +opt.value)
            ? { disabled: true }
            : {},
        }))
        : props.options
      return {
        value: state.maxLand || props.defaultValue,
        className: state.maxLand && state.maxLand !== props.defaultValue
          ? 'filter_active'
          : undefined,
        options,
      }
    },
    apply: e => ({ maxLand: e.target.value }),
    process: ({ maxLand }, current) => (+maxLand ? {
      landSize: {
        ...current.landSize,
        lte: +maxLand,
      },
    } : {}),
    options: [
      { label: 'No Max', value: '0' },
      { label: '250m²', value: '250' },
      { label: '500m²', value: '500' },
      { label: '750m²', value: '750' },
      { label: '1,000m²', value: '1000' },
      { label: '1,500m²', value: '1500' },
      { label: '2,000m²', value: '2000' },
      { label: '2,500m²', value: '2500' },
      { label: '3,000m²', value: '3000' },
      { label: '4,000m²', value: '4000' },
      { label: '5,000m²', value: '5000' },
      { label: '7,500m²', value: '7500' },
      { label: '1ha', value: '10000' },
      { label: '2ha', value: '20000' },
      { label: '3ha', value: '30000' },
      { label: '4ha', value: '40000' },
      { label: '5ha', value: '50000' },
      { label: '7.5ha', value: '75000' },
      { label: '10ha', value: '100000' },
      { label: '15ha', value: '150000' },
      { label: '20ha', value: '200000' },
      { label: '50ha', value: '500000' },
    ],
    rule: onlyOptions,
    // Not shown on non-land commercial
    excludeForCategories: ['HOTEL', 'HOT', 'IBL', 'IND', 'MED', 'OBL', 'OFF', 'RET', 'RPY', 'SAL'],
  },
  minBedsClicker: {
    component: Clicker,
    label: 'Bedrooms',
    defaultValue: '0',
    select: (state, props) => ({
      value: state.minBeds || props.defaultValue,
      min: 0,
      max: 5,
      formatLabel: valPlus,
    }),
    apply: e => ({ minBeds: e.target.value }),
    process: ({ minBeds }) => (+minBeds ? ({ bedrooms: { gte: +minBeds } }) : {}),
    rule: onlyOptions,
    queryKey: 'minBeds',
    excludeForCategories: ['STD', 'LAN', 'SEC', 'LSS'],
  },
  minBeds: {
    component: FormRadio,
    label: 'Bedrooms',
    defaultValue: '0',
    options: [
      { label: 'Any', value: '0' },
      { label: '1+', value: '1' },
      { label: '2+', value: '2' },
      { label: '3+', value: '3' },
      { label: '4+', value: '4' },
      { label: '5+', value: '5' },
    ],
    select: (state, props) => ({ value: state.minBeds || props.defaultValue }),
    apply: e => ({ minBeds: e.target.value }),
    process: ({ minBeds }) => (+minBeds ? ({ bedrooms: { gte: +minBeds } }) : {}),
    rule: onlyOptions,
    excludeForCategories: ['STD', 'LAN', 'SEC', 'LSS'],
  },
  minBathsClicker: {
    component: Clicker,
    label: 'Bathrooms',
    defaultValue: '0',
    select: (state, props) => ({
      value: state.minBaths || props.defaultValue,
      min: 0,
      max: 5,
      formatLabel: valPlus,
    }),
    apply: e => ({ minBaths: e.target.value }),
    process: ({ minBaths }) => (+minBaths ? ({ bathrooms: { gte: +minBaths } }) : {}),
    rule: onlyOptions,
    queryKey: 'minBaths',
    excludeForCategories: ['LAN', 'SEC', 'LSS'],
  },
  minBaths: {
    component: FormRadio,
    label: 'Bathrooms',
    defaultValue: '0',
    options: [
      { label: 'Any', value: '0' },
      { label: '1+', value: '1' },
      { label: '2+', value: '2' },
      { label: '3+', value: '3' },
      { label: '4+', value: '4' },
      { label: '5+', value: '5' },
    ],
    select: (state, props) => ({ value: state.minBaths || props.defaultValue }),
    apply: e => ({ minBaths: e.target.value }),
    process: ({ minBaths }) => (+minBaths ? ({ bathrooms: { gte: +minBaths } }) : {}),
    rule: onlyOptions,
    excludeForCategories: ['LAN', 'SEC', 'LSS'],
  },
  minCarsClicker: {
    component: Clicker,
    label: 'Car Spaces',
    defaultValue: '0',
    select: (state, props) => ({
      value: state.minCars || props.defaultValue,
      min: 0,
      max: 5,
      formatLabel: valPlus,
    }),
    apply: e => ({ minCars: e.target.value }),
    process: ({ minCars }) => (+minCars ? ({ carSpaces: { gte: +minCars } }) : {}),
    rule: onlyOptions,
    queryKey: 'minCars',
    excludeForCategories: ['LAN', 'SEC', 'LSS'],
  },
  minCars: {
    component: FormRadio,
    label: 'Car Spaces',
    defaultValue: '0',
    options: [
      { label: 'Any', value: '0' },
      { label: '1+', value: '1' },
      { label: '2+', value: '2' },
      { label: '3+', value: '3' },
      { label: '4+', value: '4' },
      { label: '5+', value: '5' },
    ],
    select: (state, props) => ({ value: state.minCars || props.defaultValue }),
    apply: e => ({ minCars: e.target.value }),
    process: ({ minCars }) => (+minCars ? ({ carSpaces: { gte: +minCars } }) : {}),
    rule: onlyOptions,
    excludeForCategories: ['LAN', 'SEC', 'LSS'],
  },
}

const forms: { [string]: FormOptions } = {
  // Default form
  generic: {
    bar: ['price'],
    filter: ['suburbPostCode', 'price', 'keywords'],
    themeable: true,
  },

  CUR_BUS: {
    bar: ['price'],
    filter: ['suburbPostCode', 'price', floorGroup, 'category', 'keywords'],
    labels: { category: 'Business Types' },
    themeable: true,
  },

  CUR_CLD: {
    bar: [...landGroup.elements, 'price'],
    filter: ['suburbPostCode', 'price', landGroup, 'category', 'keywords'],
    themeable: true,
  },

  'CUR_CLD_BOTH.SAL': {
    bar: [...landGroup.elements, 'price'],
    filter: ['suburbPostCode', 'price', landGroup, 'category', 'keywords'],
    themeable: true,
  },

  'CUR_CLD_BOTH.LSE': {
    bar: [...landGroup.elements, 'rentPrice'],
    filter: ['suburbPostCode', 'rentPrice', landGroup, 'category', 'keywords'],
    themeable: true,
  },

  CUR_COM: {
    bar: [...floorGroup.elements, 'price'],
    filter: ['category', floorGroup, landGroup, 'price', 'keywords', 'suburbPostCode'],
    labels: { category: 'Asset Class' },
    themeable: true,
  },

  'CUR_COM_BOTH.SAL': {
    bar: [...floorGroup.elements, 'price'],
    filter: ['category', floorGroup, landGroup, 'price', 'keywords', 'suburbPostCode'],
    labels: { category: 'Asset Class' },
    themeable: true,
  },

  'CUR_COM_BOTH.LSE': {
    bar: [...floorGroup.elements, 'rentPrice'],
    filter: ['category', floorGroup, landGroup, 'rentPrice', 'keywords', 'suburbPostCode'],
    labels: { category: 'Asset Class' },
    themeable: true,
  },

  CUR_HOL: {
    bar: ['minBeds', 'rentPrice'],
    filter: [bbcGroup, 'suburbPostCode', 'rentPrice', 'category', 'keywords'],
    themeable: true,
  },

  CUR_LAN: {
    bar: [...landGroup.elements, 'price'],
    filter: ['suburbPostCode', 'price', landGroup, 'category', 'keywords'],
    options: { minLand: minLandSelectOptions, maxLand: maxLandSelectOptions },
    themeable: true,
  },

  CUR_REN: {
    bar: ['minBeds', 'rentPrice'],
    filter: [bbcGroup, 'suburbPostCode', 'rentPrice', 'category', 'keywords'],
    themeable: true,
  },

  'CUR_COM.HOL.REN_BOTH.LSE': {
    bar: ['minBeds', 'rentPrice'],
    filter: [bbcGroup, 'suburbPostCode', 'rentPrice', 'category', 'keywords'],
    themeable: true,
  },

  CUR_RUR: {
    bar: [...landGroup.elements, 'price'],
    filter: [bbcGroup, 'suburbPostCode', 'price', landGroup, 'category', 'keywords'],
    options: { minLand: minLandSelectOptions, maxLand: maxLandSelectOptions },
    themeable: true,
  },

  CUR_LVS: {
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: true,
  },

  CUR_LVW: {
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: true,
  },

  CUR_ALP: {
    bar: [],
    filter: ['keywords'],
    themeable: true,
  },

  CUR_STU: {
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: true,
  },

  CUR_CLR: {
    bar: [],
    filter: ['keywords'],
    themeable: true,
  },

  CUR_SAL: {
    bar: ['minBeds', 'price'],
    filter: [
      bbcGroup,
      'price',
      'suburbPostCode',
      'category',
      floorGroup,
      landGroup,
      'keywords',
    ],
    themeable: true,
  },

  LSE_ANY: {
    bar: ['rentPrice'],
    filter: ['suburbPostCode', 'rentPrice', 'keywords'],
    themeable: false,
  },

  LSE_REN: {
    bar: ['minBeds', 'rentPrice'],
    filter: [bbcGroup, 'suburbPostCode', 'rentPrice', 'category', 'keywords'],
    themeable: false,
  },

  LSE_COM: {
    bar: [...floorGroup.elements, 'rentPrice'],
    filter: ['category', floorGroup, landGroup, 'rentPrice', 'keywords', 'suburbPostCode'],
    labels: { category: 'Asset Class' },
    themeable: false,
  },

  SLD_ANY: {
    ...defaultSoldConfig,
    bar: ['price'],
    filter: ['suburbPostCode', 'price', 'keywords'],
    themeable: false,
  },

  SLD_SAL: {
    ...defaultSoldConfig,
    bar: ['minBeds', 'price'],
    filter: [bbcGroup, 'suburbPostCode', 'price', 'category', 'keywords'],
    themeable: false,
  },

  SLD_RUR: {
    ...defaultSoldConfig,
    bar: [...landGroup.elements, 'price'],
    filter: [bbcGroup, 'suburbPostCode', 'price', landGroup, 'category', 'keywords'],
    themeable: false,
  },

  SLD_LVS: {
    ...defaultSoldConfig,
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: false,
  },

  SLD_LVW: {
    ...defaultSoldConfig,
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: false,
  },

  SLD_STU: {
    ...defaultSoldConfig,
    bar: [],
    filter: ['category', 'keywords'],
    labels: { category: 'Livestock Types' },
    themeable: false,
  },

  SLD_CLR: {
    ...defaultSoldConfig,
    bar: ['keywords'],
    filter: ['keywords'],
    themeable: false,
  },

  SLD_COM: {
    ...defaultSoldConfig,
    bar: [...floorGroup.elements, 'price'],
    filter: ['category', floorGroup, landGroup, 'price', 'keywords', 'suburbPostCode'],
    labels: { category: 'Asset Class' },
    themeable: false,
  },

  SLD_BUS: {
    ...defaultSoldConfig,
    bar: ['price'],
    filter: ['suburbPostCode', 'price', floorGroup, 'category', 'keywords'],
    labels: { category: 'Business Types' },
    themeable: false,
  },

  'LSE.SLD_ANY': {
    bar: ['price'],
    filter: ['suburbPostCode', 'price', 'keywords'],
    themeable: false,
  }
}

type SearchParams = {
  statusCode?: string | Array<string>,
  typeCode?: string | Array<string>,
  subTypeCode?: {
    in?: Array<string>,
    not?: Array<string>,
  },
  _formKey?: string,
};

// Generate config key
const keyify = ({ statusCode, typeCode, subTypeCode, _formKey }: SearchParams) => {
  // If a search explicitly sets a key, use that
  if (_formKey) return _formKey

  // eslint-disable-next-line no-nested-ternary
  const subTypeCodes = subTypeCode
    ? (subTypeCode.in ? subTypeCode.in : invertSubTypes(subTypeCode.not))
    : ''
  return [
    Array.isArray(statusCode) ? statusCode.sort().join('.') : statusCode || 'CUR,SLD,LSE',
    Array.isArray(typeCode) ? typeCode.sort().join('.') : typeCode || 'ANY',
    subTypeCodes ? subTypeCodes.sort().join('.') : false,
  ].filter(x => x).join('_')
}

const configs: { [string]: FormConfig } = {}

/**
 * Get a form configured for a given set of search parameters.
 */
export function getForm(
  params: Object,
  { _window, theme }: { _window?: Object, theme: string } = {},
) {
  const key = keyify(params)
  const configKey = `${key}_${theme}`

  // Used cached config
  if (configs[configKey]) return configs[configKey]

  const {
    filter = [],
    bar = [],
    options = {},
    labels = {},
    defaultValue = {},
    dateFilter,
    themeable,
  } = forms[key] || forms.generic

  const apply = updateFilters.bind(null, key)
  const filterFields = [].concat(...filter.map(item => (
    typeof item === 'object' ? item.elements : [item]
  )))

  // Build configured list of all fields, applying form specific overrides
  const fields = [...new Set(['sort', 'sortRadio', ...bar, ...filterFields])].filter(x => x)
  if (dateFilter) fields.push(dateFilter)
  const inputs = fields.reduce((all, field) => {
    const element = elements[field]

    // eslint-disable-next-line no-param-reassign
    const value = defaultValue[field] !== undefined ? defaultValue[field] : element.defaultValue

    const _input = {
      ...element,
      options: options[field] !== undefined ? options[field] : element.options,
      label: labels[field] !== undefined ? labels[field] : element.label,
      defaultValue: value,
    }
    const input = {
      key: field,
      field: {
        name: field,
        value,
      },
      ...(themeable && element.themeify ? element.themeify(_input, theme) : _input),
    }

    // Allow element to select applicable state
    const mapStateToProps = (state, props) => ({
      name: `${props.prefix || ''}${field}`,
      // override default value, element.select must provide a value
      defaultValue: undefined,
      ...input.select(
        getFilters(state.search, key), props, state, params
      ),
    })

    // Allow addition of an onChange handler via element.apply
    const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
      onChange: e => {
        if (_window && _window.dataLayer) {
          _window.dataLayer.push({
            event: 'rwCustom',
            rwCustomData: {
              category: 'Filter',
              action: 'Filter',
              label: input.label,
              value: /^\d+$/.test(e.target.value) ? parseInt(e.target.value, 10) : '',
            },
          })
        }
        dispatch(apply(input.apply(e)))
      },
    })

    // Connect component
    const component = !input.component
      ? input.component
      // eslint-disable-next-line max-len
      // $FlowFixMe connect<Object, Dispatch<*>, Object, _, _, _> works but needs babel 7 to parse for linting
      : connect(mapStateToProps, mapDispatchToProps)(input.component)

    input.component = component
    all[field] = input // eslint-disable-line no-param-reassign
    return all
  }, {})

  const config = {
    key,
    fields,
    elements: inputs,
    bar: bar.map(field => inputs[field]),
    filter: filter.map(item => {
      if (typeof item !== 'object') {
        const input = inputs[item]
        return { label: input.label, elements: [input] }
      }
      return { ...item, elements: item.elements.map(field => inputs[field]) }
    }),
    sort: inputs.sort,
    dateFilter: inputs[dateFilter || 'updatedDate'] || inputs.updatedDate,
    sortRadio: inputs.sortRadio,
    defaults: fields.reduce((defaults, field) => {
      const { key: _key, queryKey, defaultValue: _default } = inputs[field]
      // eslint-disable-next-line no-param-reassign
      defaults[queryKey || _key] = _default
      return defaults
    }, {}),
  }

  configs[configKey] = config
  return config
}

/**
 * Figure out which elements are applicable to the given categories.
 */
export function getApplicableElements(
  categories: Array<string> = [],
  formElements: { [string]: ElementOptions },
): Array<string> {
  // If no categories, everything considered applicable
  if (!categories.length) return Object.keys(formElements)
  const selected = new Set(categories)

  // Filter out any categories which
  return Object.keys(formElements).filter(key => {
    const { excludeForCategories = [] } = formElements[key]
    if (!excludeForCategories.length) return true

    // Exclude only if all selected categories are in the exclude list
    return excludeForCategories.filter(cat => selected.has(cat)).length !== categories.length
  })
}

/**
 * Use a form config to convert raw filters into a query for the listings API.
 *
 * The query is built by calling the process method for each element in the form
 * with the current filters and merging the results together.
 */
export function processFilters(config: FormConfig, filters: { [string]: any }) {
  const { category } = config.elements
  const fields = category
    ? getApplicableElements(category.process(filters, {}).categoryCode, config.elements)
    : config.fields
  return fields.reduce((result, field) => ({
    ...result,
    ...config.elements[field].process(filters, result),
  }), {})
}

/**
 * Process args from a query string, discarding invalid ones.
 *
 * This matches the filters passed in against the elements in the form, ignores
 * those that don't match an element or don't match the validation rules, then
 * returns those valid ones.
 */
export function processQuery(config: FormConfig, filters: { [string]: any }) {
  return Object.keys(config.elements).reduce((valid, _key) => {
    // Allow queryKey to override the default key
    const element = config.elements[_key]
    const key = element.queryKey || _key
    const val = filters[key]

    // Ignore filters that don't match
    if (val === undefined) return valid

    const rule = typeof element.rule === 'function'
      ? element.rule(element.options || [])
      : element.rule
    const result = Joi.validate(val, rule)
    if (result.error) return valid

    // eslint-disable-next-line no-param-reassign
    valid[key] = val

    return valid
  }, {})
}
