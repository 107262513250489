import rewrites from '../../data/menu_rewrites'
import servicePageRewrites from '../../data/menu_rewrites_service_pages'
export const getRewrites = useServicePages => (
  useServicePages ? servicePageRewrites : rewrites
)

// Avoid recompiling regexes all the time
const recache = {}
function re(src) {
  if (recache[src]) return recache[src]
  recache[src] = new RegExp(src)
  return recache[src]
}

/**
 * Check a request path against a given set of redirections.
 *
 * Redirections may be static or may be a regular expression; each rule has a
 * string src and target, and an isRegex flag.  If isRegex is true the src will
 * be compiled as a regex and matched against the path.
 *
 * @return string The new target, if any.
 */
export function checkWpRewrites(path, redirections, logger) {
  const _path = path.replace(/\/*$/, '/')

  for (const { src, isRegex, target } of redirections) {
    if (isRegex) {
      try {
        const regex = re(src)
        if (regex.test(_path)) {
          // Simple case if no replacements in target
          if (!/\$\d{1,2}/.test(target)) return target

          // Replace placeholders with values
          const [, ...reps] = _path.match(regex)
          return reps.reduce(
            (dest, value, index) => dest.replace(`$${index + 1}`, value),
            target
          )
        }
      } catch (error) {
        if (logger) {
          logger.warn(`Unable to build redirection regex: ${error.message}`, { error, src, target })
        }
      }
    } else if (_path === src.replace(/\/*$/, '/')) {
      return target
    }
  }

  return undefined
}
