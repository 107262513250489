import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import classNames from 'classnames'
import deepEqual from 'deep-equal'
import { elementRect } from '@raywhite/browser-utils/lib/browser/browser'
import onScroll from '@raywhite/browser-utils/lib/browser/onScroll'
import { scrollToId } from '@raywhite/browser-utils/lib/browser/smoothScroll'
import { depressify } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { collectionPage as collectionPageSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import { pluralize } from '@raywhite/data-utils/lib/data/listing/utils'
import { getTypeSlug } from '@raywhite/data-utils/lib/data/listing/listings'
import {
  buildTypeDescription,
  describeTypeNouns,
} from '@raywhite/data-utils/lib/data/listing/codes'
import {
  formatNumber,
} from '@raywhite/data-utils/lib/data/listing/formatting'
import {
  shouldShowMap,
  filterOutliers,
} from '@raywhite/media-utils/lib/media/geo'
import ListingCardList from './ListingCardList.jsx'
import JsonLD from './JsonLD.jsx'
import OpenGraph from './OpenGraph.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import SearchMap from './Mapbox/SearchMap.jsx'
import { preparePoints } from '../container/ListingsFilter.jsx'

const MAX_LISTINGS = 4
const width = el => elementRect(el).width

export const typeShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  typeCode: PropTypes.string.isRequired,
  subTypeCode: PropTypes.string.isRequired,
  statusCode: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
})

const getNavScrollSteps = (inner, elements) => {
  if (!inner || !elements || !elements.length) {
    return []
  }

  const space = width(inner)
  const steps = [0]
  let sum = 0
  let current = 0

  elements.forEach(el => {
    const _width = width(el)
    current = current + _width
    if (current > space) {
      steps.push(sum)
      current = 0
    }
    sum = sum + _width
  })
  return steps
}

const translate = (steps, index) => {
  if (!index) {
    return undefined
  }

  const amount = steps[Math.max(0, Math.min(index, steps.length - 1))]
  return amount && `translateX(-${amount}px)`
}

/**
 * A component for "service pages", which present an overview of a "service" that
 * the office provides.  Services are buy, rent, sell and property management.
 *
 * Within this page we will show
 *  - the types within the page (e.g. buy category may have residential, commercial, etc)
 *  - a map showing the location of matching properties
 *  - a short list of matching properties
 *  - counts of auctions/inspections
 *  - links to view more, view auctions, view inspections within that category
 *  - content that the office has set up for that category
 */
export default class ServicePage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: typeShape.isRequired,
    category: PropTypes.oneOf(['buy', 'rent', 'sell', 'property-management']),
    types: PropTypes.arrayOf(typeShape).isRequired,
    path: PropTypes.string.isRequired,
    listings: PropTypes.object,
    maps: PropTypes.object,
    _window: PropTypes.object,
    content: PropTypes.object,
    baseUrl: PropTypes.string,
    resourceUrl: PropTypes.string,
    office: PropTypes.object,
    formsHeading: PropTypes.string,
    forms: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      className: PropTypes.string.isRequired,
    })).isRequired,
    router: PropTypes.object.isRequired,
    siteTitle: PropTypes.string,
  }

  static defaultProps = {
    title: "What's On The Market",
    forms: [],
  }

  static getDerivedStateFromProps(state, props) {
    if (state.lastTypes === props.types) return null
    return {
      steps: [],
      navIndex: 0,
      lastTypes: props.types,
    }
  }

  constructor() {
    super()

    this.state = {
      navIndex: 0,
      showContentNav: false,
      steps: [],
    }
  }

  componentDidMount() {
    this.stopListening = onScroll(this.maybeShowContentNav, this.props._window)
    this.resetSteps()
  }

  componentDidUpdate(props) {
    if (props.types !== this.props.types) this.resetSteps()
  }

  componentWillUnmount() {
    if (this.stopListening) this.stopListening()
  }

  setHoverAnchor = el => {
    this.hoverAnchor = el
  }

  setNavList = el => {
    this.navList = el
  }

  setNavLeft = el => {
    this.navLeft = el
  }

  setNavRight = el => {
    this.navRight = el
  }

  setNavInner = el => {
    this.navInner = el
  }

  maybeShowContentNav = () => {
    if (!this.hoverAnchor) return
    const showContentNav = elementRect(this.hoverAnchor).top < 0
    if (showContentNav !== this.state.showContentNav) this.setState({ showContentNav })
  }

  goNavLeft = () => {
    if (this.state.navIndex === 0) {
      // Can't go less than 0
      return
    }
    this.setState(state => ({ navIndex: state.navIndex - 1 }))
  }

  goNavRight = () => {
    if (this.state.navIndex === this.state.steps.length - 1) {
      // Can't go off the end
      return
    }
    this.setState(state => ({ navIndex: state.navIndex + 1 }))
  }

  resetSteps() {
    const steps = this.navInner && this.navList
      ? getNavScrollSteps(this.navInner, [...this.navList.children])
      : []
    if (!deepEqual(steps, this.state.steps)) this.setState({ steps })
  }

  handleMapClick = () => {
    const {
      router,
      type: {
        typeCode,
        statusCode,
      },
    } = this.props
    const typeBase = `/properties/${getTypeSlug(statusCode, typeCode, null)}`
    router.push(typeBase)
  }

  render() {
    const {
      title,
      path,
      types,
      listings,
      content,
      type: {
        typeCode,
        statusCode,
        subTypeCode,
        pageDescription,
        total,
        data: {
          auctions: { total: auctions } = {},
          inspections: { total: inspections } = {},
        },
      },
      office,
      office: {
        address: officeAddress = {},
        fullName,
      },
      forms,
      formsHeading,
      _window,
      siteTitle,
      baseUrl,
      category,
    } = this.props
    const { steps, navIndex } = this.state

    const points = filterOutliers(preparePoints((listings && listings.items) || []), { devs: 2 })
    const showMap = shouldShowMap(typeCode)
    const typeBase = `properties/${getTypeSlug(statusCode, typeCode, null)}`
    const metaDescription = buildTypeDescription(
      siteTitle || fullName, total, statusCode, typeCode, subTypeCode
    )
    const structuredData = collectionPageSchema({
      name: pageDescription,
      // eslint-disable-next-line max-len
      description: metaDescription,
      url: `${baseUrl}/${typeBase}`,
    })

    const scrollAnchor = scrollToId(_window, {
      getOffset: e => -e.currentTarget.getBoundingClientRect().height,
    })

    return (
      <div className="service_page">
        <Helmet>
          <title>{pageDescription}</title>
          <meta name="description" content={structuredData.description} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <OpenGraph
          type="article"
          title={structuredData.name}
          description={structuredData.description}
          image={showMap && structuredData.image
            && { url: structuredData.image,
              resizable: false,
              height: 1200,
              width: 630,
            }}
          url={structuredData.url}
        />
        <TwitterSummaryCard
          title={structuredData.name}
          description={structuredData.description}
          image={structuredData.image}
          site={office && office.twitter}
        />

        {content && !!content.entities.length && (
          <nav
            className={classNames('service_page_content_nav', { active: this.state.showContentNav })}
          >
            <div className="inner_lg">
              <ul>
                {content.entities.slice(0, 3).map((item, index) => (
                  <li key={item.id}>
                    <a
                      href={`#${item.slug}`}
                      dangerouslySetInnerHTML={{
                        __html: item.title && item.title.rendered,
                      }}
                      onClick={scrollAnchor}
                    />
                    {index < (Math.min(content.entities.length, 3) - 1) && (
                      <span>/</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        )}

        {showMap && (
          <div className="service_listings_map">
            <div className="service_listings_map_wrap">
              <div
                className="service_listings_map_overlay"
                onClick={this.handleMapClick}
              />
              <SearchMap
                identifier={`service_${category}`}
                loaded={listings.loaded}
                points={points}
                center={[
                  officeAddress.longitude || 140.9131117,
                  officeAddress.latitude || -28.9524163,
                ]}
                zoom={12}
                pitch={60}
                bearing={0}
                active
                renderMap
                interactive={false}
                _window={_window}
              />
            </div>
          </div>
        )}

        {types.length > 1 && (
          <nav
            className={classNames('secondary_nav', {
              no_nav_scroll: steps.length <= 1,
              is_full_left: navIndex === 0,
              is_full_right: navIndex === steps.length - 1,
            })}
          >
            <div className="secondary_nav_list_wrap" ref={this.setNavInner}>
              <ul
                style={{
                  transform: translate(steps, navIndex),
                }}
                ref={this.setNavList}
              >
                {types.map(type => (
                  <li
                    key={type.typeCode}
                    className={type.typeCode === typeCode ? 'active' : undefined}
                  >
                    <Link
                      className="mini"
                      to={`${path}/${type.slug}`}
                    >
                      {type.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <span
              className="secondary_nav_more"
              ref={this.setNavRight}
              onClick={this.goNavRight}
            />
            <span
              className="secondary_nav_back"
              ref={this.setNavLeft}
              onClick={this.goNavLeft}
            />
          </nav>
        )}

        <div
          className="service_listings inner_lg"
          ref={this.setHoverAnchor}
        >
          <div className="service_listings_header">
            <div className="service_listings_header_meta">
              <h2 className="charlie">{title}</h2>
              <span className="muted mini service_listings_header_count">
                {formatNumber(total)}
                {' '}
                {describeTypeNouns(typeCode, total !== 1)}
              </span>
            </div>
            {total > MAX_LISTINGS && (
              <div className="service_listings_header_more">
                <Link
                  to={`/${typeBase}`}
                  className="btn"
                >
                  See all
                  {' '}
                  {pageDescription}
                </Link>
              </div>
            )}
          </div>
          {!!listings && (
            <ListingCardList
              className="service_listings_wrap proplist"
              listings={listings.items.slice(0, MAX_LISTINGS)}
              loaded={listings.loaded}
              error={listings.error}
            />
          )}
          <div className="service_listings_more_wrap centered_text inner">
            {total > MAX_LISTINGS && (
              <Link
                to={`/${typeBase}`}
                className="btn"
              >
                See all
                {' '}
                {pageDescription}
              </Link>
            )}
            {!!(auctions || inspections) && (
              <ul>
                {!!auctions && (
                  <li>
                    <Link
                      to={`/${typeBase}/auctions`}
                      className="muted mini anchor"
                    >
                      {auctions}
                      {' '}
                      for sale by auction
                    </Link>
                  </li>
                )}
                {!!inspections && (
                  <li>
                    <Link
                      to={`/${typeBase}/open-for-inspection`}
                      className="muted mini anchor"
                    >
                      {inspections}
                      {' '}
                      upcoming
                      {' '}
                      {pluralize(inspections, 'inspection', 'inspections')}
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>

        {!!forms.length && (
          <div className="servicepg_forms_wrap">
            <div className="inner_lg">
              <h2 className="charlie service_page_content_section_heading">
                {formsHeading}
              </h2>
              <ul className="servicepg_forms_list" data-form-count={forms.length}>
                {forms.map(({ label, onClick: handleClick, className }) => (
                  <li
                    key={label}
                    className={className}
                  >
                    <span onClick={handleClick} className="anchor">
                      <h3>{label}</h3>
                      <span className="servicepg_form_more">View</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {content && !!content.entities.length && (
          <div className="service_page_content_wrap">
            {content.entities.map(item => (
              <div
                key={item.id}
                id={item.slug}
                className={classNames({
                  service_page_content_section: true,
                })}
              >
                <div className="inner_lg">
                  <h2
                    className="charlie service_page_content_section_heading"
                    dangerouslySetInnerHTML={{
                      __html: item.title && item.title.rendered,
                    }}
                  />
                  <div
                    className="service_page_content"
                    dangerouslySetInnerHTML={depressify(
                      item.content ? item.content.rendered : ''
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
