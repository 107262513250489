// @flow

import PropTypes from 'prop-types'

import typeof fetchType from 'isomorphic-fetch'
import type { Organisation } from '@raywhite/data-client/lib/modules/organisations'
import type { Member } from '@raywhite/data-client/lib/modules/members'
import type { Listing } from '@raywhite/data-client/lib/modules/listings'
import type {
  DataClient,
  GetListingsFn,
  GetSuburbsFn,
  GetMembersFn,
  GetOrganisationsFn,
  GetOrganisationsMetadataFn,
  GetAgentMetadataFn,
} from '@raywhite/data-client/lib/client'
export type {
  GetListingsFn,
  GetMembersFn,
  GetOrganisationsFn,
} from '@raywhite/data-client/lib/client'

// eslint-disable-next-line import/first
import type { Broker, LoanMarketClient } from './lmClient'

export type MenuItem = {
  id: string,
  path?: string,
  label: string,
  showSubmenu?: () => void,
  hideSubmenu?: () => void,
  children: Array<MenuItem>,
}
export const menuItemShape = PropTypes.shape({
  path: PropTypes.string,
  label: PropTypes.string.isRequired,
  // Note: recursive prop types are too much pain
  children: PropTypes.array.isRequired,
})

export type AppError = Error & {
  error?: Error,
  data?: { [string]: mixed },
  listing?: Object,
  // For error logging, the HTTP status code also some third party libs use this
  code?: number | string,
}

/**
 * Grab bag for things that should be typed elsewhere when typing is enabled in
 * the appropriate place, but can live here until such time.  Which may well be
 * never.
 */
type UnifiedClientResponse<R> = {
  totalResults: number,
  results: Array<R>,
  nextPageAfter?: Object,
}

type UCFetchFn<R> = Object => Promise<UnifiedClientResponse<R>>

export type UnifiedClient = DataClient & {
  fetch: fetchType,
  _getListings?: GetListingsFn,
  _getSuburbs?: GetSuburbsFn,
  _getMembers?: GetMembersFn,
  _getOrganisations?: GetOrganisationsFn,
  _getOrganisationsMetadata?: GetOrganisationsMetadataFn,
  _getAgentMetadata?: GetAgentMetadataFn,
}
export type ContentClient = {
  getContentTypes: () => Promise<*>,
  getContentForType: (string, number, number, Object) => Promise<*>,
  getContentForPath: (string) => Promise<*>,
  getSyndicatedNews: () => Promise<*>,
  getMenus: () => Promise<*>,
}
type UpdateFetchFn<R> = (number, Object) => Promise<R>
type CreateFetchFn<R> = Object => Promise<R>
export type GetUsersFn = UCFetchFn<Object>
export type GetListingsMetaFn = UCFetchFn<Object>
export type UpdateUserFn = UpdateFetchFn<Object>
export type CreateUserFn = CreateFetchFn<Object>
export type UpdateListingMetaFn = UpdateFetchFn<Object>
export type SnapperClient = {
  getListingsMeta: GetListingsMetaFn,
  createUser: CreateUserFn,
  getUsers: GetUsersFn,
  updateListingMeta: UpdateListingMetaFn,
  updateUser: UpdateUserFn,
  _getListingsMeta?: GetListingsMetaFn,
  _getUsers?: GetUsersFn,
  _updateListingMeta?: UpdateListingMetaFn,
  _updateUser?: UpdateUserFn,
}
export type LeadDistributorClient = {
  publishLead: (any, ?any) => Promise<Object>,
}
export type ExtendedClient = (
  UnifiedClient
  & ContentClient
  & SnapperClient
  & LoanMarketClient
  & LeadDistributorClient
)
export type Theme = 'residential' | 'commercial'
export type CountryCode = 'au' | 'AU' | 'nz' | 'NZ'
type Loadable = {|
  loaded: boolean,
  loading: boolean,
  notFound: boolean,
  error: ?any,
|}
export type LoadableMember = {|
  ...Member,
  ...Loadable,
  hidden: boolean,
|}
export type LoadableOrganisation = {|
  ...Organisation,
  ...Loadable,
|}
export type LoadableListing = {|
  ...Listing,
  ...Loadable,
|}
export type LoadableBroker = {|
  ...Broker,
  ...Loadable,
|}
export type Manifest = { [string]: string }
export type Testimonial = {
  detail: string,
  person?: string,
}
export const testimonialShape = PropTypes.shape({
  detail: PropTypes.string.isRequired,
  person: PropTypes.string,
})
