import React from 'react'

/* eslint-disable max-len */
export default () => (
  <div>
    <strong>I agree to the collection, use and disclosure of my personal information for the following purposes:</strong>
    <ul>
      <li>Checking any of the details contained in this application form with third parties including any tenant information database and the New Zealand Transport Authority.</li>
      <li>Making enquiries with third parties in relation to my creditworthiness, including any credit reporting agency.</li>
      <li>Placing any of the information regarding my identity supplied on this application form on a national online database.</li>
      <li>Using any of the information contained on any Tenancy Tribunal Order to assist the enforcement of any unsatisfied Money Order made against me by listing details of the order on an online national database.</li>
      <li>Using any of the information on this application form to assist in the enforcement of any unsatisfied Order made against me by the Tenancy Tribunal.</li>
      <li>Debt recovery purposes including appointing an agent to collect any outstanding debts and listing defaults with any credit reporting agency.</li>
      <li>Checking the Ministry of Justice fines database. This may be undertaken by a credit reporting agency on our behalf which will require my personal information to be collected by and disclosed to the credit reporting agency.</li>
    </ul>

    <p><strong>I understand that any credit reporting agency to whom you may disclose my personal information may:</strong></p>
    <ul>
      <li>hold that information on their credit reporting database;</li>
      <li>use that information for the purpose of credit reporting or for any other lawful purpose;</li>
      <li>disclose that information to their subscribers for the purpose of credit checking or debt collection of for any other lawful purpose.</li>
    </ul>

    <p><strong>I understand that if I have provided to you driver licence information that the provision of this information was voluntary.</strong></p>

    <p><strong>I confirm the information in this form is true and correct and that I have read and signed the Cautions under the Privacy Act 1993.</strong></p>

    <p><strong>I agree that if I enter a tenancy agreement then this application will form part of the tenancy agreement.  Cautions provided pursuant to the Privacy Act 1993 and other information before applying for a residential tenancy.</strong></p>

    <p><strong>In Terms of the Privacy Act 1993 you are given notice that:</strong></p>
    <ul>
      <li>Requests information- this application form asks you to provide personal information and</li>
      <li>Purpose of collection - the information collected on this form is required so that the landlord is better able to assess whether you would be the best applicant on merit for the tenancy and to record limited information contained in the application form on a web-based database.  The limited information concerns your identity and your last known address. This information is required to assist the landlord in locating you to enforce any unsatisfied money order made by the Tenancy Tribunal.</li>
      <li>Recipient of information - the information is provided by you to the Owner/Landlord/Agent/Property Manager.</li>
      <li>Sight information - I/we advise you that you have the right to see the information I/we hold about you.</li>
      <li>Correct information - to correct that information if it is incorrect in accordance with the Privacy Act 1993.</li>
      <li>Holding information - if you do not enter a tenancy agreement with the landlord referred to on this application form, the application form will be destroyed.</li>
      <li>Uses of information - you are advised that some information relating to your identity and last known address may be supplied to a national web-based database.</li>
    </ul>

    <p><strong>Other information drawn to your attention:</strong></p>
    <p>
      By completing this form you evidence the fact that you are applying to rent a residential house/
      flat/apartment, and -
    </p>
    <p>
      You are further advised that: If you do not supply all the information requested (other than the driver licence information) in this application form you may not be considered as the best applicant for the rental property or properties available.
    </p>
    <p>
      <strong>PLEASE NOTE:</strong>
      {' '}
      A separate completed application form is required for each person who proposes to enter a tenancy agreement.
    </p>
  </div>
)
/* eslint-enable max-len */
