/* eslint-disable flowtype/no-types-missing-file-annotation */
import crypto from 'crypto'
import { stringify } from 'query-string'
import {
  validate,
  object,
  array,
  number,
  string,
  any,
} from 'joi'
import { Logger } from './logging'

export const USER_STATUS_REJECTED = 0
export const USER_STATUS_APPROVED = 1
export const USER_STATUS_PENDING = 2

export type File = {
  description: string,
  id: number,
  size: number,
  type: string,
}
;export type Category = {
  files: Array<File>,
  name: string,
  protected: boolean,
}
;export type Section = {
  categories: Array<Category>,
  content: string,
  name: string,
}
;export const fullExpansion = [
  'informationMemorandum',
  'informationMemorandum.segmentCategories',
  'informationMemorandum.users',
  'informationMemorandum.segmentCategories.categories',
  'informationMemorandum.segmentCategories.categories.files',
  'informationMemorandum.segmentCategories.segment',
].join(',')

const listingSchema = object().keys({
  identifier: array().items(number()),
  expand: string().optional(),
  fields: string().optional(),
  snapper_key: string(),
  snapper_signature: string(),
  page: number().optional(),
  rpp: number().optional(),
  sort: string().optional(),
}).pattern(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/, any())

const userSchema = object().keys({
  id: array().items(number()),
  expand: string().optional(),
  fields: string().optional(),
  snapper_key: string(),
  snapper_signature: string(),
  rpp: number().optional(),
  sort: string().optional(),
}).pattern(/^[a-zA-Z]+(\.[a-zA-Z]+)*$/, any())

const cleanResponse = (res, data, params) => {
  if (res.ok) return data

  Logger.error(
    `Snapper search failed: ${data.detail || res.statusText}`,
    { httpStatus: res.status, params, data }
  )

  const e = new Error(`Snapper search failed: ${data.detail || res.statusText}`)
  e.code = res.status
  e.params = params
  throw e
}


const cleanListings = (res, data, params) => {
  cleanResponse(res, data, params)
  let listings
  switch (true) {
    case Array.isArray(data):
      listings = data
      break
    case !data._embedded:
      listings = [data]
      break
    default:
      listings = data._embedded.listings || []
      break
  }

  return {
    totalResults: listings.length,
    results: listings.map(listing => ({
      id: parseInt(listing.identifier, 10),
      snapperId: listing.id,
      isOnlineAuction: !!listing.isOnlineAuction,
      informationMemorandum: listing.informationMemorandum,
    })),
  }
}

const cleanUsers = (res, data, params) => {
  cleanResponse(res, data, params)

  // Not sure where this comes in...
  if (Array.isArray(data)) {
    return { totalResults: data.length, results: data }
  }

  // Getting a single result by ID
  if (!data._embedded) {
    return { totalResults: 1, results: [data] }
  }

  // Getting a set of results
  const { _embedded: { 'sso.users': users = [] } } = data

  return { totalResults: users.length, results: users }
}

const bodyApi = async (
  fetch,
  endpoint,
  key,
  pass,
  body,
  method,
  cleaner = cleanResponse,
) => {
  const query = {
    snapper_key: key,
    // Simple auth method
    snapper_signature: crypto.createHash('sha1').update(pass).digest('hex'),
  }

  const params = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Signature-Format': 'simple',
    },
    body: JSON.stringify(body),
  }
  const url = `${endpoint}?${stringify(query)}`
  const res = await fetch(url, params)
  const data = await res.json()

  try {
    return cleaner(res, data, body)
  } catch (error) {
    Logger.error(`Failed PATCHing ${endpoint}: ${error.message}`, { error })
    throw error
  }
}

const queryApi = async (fetch, endpoint, key, pass, schema, _params, cleaner = cleanResponse) => {
  const params = {
    ..._params,
    snapper_key: key,
    // Simple auth method
    snapper_signature: crypto.createHash('sha1').update(pass).digest('hex'),
  }

  const err = schema && validate(params, schema).error
  if (err) return Promise.reject(new Error('Invalid query'))

  const res = await fetch(`${endpoint}?${stringify(params)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Signature-Format': 'simple',
    },
  })
  const data = await res.json()

  try {
    return cleaner(res, data, params)
  } catch (error) {
    Logger.error(`Failed getting ${endpoint}: ${error.message}`, { error })
    throw error
  }
}

export const getListingsMeta = async params => {
  const res = await fetch('/api/listings/_meta', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  })
  const data = await res.json()

  if (!res.ok) {
    throw new Error(data.message || `${res.status} ${res.statusText}`)
  }
  return data
}

export const queryListingsMeta = (fetch, endpoint, key, pass, params) => (
  queryApi(
    fetch,
    `${endpoint}listings`,
    key,
    pass,
    listingSchema,
    params,
    cleanListings,
  )
)

export const patchListingMeta = (fetch, endpoint, key, pass, listingId, body) => (
  bodyApi(
    fetch,
    `${endpoint}listings/${listingId}`,
    key,
    pass,
    body,
    'PATCH',
  )
)

export const queryUsers = (fetch, endpoint, key, pass, params) => (
  queryApi(
    fetch,
    `${endpoint}users`,
    key,
    pass,
    userSchema,
    params,
    cleanUsers,
  )
)

export const patchUser = (fetch, endpoint, key, pass, userId, body) => (
  bodyApi(
    fetch,
    `${endpoint}users/${userId}`,
    key,
    pass,
    body,
    'PATCH',
  )
)

export const createUser = (fetch, endpoint, key, pass, body) => (
  bodyApi(
    fetch,
    `${endpoint}users`,
    key,
    pass,
    body,
    'POST',
  )
)

export const getUser = async () => {
  const res = await fetch('/api/auth/user', {
    headers: {
      Accept: 'application/json',
    },
    credentials: 'same-origin',
  })
  const data = await res.json()

  if (!res.ok) {
    throw new Error(data.message || `${res.status} ${res.statusText}`)
  }
  return data
}

export const getSnapperClient = (fetch, endpoint, key, pass) => {
  const params = [undefined, fetch, endpoint, key, pass]
  return {
    getListingsMeta: queryListingsMeta.bind(...params),
    createUser: createUser.bind(...params),
    getUsers: queryUsers.bind(...params),
    updateListingMeta: patchListingMeta.bind(...params),
    updateUser: patchUser.bind(...params),
  }
}
