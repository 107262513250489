// @flow

import React, { Component } from 'react'
import { Link } from 'react-router'
import { agentUrl } from '@raywhite/data-utils/lib/data/member/agent'
import AgentCard from './AgentCard.jsx'

type Props = {
  members: Array<Object>,
  organisationIds: Array<number>,
  organisations: Object,
};

export default class AgentList extends Component<Props> {
  static defaultProps = {
    organisations: {},
    organisationIds: [],
  }

  render() {
    const { members, organisationIds, organisations } = this.props
    return (
      <ul data-agents={members.length}>
        {members.map(member => (
          <li key={member.memberId}>
            <Link to={agentUrl(member)}>
              <AgentCard
                organisationIds={organisationIds}
                organisations={organisations}
                member={member}
                showContactDetails={false}
                isStatic
              />
            </Link>
          </li>
        ))}
      </ul>
    )
  }
}
