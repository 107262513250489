// @flow

import React, { Component } from 'react'
import classNames from 'classnames'

import type { Node } from 'react'

import { isElite } from '@raywhite/data-utils/lib/data/listing/listings'
import {
  createId,
  addScrollListener,
  removeScrollListener,
} from '@raywhite/browser-utils/lib/browser/deferredSubscription'
import ListingCard from './ListingCard.jsx'

type State = {
  id: string,
};

type Props = {
  listings: Array<Object>,
  loaded: boolean,
  error: false | Error,
  children: ?Node,
  className?: string,
  cardOptions: Object,
}

export default class ListingCardList extends Component<Props, State> {
  static defaultProps = {
    className: 'proplist',
    cardOptions: {},
    error: false,
  }

  state = { id: createId() }

  /**
   * These listing card lists potentially side scroll
   * so we need to add event listeners for the non-bubbling
   * scroll event.
   */
  componentDidMount() {
    addScrollListener(this.state.id)
  }

  /**
   * If the component is going to unmount, then we won't need
   * the listener anymore.
   */
  componentWillUnmount() {
    removeScrollListener(this.state.id)
  }

  render() {
    const {
      className,
      loaded,
      error,
      listings = [],
      cardOptions,
      children,
    } = this.props

    if (loaded && !listings.length) {
      return (
        <div className="proplist_no_results centered_text">
          <div>
            {!!error ? (
              <em>Something went wrong loading listings :(</em>
            ) : (
              <em>No properties found.</em>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={className} id={this.state.id} data-proplist-itemnum={listings.length === 1 ? '1' : undefined}>
        {!!listings.length && (
          <ul>
            {listings.map((listing, idx) => (
              <li
                key={listing.id}
                className={classNames({
                  proplist_item_wrap: true,
                  elite_listing: isElite(listing),
                })}
              >
                <ListingCard
                  {...cardOptions}
                  listing={listing}
                  context={{
                    previousListItems: listings.slice(0, idx),
                  }}
                />
              </li>
            ))}
          </ul>
        )}
        {children}
      </div>
    )
  }
}
