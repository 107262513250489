import PropTypes from 'prop-types'
import { Provider as ReduxProvider } from 'react-redux'
import { createContextProvider } from '../hocs/providers'

const AppContextProvider = createContextProvider('AppContextProvider', [
  // eslint-disable-next-line react/forbid-foreign-prop-types
  { name: 'store', type: ReduxProvider.propTypes.store },
  { name: 'fetch', type: PropTypes.func.isRequired },
  { name: 'getBrokers', type: PropTypes.func.isRequired },
  { name: 'getListings', type: PropTypes.func.isRequired },
  { name: 'getSuburbs', type: PropTypes.func.isRequired },
  { name: 'getListingsMeta', type: PropTypes.func.isRequired },
  { name: 'getOrganisations', type: PropTypes.func.isRequired },
  { name: 'getOrganisationsMetadata', type: PropTypes.func.isRequired },
  { name: 'getAgentMetadata', type: PropTypes.func.isRequired },
  { name: 'getMembers', type: PropTypes.func.isRequired },
  { name: '_window', type: PropTypes.object },
  { name: 'getContentTypes', type: PropTypes.func },
  { name: 'getContentForType', type: PropTypes.func },
  { name: 'getContentForPath', type: PropTypes.func },
  { name: 'getSyndicatedNews', type: PropTypes.func },
  { name: 'getMenus', type: PropTypes.func },
  { name: 'getSyndicatedNews', type: PropTypes.func },
  { name: 'getUser', type: PropTypes.func },
])

// eslint-disable-next-line react/forbid-foreign-prop-types
if (AppContextProvider.propTypes) {
  // propTypes are undefined in production
  // eslint-disable-next-line react/forbid-foreign-prop-types
  AppContextProvider.propTypes.children = PropTypes.node.isRequired
}

export default AppContextProvider
