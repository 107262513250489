import { urlToFileName } from '@raywhite/data-utils/lib/data/listing/file'
import { defaultSearchImage } from '../../../constants'

export function defaultHeaderImage(headerImages, typeCode) {
  if (Array.isArray(typeCode) && typeCode.length > 1) {
    return headerImages.default ? headerImages.default.url : undefined
  }

  let headerImage
  switch (Array.isArray(typeCode) ? typeCode[0] : typeCode) {
    case 'SAL':
    case 'REN':
      headerImage = headerImages.residential || headerImages.default
      break
    case 'ALP':
    case 'CLR':
    case 'RUR':
    case 'LVS':
    case 'LVW':
      headerImage = headerImages.rural || headerImages.default
      break
    case 'BUS':
    case 'COM':
      headerImage = headerImages.commercial || headerImages.default
      break
    default:
      headerImage = headerImages.default
  }
  return headerImage ? headerImage.url : undefined
}

/**
 * TODO: We should really be doing a better job of finding an acceptable
 * image to use as a header image... the function below only takes into
 * account whether or not an image meets a given minimum width, we should
 * also be looking for the largest (most expensive etc) image.
 */

// Get the first listing with a decent size image for the header background
export function firstListingsImage(listings, minWidth = 640) {
  for (const listing of listings) {
    if (listing.images[0] && listing.images[0].width >= minWidth) {
      return listing.images[0].url
    }
  }
  return undefined
}

export function getHeaderImage(headerImage, listings, resourceUrl = '') {
  if (headerImage) return headerImage
  if (!listings.loaded) return undefined

  return firstListingsImage(listings.items) || `${resourceUrl}${defaultSearchImage}`
}

export function titleToName(title) {
  // Heuristic: if it doesn't have a file extension, it has been edited, and we won't
  // clean it up
  if (!/\./.test(title)) return title
  return urlToFileName(`/${title}`).name
}

function inferContentType(title) {
  if (!/\./.test(title)) return undefined
  const extension = title.split('.').pop()
  switch (extension) {
    case 'pdf': return 'application/pdf'
    default:
      return `unknown/${extension}`
  }
}

export function categoriseListingAttachments(listingId, attachments) {
  const files = attachments.reduce((result, { id, contentType, title, url }) => {
    const inferredContentType = contentType || inferContentType(title)
    const { name: description, type } = (title && inferredContentType)
      ? { name: titleToName(title), type: inferredContentType.split('/').pop() }
      : urlToFileName(url)
    result.push({
      id,
      description,
      type,
      url: `/api/auth/property/${listingId}/attachment/${id}`,
    })
    return result
  }, [])

  return {
    name: 'Attachments',
    isPublic: false,
    reportable: false,
    files,
  }
}
