// @flow
import React from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { collectionPage as collectionPageSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import JsonLD from './JsonLD.jsx'
import type { LoadableBroker } from '../../types'

import BrokerCard from './BrokerCard.jsx'

type Props = {
  brokers: Array<LoadableBroker>,
  countryCode: string,
};

const linkMeta = eventMeta({
  category: 'Outbound',
  action: 'Click',
  label: 'LoanMarket',
})

const lmLogo = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/lm_logo.svg')
  : undefined

const metaDescription = [
  'Loan Market mortgage advisers aren’t owned by a bank, they work for you.',
  'With access to over 60 lenders they’ll work with you to find a competitive',
  'loan to suit your needs.',
].join(' ')

const calculatorLinks = {
  AU: {
    repayments: ' https://www.loanmarket.com.au/calculators/loan-repayment-calculator/',
    borrow: 'https://www.loanmarket.com.au/calculators/borrowing-power-calculator/',
    stampDuty: 'https://www.loanmarket.com.au/calculators/stamp-duty-calculator/',
  },
  NZ: {
    repayments: 'https://www.loanmarket.co.nz/calculators/loan-repayment',
    borrow: 'https://www.loanmarket.co.nz/calculators/borrowing-capacity-calculator',
  },
}

const COPY = {
  AU: (
    <p className="centered_text inner_sm">
      There’s a reason most Aussies turn to a broker for their home loan.
      {' '}
      Loan Market brokers have access to over 60 lenders and take the time
      {' '}
      to understand your goals to find the right loan for you.
    </p>
  ),
  NZ: (
    <p className="centered_text inner_sm">
      There’s a reason so many Kiwis turn to an adviser for their home loan.
      {' '}
      Loan Market advisers have access to over 20 lenders and take the time to
      {' '}
      understand your goals to find the right loan for you.
    </p>
  )
}

const HomeLoans = ({ brokers, countryCode = 'AU' }: Props) => {
  const buildUtms = content => queryString.stringify({
    utm_source: 'raywhite',
    utm_medium: 'referral',
    utm_campaign: 'officewebsite',
    utm_term: 'homeloans',
    utm_content: content,
  })
  const validBrokers = brokers.filter(b => !b.error)
  const loanMarketUrl = (
    (validBrokers.length === 1 && validBrokers[0]?.website)
    || (countryCode === 'NZ' && 'https://www.loanmarket.co.nz/advisers')
    || 'https://my.loanmarket.com.au'
  )

  const header = (
    <h1 className="centered_text centered_text lm_logo">
      <span className="sr">Loan Market</span>
    </h1>
  )

  const [primary] = brokers

  const calculators = {
    repayments: primary?.calculatorUrls?.repayments || calculatorLinks[countryCode].repayments,
    borrow: primary?.calculatorUrls?.borrow || calculatorLinks[countryCode].borrow,
    stampDuty: primary?.calculatorUrls?.stampDuty || calculatorLinks[countryCode].stampDuty,
  }

  return (
    <div className="lm">
      <Helmet>
        <title>Home Loans from Loan Market</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <JsonLD>
        {collectionPageSchema({
          name: 'Home Loans from Loan Market',
          description: metaDescription,
          image: validBrokers.length === 1
            ? validBrokers[0].image
            : lmLogo,
        })}
      </JsonLD>
      <header className="lm_header dark_bg">
        {validBrokers.length > 1 ? header : (
          <a
            href={`${loanMarketUrl}?${buildUtms('lmlogo')}`}
            target="_blank"
            rel="noopener noreferrer"
            {...linkMeta}
          >
            {header}
          </a>
        )}
        {COPY[countryCode]}
        <p className="subheading centered_text inner_sm">
          Online, offline, anytime. Get your finances sorted today and bid with confidence.
        </p>
        {!validBrokers.length && (
          <div className="lm_agent_contact centered_text">
            <a
              href={`${loanMarketUrl}?${buildUtms('brokerbutton')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
              {...linkMeta}
            >
              Contact Loan Market
            </a>
          </div>
        )}
      </header>
      <div className="lm_agent_wrap">
        {validBrokers.map(broker => (
          <BrokerCard
            key={broker.brokerId}
            broker={broker}
            utmTerm="homeloans"
          />
        ))}
        {validBrokers.length === 1 && (
          <div className="lm_agent_contact centered_text">
            <a
              href={`${validBrokers[0].website}?${buildUtms('brokerbutton')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
              {...linkMeta}
            >
              Contact
              {' '}
              {validBrokers[0].firstName}
            </a>
          </div>
        )}
      </div>
      <div className="inner_lg">
        <div className="lm_calculators_wrap">
          <h2 className="charlie inner_md centered_text">Loan Calculators</h2>
          <p className="subheading inner_sm centered_text">
            Free home loan calculators to help you work out your borrowing power,
            {' '}
            how much mortgage repayments will be{countryCode === 'AU' && ', check stamp duty charges'} and more.
          </p>
          <div className="lm_calculators_list_wrap">
            <ul className="lm_calculators_list">
              <li className="lm_calculator_repayment">
                <a
                  href={`${calculators.repayments}?${buildUtms('repaymentcalculator')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>Loan Repayment Calculator</h3>
                  <span className="lm_more">View Calculator</span>
                </a>
              </li>
              <li className="lm_calculator_borrow">
                <a
                  href={`${calculators.borrow}?${buildUtms('borrowcalculator')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3>How Much Can I Borrow</h3>
                  <span className="lm_more">View Calculator</span>
                </a>
              </li>
              {countryCode === 'AU' && (
                <li className="lm_calculator_stamp">
                  <a
                    href={`${calculators.stampDuty}?${buildUtms('stampdutycalculator')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>Stamp Duty Calculator</h3>
                    <span className="lm_more">View Calculator</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {countryCode === 'AU' && (
        <div className="inner_lg">
          <div className="lm_achievements">
            <h2 className="charlie inner_md centered_text">Our Achievements</h2>
            <p className="subheading inner_sm centered_text">
              Although we're not in it for the awards, it's great to be recognised
              {' '}
              as leaders in the mortgage broking industry.
            </p>
            <div className="lm_medals">
              <img src="https://rw-media.s3.amazonaws.com/raywhitecom/wp-content/uploads/2022/03/05132730/loan-market-awards.png" alt="Loan Market Achievements" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

HomeLoans.propTypes = {
  brokers: PropTypes.array.isRequired,
}

export default HomeLoans
