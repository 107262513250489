import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TRIGGERED = 'IMAGE_LOADING_TRIGGERED'
const COMPLETE = 'IMAGE_LOADING_COMPLETE'

export default class ResponsiveIframe extends Component {
  static propTypes = {
    outerWrap: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }

  static defaultProps = {
    outerWrap: true,
    title: '',
  }

  state = {
    constant: TRIGGERED,
  }

  toggleDisplay = () => {
    this.setState({
      constant: COMPLETE,
    })
  }

  render() {
    const { constant } = this.state
    const { outerWrap, title, ...attrs } = this.props
    const inner = (
      <div className="iframe_wrap">
        <iframe
          title={title}
          className={classNames('video_item_wrapper', {
            loading: constant !== COMPLETE,
            image_loaded: constant === COMPLETE,
          })}
          {...attrs}
          onLoad={this.toggleDisplay}
        />
      </div>
    )

    return outerWrap
      ? <div className="iframe_outer_wrap">{inner}</div>
      : inner
  }
}
