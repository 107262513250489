// @flow

import React from 'react'
import { Link } from 'react-router'

type Props = {
  siteConfig: {
    currentSlug: string,
    headerTypeCode?: Array<string>,
    recentDescription: string,
    recentSlug: string,
    recentSort: string,
    recentStatusCode: Array<string> | string,
    searchParams: {
      size: number,
      sort: string,
      typeCode?: Array<string>,
      statusCode?: Array<string>,
      subTypeCode?: any,
    },
    showInvestments: boolean,
    transactionTitle: string,
  },
  cardOptions: { badgeField: string },
  Transactions: Function,
};

const RecentTransactionBox = (
  { siteConfig, cardOptions, Transactions }: Props
) => (
  <section className="home_sold_wrap">
    <div className="inner_lg">
      <div className="home_sold">
        <span className="mini muted preheading">In The Market</span>
        <h3 className="charlie">
          {siteConfig.transactionTitle}
        </h3>
        <Transactions
          className="home_sold_list_wrap proplist"
          cardOptions={cardOptions}
        />
        <div className="home_more_wrap">
          <Link
            className="btn"
            to={`/properties/${siteConfig.recentSlug}`}
          >
            See more
            {' '}
            {siteConfig.recentDescription}
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default RecentTransactionBox
