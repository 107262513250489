// @flow

import React from 'react'
import { Link } from 'react-router'
import ContentCardList from '../ContentCardList.jsx'
import Loader from '../Loader.jsx'

import type { CardOptions } from '../ContentCard.jsx'

type Props = {
  items: Array<Object>,
  loaded: boolean,
  typeName: string,
  className?: string,
  morePath?: string,
  moreLabel?: string,
  listClassName?: string,
  cardOptions?: CardOptions,
};

/**
 * Needs much more generic class names.
 */
const ContentSummary = ({
  className,
  items,
  morePath,
  moreLabel,
  cardOptions,
  loaded,
  typeName,
  listClassName,
}: Props) => {
  if (loaded && items.length === 0) {
    // Show nothing if no content
    return (
      <p>
        <em>
          {`No ${typeName && typeName.toLowerCase()} found.`}
        </em>
      </p>
    )
  }

  const loader = !loaded && items.length === 0 && <Loader />
  return (
    <div className={className}>
      {loader}
      <ContentCardList
        className="home_content_list_wrap"
        entities={items}
        typeName={typeName}
        loaded={loaded}
        cardOptions={cardOptions}
        listClassName={listClassName}
      />
      {!!(morePath && moreLabel) && (
        <div className="home_more_wrap inner centered_text">
          <Link to={morePath} className="btn">{moreLabel}</Link>
        </div>
      )}
    </div>
  )
}

export default ContentSummary
