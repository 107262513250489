import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { parse as parseUrl } from 'url'
import { stringify } from 'query-string'
import { plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'

// Extract facebook username from something that might be a facebook URL
const getUsername = maybeUrl => maybeUrl && (
  /^https?:\/\//.test(maybeUrl)
    ? parseUrl(maybeUrl).pathname.split('/').filter(x => !!x).pop()
    : maybeUrl
)

/**
 * Process image data to be in line with FB's recommendations.
 *
 * See https://developers.facebook.com/docs/sharing/best-practices#images
 */
const processImage = ({ url, height, width, resizable = true } = {}, mayCropImage) => {
  if (!url) {
    return {}
  }

  // Allow images to be specified as not resizable, e.g. for external images
  if (!resizable) {
    return {
      imageUrl: url,
      imageWidth: width,
      imageHeight: height,
    }
  }

  const [, extension] = url.match(/(\.(?:jpe?g|png|gif))/i) || []

  // Invalid looking URL, pretend it's not there
  if (!extension) return {}

  const params = {
    mode: mayCropImage ? 'crop' : 'pad',
    scale: 'both',
    bgcolor: '595959',
    quality: '90',
  }
  let imageWidth
  let imageHeight

  if (!(height && width)) {
    // Unknown dimensions, force to minimum FB dimensions
    params.width = imageWidth = 1200
    params.height = imageHeight = 630
  } else {
    params.width = imageWidth = Math.max(1200, Math.min(2400, width))
    params.height = imageHeight = Math.max(630, Math.min(1260, Math.ceil(imageWidth / 1.91)))
  }

  return {
    imageUrl: `${url}?${stringify(params)}&_ext=${extension}`,
    imageWidth,
    imageHeight,
  }
}

const OpenGraph = props => {
  const {
    mayCropImage,
    type,
    siteName,
    url,
    title,
    description,
    image = {},
    place: {
      location: {
        latitude,
        longitude,
      } = {},
    } = {},
    business: {
      contactData: {
        streetAddress,
        locality,
        region,
        postalCode,
        countryName,
        email,
        phoneNumber,
        faxNumber,
        website,
      } = {},
    } = {},
    profile: {
      firstName,
      lastName,
      username,
    } = {},
    article: {
      modifiedTime,
      publishedTime,
      section,
    } = {},
  } = props

  const { imageUrl, imageWidth, imageHeight } = processImage(image, mayCropImage)
  const meta = (property, content) => <meta property={property} content={content} />
  const isArticle = type === 'article'
  const isBusiness = type === 'business.business'
  const isProfile = type === 'profile'
  const isPlace = isBusiness || type === 'profile'

  return (
    <Helmet>
      {meta('og:type', type)}
      {meta('og:url', url)}
      {meta('og:title', plainText(title))}
      {meta('og:description', plainText(description))}
      {siteName && meta('og:site_name', siteName)}
      {imageUrl && meta('og:image', imageUrl)}
      {imageUrl && /^https:\/\//.test('og:image') && meta('og:image:secure_url', imageUrl)}
      {imageUrl && !!imageWidth && meta('og:image:width', imageWidth)}
      {imageUrl && !!imageHeight && meta('og:image:height', imageHeight)}
      {isPlace && !!latitude && meta('place:location:latitude', latitude)}
      {isPlace && !!longitude && meta('place:location:longitude', longitude)}
      {isProfile && !!firstName && meta('profile:first_name', firstName)}
      {isProfile && !!lastName && meta('profile:last_name', lastName)}
      {isProfile && !!username && meta('profile:username', getUsername(username))}
      {isBusiness && !!email && meta('business:contact_data:email', email)}
      {isBusiness && !!website && meta('business:contact_data:website', website)}
      {isBusiness && !!phoneNumber && (
        meta('business:contact_data:phone_number', phoneNumber)
      )}
      {isBusiness && !!faxNumber && meta('business:contact_data:fax_number', faxNumber)}
      {isBusiness && !!streetAddress && (
        meta('business:contact_data:street_address', streetAddress)
      )}
      {isBusiness && !!locality && meta('business:contact_data:locality', locality)}
      {isBusiness && !!region && meta('business:contact_data:region', region)}
      {isBusiness && !!postalCode && meta('business:contact_data:postal_code', postalCode)}
      {isBusiness && !!countryName && meta('business:contact_data:country_name', countryName)}
      {isArticle && !!modifiedTime && meta('article:modified_time', modifiedTime)}
      {isArticle && !!publishedTime && meta('article:published_time', publishedTime)}
      {isArticle && !!section && meta('article:section', section)}
    </Helmet>
  )
}

OpenGraph.propTypes = {
  type: PropTypes.oneOf(['place', 'business.business', 'profile', 'article']),
  // Whether auto resizing may crop the image; not safe for things like agent photos
  mayCropImage: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  siteName: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  })]),
  place: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  business: PropTypes.shape({
    contactData: PropTypes.shape({
      streetAddress: PropTypes.string,
      locality: PropTypes.string,
      region: PropTypes.string,
      postalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      countryName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      faxNumber: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userName: PropTypes.string,
  }),
  article: PropTypes.shape({
    modifiedTime: PropTypes.string,
    publishedTime: PropTypes.string,
    section: PropTypes.string,
  }),
}

OpenGraph.defaultProps = {
  mayCropImage: false,
  description: '',
}

export default OpenGraph
