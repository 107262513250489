import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createPriceString } from '@raywhite/data-utils/lib/data/listing/formatting'
import { mayShowUnderOffer } from '@raywhite/helpers-utils/lib/helpers/rules'

export default class Price extends Component {
  static propTypes = {
    className: PropTypes.string,
    expandedString: PropTypes.bool,
    listing: PropTypes.object.isRequired,
    showOfferTag: PropTypes.bool,
  }

  static defaultProps = {
    showOfferTag: true,
  }

  shouldComponentUpdate(props) {
    return this.props.listing.id !== props.listing.id
  }

  render() {
    const {
      className,
      expandedString,
      showOfferTag,
      listing,
      listing: {
        address: {
          countryCode,
        },
        availableDate,
        setSaleDate,
        statusCode,
        tender,
        underOffer,
      },
    } = this.props

    const priceString = createPriceString(listing, expandedString)
    const showUnderOffer = showOfferTag && mayShowUnderOffer(underOffer, statusCode, countryCode)
    const showSetSale = showOfferTag && statusCode === 'CUR' && !!setSaleDate
    const showTenderSale = showOfferTag && statusCode === 'CUR' && !!tender
    const showAvailableNow = (
      showOfferTag
      && statusCode === 'CUR'
      && availableDate
      && new Date(availableDate) <= new Date()
    )
    const badge = [
      showUnderOffer && 'Under Offer',
      showSetSale && 'Deadline Sale',
      showTenderSale && 'Tender Sale',
      showAvailableNow && 'Available Now',
    ].filter(Boolean).shift()

    return (
      <span className={className}>
        {priceString}
        {badge && <span className="price_feature">{badge}</span>}
      </span>
    )
  }
}
