// @flow
import React from 'react'
import ListingSection from './ListingSection.jsx'
import type { Section } from '../../snapperClient'

type Props = {
  sections: Array<Section>,
  listingId: number,
  authorised: boolean,
  toggleSignInModal: () => void,
};

const InformationMemorandum = ({ sections, ...props }: Props) => {
  if (!sections.length) return null

  return (
    <div className="pdp_commercial_info_wrap dark_bg">
      <div className="inner_smadium">
        {sections.map(section => (
          <ListingSection
            {...section}
            key={section.name}
            {...props}
          />
        ))}
      </div>
    </div>
  )
}

export default InformationMemorandum
