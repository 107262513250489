// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'
import { connect } from 'react-redux'

import type { ComponentType } from 'react'

import {
  getUserData,
  loadUser,
  patchUserData,
} from '../../redux/modules/user'
import { getDisplayName } from './providers'
import Loader from '../presentational/Loader.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import { setShowingModal } from '../../redux/modules/app'

import type { Dispatch, Loadable } from '../../redux/types'

type Props = {
  getUser?: () => Promise<Object>,
  dispatch: Dispatch,
  user: Loadable & {
    userId?: number,
    phoneNumber?: string,
    justSignedIn: boolean,
  },
  authFailed: boolean,
};

type Options = {
  required: boolean,
  deferred: boolean,
};

const mapStateToProps = state => ({
  user: getUserData(state.user),
  authFailed: state.app.authFailed,
})

let pushedUserId = false

/**
 * Load the current user and provide them as the `user` prop to the wrapped
 * component.  The `isAuthenticated` prop will also be set.
 *
 * The required option can be set in order to render a placeholder if the user
 * is not authenticated instead of rendering the wrapped component.  This defaults
 * to true because it's hard to miss that way :)
 *
 * The deferred option can be set to render a loader while loading user data instead
 * of rendering the wrapped component before the user state is known.
 */
const withUser = ({ required = true, deferred = true }: Options = {}) => (
  // eslint-disable-next-line no-undef
  (Wrappee: ComponentType<any>) => {
    class Wrapper extends Component<Props> {
      componentDidMount() {
        this.loadUser()
        this.report()
      }

      componentDidUpdate() {
        this.report()
      }

      loadUser = (reload: boolean = false) => {
        const { dispatch } = this.props
        const getUser = this.props.getUser || this.context.getUser
        dispatch(loadUser(getUser, reload))
      }

      report() {
        const { dispatch, user } = this.props
        const dataLayer = this.context._window && this.context._window.dataLayer

        if (user && user.userId && dataLayer) {
          // Set the user ID for future analytics, just once
          if (!pushedUserId) {
            pushedUserId = true
            dataLayer.push({
              rwUserId: user.userId,
            })
          }

          // Log the sign in if the user has just signed in, only happens once per session.
          if (user.justSignedIn) {
            // Mark user as not just signed in any more
            dispatch(patchUserData({ justSignedIn: false }))

            // Open nag modal if no phone number
            if (!user.phoneNumber) dispatch(setShowingModal('updateUser'))

            const signedIn = {
              event: 'rwCustom',
              rwCustomData: {
                category: 'Sign In',
                action: 'Signed In',
                label: undefined,
              },
            }

            // It's possible that multiple `withUser` HOCs would trigger this twice,
            // so we check to ensure it's not already logged
            const isLogged = dataLayer.reduce((logged, item) => {
              if (logged) return logged
              if (!item || item.event !== signedIn.event || !item.rwCustomData) {
                return false
              }
              return (
                item.rwCustomData.category === signedIn.rwCustomData.category
                && item.rwCustomData.action === signedIn.rwCustomData.action
              )
            }, false)

            // Log sign in event if not already logged
            if (!isLogged) dataLayer.push(signedIn)
          }
        }
      }

      render() {
        const {
          authFailed,
          user: { loaded, error, notFound, userId },
        } = this.props

        if (error) return <ErrorPage error={error} />

        // Note: if user is required rendering _must_ be deferred
        if (!loaded && (deferred || required)) return <Loader />

        if (required && notFound) {
          const message = authFailed
            ? 'Sign In Failed'
            : 'Not Logged In'
          return (
            <ErrorPage
              message={message}
              linkLabel="Go Home"
              linkUrl="/"
            >
              <div>
                <ul>
                  <li>
                    <a href="/api/auth/signin/google">Sign in with Google</a>
                  </li>
                  <li>
                    <a href="/api/auth/signin/facebook">Sign in with Facebook</a>
                  </li>
                </ul>
              </div>
            </ErrorPage>
          )
        }

        return (
          <Wrappee
            {...this.props}
            loadUser={this.loadUser}
            isAuthenticated={!userId}
          />
        )
      }
    }

    Wrapper.displayName = `withUser(${getDisplayName(Wrappee)})`
    Wrapper.contextTypes = {
      getUser: PropTypes.func,
      _window: PropTypes.object,
    }

    // $FlowFixMe Not sure why flow is confused here, there's no mapDispatchToProps
    return connect(mapStateToProps)(hoistStatics(Wrapper, Wrappee))
  }
)

export default withUser
