// @flow

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import type { Node } from 'react'

import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { depressify, plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { createTimeSince } from '@raywhite/helpers-utils/lib/helpers/time'
import {
  article as articleSchema,
  newsArticle as newsArticleSchema,
} from '@raywhite/helpers-utils/lib/helpers/structuredData'

import {
  format as formatUrl,
  parse as parseUrl,
} from '../../utils/url'
import DeferredImage from './DeferredImage.jsx'
import { getItemImage, getItemAuthor } from '../../utils/wordpress/content'
import VideoPlaylist from './VideoPlaylist.jsx'
import ShareLinks from './ShareLinks.jsx'
import JsonLD from './JsonLD.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'
import OpenGraph from './OpenGraph.jsx'

type Props = {
  content: Object,
  isCommercial: boolean,
  showNavigation: boolean,
  showSharing: boolean,
  showDate: boolean,
  children: ?Node,
  typeName?: string,
  typeSlug?: string,
  primaryDomain: string,
  protocol: string,
  twitter?: string,
  className?: string,
  agents: Object,
  listings: Object,
  _window: ?Object,
  loadAgents: () => void,
  loadListing: () => void,
};

class ContentItem extends Component<Props> {
  content: ?HTMLElement

  static defaultProps = {
    showNavigation: false,
    showSharing: false,
    showDate: false,
  }

  constructor(props: Props) {
    super(props)

    const { _window } = this.props
    if (_window) this.initialiseScripts(_window)
  }

  componentDidMount() {
    this.renderScripts()
  }

  loadedScripts: ?Object = undefined

  initialiseScripts = (_window: Object) => {
    // $FlowFixMe
    const sources = [..._window.document.body.querySelectorAll('script')]
      .map(el => el instanceof HTMLScriptElement && el.src)
      .filter(Boolean)
    this.loadedScripts = [...new Set(sources)].reduce((acc, source) => ({
      ...acc,
      [source]: true,
    }), {})
  }

  setContent = (el: ?HTMLElement) => {
    this.content = el
  }

  renderScripts = () => {
    const { _window } = this.props
    if (!_window || !this.content) return

    const loadedScripts = this.loadedScripts = this.loadedScripts || {}
;[...this.content.querySelectorAll('script')].forEach((el) => {
      if (!(el instanceof HTMLScriptElement)) return
      if (!el.src || loadedScripts[el.src]) return

      const script = _window.document.createElement('script')
      script.src = el.src

      if (el.parentNode) {
        el.parentNode.replaceChild(script, el)
        loadedScripts[el.src] = true
      }
    })
  }

  render() {
    const {
      content,
      isCommercial,
      showNavigation,
      showSharing,
      showDate,
      typeName,
      typeSlug,
      children,
      primaryDomain,
      protocol,
      twitter,
      className,
      agents,
      listings,
      loadAgents,
      loadListing,
    } = this.props
    const image = getItemImage(content)
    const author = getItemAuthor(content)
    const _content = content.content ? content.content.rendered : ''
    const body = depressify(_content)
    const parts = parseUrl(content.link)
    const url = formatUrl({ ...parts, protocol, hostname: primaryDomain })
    const schema = typeSlug === 'news' ? newsArticleSchema : articleSchema

    const rawTitle = content.title && content.title.rendered
    const safeTitle = rawTitle && plainText(rawTitle)
    const seoTitle = content.seo_title || safeTitle

    const structuredData = schema({
      headline: seoTitle,
      articleSection: typeName,
      articleBody: body && plainText(body.__html),
      url,
      image: image && `${image.url}?maxwidth=1280`,
      datePublished: content.date,
      dateModified: content.modified,
    })
    const metaDescription = content.excerpt
      ? plainText(content.excerpt.rendered)
      : structuredData.articleBody

    // TODO: markup sanitization will continue.
    return (
      <div
        className={classNames('contentpg', className, {
          [`${content.slug.replace(/-/g, '_')}_pg`]: true,
        })}
      >
        <Helmet>
          <title>{typeName ? `${seoTitle} - ${typeName}` : seoTitle}</title>
          <meta name="description" content={excerptify(metaDescription, 160)} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          title={safeTitle}
          description={metaDescription}
          image={structuredData.image}
          site={twitter}
        />
        <OpenGraph
          mayCropImage
          type="article"
          url={url}
          image={image}
          title={safeTitle}
          description={metaDescription}
          article={{
            modifiedTime: structuredData.datePublished,
            publishedTime: structuredData.dateModified,
            section: structuredData.articleSection,
          }}
        />
        {image && (
          <div className="contentpg_feature">
            <div
              className="responsive_image_wrapper_wrapper"
              style={{ maxWidth: image.width }}
            >
              <DeferredImage
                image={image}
                attrs={{
                  width: 750,
                  scale: 'both',
                }}
              />
            </div>
          </div>
        )}
        <div className={!!content.videos ? 'inner_lg' : 'inner_md'}>
          <div className="pg_header">
            {!!typeName && (
              <span className="mini muted preheading">{typeName}</span>
            )}
            <h1
              className="charlie centered_text"
              dangerouslySetInnerHTML={{
                __html: rawTitle,
              }}
            />
          </div>
          {showDate && !!content.date_gmt && (
            <div className="content_post_meta mini muted">
              <time>{createTimeSince(new Date().getTime(), content.date_gmt)}</time>
              <span className="content_post_meta_bull">&bull;</span>
              {!!author && (
                <span className="content_post_author">{`By ${author}`}</span>
              )}
            </div>
          )}
          <div
            className="contentpg_content"
            ref={this.setContent}
            dangerouslySetInnerHTML={body}
          />
          {!!content.videos && (
            <VideoPlaylist
              videos={content.videos}
              agents={agents}
              listings={listings}
              loadAgents={loadAgents}
              loadListing={loadListing}
            />
          )}
          {showSharing && (
            <div className="contentpg_share centered_text">
              <ShareLinks
                id={content.id}
                url={url}
                linkedIn={isCommercial}
              />
            </div>
          )}
        </div>
        {children}
        {showNavigation && (
          <div className="inner">
            <div className="contentpg_pagination">
              <a href="" className="mini anchor">Prev Article</a>
              <a href="" className="mini anchor">Next Article</a>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ContentItem
