import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { intersection } from '@raywhite/helpers-utils/lib/helpers/array'
import { normalizeMeasurements, extractPrice } from '@raywhite/data-utils/lib/data/listing/formatting'
import withSearch from '../hocs/withSearch'
import ListingCardList from '../presentational/ListingCardList.jsx'

// Get absolute percentage difference between measurements
function getMeasurementScores(a, b) {
  const {
    building: { value: valueA },
    land: { metres: metresA },
  } = normalizeMeasurements(a.measurements)
  const {
    building: { value: valueB },
    land: { metres: metresB },
  } = normalizeMeasurements(b.measurements)

  // eslint-disable-next-line no-nested-ternary
  const building = valueA && valueB
    ? Math.abs(valueA - valueB) / valueA
    : (valueA ? 1 : 0)
  // eslint-disable-next-line no-nested-ternary
  const land = metresA && metresB
    ? Math.abs(metresA - metresB) / metresA
    : (metresA ? 1 : 0)

  return { building, land }
}

// Get absolute percentage difference between prices
const getPriceScore = (priceA, priceB) => Math.abs(priceA - priceB) / priceA

const getPostCodeScore = (a, b) => {
  if (!a || !b) return 0
  if (a === b) return -3
  if (a.substring(2) === b.substring(2)) return -1
  return 0
}

const codes = categories => categories.map(
  ({ code, subCode = '' }) => `${code}:${subCode}`
)

/**
 * TODO: This function needs to be refined to deal with all edge cases,
 * such as properties with zero beds, baths, cars.
 *
 * This is an incredibly simple implementation of a algorithm to quantify the
 * conceptual similarity between two listings. Note that a lower return value
 * is better.
 *
 * @param {Object} a - a listings
 * @param {Object} b -a listing to compare a to
 * @return {Number} a numerical deviation (lower means more similar)
 */
function quantifySimilarity(a, b) {
  const { building, land } = getMeasurementScores(a, b)

  const beds = Math.abs((a.bedrooms || 0) - (b.bedrooms || 0)) * 4
  const baths = Math.abs((a.bathrooms || 0) - (b.bathrooms || 0))
  const cars = Math.abs((a.carSpaces || 0) - (b.carSpaces || 0)) * 0.5
  const postCode = getPostCodeScore(a.address.postCode, b.address.postCode)

  // eslint-disable-next-line no-nested-ternary
  const rentPrice = a.rentPrice && b.rentPrice
    ? (Math.abs(a.rentPrice - b.rentPrice) / a.rentPrice) * 10
    : (a.rentPrice ? 10 : 0)

  const priceA = extractPrice(a.displayPrice)
  const priceB = extractPrice(b.displayPrice)
  // eslint-disable-next-line no-nested-ternary
  const sellPrice = priceA && priceB
    ? getPriceScore(priceA, priceB) * 10
    : (priceA ? 10 : 0)

  const categoryWeight = ['COM', 'LVS', 'LVW', 'STU'].indexOf(a.typeCode) !== -1
    ? -10
    : -2
  const categories = intersection(codes(a.categories), codes(b.categories)).length > 1
    ? categoryWeight
    : 0

  const areaWeight = a.typeCode === 'COM' ? 3 : 1
  return (
    building * areaWeight
    + land * areaWeight
    + beds
    + baths
    + cars
    + rentPrice
    + sellPrice
    + postCode
    + categories
  )
}

/**
 * Determine the sort value that should be used for a given type of listing.
 * We should use older values for current listings, but newer values for
 * sold and leased listings.
 *
 * @param {String} statusCode
 *
 * @returns {String} the sort value to use
 */
const sorts = { LSE: ['updatedAt desc', 'id desc'], SLD: 'soldDate desc' }
function getSortValue(statusCode) {
  return sorts[statusCode] || 'creationTime desc'
}

/**
 * TODO: This will need to use a bunch of properties to determine which of the listings
 * that we pulled down are actually the most similar. It probably makes more sense to
 * grab a bunch of listings and do this on the client side as the only way to do this
 * over a request is to do an aggregation, followed by a request, or to do multiple
 * requests.
 */
class SimilarListingsSet extends Component {
  render() {
    const {
      listing,
      loaded,
      listings = [],
      error,
      slice,
      render,
      cardOptions,
    } = this.props

    /**
     * In order to determine which listings are the most similar, we sort
     * the values we have based on how similar-ish they are to the main listing,
     * and then slice of the number that we need.
     */
    const _listings = listings.filter(a => listing.id !== a.id)
      .sort((a, b) => quantifySimilarity(listing, a) - quantifySimilarity(listing, b))
      .slice(0, slice)

    _listings.forEach(l => quantifySimilarity(listing, l))

    const result = (
      <ListingCardList
        id={listing.id}
        listings={_listings}
        loaded={loaded}
        error={error}
        cardOptions={cardOptions}
      />
    )

    return render
      ? render(!loaded || !!_listings.length, result)
      : result
  }
}

SimilarListingsSet.propTypes = {
  listing: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  listings: PropTypes.array.isRequired,
  slice: PropTypes.number.isRequired,
  error: PropTypes.bool,
  // An optional function to render with, will be called with (bool, component)
  render: PropTypes.func,
  cardOptions: PropTypes.object,
}

/**
 * The SimilarListings component takes a listings, a `pool` size and a `slice` size. The
 * `pool` is the number of listings with the same `statusCode` and `typeCode` to grab from
 * the listings API, and `slice` is the number of listings to remove from that pool after
 * it has been sorted.
 */
/* eslint-disable react/prop-types, react/no-multi-comp */
export default class SimilarListings extends Component {
  shouldComponentUpdate(props) {
    return this.props.listing.id !== props.listing.id
  }

  render() {
    const {
      listing,
      listing: {
        statusCode,
        typeCode,
        subTypeCode,
      },
      pool = 100,
      slice,
      children: render,
      cardOptions,
    } = this.props

    const Set = withSearch({
      statusCode,
      typeCode,
      subTypeCode: (subTypeCode && subTypeCode !== 'BOTH')
        ? [subTypeCode, 'BOTH']
        : undefined,
      size: pool,
      sort: getSortValue(statusCode),
    }, 1)(SimilarListingsSet)

    return (
      <Set
        listing={listing}
        slice={slice}
        render={render}
        cardOptions={cardOptions}
      />
    )
  }
}

SimilarListings.propTypes = {
  listing: PropTypes.object.isRequired,
  pool: PropTypes.number,
  slice: PropTypes.number.isRequired,
  children: PropTypes.func,
  cardOptions: PropTypes.object,
}

SimilarListings.defaultProps = {
  pool: 50,
}
