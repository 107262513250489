import { stringify } from 'query-string'

const baseUrl = (teamId, sourceListingId) => (
  `https://api.snug.com/v1/partner/handoff/team/${teamId}/listing/${sourceListingId}/apply`
)

export default function snugUrl(teamId, listing, referrer) {
  if (!teamId) return undefined
  const {
    sourceId,
    address: {
      unitNumber,
      streetNumber,
      streetName,
      streetType,
      suburb,
      postCode,
      stateCode,
      countryCode,
    } = {},
  } = listing

  // Ensure all required fields are present
  if ((
    !sourceId || !streetNumber || !streetName
    || !suburb || !postCode || !stateCode
  )) return undefined

  const base = baseUrl(teamId, sourceId)
  const params = stringify({
    add_unitnum: unitNumber,
    add_streetnum: streetNumber,
    add_streetname: streetName,
    add_streettype: streetType,
    add_suburb: suburb,
    add_state: stateCode,
    add_postcode: postCode,
    add_country: countryCode,
    referrer_url: referrer,
    redirect_url: referrer,
  })

  return `${base}?${params}`
}
