// @flow

import type { ApiConfiguration } from '../../domainMapping'
import type { PublicConfig } from '../../server'

type ExtraConfig = {
  disableClientCache: boolean,
  requestTime: number,
  protocol: 'http' | 'https',
  baseUrl: string,
};

type SiteConfiguration = ApiConfiguration & PublicConfig & ExtraConfig;

// Reducer stub; state injected in intial state and is otherwise immutable
const initialState = {
  blogId: 0,
  primaryOrganisationId: 0,
  organisationIds: [],
  displayOrganisationIds: [],
  baseDomain: '',
  primaryDomain: '',
  domains: [],
  redirections: [],
  options: {
    agentShowTransactions: 'office',
    showLoanMarketCalculator: false,
    enableRentalForms: true,
    integratedNews: true,
    mdPushContacts: true,
    title: '',
    analyticsTrackingId: '',
    ga4MeasurementId: '',
    gtmContainerId: '',
    liveAdminsId: '',
    podiumId: '',
    enableCampaigntrack: false,
    agents: {
      hidden: [],
      sort: [],
      newAgents: 'show',
    },
    brokers: {
      showOnPdp: false,
      showInTeam: false,
      ids: [],
    },
    useViewingTracker: false,
    rentalFormUrl: '',
    header: {
      listingId: null,
      superscript: '',
      heading: '',
      subheading: '',
      videoUrl: '',
    },
    office: {
      blurb: '',
    },
    siteFocus: 'residential',
    siteTitle: '',
    menuTitle: '',
    defaultEmail: '',
    fbPixelId: '',
    search: {
      headers: {
        default: null,
        residential: null,
        commercial: null,
        rural: null,
      },
    },
    about: '',
  },
  theme: 'residential',
  rwApi: {
    endpoint: '',
    key: '',
    useProxy: false,
    publicOnly: true,
  },
  env: {
    nodeEnv: 'production',
    cdn: '',
    servicePageOffices: [],
  },
  snug: {
    endpoint: '',
  },
  tenapp: {
    endpoint: '',
  },
  loanMarket: {
    aoEndpoint: '',
  },
  disableClientCache: true,
  requestTime: 0,
  protocol: 'http',
  baseUrl: '',
}

export const getConfig = (state: SiteConfiguration) => state

export default function reducer(
  state: SiteConfiguration = initialState
): SiteConfiguration {
  return state
}
