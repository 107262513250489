// @flow

import React, { Component } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string,
  value: string,
  min: number,
  max: number,
  onChange: Object => void,
  formatLabel: string => string,
};

const defaultFormatLabel = (x: string) => x

// Component to allow increment/decrement of a value
export default class Clicker extends Component<Props> {
  static defaultProps = {
    formatLabel: defaultFormatLabel,
  }

  increment = () => {
    const { value, max, onChange } = this.props
    const _value = +value
    if (_value >= max) return
    onChange({ target: { value: `${_value + 1}` } })
  }

  decrement = () => {
    const { value, min, onChange } = this.props
    const _value = +value
    if (_value <= min) return
    onChange({ target: { value: `${_value - 1}` } })
  }

  render() {
    const { className, value, min, max, formatLabel } = this.props
    const _value = +value

    return (
      <div className={classNames('clicker', className)}>
        <span
          className={classNames(
            'clicker_decrement', { disabled: _value <= min },
          )}
          onClick={this.decrement}
        >
          -
        </span>
        <span className="clicker_value">{formatLabel(value)}</span>
        <span
          className={classNames(
            'clicker_increment', { disabled: _value >= max },
          )}
          onClick={this.increment}
        >
          +
        </span>
      </div>
    )
  }
}
