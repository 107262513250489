import React from 'react'
import PropTypes from 'prop-types'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

const moreMeta = eventMeta({
  category: 'Extra Features',
  action: 'More',
})

const limit = 7

const appendField = {
  kitchen: true,
  hotWater: true,
  interiorCondition: true,
  base: true,
  roof: true,
  exterior: true,
  exteriorCondition: true,
  sectionContour: true,
  sewage: true,
  aspect: true,
  stoveType: 'Stove',
  viewsExtraFeatures: 'Views',
  waterExtraFeatures: 'Water',
  frontageExtraFeatures: 'Frontage',
}

const valueMap = {
  'otherRooms.sleepOut': 'Sleepout',
  'heatingExtraFeatures.gas': 'Gas Heating',
  'heatingExtraFeatures.kentStyleFire': 'Kent Style Fireplace',
  'heatingExtraFeatures.openFire': 'Open Fireplace',
  'dining.combinedKitchen': 'Combined Dining/Kitchen',
  'dining.separate': 'Separate Dining/Kitchen',
  'dining.openplan': 'Open Plan Dining',
  'kitchen.openplan': 'Open Plan',
  'bathrooms.separateWCs': 'Separate Toilets',
  'bathrooms.combinedBathrooms': 'Combined Bathroom/Toilet',
  'lounge.combinedLoungeDining': 'Combined Lounge/Dining',
  'lounge.separateLounge': 'Separate Lounge/Dining',
  'stoveType.gasRetic': 'Reticulated Gas',
  'stoveType.gasBottled': 'Bottled Gas',
  'swimming.aboveGround': 'Above Ground Swimming Pool',
  'swimming.inGround': 'In Ground Swimming Pool',
  'swimming.spaPool': 'Spa Pool',
  'exteriorFeatures.walkInWardrobe': 'Walk In Wardrobe',
  'frontageExtraFeatures.ROW': 'Right of Way',
  'levels.aboveGround': 'Above Ground Level',
  'levels.belowRoad': 'Below Road Level',
  'levels.levelWithRoad': 'Level With Road',
  'amenities.gasInStreet': 'In Street Gas',
  'amenities.transport': 'Public Transport Nearby',
  'amenities.shops': 'Shops Nearby',
  'viewsExtraFeatures.ruralViews': 'Rural',
  'garaging.carParkingDetails': false,
  'garaging.single': 'Single Garage',
  'garaging.double': 'Double Garage',
  'garaging.tandem': 'Tandem Garage',
  'garaging.moreThanTwo': '2+ Car Garage',
  'garaging.internalAccess': 'Internal Access Garage',
  'garaging.lockup': 'Lockable Garage',
  'garaging.offStreetParking': 'Off Street Parking',
  'garaging.noGaragingOrCarport': 'No Garaging or Carport',
}

const recaser = (_, a, b) => `${a} ${b.toUpperCase()}`
const recase = field => field[0].toUpperCase() + field.slice(1).replace(/([a-z])([A-Z])/g, recaser)
const valueRecaser = letter => letter.toUpperCase()
const recaseValue = value => value && value.replace(/\b([a-z])/g, valueRecaser)
const splitValue = value => (value?.replace?.(/\.$/, '').split(/\s*,\s*/).map(recaseValue) || [])
const safeTypes = new Set(['number', 'string'])
const isValid = value => (value ? safeTypes.has(typeof value) : false)

const unique = data => {
  const seen = Object.create(null)
  return data.filter(val => {
    if (seen[val]) {
      return false
    }
    seen[val] = true
    return true
  })
}

export const processFeatures = (data = {}, additional = []) => {
  const {
    chattels = {},
    yearBuilt,
    title,
    ...features
  } = data || {}

  const chat = Object.entries(chattels).reduce((result, [field, value]) => {
    if (!isValid(value)) return result

    switch (field) {
      case 'tvAerial':
        result.push('TV Aerial')
        break
      case 'fFloorCovers':
      case 'fFloorCoveringss':
        result.push('Fixed Floor Coverings')
        break
      case 'telephoneChattels':
        result.push('Telephone')
        break
      case 'otherChattels':
        result.push(...splitValue(value))
        break
      case 'wasteMaster':
        result.push('Wastemaster')
        break
      case 'excludedChattels':
        // ignore
        break
      default:
        result.push(recase(field))
        break
    }

    return result
  }, [])

  const feat = Object.entries(features).reduce((result, [field, value]) => {
    switch (field) {
      case 'interior':
        result.push(...Object.entries(value).reduce((_result, [_field, _value]) => {
          if (!isValid(_value)) return _result
          _result.push(`${recase(_field)}: ${splitValue(_value).join(', ')}`)
          return _result
        }, []))
        break
      case 'aspect': {
        const aspects = Object.entries(value).reduce((_result, [_field, _value]) => {
          if (!isValid(_value)) return _result
          _result.push(recase(_field))
          return _result
        }, [])
        if (aspects.length) {
          const aspect = aspects.length === 1
            ? `${aspects[0]} Aspect`
            : `${aspects.slice(0, -1).join(', ')} and ${aspects.pop()} Aspects`

          result.push(aspect)
        }
        break
      }
      case 'other':
        if (isValid(value)) {
          result.push(...splitValue(value))
        }
        break
      default:
        result.push(...Object.entries(value).reduce((_result, [_field, _value]) => {
          if (!isValid(_value)) return _result

          // Allow overriding the field value
          const val = valueMap[`${field}.${_field}`] === undefined
            ? recase(_field)
            : valueMap[`${field}.${_field}`]

          if (!val) {
            // Field mapping indicates that it should be ignored
            return _result
          }

          // Add suffix for some fields
          // eslint-disable-next-line no-nested-ternary
          const suffix = appendField[field] === true
            // Recased field name if true
            ? ` ${recase(field)}`
            // Otherwise allow specified value
            : (appendField[field] ? ` ${appendField[field]}` : '')

          _result.push(`${val}${suffix}`)
          return _result
        }, []))
        break
    }

    return result
  }, [])

  if (isValid(title)) feat.unshift(title)

  if (isValid(yearBuilt)) {
    feat.unshift(/^\d+$/.test(yearBuilt) ? `Built in ${yearBuilt}` : yearBuilt)
  }

  feat.push(...additional.filter(isValid).map(recaseValue))

  return { features: unique(feat), chattels: chat }
}

export default class ListingExtraFeatures extends React.Component {
  static propTypes = {
    // Data from the "extraFeatures" structure
    data: PropTypes.object.isRequired,
    // Additional features from the "features" list
    additional: PropTypes.array.isRequired,
  }

  static defaultProps = {
    data: {},
    additional: [],
  }

  constructor(props) {
    super(props)
    const { data, additional } = props
    this.data = processFeatures(data, additional)
  }

  state = {
    features: false,
    chattels: false,
  }

  shouldComponentUpdate({ data, additional }, { features, chattels }) {
    return data !== this.props.data
      || additional !== this.props.additional
      || chattels !== this.state.chattels
      || features !== this.state.features
  }

  showFeatures = () => {
    this.setState({ features: true })
  }

  showChattels = () => {
    this.setState({ chattels: true })
  }

  render() {
    const { features, chattels } = this.data

    if (!features.length && !chattels.length) {
      return null
    }

    return (
      <div className="pdp_extra_features">
        {!!features.length && (
          <div className="pdp_features">
            <strong className="upper mini">Features:</strong>
            <ul>
              {features.map((name, index) => (
                <li
                  key={name}
                  className={(this.state.features || index < limit) ? 'active' : undefined}
                >
                  {name}
                </li>
              ))}
            </ul>
            {!this.state.features && features.length > limit && (
              <p className="pdp_features_more">
                <span
                  className="anchor"
                  onClick={this.showFeatures}
                  {...moreMeta}
                  data-ev-label="Features"
                >
                  See all features
                </span>
              </p>
            )}
          </div>
        )}
        {!!chattels.length && (
          <div className="pdp_features">
            <strong className="upper mini">Chattels:</strong>
            <ul>
              {chattels.map((name, index) => (
                <li
                  key={name}
                  className={(this.state.chattels || index < limit) ? 'active' : undefined}
                >
                  {name}
                </li>
              ))}
            </ul>
            {!this.state.chattels && chattels.length > limit && (
              <p className="pdp_features_more">
                <span
                  className="anchor"
                  onClick={this.showChattels}
                  {...moreMeta}
                  data-ev-label="Chattels"
                >
                  See all chattels
                </span>
              </p>
            )}
          </div>
        )}
      </div>
    )
  }
}
