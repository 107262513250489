import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  formatPrice,
  formatMeasurements,
  describeParking,
} from '@raywhite/data-utils/lib/data/listing/formatting'
import { capitalise } from '@raywhite/helpers-utils/lib/helpers/string'

const metadataFields = {
  measurements: 'Measurements',
  zone: 'Zone',
  parking: 'Parking',
  floorTypes: 'Floor Types',
  tenancy: 'Tenancy',
  energyRating: 'Energy Rating',
  diningRooms: 'Dining Rooms',
  fencing: 'Fencing',
  rainfall: 'Rainfall',
  soil: 'Soil',
  irrigation: 'Irrigation',
  carryingCapacity: 'Carrying Capacity',
  services: 'Services',
  rates: 'Rates',
  categories: 'Categories',
}

const formatAmount = (type, amount) => {
  const formattedAmount = (
    /^\$?\d+(\.\d+)$/.test(amount)
      ? `${formatPrice(amount)} Annually`
      : amount.replace(/^(\d)/, d => `$${d}`)
  )
  return {
    label: type,
    value: formattedAmount,
  }
}

const formatRates = (rates) => {
  const result = rates
    .filter(({ amount }) => {
      // Drop out zero-ish values
      if (!amount) return false
      try {
        return Number(amount).valueOf() !== 0
      } catch (e) {
        // Definitely not a number, so... not zero.
        return true
      }
    })
    .map(({ type, amount }) => formatAmount(type, amount))
  return result
}

const extractMetadata = (listing, parkingDetails) => {
  const safeTypes = new Set(['number', 'string'])
  const isValid = value => (value ? safeTypes.has(typeof value) : false)

  return Object.entries(metadataFields).reduce((result, [field, label]) => {
    const value = listing[field]

    // Add parkingDetails to metadata if it exists
    if (isValid(parkingDetails) && label === 'Parking') result.push({ label, value: parkingDetails })

    // Don't try pushing empty
    if (Array.isArray(value) ? !value.length : !isValid(value)) return result

    switch (label.toLowerCase()) {
      case 'measurements':
        formatMeasurements(value)
          .forEach((item) => result.push({ label: item.label, value: item.value }))
        break
      case 'zone':
        result.push({ label, value: capitalise(value) })
        break
      case 'rates':
        formatRates(value)
          .forEach((item) => result.push({ label: item.label, value: item.value }))
        break
      case 'floor types':
        value.forEach((item) => result.push({ label, value: item.type }))
        break
      case 'categories':
        if (value.length > 1) {
          const res = value
            .map(({ category, subCategory }) => subCategory || category)
            .join(', ')
          result.push({ label, value: res })
        }
        break
      default:
        result.push({ label, value })
    }
    return result
  }, [])
}

export default class ListingMetadata extends Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
  }

  static defaultProps = {
    listing: {},
  }

  render() {
    const {
      listing,
      listing: {
        carports,
        garages,
        openSpaces,
        bedrooms,
        bathrooms,
        extraFeatures: { garaging: { carParkingDetails } = {} } = {},
        sourceId,
        address: { countryCode } = {},
      },
    } = this.props

    const parkingDetails = describeParking(garages, openSpaces, carports, carParkingDetails)

    const metadata = extractMetadata(listing, parkingDetails)

    if (bedrooms) metadata.push({ label: 'Bedrooms', value: bedrooms })
    if (bathrooms) metadata.push({ label: 'Bathrooms', value: bathrooms })
    if (countryCode === 'NZ') metadata.unshift({ label: 'Property ID', value: sourceId })

    if (!metadata.length) return null
    return (
      <div className="pdp_features_table">
        {metadata.map(({ label, value }) => (
          <div key={label} className="pdp_parking tbl">
            <div className="tbr">
              <div className="tbc top">
                <span className="muted">
                  {label}
                  :
                </span>
              </div>
              <div className="tbc top">
                <p>{value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}
