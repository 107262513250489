import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import * as Joi from 'joi'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSectionHeading,
  FormSection,
  FormTextArea,
  FormSubmit,
  FormSelect,
  FormInput,
  FormRow,
  FormDescriptor,
  labelifyFormField,
} from '../../presentational/formComponents'
import ContentForm from './ContentForm.jsx'
import Disclaimer from './NzDisclaimer.jsx'

const FORM_NAME = 'nzRentalApplication'
const rentalPeriodOptions = ['', 'Weekly', 'Fortnightly', 'Monthly']
const citizenshipOptions = ['', 'NZ Citizen/Resident', 'Other']
const fencingOptions = ['Any', 'Fully', 'Partially', 'No Fencing']
const boolOptions = ['', 'Yes', 'No']
const sourceOptions = ['', 'Internet', 'Radio', 'Referral', 'Newspaper', 'Other']
const schema = schemafy(
  schemaItem('givenName', 'Given Name', types.name.required()),
  schemaItem('middleName', 'Middle Name', types.name.allow('')),
  schemaItem('surname', 'Surname', types.name.required()),
  schemaItem('dateOfBirth', 'Date of Birth', types.date.required()),
  schemaItem('email', 'Email', types.email.required()),
  schemaItem('mobileNumber', 'Mobile Number', types.phone.required()),
  schemaItem('homeNumber', 'Home Number', types.phone.allow('')),

  schemaItem('address', 'Rental Property Address', types.freeText.required()),
  schemaItem('leaseStart', 'Lease Start Date', types.date.allow('')),
  schemaItem('leaseTerm', 'Lease Term', types.freeText.allow('')),
  schemaItem('rentPerWeek', 'Rent per Week', types.freeText.allow('')),
  schemaItem('rentalPeriod', 'Rental Period', Joi.string().only(rentalPeriodOptions)),
  schemaItem('childCount', '# of Children', Joi.number().integer().allow('')),
  schemaItem('childAges', 'Ages of Children', types.freeText.allow('')),
  schemaItem('vehicleMakeModel1', 'Vehicle Make and Model', types.freeText.allow('')),
  schemaItem('vehicleRegistration1', 'Vehicle Registration', types.freeText.allow('')),
  schemaItem('vehicleMakeModel2', 'Vehicle Make and Model #2', types.freeText.allow('')),
  schemaItem('vehicleRegistration2', 'Vehicle Registration #2', types.freeText.allow('')),
  schemaItem('driversLicence', 'Drivers Licence Number', types.freeText.allow('')),
  schemaItem('licenceVersion', 'Licence Version (5b)', types.freeText.allow('')),
  schemaItem('licenceCountry', 'Licence Country or State', types.freeText.allow('')),
  schemaItem('passportNumber', 'Passport Number', types.freeText.allow('')),
  schemaItem('passportCountry', 'Passport Country', types.freeText.allow('')),
  schemaItem('gunLicenceNumber', 'Gun Licence #', types.freeText.allow('')),
  schemaItem('numberGuns', 'Guns Kept', Joi.number().integer().allow('')),
  schemaItem('superGoldCardNumber', 'Super Gold Card #', types.freeText.allow('')),
  schemaItem('citizenship', 'Citizenship', Joi.string().only(citizenshipOptions).allow('')),

  schemaItem(
    'consent',
    'I agree to the collection, use and disclosure of my personal information.',
    Joi.bool().only([true]).required(),
    false
  ),

  schemaItem('refName1', 'Referee Name', types.freeText.allow('')),
  schemaItem('refNumber1', 'Referee Mobile', types.phone.allow('')),
  schemaItem('refRelationship1', 'Referee Relationship', types.freeText.allow('')),
  schemaItem('refName2', 'Referee Name #2', types.freeText.allow('')),
  schemaItem('refNumber2', 'Referee Mobile #2', types.phone.allow('')),
  schemaItem('refRelationship2', 'Referree Relationship #2', types.freeText.allow('')),

  schemaItem('employerName', 'Employer Name', types.freeText.allow('')),
  schemaItem('employerContact', 'Employer Contact', types.freeText.allow('')),
  schemaItem('employerPhone', 'Employer Phone', types.phone.allow('')),
  schemaItem('employerAddress', 'Employer Address', types.freeText.allow('')),
  schemaItem('occupation', 'Occupation', types.freeText.allow('')),
  schemaItem('income', 'Weekly Income', Joi.number().integer().allow('')),
  schemaItem('employedSince', 'Employed Since', types.date.allow('')),
  schemaItem('employerName2', 'Prev. Employer Name', types.freeText.allow('')),
  schemaItem('employerContact2', 'Prev. Employer Contact', types.freeText.allow('')),
  schemaItem('employerPhone2', 'Prev. Employer Phone', types.phone.allow('')),
  schemaItem('employerAddress2', 'Prev. Employer Address', types.freeText.allow('')),
  schemaItem('occupation2', 'Prev. Occupation', types.freeText.allow('')),
  schemaItem('income2', 'Prev. Weekly Income', Joi.number().integer().allow('')),
  schemaItem('winzNumber', 'WINZ Number', types.freeText.allow('')),
  schemaItem('winzIncome', 'WINZ Number', Joi.number().integer().allow('')),
  schemaItem('winzCaseWorker', 'WINZ Case Worker', types.freeText.allow('')),
  schemaItem('otherIncome', 'Other Income', Joi.number().integer().allow('')),

  schemaItem('currentAddress', 'Current Address', types.freeText.allow('')),
  schemaItem('leavingReason', 'Reason for Leaving', types.freeText.allow('')),
  schemaItem('currentSince', 'At Address Since', types.date.allow('')),
  schemaItem('propertyManagerName', 'Property Manager Name', types.freeText.allow('')),
  schemaItem('propertyManagerPhone', 'Property Manager Phone', types.freeText.allow('')),
  schemaItem('previousAddress', 'Previous Address', types.freeText.allow('')),
  schemaItem('previousLeavingReason', 'Reason for Leaving', types.freeText.allow('')),
  schemaItem('previousPropertyManagerName', 'Property Manager Name', types.freeText.allow('')),
  schemaItem('previousPropertyManagerPhone', 'Property Manager Phone', types.freeText.allow('')),

  schemaItem('cars', 'Car Spaces', Joi.number().integer().allow('')),
  schemaItem('beds', 'Bedrooms', Joi.number().integer().allow('')),
  schemaItem('numberOfAdults', 'Number of Adults', Joi.number().integer().allow('')),
  schemaItem('numberOfChildren', 'Number of Children Under 18', Joi.number().integer().allow('')),
  schemaItem('fencing', 'Fencing', Joi.string().only(fencingOptions), fencingOptions[0]),
  schemaItem('propertyAccepted', 'Property Accepted', Joi.string().only(boolOptions)),
  schemaItem('propertyAcceptedDetails', 'Property Condition Details', types.freeText.allow('')),

  schemaItem('termination', 'Terminated Tenancy', Joi.string().only(boolOptions)),
  schemaItem('bonddeduct', 'Bond Withheld', Joi.string().only(boolOptions)),
  schemaItem('hasContentsInsurance', 'Contents Insurance', Joi.string().only(boolOptions)),
  schemaItem('inDebtToLessor', 'Debt to Lessor', Joi.string().only(boolOptions)),
  schemaItem('hasLiabilityInsurance', 'Liability Insurance', Joi.string().only(boolOptions)),
  schemaItem('mayAffectRent', 'Rental Risks', Joi.string().only(boolOptions)),
  schemaItem('hasCriminalCharge', 'Criminal Charges', Joi.string().only(boolOptions)),
  schemaItem('hasLodgedClaim', 'Lodged Insurance Claim', Joi.string().only(boolOptions)),
  schemaItem('awaitingTrial', 'Awaiting Trial', Joi.string().only(boolOptions)),
  schemaItem('manyQuestionDetails', 'History Details', Joi.string().only(boolOptions)),

  schemaItem('pet', 'Pet Details', types.freeText.allow('')),
  schemaItem('smoker', 'Are any occupants smokers?', Joi.string().only(boolOptions)),
  schemaItem('marriedYoung', 'Under 18 and Married', Joi.string().only(boolOptions)),
  schemaItem('chattels', 'Do you own any chattels?', Joi.number().integer().allow('')),

  schemaItem('emergName', 'Emergency Contact Name', types.freeText.allow('')),
  schemaItem('emergNumber', 'Emergency Contact Number', types.phone.allow('')),
  schemaItem('emergRelationship', 'Emergency Contact Relationship', types.freeText.allow('')),
  schemaItem('emergService', 'Alternative Service Address', types.freeText.allow('')),

  schemaItem('source', 'How did you hear about us?', Joi.string().only(sourceOptions)),
  schemaItem('sourceDetails', 'Other Details', types.freeText.allow('')),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const initialValues = schemaInitialValues(schema)
const FormField = labelifyFormField(labels)
export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)
export const process = (values, { send, referrer }) => stockFormEmail(
  send,
  'Rental Application',
  fields,
  labels,
  values,
  {
    prefix: `A rental application has been received from ${referrer || 'your office website'}.`,
    referrer,
  }
)

const NzRentalApplicationForm = props => {
  const {
    fields: {
      givenName,
      middleName,
      surname,
      dateOfBirth,
      email,
      mobileNumber,
      homeNumber,
      address,
      leaseStart,
      leaseTerm,
      rentPerWeek,
      rentalPeriod,
      childCount,
      childAges,
      vehicleMakeModel1,
      vehicleRegistration1,
      vehicleMakeModel2,
      vehicleRegistration2,
      driversLicence,
      licenceVersion,
      licenceCountry,
      passportNumber,
      passportCountry,
      gunLicenceNumber,
      numberGuns,
      superGoldCardNumber,
      citizenship,
      consent,
      refName1,
      refNumber1,
      refRelationship1,
      refName2,
      refNumber2,
      refRelationship2,
      employerName,
      employerContact,
      employerPhone,
      employerAddress,
      occupation,
      income,
      employedSince,
      employerName2,
      employerContact2,
      employerPhone2,
      employerAddress2,
      occupation2,
      income2,
      winzNumber,
      winzIncome,
      winzCaseWorker,
      otherIncome,
      currentAddress,
      leavingReason,
      currentSince,
      propertyManagerName,
      propertyManagerPhone,
      previousAddress,
      previousLeavingReason,
      previousPropertyManagerName,
      previousPropertyManagerPhone,
      cars,
      beds,
      numberOfAdults,
      numberOfChildren,
      fencing,
      propertyAccepted,
      propertyAcceptedDetails,
      termination,
      bonddeduct,
      hasContentsInsurance,
      inDebtToLessor,
      hasLiabilityInsurance,
      mayAffectRent,
      hasCriminalCharge,
      hasLodgedClaim,
      awaitingTrial,
      manyQuestionDetails,
      pet,
      smoker,
      marriedYoung,
      chattels,
      emergName,
      emergNumber,
      emergRelationship,
      emergService,
      source,
      sourceDetails,
    },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Applicant Details</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={givenName} className="one_half" required />
          <FormField field={middleName} className="one_half" />
        </FormRow>
        <FormField field={surname} required />
        <FormRow>
          <FormField field={dateOfBirth} className="one_half" required />
          <FormField field={email} className="one_half" required />
        </FormRow>
        <FormRow>
          <FormField field={mobileNumber} className="one_half" required />
          <FormField field={homeNumber} className="one_half" />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Application Details</FormSectionHeading>
      <FormSection>
        <FormField field={address} required />
        <FormRow>
          <FormField field={leaseStart} className="one_half" />
          <FormField field={leaseTerm} className="one_half" />
        </FormRow>
        <FormRow>
          <FormField field={rentPerWeek} className="one_half" />
          <FormField
            field={rentalPeriod}
            className="one_half select_form_field"
            input={FormSelect}
            options={rentalPeriodOptions}
          />
        </FormRow>
        <FormRow>
          <FormField field={childCount} className="one_third" />
          <FormField field={childAges} className="two_thirds" />
        </FormRow>
        <FormRow>
          <FormField field={vehicleMakeModel1} className="one_half" />
          <FormField field={vehicleRegistration1} className="one_half" />
        </FormRow>
        <FormRow>
          <FormField field={vehicleMakeModel2} className="one_half" />
          <FormField field={vehicleRegistration2} className="one_half" />
        </FormRow>
        <FormRow>
          <FormField field={driversLicence} className="one_third" />
          <FormField field={licenceVersion} className="one_third" />
          <FormField field={licenceCountry} className="one_third" />
        </FormRow>
        <FormRow>
          <FormField field={gunLicenceNumber} className="one_half" />
          <FormField field={numberGuns} className="one_half" />
        </FormRow>
        <FormField field={superGoldCardNumber} />
        <FormRow>
          <FormField field={passportNumber} className="one_half" />
          <FormField field={passportCountry} className="one_half" />
        </FormRow>
        <FormField field={citizenship} className="select_form_field">
          <FormSelect field={citizenship} options={citizenshipOptions} />
          <FormDescriptor>
            If not an NZ citizen or resident, please bring your visa to accompany this application.
          </FormDescriptor>
        </FormField>
      </FormSection>
      <FormSectionHeading>Applicant Consents</FormSectionHeading>
      <FormSection>
        <FormField field={consent} label="" className="checkbox_form_field">
          <FormInput type="checkbox" field={consent} checked={consent.value} />
          <label htmlFor={consent.name}>I agree to the following.</label>
        </FormField>
        <FormField field={consent} label="">
          <Disclaimer />
        </FormField>
      </FormSection>
      <FormSectionHeading>Personal Referees</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={refName1} className="one_half" />
          <FormField field={refNumber1} className="one_half" />
        </FormRow>
        <FormField field={refRelationship1} />
        <FormRow>
          <FormField field={refName2} className="one_half" />
          <FormField field={refNumber2} className="one_half" />
        </FormRow>
        <FormField field={refRelationship2} />
      </FormSection>
      <FormSectionHeading>Employment Referees</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={employerName} className="one_third" />
          <FormField field={employerContact} className="one_third" />
          <FormField field={employerPhone} className="one_third" />
        </FormRow>
        <FormField field={employerAddress} />
        <FormRow>
          <FormField field={occupation} className="one_half" />
          <FormField field={income} className="one_half" />
        </FormRow>
        <FormField field={employedSince}>
          <FormInput field={employedSince} />
          <FormDescriptor>
            If employed for less than two years, please provide previous employment details below.
          </FormDescriptor>
        </FormField>
        <FormRow>
          <FormField field={employerName2} className="one_third" />
          <FormField field={employerContact2} className="one_third" />
          <FormField field={employerPhone2} className="one_third" />
        </FormRow>
        <FormField field={employerAddress2} />
        <FormRow>
          <FormField field={occupation2} className="one_half" />
          <FormField field={income2} className="one_half" />
        </FormRow>
        <FormRow>
          <FormField field={winzNumber} className="one_half" />
          <FormField field={winzIncome} className="one_half" />
        </FormRow>
        <FormField field={winzCaseWorker} />
        <FormField field={otherIncome}>
          <FormInput field={otherIncome} />
          <FormDescriptor>
            Weekly income from other sources, if any.
          </FormDescriptor>
        </FormField>
      </FormSection>
      <FormSectionHeading>Address and Property Manager Referees</FormSectionHeading>
      <FormSection>
        <FormField field={currentAddress} />
        <FormField field={leavingReason} />
        <FormRow>
          <FormField field={currentSince} className="one_third" />
          <FormField field={propertyManagerName} className="one_third" />
          <FormField field={propertyManagerPhone} className="one_third" />
          <FormDescriptor>
            If less than two years at your current address,
            {' '}
            please provide previous address details below.
          </FormDescriptor>
        </FormRow>
        <FormField field={previousAddress} />
        <FormField field={previousLeavingReason} />
        <FormRow>
          <FormField field={previousPropertyManagerName} className="one_half" />
          <FormField field={previousPropertyManagerPhone} className="one_half" />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Applicant Requirements</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={cars} className="one_half" />
          <FormField field={beds} className="one_half" />
        </FormRow>
        <FormRow>
          <FormField field={numberOfAdults} className="one_half" />
          <FormField field={numberOfChildren} className="one_half" />
        </FormRow>
        <FormField
          field={fencing}
          options={fencingOptions}
          input={FormSelect}
          className="select_form_field"
        />
        <FormField field={propertyAccepted} className="select_form_field">
          <FormSelect field={propertyAccepted} options={boolOptions} />
          <FormDescriptor>
            Do you accept the property in its current condition?
            {' '}
            If no, please provide details below.
          </FormDescriptor>
        </FormField>
        <FormField field={propertyAcceptedDetails} />
      </FormSection>
      <FormSectionHeading>Applicant History</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField
            field={termination}
            description="Have you ever had a tenancy terminated before?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
          <FormField
            field={bonddeduct}
            description="Have you ever had money deducted from your bond?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
        </FormRow>
        <FormRow>
          <FormField
            field={hasContentsInsurance}
            description="Do you currently have contents insurance?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
          <FormField
            field={inDebtToLessor}
            description="Are you in debt to another lessor or agent?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
        </FormRow>
        <FormRow>
          <FormField
            field={hasLiabilityInsurance}
            description="Do you currently have liability insurance?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
          <FormField
            field={mayAffectRent}
            description="Are there any existing circumstances that may affect your rent payment?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
        </FormRow>
        <FormRow>
          <FormField
            field={hasCriminalCharge}
            description="Have you ever been charged for a criminal offence?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
          <FormField
            field={hasLodgedClaim}
            description="Have you ever lodged an insurance claim?"
            className="one_half select_form_field"
            input={FormSelect}
            options={boolOptions}
          />
        </FormRow>
        <FormField
          field={awaitingTrial}
          description="Are you awaiting trial for a criminal offence?"
          className="one_half select_form_field"
          input={FormSelect}
          options={boolOptions}
        />
        <FormField
          field={manyQuestionDetails}
          input={FormTextArea}
          description="Please provide details if you answered yes to any of the questions above."
        />
      </FormSection>
      <FormSectionHeading>Chattels, Pets and Smokers</FormSectionHeading>
      <FormSection>
        <FormField
          field={pet}
          description="If you have any pets, please provide details."
        />
        <FormRow>
          <FormField
            field={smoker}
            input={FormSelect}
            className="one_half select_form_field"
            options={boolOptions}
          />
          <FormField
            field={marriedYoung}
            input={FormSelect}
            className="one_half select_form_field"
            description="If you are under the age of 18 are you married?"
            options={boolOptions}
          />
        </FormRow>
        <FormField
          field={chattels}
          description="Enter the total dollar value of chattels, e.g. fridge, whiteware, beds etc."
        />
      </FormSection>
      <FormSectionHeading>Emergency Contact Details</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={emergName} />
          <FormField field={emergNumber} />
        </FormRow>
        <FormField field={emergRelationship} />
        <FormField field={emergService} />
      </FormSection>
      <FormSectionHeading>How did you hear about us?</FormSectionHeading>
      <FormSection>
        <FormField
          field={source}
          input={FormSelect}
          options={sourceOptions}
        />
        <FormField field={sourceDetails} />
      </FormSection>
      <FormSubmit submitting={submitting} label="Send Application" />
    </ContentForm>
  )
}

NzRentalApplicationForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(NzRentalApplicationForm)
