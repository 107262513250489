import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import * as Joi from 'joi'
import { types } from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import ContentForm from './ContentForm.jsx'
import {
  FormSectionHeading,
  FormSection,
  FormSubmit,
  FormSelect,
  FormRow,
  labelifyFormField,
} from '../../presentational/formComponents'

const bedOptions = ['0', '1', '2', '3', '4', '5+']
const bathOptions = ['0', '1', '2', '3+']
const carOptions = ['0', '1', '2', '3+']
const rentalOption = 'Property for Rent'
const lookingForOptions = ['', 'Property for Sale', rentalOption]
const FORM_NAME = 'nzEnquiry'
const schema = schemafy(
  schemaItem('title', 'Title', types.freeText.allow('').min(2).max(12)),
  schemaItem('givenName', 'First Name', types.name.required()),
  schemaItem('surname', 'Surname', types.name.required()),
  schemaItem('homePhone', 'Home Phone', types.phone.allow('').empty('')),
  schemaItem('mobilePhone', 'Mobile Phone', types.phone.allow('').empty('')),
  schemaItem('email', 'Email', types.email.allow('').empty('')),

  schemaItem('suburb', 'Suburb', types.freeText.required()),
  schemaItem('state', 'State / Region', types.freeText.allow('')),
  schemaItem('postCode', 'Postcode', types.postCode.required()),

  schemaItem(
    'lookingFor', 'Looking For', Joi.string().required().only(lookingForOptions.slice(1)), '',
  ),
  schemaItem(
    'beds', 'Bedrooms', Joi.string().required().only(bedOptions), '2',
  ),
  schemaItem(
    'baths', 'Bathrooms', Joi.string().required().only(bathOptions), '1',
  ),
  schemaItem(
    'cars', 'Car Spaces', Joi.string().required().only(carOptions), '1',
  ),

  schemaItem('minPrice', 'Minimum Price', types.price.allow('')),
  schemaItem('maxPrice', 'Maximum Price', types.price.allow('')),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(
  fields, schemaRules(schema).options({
    abortEarly: false,
    language: {
      object: { missing: '!!Please provide either an email, mobile or home phone number' },
    },
  })
    .or('homePhone', 'mobilePhone', 'email')
)
export const process = (values, params) => {
  const { send, referrer } = params

  const ref = referrer || 'your office website'
  return stockFormEmail(
    send,
    'Enquiry',
    fields,
    labels,
    values,
    {
      prefix: `A buy/rent enquiry has been received from ${ref}.`,
      referrer,
    }
  )
}

const EnquiryForm = props => {
  const {
    fields: {
      title,
      givenName,
      surname,
      homePhone,
      mobilePhone,
      email,
      suburb,
      state,
      postCode,
      lookingFor,
      beds,
      baths,
      cars,
      minPrice,
      maxPrice,
    },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Your Contact Details</FormSectionHeading>
      <FormSection>
        <FormRow>
          <FormField field={title} placeholder="e.g. Mrs" className="one_third" />
          <FormField field={givenName} className="two_thirds" required />
        </FormRow>
        <FormField field={surname} required />
        <FormRow>
          <FormField
            field={mobilePhone}
            placeholder="e.g. j.bloggs@gmail.com"
            required={!email.value && !homePhone.value}
            className="one_half"
          />
          <FormField
            field={homePhone}
            required={!email.value && !mobilePhone.value}
            className="one_half"
          />
        </FormRow>
        <FormField field={email} required={!homePhone.value && !mobilePhone.value} />
      </FormSection>
      <FormSectionHeading>Looking For</FormSectionHeading>
      <FormSection>
        <FormField
          field={lookingFor}
          input={FormSelect}
          options={lookingForOptions}
          className="select_form_field"
          required
        />
        <FormField field={suburb} required />
        <FormRow>
          <FormField field={state} className="one_half" />
          <FormField field={postCode} className="one_half" required />
        </FormRow>
        <FormRow>
          <FormField
            field={beds}
            input={FormSelect}
            options={bedOptions}
            className="one_third form_field select_form_field"
            required
          />
          <FormField
            field={baths}
            input={FormSelect}
            options={bathOptions}
            className="one_third form_field select_form_field"
            required
          />
          <FormField
            field={cars}
            input={FormSelect}
            options={carOptions}
            className="one_third form_field select_form_field"
            required
          />
        </FormRow>
        <FormRow>
          <FormField field={minPrice} className="one_half" />
          <FormField field={maxPrice} className="one_half" />
        </FormRow>
      </FormSection>
      <FormSubmit submitting={submitting} label="Send Enquiry" />
    </ContentForm>
  )
}

EnquiryForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(EnquiryForm)
