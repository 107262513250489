import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import fetch from 'isomorphic-fetch'
import localforage from 'localforage'
import { hot } from 'react-hot-loader/root'
import getUnifiedClient from '@raywhite/data-client'
import { sendProxiedRequest } from '@raywhite/helpers-utils/lib/helpers/proxy'

import Site from './components/Site.jsx'
import getRoutes from './routes'
import { cacheify } from './cache/utils'
import getCache from './cache/cache'
import localForageDriver from './cache/driver/localForage'
import configureStore from './store'
import { enableExperiment, loadAuthFailed } from './redux/modules/app'
import { Logger, attachToBrowser } from './logging'
import { getListingsMeta, getUser } from './snapperClient'
import { getBrokers } from './lmClient'
import { getContentClient } from './utils/wordpress/content'
import liveAdmins from './liveAdmins'
import podium from './podium'

// Required to establish dependencies for hot reloading
import './redux/modules'

// Import the CSS file so that it's transferred
import '../styles/main.scss'

const isProduction = process.env.NODE_ENV === 'production'
const config = isProduction
  ? { type: 'post', endpoint: '/api/errors', level: 'warn' }
  : { type: 'console', level: 'debug' }
const logger = Logger.factory(config)
Logger.default = logger
attachToBrowser(logger, window)

// The INITIAL_STATE atom is injected into index.html by express.
// It contains the basic data structure that will be used by the app, a config.
// and the relevant (based on request domain) organization identifiers.
const store = configureStore(
  window.INITIAL_STATE,
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
)
store.dispatch(loadAuthFailed(document.cookie))
window.getReduxState = () => store.getState()
const { disableClientCache } = window.INITIAL_STATE.config

// Add ability to enable experiments on the front end
window.enableExperiment = (experiment, variation) => (
  store.dispatch(enableExperiment(experiment, variation))
)

const { rwApi, baseDomain } = store.getState().config
const unifiedClient = {
  ...getUnifiedClient(
    fetch,
    logger,
    rwApi.endpoint,
    rwApi.key,
    // Use the default send method unless we're configured to proxy requests
    rwApi.useProxy ? sendProxiedRequest : undefined,
  ),
  fetch,
  getListingsMeta,
  getUser,
  getBrokers,
  ...getContentClient(baseDomain, disableClientCache, fetch),
}
if (rwApi.useProxy) {
  // Overwrite meta queries to use appropriate proxied ones, raw queries are not
  // permitted in proxy mode
  unifiedClient.getAgentMetadata = (memberId) => (
    fetch(`${rwApi.endpoint}member-meta/${memberId}`).then(r => r.json())
  )
  unifiedClient.getOrganisationsMetadata = () => (
    fetch(`${rwApi.endpoint}site-meta`).then(r => r.json())
  )
}

// Cacheify all of the methods on the unified client if possible to do so safely
if (!disableClientCache) {
  const lf = localforage.createInstance({ name: 'unifiedClient' })
  const prepareCache = lf.ready()
    .then(() => lf.setItem('__test', true))
    .then(() => lf.getItem('__test'))
    .then(result => {
      lf.removeItem('__test')
      // Ensure that we got back the value we set
      if (result !== true) throw new Error('Failed to retrieve value set in localforage')

      // Caching is working, wrap the unified client in it
      const cache = getCache(localForageDriver(lf))
      cache.on('error', error => logger.log(error._level || 'error', error.message, { error }))
      return cache
    })
    .catch(error => {
      logger.warn(`Unable to initialise localForage: ${error.message}`, { error })
    })

  Object.entries(unifiedClient)
    .filter(([key, val]) => (
      typeof val === 'function' && key !== 'fetch' && key !== 'getUser'
    ))
    .forEach(([key, val]) => {
      unifiedClient[key] = (...args) => prepareCache.then(cache => {
        // Note: assumes that all functions are promise returning and do not rely on `this`
        const method = cache
          ? cacheify(cache, val, { expire: 1000 * 60 * 10 }, key)
          : val

        // Replace placeholder with "real" method for next time
        unifiedClient[key] = method

        return method(...args)
      })
    })
}

if (!rwApi.publicOnly) {
  // Wrap get orgs/mems to inject the public: null parameter
  const { getOrganisations, getMembers } = unifiedClient

  unifiedClient.getOrganisations = params => (
    getOrganisations({ public: null, ...params })
  )
  unifiedClient.getMembers = params => (
    getMembers({ public: null, ...params })
  )
}

const root = document.getElementById('app')
const HotSite = hot(Site)
const render = routes => {
  ReactDOM.hydrate(
    <HotSite
      store={store}
      routes={routes}
      unifiedClient={unifiedClient}
      _window={window}
    />,
    root
  )
}
render(getRoutes(store.getState()))

liveAdmins(document, store.getState().config)
podium(document, store.getState().config)

if (module.hot) {
  // Special handling for redux reducer changes
  module.hot.accept(['./store', './redux/modules'], () => {
    // eslint-disable-next-line global-require
    store.replaceReducer(require('./redux/modules').default)
  })

  // All component updates come in via routes
  module.hot.accept('./routes', () => {
    // Note that react-router (v3) will complain about not being able to replace
    // routes, but it does actually work due to hacks in react-hot-loader
    // eslint-disable-next-line global-require
    render(require('./routes').default(store.getState()))
  })
}
