import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'
import { getSearch } from '@raywhite/redux/lib/listing'

import { fetchListings } from '../../redux/modules/listings'
import { getDisplayName } from './providers'

/**
 * Provides a set of listings or a `search` to child components.
 *
 * @param {Object} - The search query to be dispatched.
 * @param {Number} - The page number for that query (or null).
 *
 * @return {Function} - wrap.
 */
export default function withSearch(params, page = 1) {
  /**
   * Provides a set of listings or a `search` to child components.
   *
   * @param {React.Component} - The component to wrap / decorate.
   *
   * @return {React.Component} - A new HOC with a search embedded in `this.props`.
   */
  return function wrap(Wrapee) {
    class Wrapper extends Component {
      constructor() {
        super()
        this.load.bind(this)
      }

      componentDidMount() { this.load() }

      componentDidUpdate() { this.load() }

      load() {
        const { dispatch, getListings, query } = this.props
        dispatch(fetchListings(getListings || this.context.getListings, query, page))
      }

      render() {
        return (<Wrapee {...this.props} />)
      }
    }

    // Component is `connect`ed in order to implement shouldComponentUpdate.
    function mapStateToProps(state, props) {
      const { dispatch, getListings } = props
      const query = { ...params, organisationId: state.config.organisationIds }
      const search = { ...getSearch(state.listings, query, page) }
      const listings = search.items
      delete search.items
      return {
        ...search,
        listings,
        query,
        dispatch,
        getListings,
      }
    }

    Wrapper.displayName = `withSearch(${getDisplayName(Wrapee)})`

    Wrapper.propTypes = {
      query: PropTypes.object.isRequired,
      dispatch: PropTypes.func.isRequired,
      getListings: PropTypes.func,
    }

    Wrapper.contextTypes = {
      getListings: PropTypes.func,
    }

    return connect(mapStateToProps)(hoistStatics(Wrapper, Wrapee))
  }
}
