import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MapContext } from '.'

class ThreeDeeInner extends React.Component {
  static propTypes = {
    position: PropTypes.string,
    style: PropTypes.object,
    map: PropTypes.object,
  }

  static defaultProps = {
    position: 'top-left',
    style: {},
  }

  state = {
    twodee: false,
  }

  componentDidMount() {
    const { map } = this.props
    if (!map) return

    // Track pitch end
    this.props.map.on('pitchend', this.onPitchEnd)

    // Sync state
    this.onPitchEnd()
  }

  componentWillUnmount() {
    const { map } = this.props
    if (!map) return

    this.props.map.off('pitchend', this.onPitchEnd)
  }

  onClick = () => {
    const { map } = this.props
    if (!map) return

    map.setPitch(this.state.twodee ? 60 : 0)
  }

  onPitchEnd = () => {
    const twodee = !this.props.map.getPitch()

    if (twodee !== this.state.twodee) this.setState({ twodee })
  }

  render() {
    const { map, style, position } = this.props
    const nextState = this.state.twodee ? '3D' : '2D'

    if (!map) return null

    return (
      <div
        className={classNames('mb-control mb-control-3d', position)}
        style={{
          ...style,
        }}
      >
        <button
          type="button"
          aria-label={`Switch to ${nextState}`}
          data-ev-on="click"
          data-ev-category="Map Control"
          data-ev-action="Click"
          data-ev-label={this.state.twodee ? '2D' : '3D'}
          onClick={this.onClick}
        >
          {nextState}
        </button>
      </div>
    )
  }
}

const ThreeDeeControl = (props) => (
  <MapContext.Consumer>
    {map => <ThreeDeeInner {...props} map={map} />}
  </MapContext.Consumer>
)

export default ThreeDeeControl
