import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes, reset } from 'redux-form'
import { types } from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  warnForm,
  formSchemaValidator,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSection,
  FormRow,
  FormSubmit,
  labelifyFormField,
} from '../presentational/formComponents'

const FORM_NAME = 'signin'
const schema = schemafy(
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.required()),
  schemaItem('number', 'Your Phone Number', types.phone.required()),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(fields, schemaRules(schema))

const initialState = {
  submitted: false,
}

class SignInForm extends Component {
  constructor() {
    super()

    this.state = { ...initialState }
  }

  componentWillUnmount() {
    this.setState(initialState)
  }

  render() {
    const {
      fields: { name, email, number },
      submitting,
      sendForm,
      handleSubmit,
      error,
      dispatch,
      extraData,
      sendMeta,
      handleClose,
      user,
    } = this.props

    const onSubmit = data => sendForm({ ...extraData, ...data }, sendMeta)
      .then(() => {
        this.setState({ submitted: true })
        return dispatch(reset(FORM_NAME))
      })
    if (this.state.submitted && user) {
      return (
        <div className="form_fields_section">
          <div className="inner centered_text">
            <p>
              {`Thanks ${user.displayName}, you're signed in to access documents now.`}
            </p>
            <p>
              <button
                type="button"
                className="btn large"
                onClick={handleClose}
              >
                Close
              </button>
            </p>
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {warnForm(error)}
        <FormSection>
          <FormField field={name} placeholder="e.g. Joe Bloggs" required />
          <FormRow>
            <FormField
              field={number}
              placeholder="e.g. 04xx xxx xxx"
              required={!email.value}
              className="one_half"
            />
            <FormField
              field={email}
              placeholder="e.g. j.bloggs@gmail.com"
              required={!number.value}
              className="one_half"
            />
          </FormRow>
        </FormSection>
        <FormSubmit submitting={submitting} label="Sign In" />
      </form>
    )
  }
}

SignInForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  extraData: PropTypes.object.isRequired,
  sendMeta: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}

SignInForm.defaultProps = {
  extraData: {},
  sendMeta: {},
}

const ReduxSignInForm = reduxForm(
  {
    form: FORM_NAME,
    fields,
    validate,
  },
  () => ({
    initialValues: { ...initialValues },
  }),
)(SignInForm)

export default ReduxSignInForm
