import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoizee'

import ListingInfoWindow from '../ListingInfoWindow.jsx'
import SingletonMap from './SingletonMap.jsx'
import {
  Popup,
  Source,
  Layer,
  getBounds,
} from '.'

import ZoomControl from './ZoomControl.jsx'
import BuildingShapeLayer from './BuildingShapeLayer.jsx'
import ThreeDeeControl from './ThreeDeeControl.jsx'

const IS_CLUSTERED = ['has', 'clusteredListingCount']
const IS_NOT_CLUSTERED = ['!', IS_CLUSTERED]
const LISTING_CLUSTER_LAYOUT = {
  'icon-image': 'listingCluster',
  'icon-size': 0.2,
  'text-field': '{clusteredListingCount}',
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'text-optional': true,
}
const LISTING_MARKER_LAYOUT = {
  'icon-image': [
    'match',
    ['get', 'state'],
    'active', 'listingActive',
    'seen', 'listingSeen',
    'listing',
  ],
  'icon-size': 0.125,
  'text-field': [
    'match',
    ['get', 'pointListingCount'],
    1, '',
    ['get', 'pointListingCount'],
  ],
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'text-optional': true,
}

const pointsToGeoJSON = memoize((points, popup, seen, interactive) => ({
  type: 'geojson',
  cluster: interactive,
  clusterMaxZoom: 14, // Max zoom to cluster points on
  clusterRadius: 15, // Radius of each cluster when clustering points
  clusterProperties: {
    // Real listing count; one marker can have multiple listings
    clusteredListingCount: ['+', ['get', 'pointListingCount']],
  },
  data: {
    type: 'FeatureCollection',
    features: points.map(point => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat],
      },
      properties: {
        state: (
          (interactive && popup && popup.lat === point.lat && popup.lng === point.lng && 'active')
          || (interactive && seen[`${point.lat},${point.lng}`] && 'seen')
          || 'unseen'
        ),
        pointListingCount: interactive ? point.data.length : '',
        ...point,
      },
    })),
  },
}), {
  max: 1,
})

export default class SearchMap extends Component {
  static propTypes = {
    _window: PropTypes.object,
    points: PropTypes.array.isRequired,
    center: PropTypes.array.isRequired,
    loaded: PropTypes.bool.isRequired,
    children: PropTypes.node,
    active: PropTypes.bool.isRequired,
    controlPadding: PropTypes.number.isRequired,
    renderMap: PropTypes.bool.isRequired,
    zoom: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired,
    pitch: PropTypes.number,
    interactive: PropTypes.bool,
  }

  static defaultProps = {
    controlPadding: 10,
    pitch: 0,
  }

  constructor(props) {
    super(props)

    this.state = {
      popup: undefined,
      seen: {},
    }
  }

  // TODO consider other cases where we can avoid updating
  shouldComponentUpdate(props, state) {
    if (!props.active) return false
    if (!props.loaded) return false
    if (props.points !== this.props.points) return true
    if (this.state.popup !== state.popup) return true

    return props.active
  }

  componentDidUpdate(prevProps) {
    // Clear popup if content changes and the current popup is no longer in it
    const { popup } = this.state
    const { points } = this.props
    if (
      // Got a popup
      popup
      // Points have changed
      && prevProps.points !== points
      // Points no longer contain theI can't use component will update any more popup
      && !points.find(p => p.lat === popup.lat && p.lng === popup.lng)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ popup: undefined })
    }
  }

  getSourceId() {
    return this.props.interactive ? 'listings-clustered' : 'listings'
  }

  zoomCluster = (event) => {
    // Click "target" is the map
    const { target: map } = event

    // Find clicked cluster
    const layer = `${this.getSourceId()}-clusters`
    const [cluster] = map.queryRenderedFeatures(event.point, { layers: [layer] })
    if (!cluster) return

    // Zoom in on it
    const clusterId = cluster.properties.cluster_id
    map.getSource(this.getSourceId()).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return

      map.easeTo({
        zoom: zoom + 0.5, // Often seems to be just a little too short
        center: cluster.geometry.coordinates,
      })
    })
  }

  togglePopup = (event) => {
    // Click "target" is the map
    const { target: map } = event

    // Find clicked feature
    const layer = `${this.getSourceId()}-markers`
    const [feature] = map.queryRenderedFeatures(event.point, { layers: [layer] })
    if (!feature) return

    // The original point data from our set of points is in the properties of the feature
    const { properties: point } = feature

    // If the point matches the current popup point, close it...
    const { popup = {} } = this.state
    if (popup.lat === point.lat && popup.lng === point.lng) {
      this.setState({ popup: undefined })
      return
    }

    // ...otherwise open it up by setting it as the current point
    const { lat, lng } = point
    this.setState((state) => ({
      popup: {
        ...point,
        // Note: mapbox serialises this
        data: JSON.parse(point.data),
      },
      seen: { ...state.seen, [`${lat},${lng}`]: true },
    }))
  }

  closePopup = (map, event) => {
    if (!this.state.popup) return

    // Ignore clicks on markers
    const layer = `${this.getSourceId()}-markers`
    const [feature] = map.queryRenderedFeatures(event.point, { layers: [layer] })
    if (feature) return

    this.setState({ popup: undefined })
  }

  setMouseCursor(event) {
    const { target: map } = event
    const { style } = map.getCanvas()
    style.cursor = 'pointer'
  }

  unsetMouseCursor(event) {
    const { target: map } = event
    const { style } = map.getCanvas()
    style.cursor = ''
  }

  render() {
    const {
      points,
      _window,
      controlPadding,
      renderMap,
      zoom,
      center,
      identifier,
      pitch,
      interactive,
    } = this.props
    const { popup, seen } = this.state
    const bounds = _window ? getBounds(points) : undefined
    const sourceId = this.getSourceId()

    return (
      <SingletonMap
        enforce={false}
        identifier={identifier}
        mapId="light-v10"
        movingMethod={interactive ? 'easeTo' : 'jumpTo'}
        zoom={zoom}
        pitch={pitch}
        center={center}
        onClick={this.closePopup}
        fitBounds={bounds}
        renderMap={renderMap}
        fitBoundsOptions={{
          padding: {
            top: 10,
            left: 10,
            right: 10,
            bottom: controlPadding,
          },
        }}
      >
        {interactive && (
          <div className="mb-control-container bottom-right">
            <ThreeDeeControl />
            <ZoomControl />
          </div>
        )}
        <Source
          key={sourceId}
          id={sourceId}
          geoJsonSource={pointsToGeoJSON(points, popup, seen, interactive)}
        />
        <BuildingShapeLayer />
        <Layer
          key={`${sourceId}-markers`}
          id={`${sourceId}-markers`}
          sourceId={sourceId}
          type="symbol"
          filter={interactive ? IS_NOT_CLUSTERED : undefined}
          layout={LISTING_MARKER_LAYOUT}
          onMouseEnter={interactive && this.setMouseCursor}
          onMouseLeave={interactive && this.unsetMouseCursor}
          onClick={interactive && this.togglePopup}
        />
        {interactive && (
          <Layer
            key={`${sourceId}-clusters`}
            id={`${sourceId}-clusters`}
            sourceId={sourceId}
            type="symbol"
            source="listings"
            filter={IS_CLUSTERED}
            layout={LISTING_CLUSTER_LAYOUT}
            onMouseEnter={this.setMouseCursor}
            onMouseLeave={this.unsetMouseCursor}
            onClick={this.zoomCluster}
          />
        )}
        {!!popup && (
          <Popup
            coordinates={[popup.lng, popup.lat]}
            className="info_window_wrapper"
          >
            <ListingInfoWindow listings={popup.data} />
          </Popup>
        )}
      </SingletonMap>
    )
  }
}
