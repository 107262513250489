import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import url from 'url'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'

// Extract twitter username from something that might be a twitter URL
const getUsername = maybeUrl => maybeUrl && (
  /^https?:\/\//.test(maybeUrl)
    ? url.parse(maybeUrl).pathname.replace(/^.*\//, '')
    : maybeUrl
).replace(/^@?/, '@')

/**
 * Generates twitter card metadata using react-helmet.
 *
 * See https://dev.twitter.com/cards for details, but for our purposes only
 * the summary and summary_large_image seem applicable now that Gallery and
 * Photo cards have been deprecated.  Player card might be an option if we can
 * go through the approval route, although this used to be per domain which
 * is prohibitive for office sites.
 */
const TwitterSummaryCard = props => {
  const {
    type,
    site,
    creator,
    title,
    description,
    image,
    imageAlt,
  } = props

  const _site = getUsername(site)
  const _creator = creator && getUsername(creator)

  return (
    <Helmet>
      <meta name="twitter:card" content={image ? type : 'summary'} />
      <meta name="twitter:site" content={_site} />
      {!!_creator && <meta name="twitter:creator" content={_creator} />}
      {!!title && <meta name="twitter:title" content={excerptify(plainText(title), 70)} />}
      {!!description && (
        <meta name="twitter:description" content={excerptify(plainText(description), 200)} />
      )}
      {!!image && <meta name="twitter:image" content={image} />}
      {!!image && !!imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
    </Helmet>
  )
}

TwitterSummaryCard.propTypes = {
  // Note: summary_large_image will be ignored if no image is specified
  type: PropTypes.oneOf(['summary', 'summary_large_image']),
  site: PropTypes.string.isRequired,
  creator: PropTypes.string,
  title: PropTypes.string.isRequired, // max 70 chars
  description: PropTypes.string.isRequired, // max 200 chars
  image: PropTypes.string, // < 1MB in size
  imageAlt: PropTypes.string,
}

TwitterSummaryCard.defaultProps = {
  site: '@RayWhiteGroup',
  type: 'summary_large_image',
  description: '',
}

export default TwitterSummaryCard
