import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pluralize } from '@raywhite/data-utils/lib/data/listing/utils'

import { AWARD_LIMIT } from '../../constants.js'

export default class AwardsList extends Component {
  static propTypes = {
    awards: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = {
      showAllAwards: false,
    }
  }

  toggleAwardsList = () => {
    this.setState(state => ({
      showAllAwards: !state.showAllAwards,
    }))
  }

  render() {
    const { awards } = this.props
    /*
    * superceded marks lower ranking awards. eg:
    * premier of award in 2018/2019 would be marked superceded
    * if there was an elite or chairman's award in the same year
    */
    const awardList = awards.filter(a => !a.superceded)
    const awardCount = awardList.length
    const hiddenAwards = awardCount - AWARD_LIMIT

    const displayAwards = this.state.showAllAwards ? awardList : awardList.slice(0, AWARD_LIMIT)

    return (
      <div>
        <ul>
          {displayAwards.map(({ year, name, rank }) => (
            <li key={`${year}${name}${rank}`}>
              {/\d{4}/.test(name) ? name : `${year} - ${name}`}
              {rank === 'Achievement' || rank === 'Winner' ? null : ` - ${rank}`}
            </li>
          ))}
          {awardCount > AWARD_LIMIT ? (
            <a onClick={this.toggleAwardsList}>
              {this.state.showAllAwards
                ? 'Show fewer'
                : `And ${hiddenAwards} earlier ${pluralize(hiddenAwards, 'award', 'awards')}`}
            </a>
          ) : ''}
        </ul>
      </div>
    )
  }
}
