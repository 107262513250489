// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { strip } from '@raywhite/markup'

import type { Node } from 'react'

import Link from './Link.jsx'

import type { MenuItem } from '../../types'
import { menuItemShape } from '../../types'

type Props = {
  children: Node,
  hasSecondary: boolean,
  primary: Array<MenuItem>,
  showMenu(): void,
}

const cleanPath = (path = '') => (path[0] === '/' ? path.replace(/\/$/, '') : path)

const HeaderMenu = ({
  children,
  primary,
  showMenu,
  hasSecondary,
}: Props) => (
  <nav className="mini_nav show_charlie">
    {children}
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      {!!primary.length && primary.map(({ id, label, path, showSubmenu }) => (
        <li key={id}>
          {showSubmenu ? (
            <span onClick={showSubmenu}>{label}</span>
          ) : (
            <Link to={cleanPath(path)}>{strip(label)}</Link>
          )}
        </li>
      ))}
      {hasSecondary && (
        <li>
          <span onClick={showMenu}>+ More</span>
        </li>
      )}
    </ul>
  </nav>
)

HeaderMenu.propTypes = {
  primary: PropTypes.arrayOf(menuItemShape).isRequired,
  showMenu: PropTypes.func.isRequired,
  hasSecondary: PropTypes.bool.isRequired,
}

export default HeaderMenu
