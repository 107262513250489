/**
 * Creates a reducer that uses a given map to call handler reducers for given
 * actions.
 *
 * @example
 * const map = { myaction: () => { do.thing(); } };
 * const reducer = makeMappedReducer(map);
 * reducer({}, {type: 'myaction'}); // Will call the reducer for "myaction"
 * reducer({}, {type: 'otheraction'}); // Will not call reducer
 *
 * @param {(Object|Map)} map The map of actions (keys) to reducers (values).
 * @param {*} [defaultState] The default state of the reducer.
 *
 * @returns {function} The reducer function.
 */
export function makeMappedReducer(map, defaultState = {}) {
  return (state = defaultState, action) => {
    if (!map[action.type]) return state
    return map[action.type](state, action)
  }
}

export const DEFAULT_LOADABLE = Object.freeze({
  loaded: false, loading: false, notFound: false, error: false,
})
