// @flow

import React, { Component } from 'react'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import ListingCardList from './ListingCardList.jsx'
import ListingGridList from './ListingGridList.jsx'
import LoadMore from './LoadMore.jsx'

const eventProps = eventMeta({
  category: 'List',
  action: 'Load More',
})

type Props = {
  load: () => void,
  listings: Object,
  loadMoreLabel: string,
  listComponent: typeof ListingCardList | typeof ListingGridList,
  listOptions?: Object,
};

/**
 * Wraps a listing list (or the Agent's listing list) with "load more" pagination logic.
 *
 * The current page is stored as local state and an additional load more button
 * rendered if it's possible to load more results.  The containing component is
 * responsible for performing the actual load and passing the results to the
 * LoadMoreListingList which will render them in a ListingList.
 */
export default class LoadMoreListingList extends Component<Props> {
  static defaultProps = {
    loadMoreLabel: 'Load More',
    listComponent: ListingCardList,
    listOptions: {},
  }

  render() {
    const {
      load,
      loadMoreLabel,
      listings: { loaded, loading, items, totalResults, error },
      listComponent: ListingList,
      listOptions,
    } = this.props

    return (
      <ListingList
        loaded={loaded}
        listings={items}
        error={error}
        {...listOptions}
      >
        <LoadMore
          loadMoreLabel={loadMoreLabel}
          loadedCount={items.length}
          totalCount={totalResults}
          loading={loading}
          load={load}
          buttonProps={eventProps}
        />
      </ListingList>
    )
  }
}
