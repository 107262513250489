/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { strip } from '@raywhite/markup'
import Link from './Link.jsx'
import Loader from './Loader.jsx'

const itemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  children: PropTypes.array,
  show: PropTypes.func,
  hide: PropTypes.func,
})

const visibleTopLevels = {
  Buy: 'Recently Listed',
  Rent: 'Recently Listed',
  'About Us': 'Contact',
  Sell: false,
}

const cleanPath = (path = '') => (path[0] === '/' ? path.replace(/\/$/, '') : path)

const Menu = ({ items, className, isSubmenuActive }) => (
  <div className={className}>
    <ul>
      {items.map(({ id, label, path, children, showSubmenu, hideSubmenu }) => (
        <li
          key={id}
          className={classNames({
            has_submenu: !!showSubmenu,
            show_submenu: isSubmenuActive(id),
          })}
        >
          {!!showSubmenu && (
            <span
              onClick={isSubmenuActive(id) ? hideSubmenu : showSubmenu}
              onKeyPress={isSubmenuActive(id) ? hideSubmenu : showSubmenu}
            >
              {strip(label)}
            </span>
          )}
          {!showSubmenu && (
            <Link to={cleanPath(path)}>{strip(label)}</Link>
          )}
          {!!showSubmenu && (
            <ul>
              {path !== '#' && visibleTopLevels[label] !== false && (
                <li>
                  <Link to={cleanPath(path)}>{visibleTopLevels[label] || strip(label)}</Link>
                </li>
              )}
              {children.map(child => (
                <li key={child.id}>
                  <Link to={cleanPath(child.path)}>{strip(child.label)}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </div>
)

Menu.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  className: PropTypes.string,
  isSubmenuActive: PropTypes.func.isRequired,
}

export default class SlideMenu extends React.Component {
  static propTypes = {
    menuTitle: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    hideMenu: PropTypes.func.isRequired,
    primary: PropTypes.arrayOf(itemShape).isRequired,
    secondary: PropTypes.arrayOf(itemShape).isRequired,
    isSubmenuActive: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired,
    onContactUs: PropTypes.func.isRequired,
    children: PropTypes.node,
    // Only used to for shouldComponentUpdate
    showSubs: PropTypes.object.isRequired,
  }

  static defaultProps = {
    visible: false,
    loaded: false,
    showSubs: {},
    menuTitle: 'Ray White',
  }

  shouldComponentUpdate({ visible, showSubs }) {
    // Definitely rerender if the shown submenus change
    if (showSubs !== this.props.showSubs) return true

    // Otherwise no need to update if it's not visible before and after
    if (!visible && !this.props.visible) return false

    // Ideally more smarts here, but state lives outside the component...
    return true
  }

  componentDidUpdate(props) {
    if (!props.visible && this.props.visible && this.wrapper) this.wrapper.focus()
  }

  handleMenuClick = (e) => {
    const { target } = e

    // Don't toggle visibility if link was not clicked with main mouse button
    if (e.button !== 0) return

    // Don't toggle if link was clicked while holding ctrl/command key
    if (e.metaKey || e.ctrlKey) return

    // Don't close if clicking on the menu wrapper
    if (target.tagName !== 'A' && !/\bmenu_wrap\b/.test(target.classList)) return

    // Don't close the menu if clicking on an item which toggles a submenu
    if (/\bhas_submenu\b/.test(target.classList)) return

    this.props.hideMenu()
  }

  setWrapper = el => {
    this.wrapper = el
  }

  handleKeyDown = e => {
    if (e.key === 'Escape') this.props.hideMenu()
  }

  render() {
    const {
      menuTitle,
      visible,
      hideMenu,
      primary,
      secondary,
      isSubmenuActive,
      loaded,
      onContactUs: handleContactUs,
      children: content,
    } = this.props

    return (
      <div>
        <div
          className={classNames('menu_wrap', { show_menu: visible })}
          onClick={this.handleMenuClick}
          onKeyDown={this.handleKeyDown}
          tabIndex="0"
          ref={this.setWrapper}
        >
          <div className={classNames('menu', { active: visible })}>

            <div className="menu_header">
              <span
                className="menu_close"
                onClick={hideMenu}
              >
                Close
              </span>
              <Link to="/" className="logo" />
              <span className="menu_title">{menuTitle}</span>
            </div>

            {!loaded ? (
              <Loader />
            ) : (
              <nav className="menu_items">
                {!!primary.length && (
                  <Menu
                    className="top_level_menu_items"
                    items={primary}
                    isSubmenuActive={isSubmenuActive}
                  />
                )}
                {!!secondary.length && (
                  <Menu
                    className="second_level_menu_items"
                    items={secondary}
                    isSubmenuActive={isSubmenuActive}
                  />
                )}
              </nav>
            )}

            <div className="menu_footer">
              {content}
              <span
                className="btn invisible_light no_arrow"
                onClick={handleContactUs}
              >
                Contact Us
              </span>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
