// @flow

import React from 'react'

import type { Listing } from '@raywhite/data-client/lib/modules/listings'

import { describeListing } from '@raywhite/data-utils/lib/data/listing/listings'

type Props = {
  className?: string,
  listing: Listing,
  describeOptions?: Object,
  component?: string,
};

export default class ListingDescriptor extends React.Component<Props> {
  static defaultProps = {
    component: 'span',
  }

  shouldComponentUpdate(props: Props) {
    return this.props.listing.id !== props.listing.id
  }

  render() {
    const {
      className,
      listing,
      describeOptions = {},
      component: Component = 'span',
    } = this.props

    const description = describeListing(listing, describeOptions)

    return (
      <Component className={className}>
        {description}
      </Component>
    )
  }
}
