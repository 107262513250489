import url from 'url'
import _at from 'lodash.at'

import { makeMappedReducer, DEFAULT_LOADABLE } from '../utils'
import rewrites from '../../data/menu_rewrites.json'

/*
 * For content we have
 *  - content "types" (unique type name, unique "rewrite" field in URLs) (types)
 *  - content "items" (unique id, unique type/slug combo, unique path (hard to get)) (data)
 *  - mapping of type to items (queries) which can be by slug or id
 *
 * Actual pages must be accessible by the current paths on the existing office
 * sites; these *might* redirect to a different location, but not for now.
 *
 * Identifying them by path requires mapping the path prefix to a content type;
 * the specific types need to be downloaded and inspected in order to map this
 * correctly, the type name does not necessarily reflect the path that the content
 * is on (e.g. posts, pages and custom post types with spaces in them).
 *
 * Content may be loaded when a path is accessed directly by URL (e.g. from a
 * search engine) or by loading content for an archive (e.g. a list of news posts).
 * In both cases a placeholder should be returned if the data is not yet loaded,
 * an updated placeholder should be stored when content loading starts and the
 * placeholder should be replaced when content is available.
 *
 * News is a special custom content type in that it's required (so all sites
 * have it) and that it has an "archive" (a list of those items that can be
 * explored).
 */

const LOADING_PATH = 'rw-office/content/LOADING_PATH'
const LOADED_PATH = 'rw-office/content/LOADED_PATH'
const LOADING_TYPES = 'rw-office/content/LOADING_TYPES'
const LOADED_TYPES = 'rw-office/content/LOADED_TYPES'
const LOADING_CONTENT = 'rw-office/content/LOADING_CONTENT'
const LOADED_CONTENT = 'rw-office/content/LOADED_CONTENT'
const LOADING_SYNDICATED_NEWS = 'rw-office/content/LOADING_SYNDICATED_NEWS'
const LOADED_SYNDICATED_NEWS = 'rw-office/content/LOADED_SYNDICATED_NEWS'

const DEFAULT_CONTENT = Object.freeze({ ...DEFAULT_LOADABLE })
const DEFAULT_TYPES = Object.freeze({ ...DEFAULT_LOADABLE, entities: {} })
const DEFAULT_TYPE = Object.freeze({ ...DEFAULT_LOADABLE })
const DEFAULT_SYNDICATED_NEWS = Object.freeze({ ...DEFAULT_LOADABLE, entities: [] })
const DEFAULT_QUERY = Object.freeze({ ...DEFAULT_LOADABLE, ids: [], total: undefined })

const defaultState = {
  types: DEFAULT_TYPES, // Content "types" (WP post types) metadata
  syndicatedNews: DEFAULT_SYNDICATED_NEWS, // Syndicated news, e.g. from rw.com or other portals
  queries: {}, // Queries and results (including multiple loads and load by path)
  entities: {}, // Mapped list of post ID -> post data
}

/**
 * Generate a key for storing a query into the state and for looking up the
 * results of a query.
 *
 * @param {string} type The type of content being queried.
 * @param {int} count The number of records being loaded.
 *
 * @return {string} The query key.
 */
function queryKey(type, count, { orderby, order } = {}) {
  return `t:${type} c:${count} o:${orderby} ${order}`
}

/**
 * Get the supported content types.
 *
 * @param {Object} state The content slice of the state.
 *
 * @return {Object} A list of types.
 */
export function getContentTypes(state) {
  return state.types || DEFAULT_TYPES
}

export function getSyndicatedNews(state) {
  return state.syndicatedNews || DEFAULT_TYPES
}

/**
 * Get a specific content type.
 *
 * @param {Object} state The content slice of the state.
 * @param {string} type The name of the type to get.
 *
 * @return {Object} The type, or DEFAULT TYPE if not found.
 */
export function getContentType(state, type) {
  const types = getContentTypes(state)
  return types.entities[type] || {
    ...DEFAULT_TYPE,
    loaded: types.loaded,
    notFound: types.loaded,
  }
}

/**
 * Get a list of content for the specified content type.
 *
 * @param {Object} state The content slice of the state.
 * @param {string} type The type of content being queried.
 * @param {int} page The page number being loaded.
 * @param {int} count The number of records being loaded.
 *
 * @return {Object} An object indicating the loading status and containing the
 *                  results in the `entities` key (if loaded).
 */
export function getContentForType(state, type, page = 1, count = 10, params) {
  const query = state.queries[queryKey(type, count, params)]

  if (!query) {
    return { ...DEFAULT_QUERY, entities: [] }
  }

  let meta

  if (page === -1) {
    const keys = Object.keys(query)
    meta = keys.reduce((result, _page) => {
      const { ids, total, loaded, loading, notFound, error } = query[_page]

      /* eslint-disable no-param-reassign */
      result.ids = result.ids.concat(ids)
      result.total = result.total || total
      result.loaded = result.loaded && loaded
      result.loading = result.loading || loading
      result.notFound = result.notFound || notFound
      result.error = result.error || error
      /* eslint-enable no-param-reassign */

      return result
    }, { ...DEFAULT_QUERY, loaded: !!keys.length })
  } else {
    if (!query[page] || !query[page].ids) {
      return { ...DEFAULT_QUERY, entities: [] }
    }
    meta = query[page]
  }

  return {
    ...meta,
    entities: _at(state.entities, meta.ids),
  }
}

/**
 * Get the content for a given path.
 *
 * If known, the content will be returned, otherwise a DEFAULT_CONTENT will be
 * returned instead.
 *
 * @param {string} path The path to look for content on.
 * @param {Object} state The content slice of the application state.
 *
 * @returns {Object} The content if known, otherwise a DEFAULT_CONTENT.
 */
export function getContentForPath(state, path) {
  const id = state.queries[path]
  if (!id) return DEFAULT_CONTENT

  // Pending/failed path load, actually an object with details
  if (Number.isNaN(+id)) return id

  return state.entities[id] || DEFAULT_CONTENT
}

function loadingTypes() {
  return { type: LOADING_TYPES }
}

function loadedTypes(entities, { error = false } = {}) {
  return {
    type: LOADED_TYPES,
    types: { entities, loaded: true, loading: false, error },
  }
}

function loadingSyndicatedNews() {
  return { type: LOADING_SYNDICATED_NEWS }
}

function loadedSyndicatedNews(entities, { error = false } = {}) {
  return {
    type: LOADED_SYNDICATED_NEWS,
    syndicatedNews: {
      entities: entities || [],
      loaded: true,
      loading: false,
      error,
    },
  }
}

/**
 * Create action indicating that content is being loaded.
 *
 * @param {string} path The path to the content being loaded.
 *
 * @returns {Object} The action.
 */
function loadingContentForPath(path) {
  return { type: LOADING_PATH, path }
}

/**
 * Create action indicating that content has finished loading.
 *
 * @param {string} path The path to the content being loaded.
 * @param {Object} content The content object, including rendered content.
 *
 * @returns {Object} The action.
 */
function loadedContentForPath(
  path, content, { notFound = false, error = false } = {}
) {
  return {
    type: LOADED_PATH,
    path,
    content: {
      ...content,
      loaded: true,
      loading: false,
      notFound,
      error,
    },
  }
}

function loadingContent(contentType, page, count, params) {
  return { type: LOADING_CONTENT, contentType, page, count, params }
}

function loadedContent(
  entities, contentType, page, count, params, { notFound = false, error = false } = {}
) {
  return {
    type: LOADED_CONTENT,
    entities: entities || [],
    contentType,
    page,
    count,
    params,
    loaded: true,
    notFound,
    error,
  }
}

function reduceLoadingTypes(state) {
  return {
    ...state,
    types: { ...state.types, loading: true },
  }
}

function reduceLoadedTypes(state, { types }) {
  return { ...state, types }
}

function reduceLoadingSyndicatedNews(state) {
  return {
    ...state,
    syndicatedNews: { ...state.syndicatedNews, loading: true },
  }
}

function reduceLoadedSyndicatedNews(state, { syndicatedNews }) {
  return { ...state, syndicatedNews }
}

/**
 * Reduce content finished loading actions.
 *
 * @param {Object} state The content slice of the state.
 * @param {Object} action The action object.
 *
 * @returns {Object} The new content slice.
 */
function reduceLoadingContentForPath(state, action) {
  // Push in a placeholder to say that we're loading it
  const next = { ...state }
  next.queries = {
    ...next.queries,
    [action.path]: { ...DEFAULT_CONTENT, loading: true },
  }

  return next
}

/**
 * Reduce content started loading actions.
 *
 * @param {Object} state The content slice of the state.
 * @param {Object} action The action object.
 *
 * @returns {Object} The new content slice.
 */
function reduceLoadedContentForPath(state, action) {
  const next = { ...state }

  if (action.content.id) {
    next.entities = {
      ...state.entities,
      [action.content.id]: {
        ...DEFAULT_CONTENT,
        ...action.content,
        link: action.content.link
          ? action.content.link.replace(/\/$/, '')
          : action.content.link,
      },
    }
  }

  next.queries = {
    ...state.queries,
    [action.path]: action.content.id || action.content,
  }

  return next
}

function reduceLoadingContent(state, action) {
  const { contentType: type, page, count, params } = action
  const key = queryKey(type, count, params)

  return {
    ...state,
    queries: {
      ...state.queries,
      [key]: {
        ...state.queries[key],
        [page]: { ...DEFAULT_QUERY, loading: true },
      },
    },
  }
}

function reduceLoadedContent(state, action) {
  const {
    entities,
    contentType: type,
    page,
    count,
    params,
    loaded,
    notFound,
    error,
  } = action

  const total = entities._paging ? parseInt(entities._paging.total, 10) : 0
  const byid = entities.reduce((results, item) => {
    results[item.id] = { // eslint-disable-line no-param-reassign
      ...DEFAULT_CONTENT,
      ...item,
      link: item.link
        ? item.link.replace(/\/$/, '')
        : item.link,
      loaded: true,
    }
    return results
  }, {})
  const ids = entities.map(item => item.id)

  const paths = entities.reduce((results, item) => {
    const path = url.parse(item.link).path.replace(/(^\/|\/$)/g, '')
    results[path] = item.id // eslint-disable-line no-param-reassign
    return results
  }, {})

  const key = queryKey(type, count, params)

  return {
    ...state,
    queries: {
      ...state.queries,
      ...paths,
      [key]: {
        ...state.queries[key],
        [page]: { ...DEFAULT_QUERY, total, ids, loaded, notFound, error },
      },
    },
    entities: {
      ...state.entities,
      ...byid,
    },
  }
}

/**
 * Loads the types of content available for the current site.
 *
 * @param {WP} client The configured WP client.
 *
 * @return {function} The type loading thunk.
 */
export function loadContentTypes(api) {
  return function dispatchLoadTypes(dispatch, getState) {
    const types = getContentTypes(getState().content)

    if (types.loaded || types.loading) {
      // Don't reload meta
      return Promise.resolve(types)
    }

    dispatch(loadingTypes())

    return api()
      .then(_types => dispatch(loadedTypes(_types)))
      .catch(error => dispatch(loadedTypes(false, { error })))
      .then(() => getContentTypes(getState().content))
  }
}

export function loadSyndicatedNews(api) {
  return function dispatchLoadSyndicatedNews(dispatch, getState) {
    const news = getSyndicatedNews(getState().content)
    if (news.loaded || news.loading || news.error) {
      return Promise.resolve(news)
    }

    dispatch(loadingSyndicatedNews())

    return api()
      .then(entities => dispatch(loadedSyndicatedNews(entities)))
      .catch(error => dispatch(loadedSyndicatedNews(false, { error })))
      .then(() => getSyndicatedNews(getState().content))
  }
}

/**
 * Attempt to load content for the given path.
 *
 * @param {string} path The requested path.
 *
 * @returns {function} The content loading thunk.
 */
export function loadContentForPath(api, path) {
  /**
   * @param {function} dispatch The store dispatch function.
   * @param {function} getState The store getState function.
   *
   * @returns {Promise} The content loading promise, resolves when done.
   */
  return function dispatchLoadContentForPath(dispatch, getState) {
    const { content } = getState()

    // Check if already loaded or loading
    if (content.queries[path]) return Promise.resolve(content.queries[path])

    if (rewrites[`/${path.replace(/(^\/|\/$)/g, '')}/`] === false) {
      // Known "ignore" path

      dispatch(loadedContentForPath(path, false, { notFound: true }))
      return Promise.resolve(getContentForPath(getState().content, path))
    }

    // Indicate that we're loading it
    dispatch(loadingContentForPath(path))

    return api(path)
      .then(posts => {
        if (!posts.length || !posts._paging || !posts._paging.total) {
          // No results, so not found
          return dispatch(loadedContentForPath(path, false, { notFound: true }))
        }

        return dispatch(loadedContentForPath(path, posts[0]))
      })
      .catch(error => dispatch(loadedContentForPath(path, false, { error })))
      .then(() => getContentForPath(getState().content, path))
  }
}

/**
 * Load content for a specified type.
 *
 * @param {WP} client The configured WP client.
 * @param {string} type The type of content being queried.
 * @param {int} page The page number being loaded.
 * @param {int} count The number of records being loaded.
 *
 * @return {function} The content loading thunk.
 */
export function loadContentForType(api, type, page = 1, count = 10, params = {}) {
  return function dispatchLoadContent(dispatch, getState) {
    const content = getContentForType(getState().content, type, page, count, params)

    if (content.loaded || content.loading) {
      // Already loaded, return immediately
      return Promise.resolve(content)
    }

    dispatch(loadingContent(type, page, count, params))

    return api(type, page, count, params)
      .then(entities => dispatch(loadedContent(entities, type, page, count, params)))
      .catch(error => dispatch(loadedContent(false, type, page, count, params, { error })))
      .then(() => getContentForType(getState().content, type, page, count, params))
  }
}

/**
 * The main reducer, dispatches to sub reducers as indicated in the action map.
 *
 * @function
 *
 * @param {Object} state The content slice of the state.
 * @param {Object} action The action object.
 *
 * @returns {Object} The new content slice.
 */
const reducer = makeMappedReducer({
  [LOADING_PATH]: reduceLoadingContentForPath,
  [LOADED_PATH]: reduceLoadedContentForPath,
  [LOADING_TYPES]: reduceLoadingTypes,
  [LOADED_TYPES]: reduceLoadedTypes,
  [LOADING_CONTENT]: reduceLoadingContent,
  [LOADED_CONTENT]: reduceLoadedContent,
  [LOADING_SYNDICATED_NEWS]: reduceLoadingSyndicatedNews,
  [LOADED_SYNDICATED_NEWS]: reduceLoadedSyndicatedNews,
}, defaultState)
export default reducer
