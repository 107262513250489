import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { loadOrganisations } from '@raywhite/redux/lib/org'
import { loadMembers, getMember } from '@raywhite/redux/lib/member'
import { fetchListing } from '@raywhite/redux/lib/listing'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'

import {
  loadContentForType,
  getContentForType,
  getContentForPath,
} from '../../redux/modules/content'
import withContext from '../hocs/withContext'
import ContentPage from './ContentPage.jsx'
import CaseStudyContentItem from '../presentational/CaseStudyContentItem.jsx'

class CaseStudyContentPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    getListings: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    getContentForType: PropTypes.func.isRequired,
    itemOptions: PropTypes.object.isRequired,
    recentStudies: PropTypes.object.isRequired,
    relatedAgents: PropTypes.array,
    relatedListing: PropTypes.object,
    allCaseStudy: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }

  static fetchData(dispatch, { splat }, data) {
    // @todo fetch other data requirements
    return unlazyAll([
      ContentPage.fetchData(dispatch, { splat: `case-studies/${splat}` }, data),
      dispatch(loadContentForType(data.getContentForType, 'casestudy', 1, 30)),
    ])
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    this.loadData()
  }

  loadData() {
    const {
      dispatch,
      getOrganisations,
      getListings,
      getMembers,
      allCaseStudy,
      getContentForType: getContent,
      content,
    } = this.props

    const {
      related_agents: relatedAgents,
      related_listing: listing,
      related_offices: relatedOffices,
    } = content

    // Load recent case studies
    const itemIndex = allCaseStudy.entities.indexOf(content)
    const page = (itemIndex === -1 || (itemIndex - 1) % 30 === 0)
      ? Math.floor(allCaseStudy.entities.length / 30) + 1
      : 1

    dispatch(loadMembers(getMembers, relatedOffices, relatedAgents))
    dispatch(loadContentForType(getContent, 'casestudy', page, 30))
    dispatch(loadOrganisations(getOrganisations, relatedOffices))

    if (listing) dispatch(fetchListing(getListings, listing))
  }

  render() {
    const {
      itemOptions,
      recentStudies,
      allCaseStudy,
      content,
      relatedListing,
      relatedAgents,
    } = this.props

    const itemIndex = allCaseStudy.entities.indexOf(content)
    return (
      <div className="sgl_case_study_wrap">
        <ContentPage
          {...this.props}
          itemOptions={{
            ...itemOptions,
            recentStudies,
            relatedAgents,
            relatedListing,
            nextItem: itemIndex !== -1 ? allCaseStudy.entities[itemIndex + 1] : undefined,
            prevItem: (itemIndex !== -1 && itemIndex !== 0)
              ? allCaseStudy.entities[itemIndex - 1]
              : undefined,
          }}
        />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { members, listings } = state
  // Avoid duplicate loading
  const studies = getContentForType(state.content, 'casestudy', -1, 30)
  const recentStudies = {
    ...studies,
    entities: studies.entities.slice(0, 3),
  }
  const splat = props.location.pathname.replace(/(^\/|\/$)/g, '')
  // Note: loaded by ContentPage container
  const content = getContentForPath(state.content, splat)
  const {
    related_agents: relatedAgentIds = [],
    related_listing: relatedListingId,
  } = content

  const relatedAgents = relatedAgentIds
    .map(id => getMember(members, id))
    .filter(member => !member.notFound && member.loaded)
  const relatedListing = listings.entities[relatedListingId]

  return {
    shouldSetAppHeading: false,
    itemComponent: CaseStudyContentItem,
    itemOptions: {
      showDate: true,
      showSharing: true,
      showNavigation: true,
    },
    params: {
      ...props.params,
      splat,
    },
    content,
    relatedAgents,
    relatedListing,
    allCaseStudy: studies,
    recentStudies,
  }
}

export default compose(
  withContext('getContentForType', 'getMembers', 'getOrganisations', 'getListings'),
  connect(mapStateToProps),
)(CaseStudyContentPage)
