// @flow

import React, { Component } from 'react'

type Props = {
  className?: string,
  label: string,
  url: string,
};

export default class TpsLink extends Component<Props> {
  form: ?HTMLFormElement

  setForm = (form: ?HTMLFormElement) => {
    this.form = form
  }

  submit = () => {
    if (this.form) this.form.submit()
  }

  render() {
    const {
      className,
      label = 'Apply for this property',
      url,
      ...rest
    } = this.props

    return (
      <form
        action={url}
        method="post"
        ref={this.setForm}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span
          className={className}
          onClick={this.submit}
          role="button"
          tabIndex={0}
          {...rest}
        >
          {label}
        </span>
      </form>
    )
  }
}
