import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  getSyndicatedNews,
  loadSyndicatedNews,
  getContentType,
} from '../../redux/modules/content'
import withContentSearch from '../hocs/withContentSearch'
import withContext from '../hocs/withContext'
import ContentSummary from '../presentational/widget/ContentSummary.jsx'

class NewsContentSummary extends React.Component {
  componentDidUpdate() {
    const { dispatch, contentSearch, getSyndicatedNews: getNews } = this.props
    const { search: { loaded, total, error } } = contentSearch

    if (loaded && !total && !error) {
      // Load syndicated news if there is no site news
      dispatch(loadSyndicatedNews(getNews))
    }
  }

  renderSyndicatedNews() {
    const {
      className,
      morePath,
      moreLabel,
      cardOptions,
      listClassName,
      contentSearchCount,
      syndicatedNews: { loaded, entities },
    } = this.props

    return (
      <ContentSummary
        className={className}
        items={entities.slice(0, contentSearchCount)}
        loaded={loaded}
        typeName="News"
        morePath={morePath}
        moreLabel={moreLabel}
        cardOptions={{ ...cardOptions, external: true }}
        listClassName={listClassName}
      />
    )
  }

  render() {
    const {
      className,
      morePath,
      moreLabel,
      cardOptions,
      listClassName,
      contentSearch: {
        search: { loaded, error, entities },
      },
    } = this.props

    if (loaded && !error && !entities.length) return this.renderSyndicatedNews()

    return (
      <ContentSummary
        className={className}
        items={entities}
        loaded={loaded}
        typeName="News"
        morePath={morePath}
        moreLabel={moreLabel}
        cardOptions={cardOptions}
        listClassName={listClassName}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    syndicatedNews: getSyndicatedNews(state.content),
    contentSearchType: 'news',
    // Used to force a refresh after types are loaded
    type: getContentType(state.content, 'news'),
  }
}

NewsContentSummary.propTypes = {
  ...ContentSummary.propTypes,
  contentSearch: PropTypes.object.isRequired,
  contentSearchLoad: PropTypes.func.isRequired,
  syndicatedNews: PropTypes.object,
}

if (process.env.NODE_ENV !== 'production') {
  // Clean up fields that we set
  ['items', 'loaded', 'typeName'].forEach(field => {
    // eslint-disable-next-line react/forbid-foreign-prop-types
    delete NewsContentSummary.propTypes[field]
  })
}

export default compose(
  withContext('getSyndicatedNews'),
  connect(mapStateToProps),
  withContentSearch,
)(NewsContentSummary)
