// @flow

import React, { Component } from 'react'
import { compose } from 'redux'

import qs from 'query-string'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { submitForm, warnForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

import typeof fetchType from 'isomorphic-fetch'

import Modal from './Modal.jsx'
import ModalInner from './ModalInner.jsx'
import SignInForm from '../container/SignInForm.jsx'
import withContext from '../hocs/withContext'
import withUser from '../hocs/withUser'
import { loadedUser } from '../../redux/modules/user'

type Props = {
  handleClose: () => void,
  dest: string,
  fetch: fetchType,
  _window: ?Object,
  user: ?Object,
  loadUser: boolean => void,
  store: any,
  authFailed: boolean,
};

type State = {
  showForm: boolean,
}

const linkMeta = eventMeta({ category: 'Sign In', action: 'Authorise' })

class SignInModal extends Component<Props, State> {
  state = {
    showForm: false,
  }

  signIn = data => {
    const {
      fetch,
      loadUser,
      store: { dispatch },
      // $FlowFixMe This is fine flow, I'm using a default for _window
      _window: { dataLayer } = {},
    } = this.props
    // $FlowFixMe This is fine flow, I'm using a default for _window
    return submitForm(
      fetch,
      dataLayer,
      'Email Sign In',
      '/api/auth/signin/email',
      data,
      'Unable to sign in.',
      'Site',
    ).then(res => {
      if (res.error) return res

      // Immediately load user for display
      dispatch(loadedUser(res))

      // Reload user to hook into standard logging processes
      loadUser(true)

      return res
    })
  }

  toggleForm = () => this.setState(state => ({ showForm: !state.showForm }))

  render() {
    const { authFailed, handleClose, dest, user } = this.props
    const query = qs.stringify({ dest })
    const { showForm } = this.state

    return (
      <div>
        <Modal
          className="register_modal"
          handleClose={handleClose}
        >
          <ModalInner
            className="modal_form"
            title="Document Downloads"
            subtitle="Sign In"
          >
            {!showForm && ([
              authFailed && warnForm('Sorry, we couldn\'t sign you in - please try again.'),
              /* TODO reenable once facebook app is reenabled
              <a
                key="fb"
                className="btn facebook no_arrow"
                href={`/api/auth/signin/facebook?${query}`}
                {...linkMeta}
                data-ev-label="Facebook"
              >
                <span>Continue with Facebook</span>
              </a>,
              */
              <a
                key="gplus"
                className="btn gplus no_arrow"
                href={`/api/auth/signin/google?${query}`}
                {...linkMeta}
                data-ev-label="Google"
              >
                <span>Continue with Google</span>
              </a>,
              <span
                key="email"
                className="btn email no_arrow"
                {...linkMeta}
                data-ev-label="Email"
                onClick={this.toggleForm}
              >
                <span>Continue with Email</span>
              </span>,
            ].filter(Boolean))}
            {showForm && (
              <SignInForm
                sendForm={this.signIn}
                handleClose={handleClose}
                user={user}
              />
            )}
            <p className="modal_social_disclaimer muted centered_text">
              <a
                href="https://www.raywhite.com/franchisee-privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Privacy Policy
              </a>
            </p>
          </ModalInner>
        </Modal>
      </div>
    )
  }
}

export default compose(
  withContext('_window', 'fetch', 'store'),
  withUser({ required: false, deferred: false }),
)(SignInModal)
