import React from 'react'
import PropTypes from 'prop-types'

const VALID_HEADING_LEVELS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const getFormattedHeadingLevel = level => {
  const formattedLevel = level ? level.toLowerCase() : ''
  return VALID_HEADING_LEVELS.includes(formattedLevel) ? formattedLevel : 'p'
}

// TODO: Basic styling
// INFO: Currently styling is applied where the component is being used e.g. BusinessCard

const HeadingGroup = ({ heading, headingLevel, headingSuffix, showSubheading, subheading }) => {
  const Heading = getFormattedHeadingLevel(headingLevel)

  return (
    <header className="HeadingGroup">
      <Heading className="HeadingGroup__heading">
        {heading}
        {/* INFO: Used for Member names */}
      </Heading>
      {headingSuffix
        && <p className="HeadingGroup__heading--suffix muted">{headingSuffix}</p>}
      {(showSubheading && subheading)
        && <p className="HeadingGroup__subheading">{subheading}</p>}
    </header>
  )
}

HeadingGroup.propTypes = {
  heading: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  headingSuffix: PropTypes.string,
  showSubheading: PropTypes.bool,
  subheading: PropTypes.string,
}

HeadingGroup.defaultProps = {
  headingLevel: 'h1',
  headingSuffix: '',
  showSubheading: false,
  subheading: '',
}

export default HeadingGroup
