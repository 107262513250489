// @flow
import { makeMappedReducer, DEFAULT_LOADABLE } from '../utils'

import type { GetState, Action, Dispatch } from '../types'

const PATCH_USER_DATA = 'rw-office/user/PATCH_USER_DATA'
const SIGN_OUT = 'rw-office/user/SIGN_OUT'

const initialState = Object.freeze({
  ...DEFAULT_LOADABLE,
  language: 'en',
})

// Update known user data
function reducePatchUserData(state: Object, action: Action) {
  return {
    ...state,
    ...action.data,
  }
}

// Log out a user
function reduceLogOut(state: Object) {
  if (!state.userId) return state
  return {
    ...initialState,
  }
}

const reducer = makeMappedReducer({
  [PATCH_USER_DATA]: reducePatchUserData,
  [SIGN_OUT]: reduceLogOut,
}, initialState)
export default reducer

export function patchUserData(data: Object): Object {
  return {
    type: PATCH_USER_DATA,
    data,
  }
}

export function signout() {
  return { type: SIGN_OUT }
}

export function getUserData(state: Object) {
  return state
}

// Mark user as being loaded so we don't do it multiple times
function loadingUser() {
  return patchUserData({ loading: true })
}

// Note: can't get this to flow type correctly
type MaybeError = any;

// Apply loaded user data to the state
export function loadedUser(
  data: ?Object,
  { error = false }: { error?: MaybeError } = {},
) {
  return patchUserData({
    ...data,
    loaded: true,
    loading: false,
    notFound: !data || !data.userId,
    error,
  })
}

// Dispatch an action to load the user
export function loadUser(api: () => Promise<Object>, reload: boolean = false) {
  return (dispatch: Dispatch, getState: GetState): Promise<*> => {
    const user = getUserData(getState().user)

    // Check if already logged in, or loaded, or loading
    if ((!reload && user.loaded) || user.loading) {
      return Promise.resolve(user)
    }

    dispatch(loadingUser())

    return api()
      .then(data => dispatch(loadedUser(data)))
      .catch(error => dispatch(loadedUser(undefined, { error })))
      .then(() => getUserData(getState().user))
  }
}
