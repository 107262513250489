import { stockFormEmail } from '@raywhite/functionalities-utils/lib/functionalities/form'
import { fields, labels } from './SellAppraisalForm.jsx'
// eslint-disable-next-line no-restricted-exports
export { default, validate } from './SellAppraisalForm.jsx'

export const process = (values, { send, referrer }) => stockFormEmail(
  send,
  'Rent Appraisal Request',
  fields,
  labels,
  values,
  {
    prefix: `A rent appraisal request has been received from ${referrer || 'your office website'}.`,
    referrer,
  }
)
