import React from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'
import { connect } from 'react-redux'
import { compose } from 'redux'
import flagged from '@raywhite/helpers-utils/lib/helpers/flags'
import { getMenu, loadMenu } from '../../redux/modules/menu'
import withContext from './withContext'

function mapStateToProps(state) {
  return {
    useServicePages: flagged('servicePages', state),
    redirections: state.config.redirections,
    menu: getMenu(state.menu),
  }
}

/**
 * Loads and provides organisation data to wrapped components.
 */
const withMenus = Wrapee => {
  class Wrapper extends React.Component { // eslint-disable-line react/no-multi-comp
    componentDidMount() {
      const { dispatch, getMenus, useServicePages, redirections } = this.props
      dispatch(loadMenu(getMenus, { useServicePages, redirections }))
    }

    render() {
      return <Wrapee {...this.props} />
    }
  }

  Wrapper.propTypes = {
    menu: PropTypes.object.isRequired,
    redirections: PropTypes.array.isRequired,
    getMenus: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    useServicePages: PropTypes.bool.isRequired,
  }

  return compose(
    connect(mapStateToProps),
    withContext('getMenus'),
  )(hoistStatics(Wrapper, Wrapee))
}

export default withMenus
