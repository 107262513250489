import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../Image.jsx'

const Hero = ({ children, image, title }) => (
  <div className="Hero">
    {image && (
      <div className="Hero__image">
        <Image
          alt={`${title}`}
          attrs={image.attrs}
          image={image.data}
          immediate
          responsive
        />
      </div>
    )}
    <div className={classNames('Hero__content', { 'Hero__content--noimage': !image })}>
      <header className="Hero__header">
        <h1 className="Hero__heading">{title}</h1>
      </header>
      {children}
    </div>
  </div>
)

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({
    data: PropTypes.shape({
      url: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,
    attrs: PropTypes.object,
  }),
  title: PropTypes.string.isRequired,
}

export default Hero
