import deepEqual from 'deep-equal'
import { makeMappedReducer } from '../utils'

const UPDATE_FILTERS = 'rw-office/search/UPDATE_FILTERS'
const initialState = Object.freeze({})

function reduceUpdateFilters(state, { key, updates }) {
  const current = state[key] || {}
  const next = { ...current, ...updates }

  // Ignore updates that don't change the data
  if (deepEqual(current, next)) return state

  // Apply updated filters
  return {
    ...state,
    [key]: next,
  }
}

// Getter to retrieve filters for a given form
export function getFilters(state, key) {
  return state[key] || {}
}

// Action to update filters for a given form
export function updateFilters(key, updates = {}) {
  return {
    type: UPDATE_FILTERS,
    key,
    updates,
  }
}

const reducer = makeMappedReducer({
  [UPDATE_FILTERS]: reduceUpdateFilters,
}, initialState)
export default reducer
