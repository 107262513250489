import stdurl from 'url'
import WPAPI from 'wpapi'
import endpointMixins from 'wpapi/lib/mixins/parameters'
import filterMixins from 'wpapi/lib/mixins/filters'
import { invertSubTypes } from '@raywhite/data-utils/lib/data/meta/helpers'
import { getCdnImage } from '../media/renderers'
import forms from '../../components/container/contentforms'

const mixins = {
  ...endpointMixins,
  ...filterMixins,
}

export function getItemImage(item) {
  if (!item._embedded) {
    return undefined
  }

  const featured = item._embedded['wp:featuredmedia'] || []
  if (!featured.length || !featured[0].source_url) {
    return undefined
  }

  const {
    source_url: url,
    media_details: {
      height,
      width,
    } = {},
  } = featured[0]

  // Skip non-image-looking images, which have been seen.
  if (!/\.(jpe?g|png|gif)$/i.test(url)) return undefined

  return {
    url: getCdnImage(url),
    height,
    width,
  }
}

export function getItemAuthor(item) {
  if (!item._embedded) {
    return null
  }
  const author = item._embedded.author || []
  if (!author.length || !author[0].name) {
    return undefined
  }

  return author.length ? author[0].name : undefined
}

export function processContentSearch(rawSearch, eventsSince) {
  const { event, sortField, sortDirection, status, type, comtype, subtype } = rawSearch
  const _sortDirection = sortDirection ? sortDirection.toLowerCase() : 'desc'
  const params = {
    statusCode: status,
  }

  if (type) {
    params.typeCode = Array.isArray(type) ? [...type] : [type]
  }

  if (comtype) {
    params.subTypeCode = {
      not: invertSubTypes(Array.isArray(comtype) ? comtype : [comtype]),
    }
  }

  switch (sortField) {
    case 'inspection':
    case 'auction':
      params.sort = `${sortField}Date ${_sortDirection}`
      break
    case 'updated':
      params.sort = `${sortField}At ${_sortDirection}`
      break
    default:
      params.sort = 'updatedAt desc'
      break
  }

  switch (event) {
    case 'AUC':
      params.auctionDate = {
        gte: eventsSince,
      }
      break
    case 'OFI':
      params.inspectionDate = {
        gte: eventsSince,
      }
      break
    default:
      break
  }

  if (subtype) {
    params.categoryCode = subtype
  }

  return params
}

// Map from content formId values to the local form definitions
const formMap = {
  propertyAppraisal: forms.SellAppraisalForm,
  rentalAppraisal: forms.RentAppraisalForm,
  contact: forms.ContactForm,
  maintenance: forms.MaintenanceForm,
  vacating: forms.VacatingForm,
  rentAlert: forms.RentAlertForm,
  propAlert: forms.PropertySaleAlertForm,
  nzenquiries: forms.EnquiryForm,
  enquiries: forms.EnquiryForm,
  buyrentenquiry: forms.EnquiryForm,
  commercialPropertyAppraisal: forms.CommercialSellAppraisalForm,
  leaseAppraisal: forms.CommercialLeaseAppraisalForm,
  nzRentApplication: forms.NzRentalApplicationForm,
}
export const getContentForm = type => formMap[type] || {}

/**
 * Removes the `.then` from a thenable; used to stop WP requests from being
 * thenned when returned from a promise.
 *
 * @param {thenable} thenable The thenable to unthennable.
 *
 * @return {*} The unthennabled thenable.
 */
function unthenable(thenable) {
  thenable.then = undefined // eslint-disable-line no-param-reassign
  return thenable
}

/**
 * Configure a custom route for a type and return it.  If the route already
 * exists it will be returned instead of rebuilt.
 *
 * @param {object} client The WP client to configure.
 * @param {object} type The type object from the WP API.
 *
 * @return {function}
 */
function configureCustomRoute(client, type) {
  const { slug } = type
  if (client[slug]) return client[slug]

  // Build a route handler for the custom post type
  const { href } = type._links['wp:items'][0]
  const [namespace, path] = href.match(/\/wp-json\/(wp\/v\d+)(\/.*)/).slice(1)
  const handler = client.registerRoute(namespace, `${path}/(?P<id>[\\d]+)`, {
    methods: ['head', 'get'],
    mixins,
  }).bind(client)
  client[slug] = handler // eslint-disable-line no-param-reassign

  return client[slug]
}

/**
 * Figure out the real type for a rewritten URL, e.g. a path like
 * /this-has-spaces/my-post-slug would have a rewrite of this-has-spaces but
 * probably a type of thishasspaces.
 *
 * @param {object} client The WP client to confiugre.
 * @param {string} slug The type or slug to load.
 * @param {Promise} types A promise returning the types.
 *
 * @return {(string|false|null)} The real type, or false if we can't check
 *         or null if we checked and don't know the type.
 */
function getTypeForRewrite(client, slug, getTypes) {
  return getTypes().then(types => {
    // Look for entities matching the rewrite
    for (const entity of Object.values(types)) {
      if (entity.rewrite === slug) {
        return configureCustomRoute(client, entity)
      }
    }

    // Otherwise look for one that matches the slug
    return types[slug] ? configureCustomRoute(client, types[slug]) : null
  })
}


/**
 * Configure a WP client to load the appropriate type of data for the given
 * type.  This includes special handling for built in types as well as reverse
 * mapping rewritten slugs (e.g. my-type-with-spaces to mytypewithspaces).
 *
 * As such it may need to load the content types and so requires the dispatch
 * and getState methods to do so.
 *
 * @param {object} client The WP client to confiugre.
 * @param {string} type The type or slug to load.
 * @param {function} types A function returning a promise returning the types.
 *
 * @return {(object|boolean|null)} A client request, false if the request cannot
 *         be sent or null if the request is for an invalid type.
 */
function getTypedRequest(client, type, getTypes) {
  let request
  switch (type) {
    case 'pages':
    case 'page':
      request = client.pages()
      break
    case 'posts':
    case 'post':
      request = client.posts()
      break
    case 'media':
    case 'attachment':
      request = client.media()
      break
    default:
      // Load real type for path prefix, then return
      return getTypeForRewrite(client, type, getTypes)
        .then(handler => (handler ? unthenable(handler()) : handler))
  }

  return Promise.resolve(unthenable(request))
}

// Sends requests using fetch instead of superagent
function send(fetch, url, disabledCache) {
  const parts = stdurl.parse(url, true)
  const params = {
    headers: {
      Accept: '*/*',
    },
  }

  if (disabledCache) {
    parts.query[Date.now().toString()] = 'bust'
    parts.search = undefined
  }

  return fetch(stdurl.format(parts), params).then(async res => {
    if (!res.ok) {
      const error = new Error(`HTTP ${res.status} ${res.statusText}`)
      error.url = url
      error.params = params
      throw error
    }

    // Minimally compatible paging data
    const body = await res.json()
    if (Array.isArray(body) && res.headers.get('x-wp-total')) {
      body._paging = {
        total: res.headers.get('x-wp-total'),
        totalPages: res.headers.get('x-wp-totalpages'),
      }
    }

    return body
  })
}

export const getContentClient = (baseDomain, disableCache, fetch) => {
  const client = new WPAPI({
    endpoint: `https://${baseDomain}/wp-json`,
    transport: {
      get(wpreq) {
        return send(fetch, wpreq.toString(), disableCache)
      },
    },
  })

  // New routes
  client.menu = client.registerRoute('rw/office/v1', 'menu')
  client.agents = client.registerRoute('rw/office/v1', 'agents')
  client.syndicatedNews = client.registerRoute('rw/office/v1', 'syndicated-news')

  client.getSyndicatedNews = () => client.syndicatedNews()
  client.getTypedRequest = getTypedRequest.bind(null, client)

  let types
  function getContentTypes() {
    if (types) return types
    types = client.types().get()
    return types
  }

  async function getContentForType(type, page, count, params) {
    const request = await getTypedRequest(client, type, getContentTypes)

    if (!request) {
      // Unable to get a request for that type
      throw new Error(`Invalid type "${type}"`)
    }

    let partial = request
      .embed()
      .page(page)
      .perPage(count)

    // Chaining really isn't elegant for these...
    const { orderby, order, id } = params
    if (orderby) partial = partial.param('orderby', orderby)
    if (order) partial = partial.param('order', order)
    if (id) partial = partial.id(id)

    return partial.get()
  }

  async function getContentForPath(path) {
    const parts = path.split('/')
    let wp

    if (parts.length === 1) {
      // WP "page"
      wp = getTypedRequest(client, 'page', getContentTypes)
    } else if (parts.length === 2) {
      // WP custom post type
      wp = getTypedRequest(client, parts[0], getContentTypes)
    } else {
      // WP "post", not used in office sites
      wp = getTypedRequest(client, 'post', getContentTypes)
    }

    const request = await wp
    if (!request) {
      // Unable to get a request for that type
      return []
    }

    return request.embed().slug(parts.pop()).get()
  }

  function getSyndicatedNews() {
    return client.syndicatedNews()
  }

  function getMenus() {
    return client.menu()
  }

  return {
    getContentTypes,
    getContentForType,
    getContentForPath,
    getSyndicatedNews,
    getMenus,
  }
}
