import React from 'react'
import PropTypes from 'prop-types'
import { reset } from 'redux-form'
import { warnForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

/**
 * A generic container for content forms, providing the logic for rendering form
 * wide errors (e.g. submission errors), for managing submitted state and for
 * handling submissions.
 */
export default class ContentForm extends React.Component {
  constructor() {
    super()
    this.state = { submitted: false }
  }

  componentWillUnmount() {
    this.setState({ submitted: false })
  }

  onSubmit = data => {
    const { dispatch, sendForm, formName } = this.props

    return sendForm({ ...data }).then(() => {
      this.setState({ submitted: true })
      return dispatch(reset(formName))
    })
  }

  render() {
    const { handleSubmit, error, children, handleClose, showCloseButton } = this.props

    if (this.state.submitted) {
      return (
        <div className="form_fields_section">
          <div className="inner_sm centered_text">
            <p>Thanks, we'll be in touch!</p>
            {showCloseButton && !!handleClose && (
              <p>
                <button
                  type="button"
                  className="btn large"
                  onClick={handleClose}
                >
                  Close
                </button>
              </p>
            )}
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {warnForm(error)}
        {children}
      </form>
    )
  }
}

ContentForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sendForm: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  showCloseButton: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
}

ContentForm.defaultProps = {
  showCloseButton: false,
}
