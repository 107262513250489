// @flow

import React, { Component } from 'react'
import url from 'url'
import classNames from 'classnames'
import { stripMarkup } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { createTimeSince } from '@raywhite/helpers-utils/lib/helpers/time'

import Link from './Link.jsx'
import DeferredImage from './DeferredImage.jsx'
import { getItemImage, getItemAuthor } from '../../utils/wordpress/content'

export type CardOptions = {
  showImage?: boolean,
  showDate?: boolean,
  headerClassName?: string,
  excerptStrict?: boolean,
  excerptLength?: number,
  external?: boolean,
  titleComponent?: string
}
;type Props = {
  item: Object,
  showImage: boolean,
  showDate: boolean,
  headerClassName?: string,
  excerptStrict: boolean,
  excerptLength?: number,
  external?: boolean,
  typeName: string,
  titleComponent?: string
};

export default class ContentCard extends Component<Props> {
  static defaultProps = {
    showImage: true,
    showDate: false,
    excerptStrict: false,
    external: false,
    typeName: 'Post',
    titleComponent: 'h3',
  }

  render() {
    const {
      item,
      showImage,
      headerClassName,
      excerptStrict,
      excerptLength,
      external,
      typeName,
      titleComponent: TitleComponent = 'h3',
    } = this.props
    const author = getItemAuthor(item)

    const {
      excerpt,
      link,
      title,
      date_gmt: date,
    } = item
    const _url = (
      (external === true && link)
      || (typeof external === 'function' && external(item) && link)
      || url.parse(link).path
    )
    const imageSrc = getItemImage(item)
    const image = showImage && !!imageSrc && (
      <div className="content_post_item_image">
        <div
          className="responsive_image_wrapper_wrapper"
          style={{ maxWidth: imageSrc.width }}
        >
          <Link to={_url}>
            <DeferredImage
              image={imageSrc}
              attrs={{ width: 500, scale: 'both' }}
            />
          </Link>
        </div>
      </div>
    )

    return (
      <article className="content_post">
        {image}
        <header
          className={classNames('content_post_item_intro', headerClassName)}
        >
          <div className="content_post_meta mini muted">
            {date ? <time>{createTimeSince(new Date().getTime(), date)}</time> : null}
            {!!(date && author) && (
              <span className="content_post_meta_bull">&bull;</span>
            )}
            {!!author && (
              <span className="content_post_author">{`By ${author}`}</span>
            )}
          </div>
          <Link to={_url}>
            <TitleComponent
              className="delta"
              dangerouslySetInnerHTML={{
                __html: title && title.rendered,
              }}
            />
          </Link>
          {!!excerpt && (
            <div className="content_post_intro">
              <p
                className="content_post_intro_text"
                dangerouslySetInnerHTML={{
                  __html: excerptify(
                    stripMarkup(excerpt.rendered).__html,
                    excerptLength,
                    { strict: excerptStrict }
                  ),
                }}
              />
              <p className="centered_text content_post_read_more">
                <Link to={_url}>
                  <span className="anchor mini">
                    {`Read Full ${typeName}`}
                  </span>
                </Link>
              </p>
            </div>
          )}
        </header>
      </article>
    )
  }
}
