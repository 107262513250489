import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  loadMembers,
  getMember,
} from '@raywhite/redux/lib/member'
import { getSearch } from '@raywhite/redux/lib/listing'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { loadMemberMeta, getMemberMeta } from '@raywhite/redux/lib/meta'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { telLink, eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { cleanMarkup } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  agentUrl,
  getStructuredData,
  processMeta,
  getMemberTitle,
  getMemberPhones,
} from '@raywhite/data-utils/lib/data/member/agent'
import { fetchListings } from '../../redux/modules/listings'
import { renderLink } from '../../utils/media/renderers'
import withContext from '../hocs/withContext'
import NotFoundPagePage from '../presentational/NotFoundPage.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import Loader from '../presentational/Loader.jsx'
import SocialIcons, { hasSocial } from '../presentational/SocialIcons.jsx'
import JsonLD from '../presentational/JsonLD.jsx'
import TwitterSummaryCard from '../presentational/TwitterSummaryCard.jsx'
import OpenGraph from '../presentational/OpenGraph.jsx'
import EnquiryForm from './EnquiryForm.jsx'
import SellAppraisalForm from './contentforms/SellAppraisalForm.jsx'
import RentAppraisalForm from './contentforms/RentAppraisalForm.jsx'
import Modal from '../presentational/Modal.jsx'
import ModalInner from '../presentational/ModalInner.jsx'
import AgentTestimonialList from '../presentational/AgentTestimonialList.jsx'
import LoadMoreListingList from '../presentational/LoadMoreListingList.jsx'
import ListingCard from '../presentational/ListingCard.jsx'
import ListingCardList from '../presentational/ListingCardList.jsx'
import Image from '../presentational/Image.jsx'
import withSiteMetadata from '../hocs/withSiteMetadata'
import AwardsList from '../presentational/AwardsList.jsx'

const LISTING_CARD_OPTIONS = {
  cardOptions: {
    badgeField: [
      'underOffer',
      ...ListingCard.defaultProps.badgeField],
  },
}

const tabMeta = eventMeta({
  category: 'Agent Listings',
  action: 'View',
})

const appraisalOpenAttrs = eventMeta({
  category: 'Appraisal',
  action: 'Open',
})

const enquiryOpenAttrs = eventMeta({
  category: 'Ask Question',
  action: 'Open',
})


const resizeParams = {
  headshot: {
    width: 856,
    height: 856,
    scale: 'both',
  },
}

const showSellAppraisal = listingsTypes => (
  listingsTypes.indexOf('Sold') !== -1 || listingsTypes.indexOf('For Sale') !== -1
)
const showRentAppraisal = listingsTypes => (
  listingsTypes.indexOf('Leased') !== -1 || listingsTypes.indexOf('For Rent') !== -1
)
const showBoth = listingsTypes => (
  showSellAppraisal(listingsTypes) && showRentAppraisal(listingsTypes)
)

class AgentPageUpperBlock extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(['commercial', 'residential']),
    member: PropTypes.object.isRequired,
    showAppraisal: PropTypes.bool.isRequired,
    listingsTypes: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    awards: PropTypes.array.isRequired,
    recognitionLevels: PropTypes.array.isRequired,
    _window: PropTypes.object,
    router: PropTypes.object,
    organisationIds: PropTypes.array,
  }

  static defaultProps = {
    showAppraisal: false,
  }

  constructor() {
    super()
    this.state = {
      showEnquiryModal: false,
      showAppraisalModal: false,
      showRentalAppraisalModal: false,
    }
  }

  componentDidMount() {
    const { _window, router } = this.props

    const { hash, pathname } = _window ? _window.location : {}
    switch (hash) {
      case '#contact':
        router.replace(pathname)
        this.toggleEnquiryModal()
        break
      case '#appraisal':
        router.replace(pathname)
        this.toggleAppraisalModal()
        break
      default:
        // No specific action
    }
  }

  shouldComponentUpdate(props, state) {
    return this.state.showEnquiryModal !== state.showEnquiryModal
      || this.state.showAppraisalModal !== state.showAppraisalModal
      || this.state.showRentalAppraisalModal !== state.showRentalAppraisalModal
      || this.props.showAppraisal !== props.showAppraisal
  }

  sendEnquiryForm = data => (
    submitForm(
      this.props.fetch,
      this.props._window && this.props._window.dataLayer,
      'Ask Question',
      `/api/contact/agent/${this.props.member.memberId}`,
      data,
      'Unable to send enquiry.',
      this.props.member.fullName,
    )
  )

  sendAppraisalForm = data => (
    submitForm(
      this.props.fetch,
      this.props._window && this.props._window.dataLayer,
      'Appraisal',
      `/api/contact/agent/${this.props.member.memberId}`,
      { ...data, type: 'propertyAppraisal' },
      'Unable to send appraisal request.',
      this.props.member.fullName,
    )
  )

  sendRentalAppraisalForm = data => (
    submitForm(
      this.props.fetch,
      this.props._window && this.props._window.dataLayer,
      'Rent Appraisal',
      `/api/contact/agent/${this.props.member.memberId}`,
      { ...data, type: 'rentalAppraisal' },
      'Unable to send appraisal request.',
      this.props.member.fullName,
    )
  )

  toggleEnquiryModal = () => {
    this.setState(state => ({
      showEnquiryModal: !state.showEnquiryModal,
    }))
  }

  toggleAppraisalModal = () => {
    this.setState(state => ({
      showAppraisalModal: !state.showAppraisalModal,
    }))
  }

  toggleRentalAppraisalModal = () => {
    this.setState(state => ({
      showRentalAppraisalModal: !state.showRentalAppraisalModal,
    }))
  }

  render() {
    const {
      theme,
      organisationIds,
      awards,
      recognitionLevels,
      showAppraisal: showListingsAppraisal,
      listingsTypes,
      member,
      member: {
        imageHeadshot: headshot,
        fullName,
        alternateName,
        titles,
        description,
        email,
        video,
        organisationIds: memberOrgIds,
        phones = {},
      },
    } = this.props

    const image = headshot
    const imageParams = image
      ? resizeParams.headshot
      : undefined

    const title = getMemberTitle(organisationIds, titles, memberOrgIds) || member.title
    const salesTitle = Boolean(title?.toLowerCase().match(/(sales|- selling)/))
    const showAppraisal = showListingsAppraisal || salesTitle
    const hasListingsForSale = showSellAppraisal(listingsTypes) || salesTitle
    const hasListingsForRent = showRentAppraisal(listingsTypes)
    const hasBoth = showBoth(listingsTypes)
    const {
      mobilePhone,
      officePhone,
      promoPhone,
    } = {
      ...member,
      ...getMemberPhones(organisationIds, phones, memberOrgIds),
    }

    return (
      <div className="inner">
        <div className="agent_meta_wrap">
          <div className="agent_image_wrap">
            <div className={`agent_image${image ? '' : ' with_placeholder'}`}>
              {image
                ? (
                  <Image
                    image={{ url: image, width: 856, height: 856 }}
                    attrs={imageParams}
                    alt={fullName}
                  />
                )
                : <picture className="agent_image_placeholder" />}
            </div>
            <div className="show_bravo">
              <AgentPageRecogntionLogo recognitionLevels={recognitionLevels} />
            </div>
            <div className="show_bravo agent_contact_links">
              <a
                className="mini anchor agent_contact_link_question"
                onClick={this.toggleEnquiryModal}
                {...enquiryOpenAttrs}
                data-ev-label={fullName}
              >
                Ask a question
              </a>
              {theme !== 'commercial'
                && showAppraisal && (hasListingsForSale || hasBoth) && (
                <a
                  className="mini anchor agent_contact_link_appraisal"
                  onClick={this.toggleAppraisalModal}
                  {...appraisalOpenAttrs}
                  data-ev-label={fullName}
                >
                  Get Sales Appraisal
                </a>
              )}
              {theme !== 'commercial'
                && showAppraisal && (hasListingsForRent || hasBoth) && (
                <a
                  className="mini anchor agent_contact_link_appraisal"
                  onClick={this.toggleRentalAppraisalModal}
                  {...appraisalOpenAttrs}
                  data-ev-label={fullName}
                >
                  Get Rental Appraisal
                </a>
              )}
            </div>
          </div>
          <div className="agent_details">
            <div className="agent_name_wrap">
              <div className="agent_name">
                <h1 className="charlie">
                  {fullName}
                  {alternateName && (
                    <span className="muted">
                      {alternateName}
                    </span>
                  )}
                </h1>
                <h2 className="micro muted">{title}</h2>
              </div>
              {hasSocial(member) && (
                <div className="agent_social show_bravo">
                  <SocialIcons social={this.props.member} />
                </div>
              )}
              <div className="agent_contact_button centered_text hide_bravo">
                {theme !== 'commercial' && showAppraisal && (hasListingsForSale || hasBoth) && (
                  <a
                    className="btn agent_profile_cta"
                    onClick={this.toggleAppraisalModal}
                    {...appraisalOpenAttrs}
                    data-ev-label={fullName}
                  >
                    Get Sales Appraisal
                  </a>
                )}
                <a
                  className="btn agent_profile_cta"
                  onClick={this.toggleEnquiryModal}
                  {...enquiryOpenAttrs}
                  data-ev-label={fullName}
                >
                  {`Contact ${member.givenName}`}
                </a>
              </div>
            </div>
            <div className="hide_bravo">
              <AgentPageRecogntionLogo recognitionLevels={recognitionLevels} />
            </div>
            <div className="agent_contact_details">
              {promoPhone && (
                <div>
                  <div className="agent_contact_label promo landline">
                    <span className="muted">Free Phone:</span>
                  </div>
                  <div className="agent_contact_detail">
                    <span>
                      {telLink(promoPhone, { 'data-ev-label': fullName })}
                    </span>
                  </div>
                </div>
              )}
              {mobilePhone && (
                <div>
                  <div className="agent_contact_label mobile">
                    <span className="muted">Mobile:</span>
                  </div>
                  <div className="agent_contact_detail">
                    <span>
                      {telLink(mobilePhone, { 'data-ev-label': fullName })}
                    </span>
                  </div>
                </div>
              )}
              {officePhone && (
                <div>
                  <div className="agent_contact_label landline">
                    <span className="muted">Phone:</span>
                  </div>
                  <div className="agent_contact_detail">
                    <span>
                      {telLink(officePhone, { 'data-ev-label': fullName })}
                    </span>
                  </div>
                </div>
              )}
              {email && (
                <div>
                  <div className="agent_contact_label email">
                    <span className="muted">Email:</span>
                  </div>
                  <div className="agent_contact_detail">
                    <span>
                      <span
                        onClick={this.toggleEnquiryModal}
                        role="button"
                        tabIndex="0"
                        data-ev-on="click"
                        data-ev-category="Agent Page Email"
                        data-ev-action="Click"
                        data-ev-label={fullName}
                      >
                        {email}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
            {hasSocial(this.props.member) && (
              <div className="agent_social hide_bravo">
                <SocialIcons social={this.props.member} />
              </div>
            )}
            {description && (
              <div className="agent_bio">
                {!!video && (
                  <div className="agent_video_profile">
                    {renderLink(video)}
                  </div>
                )}
                <div className="agent_bio_content">
                  <div dangerouslySetInnerHTML={cleanMarkup(description)} />
                </div>
                {!!awards.length && (
                  <div className="agent_awards" id="awards">
                    <h4 className="mini bold_font">Awards</h4>
                    <AwardsList awards={awards} />
                  </div>
                )}
              </div>
            )}
          </div>
          {this.state.showEnquiryModal && (
            <Modal handleClose={this.toggleEnquiryModal}>
              <ModalInner
                className="modal_form"
                title="Get in Touch"
                subtitle={fullName}
              >
                <EnquiryForm
                  sendForm={this.sendEnquiryForm}
                  handleClose={this.toggleEnquiryModal}
                  submittedMessage="Thanks, I'll be in touch!"
                />
              </ModalInner>
            </Modal>
          )}
          {this.state.showAppraisalModal && hasListingsForSale && (
            <Modal handleClose={this.toggleAppraisalModal}>
              <ModalInner
                className="modal_form long_form"
                title="Request an Appraisal"
                subtitle={fullName}
              >
                <SellAppraisalForm
                  sendForm={this.sendAppraisalForm}
                  handleClose={this.toggleAppraisalModal}
                  submittedMessage="Thanks, I'll be in touch!"
                />
              </ModalInner>
            </Modal>
          )}
          {this.state.showRentalAppraisalModal && hasListingsForRent && (
            <Modal handleClose={this.toggleRentalAppraisalModal}>
              <ModalInner
                className="modal_form long_form"
                title="Request a Rental Appraisal"
                subtitle={fullName}
              >
                <RentAppraisalForm
                  sendForm={this.sendRentalAppraisalForm}
                  handleClose={this.toggleRentalAppraisalModal}
                  submittedMessage="Thanks, I'll be in touch!"
                />
              </ModalInner>
            </Modal>
          )}
        </div>
      </div>
    )
  }
}

class AgentPageRecogntionLogo extends Component { // eslint-disable-line react/no-multi-comp
  /**
   * This is relatively simple to implement assuming that a single
   * instance of the site will never actually be invalidating the
   * data that it has for a member (data will only be fetched on
   * first load).
   */
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { recognitionLevels } = this.props
    const logoLevels = recognitionLevels.filter(r => r.webLogoUrl)

    const currentLogo = ['SALES', 'LEADER'].reduce((acc, type) => {
      if (acc) return acc
      return logoLevels.find(r => r.recognitionType === type)
    }, undefined)

    if (!currentLogo) return null

    return (
      <div className="elite">
        <img
          src={currentLogo.webLogoUrl}
          alt="Award"
        />
      </div>
    )
  }
}

AgentPageRecogntionLogo.propTypes = {
  recognitionLevels: PropTypes.array.isRequired,
}

class AgentPage extends Component { // eslint-disable-line react/no-multi-comp
  static getDerivedStateFromProps(props, state) {
    if (props.memberListingMeta === state.lastMemberListingMeta) return null
    return {
      listings: processMeta(props.memberListingMeta),
      lastMemberListingMeta: props.memberListingMeta,
    }
  }

  constructor(props) {
    super(props)
    this.state = { listingsType: '' }
  }

  componentDidMount() {
    const {
      dispatch,
      getAgentMetadata,
      config: { options: { agentShowTransactions } },
      organisationIds,
      memberId,
    } = this.props

    const allowOtherTransactions = agentShowTransactions === 'any'

    dispatch(loadMemberMeta(getAgentMetadata, memberId, organisationIds, allowOtherTransactions))
    this.load()
  }

  componentDidUpdate() {
    this.load()
  }

  load() {
    const {
      dispatch,
      getMembers,
      getOrganisations,
      memberId,
      config,
    } = this.props

    this.constructor.fetchData(
      dispatch, { memberId }, { ...config, getMembers, getOrganisations }
    )
  }

  toggleListingsType(text) {
    this.setState({
      listingsType: text,
    })
  }

  render() {
    const {
      config: {
        theme,
      },
      organisationIds,
      dispatch,
      member,
      member: {
        memberId,
        error,
        loaded,
        hidden,
        notFound,
        fullName,
        title: _title,
        titles,
        testimonials: _testimonials = [],
        awards,
        recognitionLevels,
        organisationIds: memberOrganisationIds = [],
      },
      memberListingMeta: {
        loaded: _loaded,
        hasListings,
      },
      fetch,
      baseUrl,
      primaryOffice,
      offices,
      getListings,
      _listings,
      _window,
      router,
      transactionsOrganisationId,
    } = this.props

    const testimonials = _testimonials.filter(t => t.detail)

    // Select the first title that matches an office on this site
    const title = offices.reduce((result, { organisationId }) => {
      if (result) return result
      return titles[organisationId]
    }, false) || _title

    // Check if agent actually belongs to the office (not the case for conjuncting agents)
    const matchesOffice = !loaded || organisationIds.reduce((result, organisationId) => {
      if (result) return result
      return memberOrganisationIds.indexOf(organisationId) !== -1
    }, false)

    let { listingsType } = this.state
    let listingsTypes = []
    let listingsProps = null

    if (_loaded && hasListings) {
      listingsTypes = Object.keys(this.state.listings).filter(t => !!this.state.listings[t])
      if (!listingsType) {
        listingsType = listingsTypes[0] || ''
      }

      if (listingsType) {
        listingsProps = { ...this.state.listings[listingsType] } || {}
        listingsProps.memberId = memberId

        if (listingsProps.statusCode && !~listingsProps.statusCode.indexOf('CUR')) {
          // Non-current searches may be permitted to show listings from other offices
          listingsProps.organisationId = transactionsOrganisationId
        }
      }
    }

    if (testimonials.length) {
      listingsTypes.push('Testimonials')
    }

    if (hidden || notFound || !matchesOffice) return <NotFoundPagePage />

    if (error) return <ErrorPage error={error} />

    if (!loaded) return <Loader />

    const structuredData = getStructuredData(
      member,
      offices.length && offices[0].loaded ? offices[0] : primaryOffice,
      baseUrl,
    )
    const baseImage = structuredData.image && structuredData.image.replace(/\?.*/, '')
    const shortName = member.isServiceAccount ? member.fullName : member.givenName
    const hasSections = !!((_loaded && hasListings && listingsProps) || testimonials.length)

    // Note: style args below are mainly to confirm it's possible, needs adjustment
    /* eslint-disable no-nested-ternary */
    return (
      <article className="pg_agent">
        <Helmet>
          <title>{fullName ? `${fullName} - ${title}` : 'Agent'}</title>
          <meta name="description" content={excerptify(structuredData.description, 160)} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          creator={member.twitter}
          title={structuredData.name}
          site={primaryOffice.twitter}
          description={structuredData.description}
          image={structuredData.image}
        />
        <OpenGraph
          type="profile"
          url={baseUrl && `${baseUrl}${agentUrl(member)}`}
          title={structuredData.name}
          siteName={primaryOffice.fullName}
          description={structuredData.description}
          mayCropImage={!!member.imageLifestyle}
          image={baseImage && { url: baseImage }}
          video={member.video}
          profile={{
            firstName: member.givenName,
            lastName: member.surname,
            username: member.facebook,
          }}
        />
        <AgentPageUpperBlock
          member={member}
          theme={theme}
          showAppraisal={hasListings}
          listingsTypes={listingsTypes}
          awards={awards}
          recognitionLevels={recognitionLevels}
          fetch={fetch}
          _window={_window}
          router={router}
          organisationIds={organisationIds}
        />
        {(!_loaded || hasSections) && (
          <div
            className="agent_sections_wrap"
            id="market"
            // Note: retain height while loading for successful deep links to #market
            style={_loaded ? undefined : { minHeight: '70vh' }}
          >
            {hasSections ? (
              <div className="inner_lg">
                <div className="agent_sections_inner">
                  <span className="mini muted">In the market</span>
                  <h2 className="charie centered_text">
                    {shortName}
                    {shortName && (shortName.match(/s$/) ? "' " : "'s ")}
                    {(!_loaded || hasListings) ? 'Listings' : 'Testimonials'}
                  </h2>
                  {(!_loaded || hasListings) && (
                    <div className="switcher_wrap">
                      <div className="switcher">
                        {listingsTypes.map(type => (
                          <span
                            key={type}
                            className={type === listingsType ? 'active' : ''}
                            onClick={this.toggleListingsType.bind(this, type)} // eslint-disable-line react/jsx-no-bind, max-len
                            {...tabMeta}
                            data-ev-label={type}
                          >
                            {type}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="agent_sections">
                    {_loaded && hasListings && (listingsType !== 'Testimonials' ? (
                      <LoadMoreListingList
                        key={`listings_${listingsType}`}
                        listings={getSearch(_listings, listingsProps, -1)}
                        loadMoreLabel={`Load more ${listingsType}`}
                        listComponent={ListingCardList}
                        listOptions={LISTING_CARD_OPTIONS}
                        load={function load(page) { // eslint-disable-line react/jsx-no-bind
                          dispatch(fetchListings(getListings, listingsProps, page))
                        }}
                      />
                    ) : <AgentTestimonialList testimonials={testimonials} />
                    )}
                    {_loaded && !hasListings && (
                      <AgentTestimonialList testimonials={testimonials} />
                    )}
                  </div>
                </div>
              </div>
            ) : !!(hasListings && listingsProps) ? <Loader /> : null}
          </div>
        )}
      </article>
    )
  }
  /* eslint-enable no-nested-ternary */
}

// Define data requirements for server side rendering
AgentPage.fetchData = (dispatch, { memberId }, params) => {
  const {
    organisationIds,
    getMembers,
    getOrganisations,
    getState,
    response,
    options: {
      agents: { hidden = [] } = {},
    } = {},
  } = params

  // Ensure valid ID given
  const _memberId = /^\d+$/.test(memberId) && parseInt(memberId, 10)
  if (!_memberId) {
    const error = new Error('Invalid member ID given.')
    error.code = 404
    error.__abort = true
    error.__ignore = true
    return Promise.reject(error)
  }

  const isHidden = hidden.indexOf(_memberId) !== -1
  if (isHidden && response) response.status(404)

  return unlazyAll([
    dispatch(loadMembers(getMembers, organisationIds, [_memberId])),
    dispatch(loadOrganisations(getOrganisations, organisationIds)),
  ]).then(() => {
    // Only runs server side
    if (!response) return

    // Check if member is not found
    const member = getMember(getState().members, _memberId)
    if (member.notFound) response.status(404)
  })
}

function mapStateToProps(state, props) {
  const { params: { memberId: _memberId } } = props
  const memberId = parseInt(_memberId, 10)
  const { members, meta, config, config: { options } } = state
  const memberListingMeta = getMemberMeta(meta, memberId)

  const { organisationIds } = state.config
  const member = getMember(members, memberId)
  const offices = (member.organisationIds || [])
    .filter(id => organisationIds.indexOf(id) !== -1)
    .map(id => getOrganisation(state.orgs, id))
  const transactionsOrganisationId = (
    options.agentShowTransactions === 'any' ? [] : config.organisationIds
  )

  return {
    config,
    _listings: state.listings, // TODO: Fix this, we shouldn't pass the whole thing down.
    member: { ...member },
    memberListingMeta,
    memberId,
    baseUrl: state.config.baseUrl,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    offices,
    organisationIds,
    transactionsOrganisationId,
  }
}

AgentPage.propTypes = {
  memberId: PropTypes.number.isRequired,
  organisationIds: PropTypes.array.isRequired,
  member: PropTypes.object.isRequired,
  memberListingMeta: PropTypes.object,
  config: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  getAgentMetadata: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  primaryOffice: PropTypes.object.isRequired,
  offices: PropTypes.array,
  fetch: PropTypes.func,
  getListings: PropTypes.func.isRequired,
  transactionsOrganisationId: PropTypes.array.isRequired,
  _listings: PropTypes.object.isRequired,
  _window: PropTypes.object,
  router: PropTypes.object,
  theme: PropTypes.oneOf(['commercial', 'residential']),
}

export default compose(
  withSiteMetadata,
  connect(mapStateToProps),
  withRouter,
  withContext(
    '_window',
    'fetch',
    'getListings',
    'getOrganisations',
    'getAgentMetadata',
    'getMembers',
  ),
)(AgentPage)
