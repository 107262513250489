// @flow

import React from 'react'

import type { Image as ImageType } from '@raywhite/data-client/lib/modules/listings'

import Image from './Image.jsx'
import DeferredImage from './DeferredImage.jsx'

type HashedImage = {
  hash: string,
  ...ImageType,
};

type Props = {
  id?: string,
  images: Array<HashedImage>,
  discard?: number,
  largeCount: number,
  open: (?HashedImage | ?Array<HashedImage>, ?number) => (SyntheticMouseEvent<*>) => void,
  openAttrs?: Object,
  alt?: string,
};

/**
 * Display a set of listing images, split between small and large images.
 *
 * Note: discard is intended to drop images from the beginning of the list, e.g.
 * if they're already used elsewhere (like the PDP header).
 */
const ListingImages = ({
  id = 'pdp_images',
  images,
  discard = 1,
  largeCount,
  open,
  openAttrs,
  alt,
}: Props) => {
  if (images.length <= discard) return null

  const large = images.slice(discard, largeCount + discard)
  const small = images.slice(largeCount + discard)

  return (
    <div
      id={id}
      className="pdp_image_list"
    >
      {!!large.length && (
        <div className="pdp_image">
          <a
            onClick={open(large[0])}
            {...openAttrs}
          >
            <Image image={large[0]} alt={alt} />
          </a>
        </div>
      )}
      {large.slice(1).map(c => (
        <div className="pdp_image" key={c.hash}>
          <a
            onClick={open(c)}
            {...openAttrs}
          >
            <DeferredImage image={c} key={c.url} alt={alt} />
          </a>
        </div>
      ))}
      {!!small.length && (
        <div className="pdp_mini_images">
          {small.map(c => (
            <div className="pdp_image pdp_mini_image" key={c.hash}>
              <a
                onClick={open(c)}
                {...openAttrs}
              >
                <DeferredImage
                  key={c.url}
                  image={c}
                  alt={alt && `Thumbnail of ${alt}`}
                  attrs={{
                    mode: 'crop',
                    anchor: 'middlecenter',
                    width: 640,
                    height: 640,
                    quality: 75,
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ListingImages
