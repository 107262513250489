import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as portals from 'react-reverse-portal'
import { renderMap } from '../../../redux/modules/app'
import ClientOnly from '../../container/ClientOnly.jsx'

export const MapNodeContext = React.createContext(null)

export class SingletonMap extends Component {
  static contextType = MapNodeContext

  static propTypes = {
    enableRendering: PropTypes.func.isRequired,
    renderMap: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
  }

  static defaultProps = {
    renderMap: true,
  }

  componentDidMount() {
    this.props.enableRendering()
  }

  render() {
    return (
      <div className="singleton-mapbox-map">
        <ClientOnly>
          {renderMap && (
            <MapNodeContext.Consumer>
              {node => (
                <portals.OutPortal
                  node={node}
                  {...this.props}
                />
              )}
            </MapNodeContext.Consumer>
          )}
        </ClientOnly>
      </div>
    )
  }
}

export default connect(undefined, { enableRendering: renderMap })(SingletonMap)
