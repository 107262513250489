import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistStatics from 'hoist-non-react-statics'
import { getOrganisations, loadOrganisations } from '@raywhite/redux/lib/org'
import { getDisplayName } from './providers'

function mapStateToProps(state) {
  const {
    primaryOrganisationId,
    organisationIds = [],
    displayOrganisationIds = [],
  } = state.config

  const orgs = getOrganisations(state.orgs, displayOrganisationIds)
  const primaryOrg = orgs.entities[primaryOrganisationId] || {}
  const displayOrgs = displayOrganisationIds.map(id => orgs.entities[id])

  return {
    organisationIds,
    displayOrganisationIds,
    orgs,
    primaryOrg,
    displayOrgs,
  }
}

/**
 * Loads and provides organisation data to wrapped components.
 */
const withOrgs = Wrapee => {
  class Wrapper extends React.Component { // eslint-disable-line react/no-multi-comp
    componentDidMount() {
      this.load()
    }

    componentDidUpdate() {
      this.load()
    }

    load() {
      const { dispatch, displayOrganisationIds } = this.props
      const getOrgs = this.props.getOrganisations || this.context.getOrganisations
      dispatch(loadOrganisations(getOrgs, displayOrganisationIds))
    }

    render() {
      return <Wrapee {...this.props} />
    }
  }

  Wrapper.propTypes = {
    getOrganisations: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    orgs: PropTypes.object.isRequired,
    primaryOrg: PropTypes.object.isRequired,
    displayOrgs: PropTypes.arrayOf(PropTypes.object).isRequired,
    organisationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    displayOrganisationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }

  Wrapper.contextTypes = {
    getOrganisations: PropTypes.func,
  }

  Wrapper.displayName = `withOrgs(${getDisplayName(Wrapee)})`

  return connect(mapStateToProps)(hoistStatics(Wrapper, Wrapee))
}

export default withOrgs
