// @flow
import React from 'react'

type Link = {
  key: string,
  className?: string,
  label: string,
  onClick?: (Event) => void,
  href?: string,
  attrs?: Object,
};

type Props = {
  children?: Array<Link>,
};

// Use anchors if there's an href, otherwise not
const A = ({ children, ...props }: { children: any, href?: string }) => (
  props.href ? <a {...props}>{children}</a> : <span {...props}>{children}</span>
)

/**
 * Render lists of listing jump links.
 */
const ListingJumpLinks = ({ children: links = [] }: Props) => {
  if (!links.length) return null

  return (
    <ul>
      {links.map(({ key, className, label, onClick, href, attrs }) => (
        <li key={key}>
          <A
            className={className}
            onClick={onClick}
            href={href}
            {...attrs}
          >
            {label}
          </A>
        </li>
      ))}
    </ul>
  )
}

export default ListingJumpLinks
