import { LOADED_MEMBERS } from '@raywhite/redux/lib/member'

export const filterAgents = store => next => action => {
  const {
    config: {
      rwApi = {},
      options: {
        agents: {
          hidden = [],
        } = {},
      } = {},
    },
  } = store.getState()

  if (action.type !== LOADED_MEMBERS || !action.organisationIds.length || action.orgsAreTeams) {
    return next(action)
  }

  const filtered = action.members
    .filter(member => (
      !rwApi.publicOnly
      || (
        member.organisationIds
          .filter(id => action.organisationIds.includes(id) && member.organisations[id].public)
          .length
      )
    ))
    .map(member => ({
      ...member,
      hidden: hidden.includes(member.id),
    }))

  return next({
    ...action,
    members: filtered,
  })
}
