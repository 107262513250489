import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withContext from '../hocs/withContext'

/**
 * Renders children only on the clien side, with an optional placeholder.
 *
 * Client side detection works by checking the presence of the _window injected
 * into context.
 */
export class ClientOnly extends Component {
  static propTypes = {
    _window: PropTypes.object,
    placeholder: PropTypes.node,
    children: PropTypes.node,
  }

  static defaultProps = {
    placeholder: <div />,
  }

  render() {
    const { _window, children, placeholder } = this.props
    if (!children) return null
    return _window
      ? React.Children.only(children)
      : placeholder
  }
}

export default withContext('_window')(ClientOnly)
