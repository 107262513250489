import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoizee'
import { connect } from 'react-redux'
import { compose } from 'redux'
import hoistStatics from 'hoist-non-react-statics'

import withContext from './withContext'
import { getDisplayName } from './providers'
import {
  loadContentTypes,
  loadContentForType,
  getContentType,
  getContentForType,
} from '../../redux/modules/content'

/**
 * Provides an HOC which loads content and content type information and passes
 * this to the wrapped component via the contentSearch property, which itself
 * contains type (for the content type info) and search (for the content search
 * info) properties.
 *
 * It also provides a contentSearchLoad property which can be called to load
 * additional content, e.g. for load more functionality in child components.  If
 * this is desired, set contentSearchPage to -1 in order to ensure that all pages
 * are loaded and provided to child components rather than the default first
 * page.
 */

// Memoize response to avoid changing props every invocation
const getContentSearch = memoize(
  (search, type, page, count) => ({ search, type, page, count }),
  { max: 10 },
)

/**
 * Loads content search and type data from the store and provides them as props.
 */
function mapStateToProps({ content }, props) {
  const {
    contentSearchType,
    contentSearchPage,
    contentSearchCount,
  } = props

  const type = getContentType(content, contentSearchType)
  const search = getContentForType(
    content, contentSearchType, contentSearchPage, contentSearchCount
  )
  const contentSearch = getContentSearch(
    search, type, contentSearchPage, contentSearchCount
  )

  return { contentSearch }
}

/**
 * Props pulled out to apply to the wrapped HOC.
 */
const wrappedPropTypes = {
  contentSearchLoadTypes: PropTypes.bool.isRequired,
  contentSearchType: PropTypes.string.isRequired,
  contentSearchPage: PropTypes.number.isRequired,
  contentSearchCount: PropTypes.number.isRequired,
}
const wrappedDefaultProps = {
  contentSearchLoadTypes: true,
  contentSearchPage: -1,
  contentSearchCount: 10,
}

/**
 * Wraps the Wrapee with lifecycle hooks to load content.
 */
const withContentSearch = Wrapee => {
  class Wrapper extends React.Component {
    componentDidMount() {
      const { dispatch, getContentTypes, contentSearchLoadTypes } = this.props
      if (contentSearchLoadTypes) {
        dispatch(loadContentTypes(getContentTypes))
      }
      this.load()
    }

    componentDidUpdate() {
      this.load()
    }

    load = (page = 1) => {
      const {
        dispatch,
        getContentForType: getContent,
        contentSearchType,
        contentSearchCount,
      } = this.props
      dispatch(
        loadContentForType(getContent, contentSearchType, page, contentSearchCount)
      )
    }

    render() {
      return (
        <Wrapee
          {...this.props}
          contentSearchLoad={this.load}
          contentSearch={this.props.contentSearch}
        />
      )
    }
  }

  Wrapper.propTypes = {
    getContentTypes: PropTypes.func.isRequired,
    getContentForType: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    contentSearch: PropTypes.object.isRequired,
    ...wrappedPropTypes,
  }
  Wrapper.defaultProps = {
    ...wrappedDefaultProps,
  }
  Wrapper.displayName = `withContentSearch(${getDisplayName(Wrapee)})`

  const Composed = compose(
    withContext('getContentTypes', 'getContentForType'),
    connect(mapStateToProps),
  )(hoistStatics(Wrapper, Wrapee))

  // Ensure that defaults are available for mapStateToProps
  Composed.propTypes = {
    ...Composed.propTypes,
    ...wrappedPropTypes,
  }
  Composed.defaultProps = {
    ...Composed.defaultProps,
    ...wrappedDefaultProps,
  }

  return Composed
}

export default withContentSearch
