import React from 'react'
import PropTypes from 'prop-types'
import * as Joi from 'joi'
import { reduxForm, propTypes } from 'redux-form'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSectionHeading,
  FormSection,
  FormSelect,
  FormSubmit,
  FormInput,
  FormRow,
  labelifyFormField,
} from '../../presentational/formComponents'
import ContentForm from './ContentForm.jsx'

const FORM_NAME = 'saleAlert'
const interestedAsOptions = ['', 'Owner', 'Investor']
const bedOptions = ['Any', 'Studio', '1+', '2+', '3+', '4+', '5+']
const bathOptions = ['Any', '1+', '2+', '3+']
const carOptions = ['Any', '1+', '2+', '3+']
const priceOptions = [
  'None', '$250,000', '$500,000', '$750,000', '$1,000,000', '$1,500,000',
  '$2,000,000', '$2,500,000', '$3,000,000', '$3,500,000', '$4,000,000',
  '$4,500,000', '$5,000,000', '$6,000,000', '$7,000,000', '$8,000,000',
  '$9,000,000', '$10,000,000', '$10,000,000+',
]
const categoryOptions = [
  'All Property Types', 'House', 'Apartment', 'Townhouse', 'Semi/Duplex',
]
const schema = schemafy(
  // Contact info
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.allow('').empty('')),
  schemaItem('number', 'Your Phone Number', types.phone.allow('').empty('')),

  // Enquirer Address
  schemaItem('yourStreet', 'Your Street Address', types.freeText.allow('')),
  schemaItem('yourSuburb', 'Your Suburb', types.freeText.allow('')),
  schemaItem('yourState', 'Your State / Region', types.freeText.allow('')),
  schemaItem('yourPostCode', 'Your Postcode', types.postCode.allow('')),

  // Interested in
  schemaItem(
    'interestedAs',
    'Interested As',
    Joi.string().required().only(interestedAsOptions.slice(1)),
  ),
  schemaItem('suburb', 'Suburb', types.freeText.required()),
  schemaItem(
    'beds', 'Bedrooms Wanted', Joi.string().required().only(bedOptions), bedOptions[0]
  ),
  schemaItem(
    'baths', 'Bathrooms Wanted', Joi.string().required().only(bathOptions), bathOptions[0]
  ),
  schemaItem(
    'cars', 'Car Spaces Wanted', Joi.string().required().only(carOptions), carOptions[0]
  ),
  schemaItem(
    'minPrice', 'Minimum Price', Joi.string().required().only(priceOptions), priceOptions[0]
  ),
  schemaItem(
    'maxPrice', 'Maximum Price', Joi.string().required().only(priceOptions), priceOptions[0]
  ),
  schemaItem(
    'category',
    'Property Category',
    Joi.string().required().only(categoryOptions),
    categoryOptions[0]
  ),

  schemaItem('thinkingOfSelling', 'Thinking of Selling?', Joi.bool(), false),
  schemaItem('loanMarketContact', 'Loan Market Contact', Joi.bool(), false)
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)

// Convert boolean values to yes/no.
const preSendForm = data => {
  const result = { ...data }

  result.thinkingOfSelling = result.thinkingOfSelling ? 'Yes' : 'No'
  result.loanMarketContact = result.loanMarketContact ? 'Yes' : 'No'

  return result
}

export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)
export const process = (values, { send, referrer }) => stockFormEmail(
  send,
  'Property Sale Alert Request',
  fields,
  labels,
  preSendForm(values),
  {
    prefix: `A property sale alert request has been received from ${referrer || 'your office website'}.`,
    referrer,
  }
)

const PropertySaleAlertForm = props => {
  const {
    fields: {
      name,
      email,
      number,
      yourStreet,
      yourSuburb,
      yourState,
      yourPostCode,
      interestedAs,
      suburb,
      beds,
      baths,
      cars,
      minPrice,
      maxPrice,
      category,
      thinkingOfSelling,
      loanMarketContact,
    },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Your Contact Details</FormSectionHeading>
      <FormSection>
        <FormField field={name} placeholder="e.g. Joe Bloggs" required />
        <FormRow>
          <FormField
            field={number}
            placeholder="e.g. 04xx xxx xxx"
            required={!email.value}
            className="one_half"
          />
          <FormField
            field={email}
            placeholder="e.g. j.bloggs@gmail.com"
            required={!number.value}
            className="one_half"
          />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Your Address Details</FormSectionHeading>
      <FormSection>
        <FormField field={yourStreet} />
        <FormField field={yourSuburb} />
        <FormRow>
          <FormField field={yourState} className="one_half" />
          <FormField field={yourPostCode} className="one_half" />
        </FormRow>
      </FormSection>
      <FormSectionHeading>Desired Property Details</FormSectionHeading>
      <FormSection>
        <FormField
          input={FormSelect}
          options={interestedAsOptions}
          field={interestedAs}
          className="select_form_field"
          required
        />
        <FormField field={suburb} required />
        <FormRow>
          <FormField
            input={FormSelect}
            options={bedOptions}
            field={beds}
            className="one_third select_form_field"
          />
          <FormField
            input={FormSelect}
            options={bathOptions}
            field={baths}
            className="one_third select_form_field"
          />
          <FormField
            input={FormSelect}
            options={carOptions}
            field={cars}
            className="one_third select_form_field"
          />
        </FormRow>
        <FormRow>
          <FormField
            input={FormSelect}
            options={priceOptions}
            field={minPrice}
            className="one_half select_form_field"
          />
          <FormField
            input={FormSelect}
            options={priceOptions}
            field={maxPrice}
            className="one_half select_form_field"
          />
        </FormRow>
        <FormField
          input={FormSelect}
          options={categoryOptions}
          field={category}
          className="select_form_field"
          required
        />
        <FormField
          field={thinkingOfSelling}
          className="checkbox_form_field"
          label=""
        >
          <FormInput
            field={thinkingOfSelling}
            type="checkbox"
            checked={thinkingOfSelling.value}
          />
          <label htmlFor={thinkingOfSelling.name}>
            Are you thinking of selling your property in the next six months?
          </label>
        </FormField>
        <FormField
          field={loanMarketContact}
          className="checkbox_form_field"
          label=""
        >
          <FormInput
            field={loanMarketContact}
            type="checkbox"
            checked={loanMarketContact.value}
          />
          <label htmlFor={loanMarketContact.name}>
            I would like to be contacted by a Loan Market representative.
          </label>
        </FormField>
      </FormSection>
      <FormSubmit label="Request Alert" submitting={submitting} />
    </ContentForm>
  )
}

PropertySaleAlertForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(PropertySaleAlertForm)
