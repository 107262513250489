// @flow

import React, { Component } from 'react'
import { telLink, emailLink } from '@raywhite/helpers-utils/lib/helpers/link'
import { getAgentLicenceCode } from '@raywhite/helpers-utils/lib/helpers/rules'
import { hasMinimalInfo } from '@raywhite/data-utils/lib/data/member/agent'
import AgentCard from './AgentCard.jsx'
import Loader from './Loader.jsx'

import type {
  LoadableMember,
  LoadableOrganisation,
} from '../../types'

type Props = {
  listingId: number,
  organisationIds: Array<number>,
  members: Array<LoadableMember>,
  office: LoadableOrganisation,
  title: string,
  subtitle: string,
  children?: React$Element<*>,
  showLicenceCode: boolean,
  className: string,
  id: string,
  onClickEmail?: () => ?null,
};

/**
 * Renders a contact block for a listing; if no valid agents are shown it
 * will instead use the office details.
 */
export default class ListingContacts extends Component<Props> {
  static defaultProps = {
    className: 'pdp_agents',
    id: 'agents',
  }

  shouldComponentUpdate(props: Props) {
    // This covers all situations where the listing actually changes.
    if (this.props.listingId !== props.listingId) return true

    const { members } = this.props
    const _members = props.members

    for (let i = 0; i < _members.length; i++) {
      if (_members[i].loaded !== members[i].loaded) return true
      if (_members[i].loading !== members[i].loading) return true
      if (_members[i].notFound !== members[i].notFound) return true
    }

    return false
  }

  render() {
    const {
      members: _members,
      title,
      subtitle,
      children,
      office,
      office: {
        organisationId,
        licenceCode,
        address,
      } = {},
      showLicenceCode,
      className,
      id,
      onClickEmail,
      organisationIds,
    } = this.props
    const { countryCode, stateCode } = (address || {})

    const members = _members.filter(m => !m.loaded || hasMinimalInfo(m, { orgId: organisationId }))
    const _licenceCode = showLicenceCode
      ? getAgentLicenceCode(countryCode, stateCode, licenceCode)
      : undefined

    return (
      <div className={className} id={id}>
        <div className="inner">
          <span className="muted mini preheading">{subtitle}</span>
          <h3 className="centered_text">{title}</h3>
          <ul data-agents={members.length || 1}>
            {members.length ? (
              members.map(member => (
                <li key={member.memberId}>
                  {member.loaded ? (
                    <AgentCard
                      member={member}
                      organisationId={organisationId}
                      organisationIds={organisationIds}
                      licenceCode={licenceCode}
                      stateCode={stateCode}
                      countryCode={countryCode}
                      showLicenceCode={showLicenceCode}
                      showAsLink={(
                        // Note: set in the redux middleware for agents hidden on the site
                        !member.hidden
                        && member.organisationIds.indexOf(office.organisationId) !== -1
                      )}
                      onClickEmail={onClickEmail}
                    />
                  ) : <Loader />}
                </li>
              ))
            ) : (
              <li>
                <div className={_licenceCode ? 'agent_card has_license' : 'agent_card'}>
                  <div className="agent_card_contact_details_wrap">
                    <div className="agent_card_contact_details">
                      <h4 className="echo">
                        {office.fullName}
                      </h4>
                      <ul>
                        {office.phone && (
                          <li className="contact_link_mobile">
                            <span>
                              {telLink(office.phone, { 'data-ev-label': office.fullName })}
                            </span>
                          </li>
                        )}
                        {office.email && (
                          <li className="contact_link_email">
                            <span>
                              {emailLink(office.email, { 'data-ev-label': office.fullName })}
                            </span>
                          </li>
                        )}
                        {_licenceCode && (
                          <li className="office_card_license">
                            <small className="muted">{_licenceCode}</small>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
          {children}
        </div>
      </div>
    )
  }
}
