// @flow
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {
  getListingUrl,
} from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'

import type { Listing } from '@raywhite/data-client/lib/modules/listings'

import ListingDetail from './ListingDetail.jsx'
import Image from './Image.jsx'

type Props = {
  listings: Array<Listing>,
};

const defaultMapListingImage = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/default_map_listing_image.svg')
  : undefined

const openMeta = {
  'data-ev-on': 'click',
  'data-ev-category': 'Map',
  'data-ev-action': 'View Marker PDP',
}

/**
 * Renders content for a GMap InfoWindow representing a listing or set of listings.
 */
const ListingInfoWindow = ({ listings }: Props) => {
  const cards = listings.map(listing => {
    const address = stringifyAddress(listing.address)
    const image = listing.images[0]
    const url = getListingUrl(listing)
    const imageEl = image
      ? (
        <Image
          className="info_window_image"
          image={image}
          attrs={{ width: 300, height: 200, mode: 'crop' }}
          immediate
          {...openMeta}
        />
      ) : (
        <img
          alt={address}
          src={defaultMapListingImage}
          {...openMeta}
        />
      )

    return (
      <div key={listing.id}>
        <a
          href={url}
          target="_blank"
        >
          <div className="proplist_item_image">
            {imageEl}
          </div>
        </a>
        <div className="info_window_text proplist_item_header">
          <a
            href={url}
            target="_blank"
          >
            <h2 className="gamma">{address}</h2>
          </a>
          <ListingDetail
            {...listing}
            className="alt"
            countryCode={listing.address.countryCode}
          />
        </div>
      </div>
    )
  })
  const className = listings.length > 1 ? 'info_window_content_multiple' : ''
  return (
    <div className={`info_window_content ${className}`}>
      {cards}
    </div>
  )
}

export default ListingInfoWindow
