import React from 'react'
import PropTypes from 'prop-types'

const Introduction = ({ content }) => (
  <section className="Introduction">
    <div
      className="Introduction__content inner_smadium"
      dangerouslySetInnerHTML={content}
    />
  </section>
)

Introduction.propTypes = {
  content: PropTypes.object.isRequired,
}

export default Introduction
