import { ONE_HOUR, ONE_MINUTE } from '@raywhite/helpers-utils/lib/helpers/time'

const DEFAULT_OFFSET = 10 * ONE_HOUR // Accurate-ish enough for our purposes

export const inferTimestampOffset = (date) => {
  if (!date) return DEFAULT_OFFSET

  const [, symbol, hours, minutes] = date.match(/([-+])(\d{2}):(\d{2})$/) || []

  if (!symbol) return DEFAULT_OFFSET

  const value = ((+hours * 60) + +minutes) * ONE_MINUTE

  return symbol === '-' ? -value : value
}

export const inferEventsOffset = (events) => inferTimestampOffset(
  events.inspections[0]?.startAt
  || events.auction?.at
  || events.tender?.at
)
