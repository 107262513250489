// @flow

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { collectionPage as collectionPageSchema } from '@raywhite/helpers-utils/lib/helpers/structuredData'
import LoadMore from './LoadMore.jsx'
import Loader from './Loader.jsx'
import ContentCardList from './ContentCardList.jsx'
import { getItemImage } from '../../utils/wordpress/content'
import JsonLD from './JsonLD.jsx'
import OpenGraph from './OpenGraph.jsx'
import TwitterSummaryCard from './TwitterSummaryCard.jsx'

import type { CardOptions } from './ContentCard.jsx'

type Props = {
  heading: string,
  entities: Array<Object>,
  typeName: string,
  loaded: boolean,
  // Not provided if the total is not known (e.g. nothing loaded at all yet)
  total?: number,
  loading: boolean,
  load: () => void,
  cardOptions?: CardOptions,
  primaryOffice: Object,
  url: string,
};

class ContentArchive extends Component<Props> {
  render() {
    const {
      entities,
      typeName,
      loading,
      total,
      load,
      loaded,
      cardOptions,
      url,
      primaryOffice,
      heading,
    } = this.props

    // Show full loader if nothing is loaded yet; once some are loaded the
    // LoadMore below will show loading status
    if (!entities.length && !loaded) {
      return (
        <div>
          <Helmet>
            <title>{typeName}</title>
          </Helmet>
          <Loader />
        </div>
      )
    }

    const structuredData = collectionPageSchema({
      name: typeName,
      description: `${typeName} from ${primaryOffice.fullName}`,
      url,
      image: entities.reduce((result, item) => {
        if (result) return result
        const image = getItemImage(item)
        return image && `${image.url}?maxwidth=1280`
      }, ''),
    })

    return (
      <section className="pg_news">
        <Helmet>
          <title>{structuredData.name}</title>
          <meta name="description" content={structuredData.description} />
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          title={structuredData.name}
          site={primaryOffice.twitter}
          description={structuredData.description}
          image={structuredData.image}
        />
        <OpenGraph
          type="article"
          title={structuredData.name}
          description={structuredData.description}
          image={structuredData.image && {
            url: structuredData.image.replace(/\?.*/, ''),
          }}
          url={structuredData.url}
        />
        <div className="centered_text pg_content_header">
          <span className="muted mini">Up to Date</span>
          <h1 className="charlie">{heading || typeName}</h1>
        </div>
        <div className="inner_md">
          <ContentCardList
            entities={entities}
            typeName={typeName}
            loaded={loaded}
            cardOptions={cardOptions}
            className="content_post_list centered_text"
          >
            {load && (
              <div className="content_post_more">
                <LoadMore
                  load={load}
                  loading={loading}
                  loadedCount={entities.length}
                  totalCount={total}
                  loadMoreLabel={`Load more ${typeName.toLowerCase()}`}
                />
              </div>
            )}
          </ContentCardList>
        </div>
      </section>
    )
  }
}

export default ContentArchive
