import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import memoize from 'memoizee'
import { parseLink as _parseLink, YOUTUBE, UNKNOWN } from '@raywhite/media-utils/lib/media/media'
import { getListingUrl } from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'
import { agentUrl } from '@raywhite/data-utils/lib/data/member/agent'
import { renderLink } from '../../utils/media/renderers'

const parseLink = memoize(_parseLink, {
  max: 1000,
  normalizer: ([{ url }]) => url,
})

const defaultThumbnail = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/video_page_play.svg')
  : undefined

const getThumbnail = meta => {
  if (meta.type !== YOUTUBE) {
    return defaultThumbnail
  }
  return `https://img.youtube.com/vi/${meta.id}/mqdefault.jpg`
}

const videoShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  thumb: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    url: PropTypes.string.isRequired,
  })]),
  listingId: PropTypes.number,
  memberId: PropTypes.number,
})

export default class VideoPlaylist extends React.Component {
  static propTypes = {
    videos: PropTypes.arrayOf(videoShape).isRequired,
    agents: PropTypes.object.isRequired,
    listings: PropTypes.object.isRequired,
    loadAgents: PropTypes.func.isRequired,
    loadListing: PropTypes.func.isRequired,
  }

  constructor({ videos }) {
    super()
    this.state = {
      selected: videos.length ? 0 : undefined,
    }
  }

  componentDidMount() {
    if (this.props.videos.length) this.selectVideo(0)
  }

  selectVideo(selected) {
    const { loadAgents, loadListing, videos } = this.props
    const current = videos[selected]

    // Nothing more to do if the selection is invalid
    if (!current) return

    // Load required data if possible
    const { memberId, listingId } = current
    if (memberId) loadAgents(memberId)
    if (listingId) loadListing(listingId)

    // Only force rerender if the selected video changes
    if (this.state.selected !== selected) this.setState({ selected })
  }

  render() {
    const { selected } = this.state
    const { videos, agents, listings } = this.props
    const current = videos[selected]
    const agent = current && agents[current.memberId]
    const listing = current && listings[current.listingId]
    const hasAgent = agent && agent.loaded && !agent.notFound && !agent.error
    const hasListing = listing && listing.loaded && !listing.notFound && !listing.error

    return (
      <div className="content_video_playlist">
        {current && (
          <div className="content_video_current_wrap" id="current_video">
            <div className="iframe_wrap">
              {renderLink(current.url)}
            </div>
            <h4 className="echo">{current.title}</h4>
            {(hasAgent || hasListing) && (
              <p>
                {hasAgent && (
                  <Link to={agentUrl(agent)} className="anchor">
                    More about
                    {' '}
                    {agent.givenName}
                  </Link>
                )}
                {hasAgent && hasListing && ([
                  ' ',
                  <span key="separator" className="muted">/</span>,
                  ' ',
                ])}
                {hasListing && (
                  <Link to={getListingUrl(listing)} className="anchor">
                    More about
                    {' '}
                    {stringifyAddress(listing.address)}
                  </Link>
                )}
              </p>
            )}
          </div>
        )}
        <ul className="content_video_items">
          {videos.map(({ title, url, thumb }, index) => {
            const meta = parseLink({ url })
            const inner = [
              <div key="icon" className="content_video_item_img">
                <div
                  className="responsive_image_wrapper"
                  style={{ paddingBottom: '56.25%' }}
                >
                  <img
                    alt={title}
                    src={(thumb && thumb.url) || getThumbnail(meta)}
                  />
                </div>
              </div>,
              // eslint-disable-next-line react/jsx-indent
              <h5
                key="title"
                className="gamma bold_font"
                dangerouslySetInnerHTML={{ __html: title }}
              />,
            ]

            const link = meta.type === UNKNOWN
              ? <a href={url} target="_blank" rel="noopener noreferrer">{inner}</a>
              : (
                <a
                  href="#current_video"
                  onClick={() => this.selectVideo(index)}
                  className={classNames('content_video_link', {
                    active: selected === index,
                  })}
                >
                  {inner}
                </a>
              )
            return <li key={url}>{link}</li>
          })}
        </ul>
      </div>
    )
  }
}
