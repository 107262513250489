const podium = (doc, config) => {
  const {
    options: {
      podiumId,
    },
  } = config

  if (!podiumId) return

  // Inject script tag
  const script = document.createElement('script')
  script.id = 'podium-widget'
  script.defer = true
  script.setAttribute('data-organization-api-token', podiumId)
  script.src = `https://connect.podium.com/widget.js#$ORG_TOKEN=${podiumId}`
  document.body.append(script)
}

export default podium
