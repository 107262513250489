// @flow
import React, { Component } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import Loader from './Loader.jsx'

export type PropertyType = {
  label: string,
  typeKey: string,
  typeCode: string,
  subTypeCode?: string,
  statusCode: string,
  categoryCode?: string,
  total: number,
  suburbs: Array<{
    name: string,
    total: number,
  }>,
}
;export type Category = {
  label: string,
  typeKey: string,
  total: number,
  types: Array<PropertyType>,
}
;export type LinkDetails = {
  label: string,
  path: string,
  className: string,
  count?: number,
  eventLabel: string,
}
;type Props = {
  className?: string,
  onClick: (number) => void,
  searching: boolean,
  setPropertyType: PropertyType => void,
  setKeywords: string => void,
  categories: Array<Category>,
  options: Array<LinkDetails>,
  keywords: string,
};

type State = {
  category: number,
  type: number,
  index: number,
  showLinks: boolean,
};

type Event = {
  target: {
    value: string | number,
  },
};

const preventDefault = e => e.preventDefault()

// Component for displaying the autosearch options on the home page
export default class HomePageSearch extends Component<Props, State> {
  static defaultProps = {
    categories: [],
  }

  state = {
    category: 0,
    type: 0,
    index: 0,
    showLinks: false,
  }

  componentDidMount() {
    this.setPropertyType(this.props, this.state)
  }

  componentDidUpdate(props: Props, state: State) {
    if (state.type === this.state.type && state.category === this.state.category) return
    this.setPropertyType(this.props, this.state)
  }

  setPropertyType(props: Props, state: State) {
    const category = props.categories[state.category]
    if (category) props.setPropertyType(category.types[state.type])
  }

  handleSetCategory = (event: Event) => {
    this.setState({ category: +event.target.value, type: 0 })
  }

  handleSetType = (event: Event) => {
    this.setState({ type: +event.target.value })
  }

  setKeywords = (event: Event) => {
    this.props.setKeywords(`${event.target.value}`)
  }

  handleFocus = () => {
    this.setState({ showLinks: true, index: 0 })
  }

  handleBlur = () => {
    this.setState({ showLinks: false })
  }

  handleClick = () => {
    this.props.onClick(0)
  }

  // Trigger a "click" on enter
  handleKeyDown = (event: SyntheticKeyboardEvent<*>) => {
    const { key } = event
    const { index } = this.state
    const { options } = this.props

    switch (true) {
      case key === 'Enter':
        this.props.onClick(index)
        break
      case key === 'ArrowDown':
        if (index === options.length - 1) return
        this.setState({ index: index + 1 })
        break
      case key === 'ArrowUp':
        if (index === 0) return
        this.setState({ index: index - 1 })
        break
      default:
        if (index === 0) return
        this.setState({ index: 0 })
    }
  }

  render() {
    const { category, type, showLinks, index: currentIndex } = this.state
    const { categories, options, keywords, searching, className } = this.props
    const { types = [] } = categories[category] || {}

    return (
      <div className={classNames('home_search', className, { home_search_show_links: showLinks })}>
        <div className="home_search_form">
          <div
            className={classNames('home_search_field home_search_field_type', {
              single: categories.length < 2,
            })}
          >
            {categories.length > 1 && (
              <select
                onChange={this.handleSetCategory}
                value={category}
              >
                {categories.map(({ label }, index) => (
                  <option
                    key={label}
                    value={index}
                  >
                    {label}
                  </option>
                ))}
              </select>
            )}
            {categories.length === 1 && (
              <span className="field_value">{categories[0].label}</span>
            )}
          </div>
          <div
            className={classNames('home_search_field home_search_field_sale', {
              single: types.length < 2,
            })}
          >
            {types.length > 1 && (
              <select
                onChange={this.handleSetType}
                value={type}
              >
                {types.map(({ label }, index) => (
                  <option
                    key={label}
                    value={index}
                  >
                    {label}
                  </option>
                ))}
              </select>
            )}
            {types.length === 1 && (
              <span className="field_value">{types[0].label}</span>
            )}
          </div>
          <div className="home_search_field home_search_field_keywords">
            <input
              type="text"
              value={keywords}
              onChange={this.setKeywords}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              placeholder="Search for properties, suburbs, or keywords…"
              onKeyDown={this.handleKeyDown}
            />
            {searching && <Loader />}
            <div
              className="home_search_options"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            >
              {!!options.length && (
                <ul>
                  {options.map(({ path, label, className: cn, eventLabel, count }, index) => (
                    <li key={path}>
                      <Link
                        onMouseDown={preventDefault}
                        className={classNames(cn, { active: index === currentIndex })}
                        to={path}
                        data-ev-on="click"
                        data-ev-category="Home Search"
                        data-ev-action="Accept Suggestion"
                        data-ev-label={eventLabel}
                      >
                        {label}
                        {!!count && (
                          <span className="listings_count">{count}</span>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="home_search_field_submit">
            <button
              type="button"
              className="btn brand_btn no_arrow"
              onClick={this.handleClick}
            >
              Find Properties
            </button>
          </div>
        </div>
      </div>
    )
  }
}
