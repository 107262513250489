// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { stringify } from 'query-string'

import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

const meta = eventMeta({
  category: 'Outbound',
  action: 'Share',
})

type Props = {
  url: string,
  snippet?: string,
  linkedIn: boolean,
};

const ShareLinks = ({ url, snippet, linkedIn }: Props) => (
  <ul className="social circle addthis_auto">
    <li className="social_facebook">
      <a
        className="anchor addthis_button_facebook"
        href={`https://facebook.com/sharer/sharer.php?${stringify({ u: url })}`}
        {...meta}
        data-ev-label="Facebook"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        <span>Facebook</span>
      </a>
      <span className="muted">/</span>
    </li>
    <li className="social_twitter">
      <a
        className="anchor addthis_button_twitter"
        href={`https://twitter.com/intent/tweet/?${stringify({ url, text: snippet })}`}
        {...meta}
        data-ev-label="Twitter"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        <span>Twitter</span>
      </a>
      <span className="muted">/</span>
    </li>
    {!!linkedIn && (
      <li className="social_linkedin">
        <a
          className="anchor addthis_button_linkedin_share"
          href={`https://www.linkedin.com/sharing/share-offsite/?${stringify({ url })}`}
          {...meta}
          data-ev-label="LinkedIn"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <span>LinkedIn</span>
        </a>
        <span className="muted">/</span>
      </li>
    )}
    <li className="social_mail">
      <a
        className="anchor addthis_button_email"
        href={`mailto:?${stringify({ subject: snippet, body: url })}`}
        {...meta}
        data-ev-label="Email"
      >
        <span>Email</span>
      </a>
    </li>
  </ul>
)

ShareLinks.propTypes = {
  // Used for email subject, twitter template
  snippet: PropTypes.string,
  url: PropTypes.string.isRequired,
  linkedIn: PropTypes.bool.isRequired,
}

export default ShareLinks
