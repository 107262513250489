import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-swipe'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { renderMedia } from '../../utils/media/renderers'
import Modal from './Modal.jsx'

const unresponsive = { responsive: false, galleryItem: true }

const nextItemMeta = eventMeta({
  category: 'Photo Gallery',
  action: 'Next',
})

const prevItemMeta = eventMeta({
  category: 'Photo Gallery',
  action: 'Prev',
})

export default class SimpleGallery extends Component {
  static getDerivedStateFromProps(props, state) {
    if (state.lastPropsIndex === props.index) return null
    return {
      index: props.index,
      lastPropsIndex: props.index,
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      index: props.index,
      lastPropsIndex: props.index,
    }
  }

  shouldComponentUpdate() {
    return true
  }

  next = () => {
    this.props?.onNext() // eslint-disable-line no-unused-expressions
    this.refs.swiper.next() // eslint-disable-line react/no-string-refs
  }

  prev = () => {
    this.props?.onPrev() // eslint-disable-line no-unused-expressions
    this.refs.swiper.prev() // eslint-disable-line react/no-string-refs
  }

  navigateGallery = e => {
    if (e.key === 'ArrowRight') this.next()
    if (e.key === 'ArrowLeft') this.prev()
  }

  render() {
    const {
      props: { media },
      state: { index },
    } = this

    if (!media) return null

    function transitionEnd(_index) {
      this.setState({
        index: _index,
      })
    }

    /* eslint-disable react/jsx-indent */
    return (
      <Modal
        full
        className="gallery_modal"
        handleClose={this.props.handleClose}
        onKeyDown={this.navigateGallery}
      >
        {(function () { // eslint-disable-line func-names
          if (!Array.isArray(media)) {
            return (
              <div className="gallery_modal_slide_wrap_outer">
                <div key={media.hash} className="gallery_modal_slide">
                  <div
                    className="gallery_modal_slide_img"
                    style={{ maxWidth: '1440px', margin: 'auto auto' }}
                  >
                    {renderMedia(media, undefined, unresponsive)}
                  </div>
                </div>
              </div>
            )
          }

          return [
            <Swiper
              className="gallery_modal_slide_wrap_outer"
              ref="swiper" // eslint-disable-line react/no-string-refs
              key="SWIPER"
              speed="22"
              swipeOptions={{
                stopPropagation: true,
                continuous: false,
                startSlide: index,
                transitionEnd: transitionEnd.bind(this), // eslint-disable-line react/jsx-no-bind
              }}
            >
              {media.map((m, x) => (
                <div key={m.hash} className="gallery_modal_slide">
                  <div className="gallery_modal_slide_img">
                    {(
                      x <= index + 1
                      && x >= index - 1
                      && renderMedia(m, undefined, {
                        ...unresponsive,
                        // Do not autoplay adjacent videos
                        autoplay: (m.code === 'VID' && x === index)
                          ? 1
                          : undefined,
                      })
                    )}
                  </div>
                </div>
              ))}
            </Swiper>,
            <div
              className="gallery_modal_slide_nav"
              key="SLIDE_NAV"
            >
              {index > 0
                && (
                  <span
                    className="prev micro"
                    onClick={this.prev}
                    {...prevItemMeta}
                  >
                    <span>Prev</span>
                  </span>
                )}
              {index < (media.length - 1)
                && (
                  <span
                    className="next micro"
                    onClick={this.next}
                    {...nextItemMeta}
                  >
                    <span>Next</span>
                  </span>
                )}
            </div>,
            <div
              className="gallery_modal_slide_meta micro"
              key="SLIDE_META"
            >
              <span className="gallery_modal_pagination">
                {`${index + 1}/${media.length}`}
              </span>
            </div>,
          ]
        }.call(this))}
      </Modal>
    )
    /* eslint-enable react/jsx-indent */
  }
}

SimpleGallery.propTypes = {
  media: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
}
