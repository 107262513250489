import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const stopPropagation = e => {
  const { target } = e

  // Propagate clicks on the modal wrapper itself...
  if (target.tagName === 'DIV' && /\bmodal_wrapper\b/.test(target.classList)) {
    return
  }

  // But prevent propagation of events from children
  e.stopPropagation()
}

/**
 * Provides a standard modal component with a close button and modal background.
 *
 * The modal will render children as the modal content.
 */
class Modal extends Component {
  setWrap = el => {
    this.wrap = el

    // Prevent scrolling then restore after reset
    if (el) {
      el.focus()
      const { body } = el.ownerDocument
      const doc = el.ownerDocument.documentElement
      const { scrollTop } = body
      doc.classList.add('showing_modal')
      this.restoreBodyScroll = function restoreBodyScroll() {
        doc.classList.remove('showing_modal')
        body.scrollTop = scrollTop
      }
    } else if (this.restoreBodyScroll) {
      this.restoreBodyScroll()
      delete this.restoreBodyScroll()
    }
  }

  close = e => {
    e.stopPropagation()
    this.props.handleClose(e)
  }

  handleKeyDown = e => {
    if (e.key === 'Escape') this.close(e)
    if (this.props.onKeyDown) this.props.onKeyDown(e)
  }

  render() {
    const { children, full, className } = this.props

    return (
      <div
        className={classNames('modal', className, { full })}
        onClick={this.close}
        onKeyDown={this.handleKeyDown}
        ref={this.setWrap}
        tabIndex="0"
      >
        <span
          onClick={this.close}
          className="modal_close"
        />
        <div
          className="modal_wrapper"
          onClick={stopPropagation}
        >
          {children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  // Method to cause the modal to hide
  handleClose: PropTypes.func.isRequired,
  // Contents of the modal
  children: PropTypes.node.isRequired,
  // Whether to render as a "full" modal (adds class to container)
  full: PropTypes.bool.isRequired,
  // Additional classNames to add to the modal
  className: PropTypes.string,
  // Key handler set by SimpleGallery to handle navigation
  onKeyDown: PropTypes.func,
}

Modal.defaultProps = {
  full: false,
}

export default Modal
