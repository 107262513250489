// @flow
import React, { Component } from 'react'
import InputRange from 'react-input-range'
import { compressPrice } from '@raywhite/helpers-utils/lib/helpers/string'

type Price = {
  price: number,
  height: number,
  width: number,
  total: number,
};

type Range = {
  min: number,
  max: number,
};

type Props = {
  prices: Array<Price>,
  onChange: Object => void,
  formatLabel: number => string,
  step: number,
  disabled: boolean,
  // Minimum permitted value
  rangeMin: number,
  // Maximum permitted value
  rangeMax: number,
  // Actual min value
  min: number,
  // Actual max value
  max: number,
  minLabel?: string,
  maxLabel?: string,
};

type State = {
  value: ?Range,
};

const noLabel = () => ''

const findHighestCount = prices => {
  const maxPrice = prices.map(price => price.total)
  return Math.max(...maxPrice)
}

const buildColumns = prices => {
  const highestCount = findHighestCount(prices)
  const highestHeight = 40

  return prices.reduce((result, { price, total }) => {
    const height = total === highestCount
      ? highestHeight
      : (highestHeight * (total / highestCount))
    result.push({
      price,
      total,
      height: Number(height.toFixed(2)),
      width: 1 / prices.length * 100,
    })
    return result
  }, [])
}

export default class PriceBar extends Component<Props, State> {
  static defaultProps = {
    formatLabel: compressPrice,
  }

  state = {
    // Stash for a local copy of the value while adjusting the slider
    value: undefined,
  }

  /**
   * Stashes a local value to reflect changes in the UI, but does not call
   * the passed in onChange handler until the change is complete.
   */
  onChange = (value: Range) => {
    this.setState({ value })
  }

  /**
   * Dispatches the final changed value using the onChange prop and clears the
   * local override.
   */
  onChangeComplete = (value: Range) => {
    this.setState({ value: undefined })
    const { rangeMin, rangeMax } = this.props
    this.props.onChange({
      target: {
        value: {
          ...value,
          rangeMin,
          rangeMax,
        },
      },
    })
  }

  formatLabel = (value: number) => {
    const {
      rangeMin,
      rangeMax,
      formatLabel,
      minLabel,
      maxLabel,
    } = this.props

    if (value === rangeMax) return maxLabel || `${formatLabel(value)}+`
    if (value === rangeMin && minLabel) return minLabel

    return formatLabel(value)
  }

  render() {
    const {
      prices,
      rangeMin,
      rangeMax,
      min,
      max,
      disabled,
      step,
    } = this.props

    const minVal = this.state.value ? this.state.value.min : min
    const maxVal = this.state.value ? this.state.value.max : max

    return (
      <div className="price-bar">
        <InputRange
          disabled={disabled}
          minValue={rangeMin}
          maxValue={rangeMax}
          formatLabel={noLabel}
          step={step}
          value={this.state.value || { min, max }}
          onChange={this.onChange}
          onChangeComplete={this.onChangeComplete}
        />
        <div className="price_histogram">
          {buildColumns(prices).map(({ price, height, width }) => {
            const style = {
              height: `${height}px`,
              width: `${width}%`,
            }
            return <div key={price} style={style}></div>
          })}
        </div>
        <div className="price_values">
          <span className="price_min">{this.formatLabel(minVal)}</span>
          <span className="price_max">{this.formatLabel(maxVal)}</span>
        </div>
      </div>
    )
  }
}
