import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes, reset } from 'redux-form'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  warnForm,
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSection,
  FormTextArea,
  FormRow,
  FormSubmit,
  labelifyFormField,
} from '../presentational/formComponents'

const FORM_NAME = 'enquiry'
const schema = schemafy(
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.allow('').empty('')),
  schemaItem('number', 'Your Phone Number', types.phone.allow('').empty('')),
  schemaItem('message', 'Additional Message', types.freeText.required('')),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)

export const process = (values, params = {}) => {
  const {
    send,
    subject,
    prefix,
    referrer,
  } = params

  const ref = referrer || 'your office website'
  return stockFormEmail(
    send,
    subject,
    fields,
    labels,
    values,
    {
      prefix: prefix || `An enquiry has been received from ${ref}.`,
      referrer,
    }
  )
}

const initialState = {
  submitted: false,
}

class EnquiryForm extends Component {
  constructor() {
    super()

    this.state = { ...initialState }
  }

  componentWillUnmount() {
    this.setState(initialState)
  }

  render() {
    const {
      fields: { name, email, number, message },
      submitting,
      sendForm,
      handleSubmit,
      error,
      dispatch,
      submitLabel,
      extraData,
      sendMeta,
      handleClose,
      submittedMessage,
    } = this.props

    const onSubmit = data => sendForm({ ...extraData, ...data }, sendMeta)
      .then(() => {
        this.setState({ submitted: true })
        return dispatch(reset(FORM_NAME))
      })
    if (this.state.submitted) {
      return (
        <div className="form_fields_section">
          <div className="inner centered_text">
            <p>{submittedMessage}</p>
            <p>
              <button
                type="button"
                className="btn large"
                onClick={handleClose}
              >
                Close
              </button>
            </p>
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {warnForm(error)}
        <FormSection>
          <FormField field={name} placeholder="e.g. Joe Bloggs" required />
          <FormRow>
            <FormField
              field={number}
              placeholder="e.g. 04xx xxx xxx"
              required={!email.value}
              className="one_half"
            />
            <FormField
              field={email}
              placeholder="e.g. j.bloggs@gmail.com"
              required={!number.value}
              className="one_half"
            />
            <FormField field={message} input={FormTextArea} />
          </FormRow>
        </FormSection>
        <FormSubmit submitting={submitting} label={submitLabel} />
      </form>
    )
  }
}

EnquiryForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  extraData: PropTypes.object.isRequired,
  sendMeta: PropTypes.object.isRequired,
  defaultMessage: PropTypes.string,
  submittedMessage: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

EnquiryForm.defaultProps = {
  submitLabel: 'Get in touch',
  submittedMessage: "Thanks, we'll be in touch!",
  extraData: {},
  sendMeta: {},
}

const ReduxEnquiryForm = reduxForm(
  {
    form: FORM_NAME,
    fields,
    validate,
  },
  (state, props) => ({
    initialValues: { ...initialValues, message: props.defaultMessage },
  }),
)(EnquiryForm)

export default ReduxEnquiryForm
