export const mapboxToken = 'pk.eyJ1IjoicmF5d2hpdGUiLCJhIjoiY2prbm4yajQ0MDh5aTNwb2NkMTFvb3I0bCJ9.bOKyrQmwfrVXWTdHxIpGNw'

export const MAX_JSON_BODY_SIZE = 102400

export const AWARD_LIMIT = 10

const wp = process.env.WEBPACKED

const s3base = 'https://cdn6.ep.dynamics.net/s3/rw-media/residential-offices/resources'
export const fallbackMarkerIcon = `${s3base}/gmap_marker_2x.d3ac388ca19c9e7fc5ec674c31cb1812c7b8f34e.png`

/* eslint-disable global-require */
export const defaultSearchImage = wp ? require('../assets/default-search.jpg') : undefined
export const plainMarkerIcon = wp ? require('../assets/plain_location_marker.png') : undefined
export const exactMarkerIcon = wp ? require('../assets/exact_location_marker.png') : undefined
export const inexactMarkerIcon = wp ? require('../assets/inexact_loction_marker.png') : undefined
export const auctionsPlusLogo = wp ? require('../assets/auctionsplus.svg') : undefined
export const auctionsPlusLogoAlt = wp ? require('../assets/auctionsplus_alt.svg') : undefined
export const favicon = wp ? require('../assets/favicon.svg') : undefined
export const faviconCommercial = wp ? require('../assets/icons/dist/rw_logo_comm.svg') : undefined
/* eslint-enable global-require */

// Where we load the font style from
export const fontStyleUrls = {
  residential: 'https://fonts.googleapis.com/css?family=Lato:400,400i,700|Playfair+Display:700',
  // NOTE(@elybo): Since the July 2023 rebrand these have been the same
  commercial: 'https://fonts.googleapis.com/css?family=Lato:400,400i,700|Playfair+Display:700',
}
// Where the actual font files are hosted
export const fontHost = 'https://fonts.gstatic.com'
export const listingImageCdn = 'https://cdn6.ep.dynamics.net'

// Intervals for creating price range buckets and handling the price data
export const PRICE_INTERVAL = 50000
export const RENT_PRICE_INTERVAL = 5000

export const VIC_RENTAL_DEFAULT_APPLICATION = 'https://rw-media.s3.amazonaws.com/residential-offices/rw_rental_application_vic_2021.pdf'
export const VIC_RENTAL_SOI = 'https://rw-media.s3.amazonaws.com/residential-offices/rw_rental_soi_vic_2021.pdf'

/* eslint-disable max-len */
const dataUri = str => `data:image/svg+xml;base64,${Buffer.from(str, 'utf8').toString('base64')}`

export const clusterIconUri = dataUri('<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201"><circle fill="rgba(255, 229, 18, 0.7)" stroke="#fff" stroke-width="10" cx="100" cy="100" r="90" /><circle fill="rgba(255, 255, 255, 1)" stroke-width="0" cx="100" cy="100" r="50" /></svg>')

export const singleIconUri = dataUri('<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201"><circle fill="rgba(255, 229, 18, 1)" stroke="#fff" stroke-width="15" cx="100" cy="100" r="85" /></svg>')

export const activeIconUri = dataUri('<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201"><circle fill="rgba(51, 51, 51, 1)" stroke="#fff" stroke-width="15" cx="100" cy="100" r="85" /></svg>')

export const seenIconUri = dataUri('<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201"><circle fill="rgba(255, 229, 18, .45)" stroke="#fff" stroke-width="15" cx="100" cy="100" r="85" /></svg>')

export const approximateIconUri = dataUri('<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201"><circle fill="rgba(255, 229, 18, 0.3)" stroke="rgba(228, 206, 30, 1)" stroke-width="4" cx="100" cy="100" r="85" /></svg>')

/* eslint-enable max-len */
