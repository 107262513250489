import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pluralize } from '@raywhite/data-utils/lib/data/listing/utils'
import { createMeasurementsStrings, LAND } from '@raywhite/data-utils/lib/data/listing/formatting'

function createListItem(className, count, singular, plural) {
  return (
    <li
      key={className}
      className={className}
    >
      <span>
        {count}
        {' '}
        <span>{pluralize(count, singular, plural)}</span>
      </span>
    </li>
  )
}

/**
 * NOTE: See #970 (`https://github.com/raywhite/office-sites/issues/970`),
 * for the discussion that eventuated in the logic used below.
 */
export default class ListingDetail extends Component {
  static defaultProps = {
    categories: [],
  }

  static propTypes = {
    className: PropTypes.string,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    garages: PropTypes.number,
    carSpaces: PropTypes.number,
    countryCode: PropTypes.string,
    typeCode: PropTypes.string,
    measurements: PropTypes.array,
    categories: PropTypes.array,
  }

  render() {
    const {
      className,
      typeCode,
      measurements,
      bathrooms,
      garages,
      carSpaces,
      countryCode,
      bedrooms,
      categories,
    } = this.props

    const isNz = countryCode === 'NZ'
    const listingCategoryCode = categories.map(listing => listing.code).toString()

    const isCommercial = typeCode === 'COM'
    const isBusiness = typeCode === 'BUS'

    const isRural = typeCode === 'RUR'
    const isRuralLife = listingCategoryCode === 'LSP'

    const isforSale = typeCode === 'SAL'
    const isResiLife = listingCategoryCode === 'LST'

    const isEmptyLand = typeCode === 'SAL' && ['SEC', 'LSS', 'LAN'].indexOf(listingCategoryCode) !== -1
    const isLifestyle = (isRural && isRuralLife) || (isforSale && isResiLife)

    /**
     * Show land/building size for 'COM', 'BUS', 'RUR' listing and
     * emptyland listing (:65) & rural lifestype listing (:66)
     */
    const showMeasurements = isCommercial || isBusiness || isRural || isEmptyLand || isLifestyle

    /**
     * Showing BBC for listings that are not commercial, not business, not rural.
     * Show BBC if its rural lifestyle listing (:61)
     */
    const showBBC = !isCommercial && !isBusiness && (!isRural || isRuralLife)

    const results = []

    if (showBBC) {
      if (bedrooms) results.push(createListItem('bed', bedrooms, 'Bed', 'Beds'))
      if (bathrooms) results.push(createListItem('bath', bathrooms, 'Bath', 'Baths'))
      if (isNz && garages) results.push(createListItem('car', garages, 'Car', 'Cars'))
      if (!isNz && carSpaces) results.push(createListItem('car', carSpaces, 'Car', 'Cars'))
    }

    if (showMeasurements) {
      const _measurements = createMeasurementsStrings(measurements, {
        // Andrew Freeman has requested 3 acres minimum to be shown as acres for commercial
        minAcres: isCommercial ? 3 : 1 / 8,
      })
      const elements = _measurements.map(({ type, value }) => (
        <li
          key={value}
          className={type === LAND ? 'land' : 'building'}
        >
          <span>
            <span>
              {' '}
              {value}
              {' '}
            </span>
          </span>
        </li>
      ))
      results.push(...elements)
    }

    return (
      <ul className={`bedbathcar ${className || ''}`.trim()}>
        {results}
      </ul>
    )
  }
}
