// @flow

import React, { Component } from 'react'

import type { Node } from 'react'

import ContentCard from './ContentCard.jsx'

import type { CardOptions } from './ContentCard.jsx'

type Props = {
  entities: Array<Object>,
  loaded: boolean,
  className?: string,
  children?: Node,
  listClassName?: string,
  cardOptions?: CardOptions,
}

export default class ContentCardList extends Component<Props> {
  render() {
    const {
      entities,
      loaded,
      className,
      children,
      listClassName,
      cardOptions,
    } = this.props

    if (!entities.length && loaded) {
      return (
        <div className="proplist_no_results centered_text">
          <div className="inner">
            <em>No content found.</em>
          </div>
        </div>
      )
    }

    return (
      <div className={className}>
        {!!entities.length && (
          <ul className={listClassName}>
            {entities.map(item => (
              <li
                key={item.id}
                className="content_post_item"
              >
                <ContentCard
                  {...cardOptions}
                  item={item}
                />
              </li>
            ))}
          </ul>
        )}
        {children}
      </div>
    )
  }
}
