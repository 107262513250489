import { fetchListings as fetchListingsRedux } from '@raywhite/redux/lib/listing'

/**
 * Wrap redux-thunk callback to provide our redux module's fetchListings with
 * the organisationId to limit the search to the current office when no organisationId
 * is supplied in search parameters
 */
export function fetchListings(api, search, page) {
  return (dispatch, getState) => {
    const { config } = getState()
    const organisationId = search.organisationId || config.organisationIds

    return fetchListingsRedux(api, search, page, organisationId)(dispatch, getState)
  }
}
