import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { cleanMarkup, plainText } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { eventMeta, telLink } from '@raywhite/helpers-utils/lib/helpers/link'
import { excerptify } from '@raywhite/helpers-utils/lib/helpers/string'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { getStructuredData, teamUrl } from '@raywhite/data-utils/lib/data/member/team'

import {
  fetchListings,
  getSearch,
} from '@raywhite/redux/lib/listing'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { getOrganisationMembers, loadMembers } from '@raywhite/redux/lib/member'

import Button from '../presentational/Button/Button.jsx'
import EnquiryForm from './EnquiryForm.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import Hero from '../presentational/Hero/Hero.jsx'
import Introduction from '../presentational/Introduction/Introduction.jsx'
import JsonLD from '../presentational/JsonLD.jsx'
import ListingCardList from '../presentational/ListingCardList.jsx'
import Loader from '../presentational/Loader.jsx'
import MembersGridWithHeader from '../presentational/MembersGridWithHeader/MembersGridWithHeader.jsx'
import Modal from '../presentational/Modal.jsx'
import ModalInner from '../presentational/ModalInner.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import OpenGraph from '../presentational/OpenGraph.jsx'
import SellAppraisalForm from './contentforms/SellAppraisalForm.jsx'
import TestimonialsGridWithHeader from '../presentational/TestimonialsGridWithHeader/TestimonialsGridWithHeader.jsx'
import TwitterSummaryCard from '../presentational/TwitterSummaryCard.jsx'

import withContext from '../hocs/withContext'

const INITIAL_STATE = {
  showAppraisalModal: false,
  showEnquiryModal: false,
}

// TODO: Image sizing
const RESIZE_PARAMS = {
  height: 781, // QUESTION: Hardcoded?
  width: 1388, // QUESTION: Hardcoded?
  scale: 'both',
}

const appraisalMeta = eventMeta({
  category: 'Appraisal',
  action: 'Open',
})

const enquiryMeta = eventMeta({
  category: 'Ask Question',
  action: 'Open',
})

const getHeroImageData = imageUrl => ({
  attrs: RESIZE_PARAMS,
  data: {
    height: RESIZE_PARAMS.height,
    width: RESIZE_PARAMS.width,
    url: imageUrl,
  },
})

const getSortedMembers = (sortedMemberIds, hiddenMemberIds, members, teamId) => {
  const NOT_FOUND = -1
  const A_FIRST = -1
  const B_FIRST = 1
  const EQUAL = 0

  const membersToDisplay = members.filter(member => !hiddenMemberIds.includes(member.id))

  return membersToDisplay.sort((a, b) => {
    const teamA = a.teams[teamId]
    const teamB = b.teams[teamId]
    if (teamA.teamLeader !== teamB.teamLeader) return teamA.teamLeader ? A_FIRST : B_FIRST

    const idxA = sortedMemberIds.indexOf(a.id)
    const idxB = sortedMemberIds.indexOf(b.id)
    if (idxA === idxB) return EQUAL
    if (idxA === NOT_FOUND) return B_FIRST
    if (idxB === NOT_FOUND) return A_FIRST

    return idxA < idxB ? A_FIRST : B_FIRST
  })
}

const getSrPhone = (phone = '') => phone.split('').join(' ')

class TeamPage extends React.Component {
  static propTypes = {
    baseUrl: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    getListings: PropTypes.func.isRequired,
    getMembers: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    hiddenMemberIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    listings: PropTypes.object.isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    memberIds: PropTypes.arrayOf(PropTypes.number),
    organisationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    primaryOffice: PropTypes.object,
    showLicenceCode: PropTypes.bool,
    sortedMemberIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    team: PropTypes.shape({
      address: PropTypes.object,
      description: PropTypes.string,
      email: PropTypes.string,
      error: PropTypes.bool,
      facebook: PropTypes.string,
      id: PropTypes.number,
      imageTeam: PropTypes.string,
      licenceCode: PropTypes.string,
      loaded: PropTypes.bool,
      name: PropTypes.string,
      notFound: PropTypes.bool,
      organisationId: PropTypes.number,
      parentOrganisationId: PropTypes.number,
      primaryPhone: PropTypes.string,
      testimonials: PropTypes.arrayOf(PropTypes.object),
      twitter: PropTypes.string,
      video: PropTypes.string,
    }).isRequired,
    _window: PropTypes.object,
  }

  static defaultProps = {
    memberIds: [],
    showLicenceCode: false,
  }

  static fetchData = (dispatch, params, settings) => {
    const { teamId: _teamId } = params
    const { getMembers, getOrganisations, response } = settings

    const teamId = /^\d+$/.test(_teamId) && parseInt(_teamId, 10)

    if (!teamId) {
      const error = new Error('Invalid team ID given.')
      error.code = 404
      error.__abort = true
      error.__ignore = true

      return Promise.reject(error)
    }

    return unlazyAll([
      dispatch(loadOrganisations(getOrganisations, [teamId]))
        .then((orgs) => {
          const org = orgs.entities[teamId]
          if (org) return
          if (response) response.status(404)
        }),
      dispatch(loadMembers(getMembers, [teamId], [], true)),
    ])
  }

  state = INITIAL_STATE

  componentDidMount() {
    this.load()
  }

  componentDidUpdate() {
    this.load()
  }

  load() {
    const {
      dispatch,
      getListings,
      getMembers,
      getOrganisations,
      memberIds,
      team,
    } = this.props

    dispatch(loadOrganisations(getOrganisations, [team.organisationId]))

    if (team) {
      dispatch(loadMembers(getMembers, [team.organisationId], [], true))
    }

    if (memberIds && memberIds.length > 0) {
      const searchParams = { memberId: memberIds }
      dispatch(fetchListings(getListings, searchParams, 1))
    }
  }

  handleCloseModal = () => {
    this.setState(INITIAL_STATE)
  }

  handleShowAppraisalForm = () => {
    this.setState({ showAppraisalModal: true })
  }

  handleShowEnquiryForm = () => {
    this.setState({ showEnquiryModal: true })
  }

  handleSubmitAppraisalForm = data => {
    const { fetch, team, _window } = this.props

    return submitForm(
      fetch,
      _window && _window.dataLayer,
      'Appraisal',
      `/api/contact/office/${team.id}`, // QUESTION: Use team or Org id?
      { ...data, type: 'propertyAppraisal' },
      'Unable to send appraisal request.',
      team.name,
    )
  }

  handleSubmitEnquiryForm = data => {
    const { fetch, team, _window } = this.props

    return submitForm(
      fetch,
      _window && _window.dataLayer,
      'Ask a Question',
      `/api/contact/office/${team.id}`, // QUESTION: Use team or org or agent?
      data,
      'Unable to send enquiry.',
      team.name,
    )
  }

  render() {
    const {
      props: {
        baseUrl,
        hiddenMemberIds,
        listings,
        members,
        organisationIds,
        primaryOffice,
        showLicenceCode,
        sortedMemberIds,
        team,
        team: {
          address,
          description,
          email,
          error,
          id: teamId,
          imageTeam,
          licenceCode,
          loaded,
          name,
          notFound,
          parentOrganisationId,
          primaryPhone,
          testimonials,
        },
      },
      state: { showAppraisalModal, showEnquiryModal }
    } = this

    if (notFound) return <NotFoundPage />

    if (error) return <ErrorPage error={error} />

    if (!loaded) return <Loader />

    const sortedMembers = getSortedMembers(sortedMemberIds, hiddenMemberIds, members, teamId)
    const structuredData = getStructuredData(team, baseUrl)

    return (
      <div className="TeamPage">
        <Helmet>
          <title>{name || 'Team'}</title>
          {!!description && (
            <meta
              name="description"
              content={excerptify(plainText(description), 160)}
            />
          )}
        </Helmet>
        <JsonLD>{structuredData}</JsonLD>
        <TwitterSummaryCard
          creator={team.twitter}
          title={structuredData.name}
          site={primaryOffice.twitter}
          description={structuredData.description}
          image={structuredData.image}
        />
        <OpenGraph
          type="profile"
          url={baseUrl && teamUrl(teamId, name)}
          title={structuredData.name}
          siteName={primaryOffice.fullName}
          description={structuredData.description}
          mayCropImage
          image={imageTeam && { url: imageTeam }}
          video={team.video}
          profile={{ username: team.facebook }}
        />
        <Hero
          image={imageTeam && getHeroImageData(imageTeam)}
          title={name}
        >
          <address className="Hero__address">
            <span className="Hero__contact">
              {telLink(primaryPhone, { 'data-ev-label': name, 'aria-label': getSrPhone(primaryPhone) })}
            </span>
            <span className="Hero__contact">
              <Button
                className="Hero__cta--link"
                onClick={this.handleShowEnquiryForm}
                plain
                theme="light"
                type="button"
                {...enquiryMeta}
              >
                {email}
              </Button>
            </span>
          </address>
          <Button
            className="Hero__cta--button"
            onClick={this.handleShowAppraisalForm}
            theme="dark"
            type="button"
            {...appraisalMeta}
          >
            Contact us about your property
          </Button>
        </Hero>
        {showAppraisalModal && (
          <Modal handleClose={this.handleCloseModal}>
            <ModalInner
              className="modal_form long_form"
              title="Request an Appraisal"
              subtitle={name}
            >
              <SellAppraisalForm
                sendForm={this.handleSubmitAppraisalForm}
                handleClose={this.handleCloseModal}
                submittedMessage="Thanks, we'll be in touch!"
              />
            </ModalInner>
          </Modal>
        )}
        {showEnquiryModal && (
          <Modal handleClose={this.handleCloseModal}>
            <ModalInner
              className="modal_form"
              title="Get in Touch"
              subtitle={name}
            >
              <EnquiryForm
                sendForm={this.handleSubmitEnquiryForm}
                handleClose={this.handleCloseModal}
                submittedMessage="Thanks, we'll be in touch!"
              />
            </ModalInner>
          </Modal>
        )}
        {!!description && <Introduction content={cleanMarkup(description)} />}
        {!!testimonials && <TestimonialsGridWithHeader testimonials={testimonials} />}
        <MembersGridWithHeader
          address={address}
          heading="Team Members"
          licenceCode={licenceCode}
          members={sortedMembers}
          name={name}
          organisationIds={organisationIds}
          parentOrganisationId={parentOrganisationId}
          showLicenceCode={showLicenceCode}
        />
        <section className="Listings">
          <header className="Listings__header inner_lg centered_text">
            <h2 className="Listings__heading">Listings</h2>
          </header>
          <div className="inner_lg">
            <ListingCardList
              listings={listings.items}
              loaded={listings.loaded}
            />
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { params: { teamId: _teamId } } = props
  const {
    config: {
      baseUrl,
      options: {
        agents: {
          hidden: hiddenMemberIds,
          sort: sortedMemberIds,
        },
      },
      organisationIds,
      primaryOrganisationId,
    },
    members: _members,
    orgs,
  } = state

  const teamId = parseInt(_teamId, 10)
  const team = getOrganisation(orgs, teamId)

  const members = getOrganisationMembers(_members, [teamId])
  const memberIds = members.entities.map(member => member.id)

  const listingParams = {
    memberId: memberIds,
  }

  return {
    baseUrl,
    hiddenMemberIds,
    listings: getSearch(state.listings, listingParams, 1),
    memberIds,
    members: members.entities,
    organisationIds,
    primaryOffice: getOrganisation(orgs, primaryOrganisationId),
    sortedMemberIds,
    team,
  }
}

export default (
  connect(mapStateToProps)(
    withContext(
      'fetch',
      'getListings',
      'getMembers',
      'getOrganisations',
      '_window',
    )(TeamPage)
  )
)
