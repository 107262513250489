// @flow

import React from 'react'
import Select from 'react-select'

type Props = {
  name: string,
  className?: string,
  value: string,
  options: Array<{ value: string, label: string, disabled?: boolean }>,
  onChange: Object => void,
};

const MultiSelect = ({ name, value, options, onChange, className }: Props) => (
  <Select
    name={name}
    className={className}
    value={value}
    options={options}
    onChange={val => onChange({ target: { value: val } })}
    multi
    joinValues
    simpleValue
    delimiter="|"
  />
)

export default MultiSelect
