// @flow
import React, { useEffect, useState } from 'react'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'
import { depressify } from '@raywhite/helpers-utils/lib/helpers/sanitization'
import { convertToMb, getExtension } from '@raywhite/data-utils/lib/data/listing/file'
import type { File, Section } from '../../snapperClient'

const registerMeta = eventMeta({
  category: 'Sign In',
  action: 'Register',
})

const downloadMeta = eventMeta({
  category: 'Listing Attachment',
  action: 'Download',
})

type ListingSectionFilesProps = {
  authFailed: boolean,
  authorised: boolean,
  categories: Array<File>,
  toggleSignInModal: () => void,
  listingId: number
}

const ListingSectionFiles = ({
  authFailed,
  authorised,
  categories = [],
  toggleSignInModal,
  listingId,
}: ListingSectionFilesProps) => {
  // Track client side display to force rerender and correct auth failure messaging,
  // otherwise fails because SSR != client side render.
  const [isClient, setIsClient] = useState(false)
  useEffect(() => setIsClient(true), [])

  if (!categories.length) return null

  // Default item shown for all non-downloadable files.
  const openSignIn = (
    <span
      className="anchor"
      onClick={toggleSignInModal}
      {...registerMeta}
    >
      Download File
    </span>
  )

  return (
    <div className="pdp_downloads_list_wrap">
      {categories.map(({ name, isPublic, reportable = true, files }) => (
        <div className="pdp_downloads_list" key={name}>
          <h4 className="mini">{name}</h4>
          {authFailed && isClient && (
            <div className="tbl auth_failure">Sorry, we couldn't sign you in - please try again.</div>
          )}
          <div className="tbl">
            {files.map(file => {
              const imMeta = reportable
                ? {
                  'data-ev-file-id': file.id,
                  'data-ev-file-description': file.description,
                  'data-ev-listing-id': listingId,
                }
                : {}
              const canDownload = !!(authorised || isPublic)

              const description = canDownload
                ? (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    {...downloadMeta}
                    {...imMeta}
                  >
                    {file.description}
                  </a>
                )
                : file.description
              const download = canDownload
                ? (
                  <a
                    className="anchor active"
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    {...downloadMeta}
                    {...imMeta}
                  >
                    Download File
                  </a>
                )
                : openSignIn

              return (
                <div className="tbr" key={file.id}>
                  <div className="tbc">
                    <p>{description}</p>
                  </div>
                  <div className="tbc">
                    <p className="muted uppercase">
                      {getExtension(file.type)}
                      {!!file.size && (convertToMb(file.size))}
                    </p>
                  </div>
                  <div className="tbc">
                    {download}
                  </div>
                </div>
              )
            }
            )}
          </div>
        </div>
      )
      )}
    </div>
  )
}

type Props = Section & { authFailed: boolean, authorised: boolean, listingId: number };

const ListingSection = (props: Props) => {
  const {
    authFailed,
    categories,
    content,
    name,
    slug,
    listingId,
    authorised,
    toggleSignInModal,
  } = props

  if (!categories) return null

  return (
    <div
      className="pdp_commercial_info"
      id={slug}
    >
      <h3 className="centered_text">{name}</h3>
      <div
        className="pdp_commercial_info_content"
        dangerouslySetInnerHTML={depressify(content)}
      />
      {!!categories.length && (
        <ListingSectionFiles
          authFailed={authFailed}
          authorised={authorised}
          categories={categories}
          toggleSignInModal={toggleSignInModal}
          listingId={listingId}
        />
      )}
    </div>
  )
}

export default ListingSection
