const liveAdmins = (doc, config) => {
  const {
    primaryDomain,
    options: {
      liveAdminsId
    },
  } = config

  if (!liveAdminsId) return

  // Inject target div
  const div = document.createElement('div')
  div.id = 'live_chat_status'
  document.body.append(div)

  // If the live admins ID is a domain, use it to override the primary domain
  const host = /^[a-z]+\.(co\.nz|com\.au|com)$/.test(liveAdminsId)
    ? liveAdminsId
    : primaryDomain

  // Inject script tag
  const script = document.createElement('script')
  script.async = true
  script.src = `https://cdn1.thelivechatsoftware.com/assets/liveadmins/${host}/chatloader.min.js`
  document.head.append(script)
}

export default liveAdmins
