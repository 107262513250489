import React from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'

export function getDisplayName(component) {
  return component.displayName || component.name || 'Component'
}

/**
 * Creates a provider component which provides the indicated properties as
 * context to children rendered inside it.
 *
 * @param {String} componentName The display name for the component.
 * @param {Array} props A list of properties to provide, each being an object
 *                      with a string name and a React.PropType type property.
 *
 * @return {React.Component}
 */
export function createContextProvider(componentName, props) {
  class Provider extends React.Component { // eslint-disable-line react/no-multi-comp
    getChildContext() {
      return props.reduce((result, { name }) => {
        result[name] = this.props[name] // eslint-disable-line no-param-reassign
        return result
      }, {})
    }

    render() {
      return React.Children.only(this.props.children)
    }
  }

  Provider.displayName = componentName
  Provider.childContextTypes = props.reduce((result, { name, type }) => {
    result[name] = type // eslint-disable-line no-param-reassign
    return result
  }, {})
  Provider.propTypes = {
    ...Provider.childContextTypes,
    children: PropTypes.node.isRequired,
  }

  return Provider
}

/**
 * Creates an HOC which extracts the indicated properties from the context and
 * provides them as properties to its children.
 *
 * @param {String} componentName The display name for the component.
 * @param {Array} props A list of properties to provide, each being an object
 *                      with a string name and a React.PropType type property.
 *
 * @return {React.Component}
 */
export function createContextConsumer(componentName, props) {
  return Wrapee => {
    class Wrapper extends React.Component { // eslint-disable-line react/no-multi-comp
      render() {
        // Note: deliberately allows passed in props to override context props
        const withProps = props.reduce((result, { name }) => {
          // eslint-disable-next-line no-param-reassign
          result[name] = this.props[name] || this.context[name]
          return result
        }, {})

        return <Wrapee {...this.props} {...withProps} />
      }
    }

    Wrapper.contextTypes = props.reduce((result, { name, type }) => {
      result[name] = type // eslint-disable-line no-param-reassign
      return result
    }, {})
    Wrapper.displayName = `${componentName}(${getDisplayName(Wrapee)})`

    return hoistStatics(Wrapper, Wrapee)
  }
}

/**
 * Wrapper to bind custom props to a component via a decorator.
 *
 * Generally properties would be specified directly, however this isn't possible
 * with components used via React Router.  This can be used to decorate a component
 * and pass down the props directly.
 *
 * @param {String} componentName The display name for the component.
 * @param {Object} props A set of properties to apply to the decorated component.
 *
 * @return {React.Component}
 */
export function withStaticProps(componentName, props) {
  return Wrapee => {
    class Wrapper extends React.Component { // eslint-disable-line react/no-multi-comp
      render() {
        return <Wrapee {...this.props} {...props} />
      }
    }

    Wrapper.displayName = `${componentName}(${getDisplayName(Wrapee)})`

    return hoistStatics(Wrapper, Wrapee)
  }
}
