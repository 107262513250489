import { makeMappedReducer } from '../utils'

const AUTH_FAILED = 'rw-office/app/AUTH_FAILED'
const SHOW_MODAL = 'rw-office/app/SHOW_MODAL'
const RENDER_MAP = 'rw-office/app/RENDER_MAP'
const ENABLE_EXPERIMENT = 'rw-office/app/ENABLE_EXPERIMENT'

const initialState = {
  showingModal: undefined,
  renderMap: false,
  authFailed: false,
  experiments: {
    appraisalFormVariant: 'default',
    pdpAgentPlacement: 'default',
  },
}

function reduceAuthFailed(state, { authFailed }) {
  return state.authFailed === authFailed
    ? state
    : { ...state, authFailed }
}

function reduceShowModal(state, { showingModal }) {
  return state.showingModal === showingModal
    ? state
    : { ...state, showingModal }
}

function reduceRenderMap(state) {
  return state.renderMap
    ? state
    : { ...state, renderMap: true }
}

function reduceEnableExperiment(state, { experiment, variation }) {
  if (state.experiments[experiment] === variation) return state
  return {
    ...state,
    experiments: {
      ...state.experiments,
      [experiment]: variation,
    },
  }
}

const reducer = makeMappedReducer({
  [AUTH_FAILED]: reduceAuthFailed,
  [SHOW_MODAL]: reduceShowModal,
  [RENDER_MAP]: reduceRenderMap,
  [ENABLE_EXPERIMENT]: reduceEnableExperiment,
}, initialState)
export default reducer

export function loadAuthFailed(cookie) {
  const value = cookie
    ?.split('; ')
    .find(row => row.startsWith('AUTH_FAILED='))
    ?.split('=')[1]

  return {
    type: AUTH_FAILED,
    authFailed: value === 'true',
  }
}

export function setShowingModal(showingModal) {
  return {
    type: SHOW_MODAL,
    showingModal,
  }
}

export function getShowingModal(state) {
  return state.showingModal
}

export function renderMap() {
  return { type: RENDER_MAP }
}

export function getRenderMap(state) {
  return state.renderMap
}

export function enableExperiment(experiment, variation = true) {
  return { type: ENABLE_EXPERIMENT, experiment, variation }
}
