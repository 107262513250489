/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar, footer)
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as portals from 'react-reverse-portal'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

import AppHeader from './container/ConnectedAppHeader.jsx'
import Footer from './container/Footer.jsx'
import withOrgs from './hocs/withOrgs'
import withContext from './hocs/withContext'
import EnquiryForm from './container/EnquiryForm.jsx'
import UpdateUserForm from './container/UpdateUserForm.jsx'
import SellAppraisalForm from './container/contentforms/SellAppraisalForm.jsx'
import RentAppraisalForm from './container/contentforms/RentAppraisalForm.jsx'
import BuyRentEnquiryForm from './container/contentforms/EnquiryForm.jsx'
import MaintenanceForm from './container/contentforms/MaintenanceForm.jsx'
import VacatingForm from './container/contentforms/VacatingForm.jsx'
import NzRentalApplicationForm from './container/contentforms/NzRentalApplicationForm.jsx'
import Modal from './presentational/Modal.jsx'
import ModalInner from './presentational/ModalInner.jsx'
import siteFocusConfig from '../siteFocusConfig'
import {
  setShowingModal,
  getShowingModal,
  getRenderMap,
} from '../redux/modules/app'
import { getUserData, patchUserData } from '../redux/modules/user'
import Map from './presentational/Mapbox/Map.jsx'
import { MapNodeContext } from './presentational/Mapbox/SingletonMap.jsx'
import {
  clusterIconUri,
  singleIconUri,
  seenIconUri,
  activeIconUri,
} from '../constants'

// Urrgggggh
const inferHeaderClass = (children) => {
  if (typeof children !== 'object') return undefined
  const {
    props: {
      location: {
        pathname,
      } = {},
    } = {},
  } = children

  return pathname === '/' ? 'home' : undefined
}

class App extends React.Component {
  static propTypes = {
    primaryOrg: PropTypes.object.isRequired,
    orgs: PropTypes.object.isRequired,
    displayOrgs: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired,
    showFooter: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    renderMap: PropTypes.bool.isRequired,
    _window: PropTypes.object,
    showingModal: PropTypes.string,
    hideModal: PropTypes.func.isRequired,
    showBuyRentEnquiryModal: PropTypes.func.isRequired,
    showMaintenanceModal: PropTypes.func.isRequired,
    showVacatingModal: PropTypes.func.isRequired,
    showAppraisalModal: PropTypes.func.isRequired,
    showRentalAppraisalModal: PropTypes.func.isRequired,
    showNzRentalApplicationModal: PropTypes.func.isRequired,
    showEnquiryModal: PropTypes.func.isRequired,
    showUpdateUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    siteFocus: PropTypes.string.isRequired,
    siteTitle: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    showFooter: true,
  }

  constructor(props) {
    super(props)

    this.mapPortalNode = props._window && portals.createPortalNode()

    this.sendEnquiryForm = this.sendEnquiryForm.bind(this)
    this.sendAppraisalForm = this.sendAppraisalForm.bind(this)
    this.sendRentalAppraisalForm = this.sendRentalAppraisalForm.bind(this)
    this.sendBuyRentEnquiryForm = this.sendBuyRentEnquiryForm.bind(this)
    this.sendMaintenanceForm = this.sendMaintenanceForm.bind(this)
    this.sendVacatingForm = this.sendVacatingForm.bind(this)
    this.sendNzRentalApplicationForm = this.sendNzRentalApplicationForm.bind(this)
  }

  lockBody = () => {
    const html = this.props._window && this.props._window.document.documentElement
    if (!html) return
    html.classList.add('showing_modal')
  }

  unlockBody = () => {
    const html = this.props._window && this.props._window.document.documentElement
    if (!html) return
    html.classList.remove('showing_modal')
  }

  sendUpdateUserForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Update User',
    '/api/auth/user',
    data,
    'Unable to update details.',
    'Site',
    'PATCH',
  ).then(user => this.props.updateUser(user))

  sendEnquiryForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Ask Question',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    data,
    'Unable to send enquiry.',
    'Site',
  )

  sendAppraisalForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Appraisal',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'propertyAppraisal' },
    'Unable to send appraisal request.',
    'Site',
  )

  sendRentalAppraisalForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Rental Appraisal',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'rentalAppraisal' },
    'Unable to send appraisal request.',
    'Site',
  )

  sendBuyRentEnquiryForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Buy/Rent Enquiry',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'buyrentenquiry', subject: 'Buy/Rent Enquiry' },
    'Unable to send enquiry.',
    'Site',
  )

  sendMaintenanceForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Maintenance Request',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'maintenance', subject: 'Maintenance Request' },
    'Unable to send request.',
    'Site',
  )

  sendVacatingForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Notice to Vacate',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'vacating', subject: 'Notice to Vacate' },
    'Unable to send notice.',
    'Site',
  )

  sendNzRentalApplicationForm = data => submitForm(
    this.props.fetch,
    this.props._window && this.props._window.dataLayer,
    'Rental Application',
    `/api/contact/office/${this.props.primaryOrg.organisationId}`,
    { ...data, type: 'nzRentApplication', subject: 'Rental Application' },
    'Unable to send application.',
    'Site',
  )

  render() {
    const {
      primaryOrg,
      primaryOrg: { countryCode },
      displayOrgs,
      orgs: { loaded: orgsLoaded },
      showFooter,
      siteTitle,
      user,
      renderMap,
      _window,
    } = this.props
    const { appraisalLabel } = siteFocusConfig[this.props.siteFocus] || {}

    const name = siteTitle || primaryOrg.fullName || 'Ray White Office'
    const headerClass = inferHeaderClass(this.props.children)
    const showAppraisalModal = this.props.siteFocus === 'propertyManagement'
      ? this.props.showRentalAppraisalModal
      : this.props.showAppraisalModal

    const serviceForms = {
      buy: [{
        label: 'Enquire about Properties',
        onClick: this.props.showBuyRentEnquiryModal,
      }],
      rent: [
        { label: 'Enquire about Rentals', onClick: this.props.showBuyRentEnquiryModal },
        { label: 'Request Maintenance', onClick: this.props.showMaintenanceModal },
        { label: 'Notice to Vacate', onClick: this.props.showVacatingModal },
      ],
      sell: [{
        label: 'Request an Appraisal',
        onClick: this.props.showAppraisalModal,
      }],
    }
    if (countryCode === 'NZ') {
      serviceForms.rent.push({
        label: 'Rental Application',
        onClick: this.props.showNzRentalApplicationModal,
      })
    }

    return (
      <div className="wrapper">
        <Helmet
          defaultTitle={name}
          titleTemplate={`%s - ${name}`}
        >
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta property="og:site_name" content={name} />
        </Helmet>
        <AppHeader
          lockBody={this.lockBody}
          unlockBody={this.unlockBody}
          homeLinkLabel={name}
          headerClass={headerClass}
          toggleEnquiryModal={this.props.showEnquiryModal}
          showAppraisalModal={showAppraisalModal}
          siteFocus={this.props.siteFocus}
        />
        {!!renderMap && !!_window && (
          <portals.InPortal node={this.mapPortalNode}>
            <Map
              identifier="default"
              mapId="streets-v10"
              zoom={15}
              pitch={0}
              bearing={0}
              center={[0, 0]}
              onStyleLoad={(map) => {
                // Register SVG images for use as Mapbox symbol layer images
                [
                  ['listing', singleIconUri, 201, 201],
                  ['listingSeen', seenIconUri, 201, 201],
                  ['listingActive', activeIconUri, 201, 201],
                  ['listingCluster', clusterIconUri, 201, 201],
                ].forEach(([label, src, height, width]) => {
                  const image = new Image(width, height)
                  image.onload = () => map.addImage(label, image)
                  image.src = src
                })
              }}
            />
          </portals.InPortal>
        )}
        <MapNodeContext.Provider value={this.mapPortalNode}>
          {this.props.children}
        </MapNodeContext.Provider>
        {this.props.showingModal === 'enquiry' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form"
              title="Contact Us"
              subtitle={name}
            >
              <EnquiryForm
                sendForm={this.sendEnquiryForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'updateUser' && !!user.userId && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form"
              subtitle={user.displayName}
              title="Update Your Details"
            >
              <UpdateUserForm
                sendForm={this.sendUpdateUserForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'appraisal' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="Request an Appraisal"
              subtitle={name}
            >
              <SellAppraisalForm
                sendForm={this.sendAppraisalForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'rentalAppraisal' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="Request a Rental Appraisal"
              subtitle={name}
            >
              <RentAppraisalForm
                sendForm={this.sendRentalAppraisalForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'buyRentEnquiry' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="What Are You Looking For?"
              subtitle={primaryOrg.fullName}
            >
              <BuyRentEnquiryForm
                sendForm={this.sendBuyRentEnquiryForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'nzRentalApplication' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="Rental Application"
              subtitle={primaryOrg.fullName}
            >
              <NzRentalApplicationForm
                sendForm={this.sendNzRentalApplicationForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'maintenance' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="Request Maintenance"
              subtitle={primaryOrg.fullName}
            >
              <MaintenanceForm
                sendForm={this.sendMaintenanceForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {this.props.showingModal === 'vacating' && (
          <Modal handleClose={this.props.hideModal}>
            <ModalInner
              className="modal_form long_form"
              title="Notice to Vacate"
              subtitle={primaryOrg.fullName}
            >
              <VacatingForm
                sendForm={this.sendVacatingForm}
                handleClose={this.props.hideModal}
                showCloseButton
              />
            </ModalInner>
          </Modal>
        )}
        {showFooter && (
          <Footer
            appraisalLabel={appraisalLabel}
            orgsLoaded={orgsLoaded}
            orgs={displayOrgs}
            primaryOrg={primaryOrg}
            toggleAppraisalModal={showAppraisalModal}
            toggleEnquiryModal={this.props.showEnquiryModal}
            serviceForms={serviceForms}
          />
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => dispatch(setShowingModal(undefined)),
    showBuyRentEnquiryModal: () => dispatch(setShowingModal('buyRentEnquiry')),
    showEnquiryModal: () => dispatch(setShowingModal('enquiry')),
    showAppraisalModal: () => dispatch(setShowingModal('appraisal')),
    showRentalAppraisalModal: () => dispatch(setShowingModal('rentalAppraisal')),
    showMaintenanceModal: () => dispatch(setShowingModal('maintenance')),
    showVacatingModal: () => dispatch(setShowingModal('vacating')),
    showNzRentalApplicationModal: () => dispatch(setShowingModal('nzRentalApplication')),
    showUpdateUser: () => dispatch(setShowingModal('updateUser')),
    updateUser: data => dispatch(patchUserData(data)),
  }
}

function mapStateToProps(state) {
  return {
    siteTitle: state.config.options.siteTitle,
    showingModal: getShowingModal(state.app),
    user: getUserData(state.user),
    renderMap: getRenderMap(state.app),
    siteFocus: state.config.options.siteFocus,
  }
}

const ConnectedApp = compose(
  withContext('_window', 'fetch'),
  withOrgs,
  connect(mapStateToProps, mapDispatchToProps),
)(App)
export default ConnectedApp
