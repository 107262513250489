import React from 'react'

import PropTypes from 'prop-types'

const TestimonialItem = (props) => {
  const {
    detail,
    person,
  } = props.testimonial

  return (
    <li>
      <blockquote>
        <p>
          {detail}
        </p>
        {person && (
          <cite>
            {person}
            {' '}
            –
            {' '}
          </cite>
        )}
      </blockquote>
    </li>
  )
}
TestimonialItem.propTypes = {
  testimonial: PropTypes.object.isRequired,
}

const TestimonialList = (props) => (
  <ul className="reviews_list">
    {props.testimonials.filter(t => t.detail).map((testimonial, i) => (
      <TestimonialItem
        key={testimonial.person || i}
        testimonial={testimonial}
      />
    ))}
  </ul>
)

TestimonialList.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TestimonialList
