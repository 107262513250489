import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import {
  getSyndicatedNews,
  loadSyndicatedNews,
  getContentType,
  loadContentForType,
} from '../../redux/modules/content'
import ContentArchive from '../presentational/ContentArchive.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import withContentSearch from '../hocs/withContentSearch'
import withContext from '../hocs/withContext'

const merge = (standard, syndicated = []) => {
  if (!syndicated || !syndicated.length) return standard
  return [...standard, ...syndicated].sort((a, b) => (
    new Date(b.date).getTime() - new Date(a.date).getTime()
  ))
}

class NewsContentArchive extends React.Component {
  componentDidUpdate() {
    const {
      contentSearch,
      dispatch,
      integrateSyndicatedNews,
      getSyndicatedNews: getNews,
    } = this.props
    const { search: { loaded, total, error } } = contentSearch

    if (integrateSyndicatedNews || (loaded && !total && !error)) {
      // Load syndicated news if there is no site news
      dispatch(loadSyndicatedNews(getNews))
    }
  }

  renderSyndicatedNews() {
    const { loaded, notFound, loading, error, entities } = this.props.syndicatedNews

    if (notFound) return <NotFoundPage />
    if (error) return <ErrorPage error={error} />

    const { primaryOffice, baseUrl } = this.props

    return (
      <ContentArchive
        entities={entities}
        typeName="News"
        loading={loading}
        total={entities.length}
        loaded={loaded}
        cardOptions={{ external: true, showDate: true }}
        url={`${baseUrl}/news`}
        primaryOffice={primaryOffice}
      />
    )
  }

  render() {
    const {
      contentSearch: {
        search: { loaded, loading, total, entities: newsEntities, error },
        type: { notFound },
      },
      integrateSyndicatedNews,
      syndicatedNews,
    } = this.props

    // If successful loading shows no items, fall back to alternate
    if (loaded && !total && !error) return this.renderSyndicatedNews()

    // If still loading or we have results, load a generic archive
    if (error) return <ErrorPage error={error} />

    if (notFound) return <NotFoundPage />

    const { primaryOffice, baseUrl } = this.props

    const entities = integrateSyndicatedNews
      ? merge(newsEntities, syndicatedNews.entities)
      : newsEntities

    return (
      <ContentArchive
        {...this.props}
        cardOptions={{
          // loaded/loading/error etc only apply to standard loaded items
          external: item => item.loaded === undefined,
          showDate: true,
        }}
        entities={entities}
        typeName="News"
        loading={loading}
        total={(
          total
          + (integrateSyndicatedNews && syndicatedNews.loaded ? syndicatedNews.entities.length : 0)
        )}
        loaded={loaded && (!integrateSyndicatedNews || syndicatedNews.loaded)}
        load={this.props.contentSearchLoad}
        url={`${baseUrl}/news`}
        primaryOffice={primaryOffice}
      />
    )
  }
}

NewsContentArchive.propTypes = {
  contentSearch: PropTypes.object.isRequired,
  contentSearchLoad: PropTypes.func.isRequired,
  integrateSyndicatedNews: PropTypes.bool.isRequired,
  syndicatedNews: PropTypes.object,
  baseUrl: PropTypes.string.isRequired,
  primaryOrganisationId: PropTypes.number.isRequired,
  primaryOffice: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  getSyndicatedNews: PropTypes.func.isRequired,
}

NewsContentArchive.fetchData = (dispatch, params, settings) => {
  const {
    getContentForType: getContent,
    getSyndicatedNews: getNews,
    options: { integrateSyndicatedNews },
    primaryOrganisationId,
    getOrganisations,
  } = settings

  return unlazyAll([
    dispatch(loadContentForType(getContent, 'news', 1, 10)),
    dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])),
    integrateSyndicatedNews && dispatch(loadSyndicatedNews(getNews)),
  ]).then(([content]) => (
    // Load syndicated news if no local news present
    !content.entities.length && !integrateSyndicatedNews && dispatch(loadSyndicatedNews(getNews))
  ))
}

function mapStateToProps(state) {
  return {
    syndicatedNews: getSyndicatedNews(state.content),
    contentSearchType: 'news',
    // Used to force a refresh after types are loaded
    type: getContentType(state.content, 'news'),
    baseUrl: state.config.baseUrl,
    primaryOrganisationId: state.config.primaryOrganisationId,
    primaryOffice: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    integrateSyndicatedNews: state.config.options.integrateSyndicatedNews,
  }
}

export default compose(
  withContext('getContentForType', 'getOrganisations', 'getSyndicatedNews'),
  connect(mapStateToProps),
  withContentSearch,
)(NewsContentArchive)
