import memoize from 'memoizee'
import {
  filterOutliers,
} from '@raywhite/media-utils/lib/media/geo'

const NullComponent = () => null

/* eslint-disable global-require */
const Lib = process.env.WEBPACKED
  ? require('react-mapbox-gl')
  : NullComponent
const mapboxgl = process.env.WEBPACKED
  ? require('mapbox-gl')
  : undefined
/* eslint-enable global-require */
export const mapboxFactory = Lib.default || NullComponent
export const MapContext = Lib.MapContext || { Consumer: NullComponent }

export const Source = Lib.Source || NullComponent
export const Layer = Lib.Layer || NullComponent
export const Feature = Lib.Feature || NullComponent
export const Marker = Lib.Marker || NullComponent
export const Cluster = Lib.Cluster || NullComponent
export const Popup = Lib.Popup || NullComponent
export const ZoomControl = Lib.ZoomControl || NullComponent
export const RotationControl = Lib.RotationControl || NullComponent

export const getBounds = memoize((points) => {
  if (!points.length) return undefined
  if (!mapboxgl || !mapboxgl.LngLatBounds) return undefined

  const boundPoints = filterOutliers(points, { devs: 1.5 })
  const firstPoint = [boundPoints[0].lng, boundPoints[0].lat]

  return boundPoints
    .slice(1).reduce((acc, point) => {
      acc.extend([point.lng, point.lat])
      return acc
    }, new mapboxgl.LngLatBounds(firstPoint, firstPoint))
    .toArray()
}, {
  max: 10,
})
