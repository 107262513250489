/**
 * Provides an adaptor layer for error handling consistently between browser
 * and node.
 */
import winston from 'winston'
import fetch from 'isomorphic-fetch'
import { Buffer } from 'buffer'
import { MAX_JSON_BODY_SIZE } from './constants'

/**
 * Available logging levels, based more or less on the console methods.
 * @var {Object}
 */
const levels = Object.freeze({
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
})

export function plainError(error) {
  if (!(error instanceof Error)) return error

  return Object.getOwnPropertyNames(error).reduce((res, field) => {
    res[field] = error[field] // eslint-disable-line no-param-reassign
    return res
  }, {})
}


/**
 * Wraps a handler with a filter which prevents handling errors lower than the
 * configured threshhold.
 *
 * @param {Logger} handler The error handler to wrap.
 * @param {string} level The minimum level of message to log.
 *
 * @return {Object} An object which exposes the appropriate methods but ignores
 *                  requests to log a lower level than configured.
 */
export function levelFilter(handler, level) {
  const logger = {}
  logger.handler = handler

  Object.keys(levels).forEach(name => {
    logger[name] = (...args) => {
      if (levels[name] <= levels[level]) {
        handler[name].apply(handler, args)
      }
    }
  })

  return logger
}

/**
 * Provides a logger which POSTs logs to an HTTP/HTTPS endpoint.
 *
 * This is used to send client side errors back to the server for logging so
 * that they can be tracked.
 */
class PostLogger {
  endpoint = '/api/errors'

  fetch

  constructor(options) {
    this.endpoint = options.endpoint || this.endpoint
    this.fetch = options.fetch || fetch
  }

  log(level, msg, meta = {}) {
    const { error } = meta
    const data = {
      level,
      message: msg,
      meta: {
        ...meta,
        error: error instanceof Error ? plainError(error) : error,
      },
    }
    const minimal = {
      ...data,
      meta: {
        error: error instanceof Error
          ? {
            message: error.message || error.description,
            name: error.name,
            stack: error.stack,
            fileName: error.fileName,
            lineNumber: error.lineNumber,
            columnNumber: error.colunNumber,
          }
          : !!error,
      },
    }

    const fullBody = JSON.stringify(data)

    this.fetch(this.endpoint, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: Buffer.from(fullBody).length > MAX_JSON_BODY_SIZE
        ? JSON.stringify(minimal)
        : fullBody,
    })
      // TODO: is there anything meaningful to be done here?
      .catch(() => {})
  }

  debug(msg, meta = {}) {
    this.log('debug', msg, meta)
  }

  info(msg, meta = {}) {
    this.log('info', msg, meta)
  }

  warn(msg, meta = {}) {
    this.log('warn', msg, meta)
  }

  error(msg, meta = {}) {
    this.log('error', msg, meta)
  }
}

/**
 * Simple error adapter which proxies the methods on to a contained logger,
 * while providing points for children to extend this behaviour by overriding
 * these methods.
 */
class SimpleLogAdapter {
  _logger

  constructor(logger) {
    this._logger = logger
  }

  log(level, msg, meta = {}) {
    this._logger.log(level, msg, meta)
  }

  debug(msg, meta = {}) {
    this._logger.debug(msg, meta)
  }

  info(msg, meta = {}) {
    this._logger.info(msg, meta)
  }

  warn(msg, meta = {}) {
    this._logger.warn(msg, meta)
  }

  error(msg, meta = {}) {
    this._logger.error(msg, meta)
  }
}

/**
 * An adapter to log messages to the console.
 */
export class ConsoleLogAdapter extends SimpleLogAdapter {
  static factory(config) {
    return new this(config.console || console)
  }

  debug(msg, meta = {}) {
    this._logger.log(msg, meta)
  }
}

/**
 * An adapter to log messages to winston (server side only).  Winston itself
 * supports a large number of "transports", see the winston documentation for
 * examples.
 */
export class WinstonLogAdapter extends SimpleLogAdapter {
  static factory(config) {
    const logger = new (winston.Logger)({
      transports: config.transports || [],
    })
    return new WinstonLogAdapter(logger)
  }

  error(msg, meta = {}) {
    const error = meta.error instanceof Error
      ? {
        ___winston: winston.exception.getAllInfo(meta.error),
        ...plainError(meta.error),
      }
      : meta.error

    super.error(msg, { ...meta, error })
  }
}

/**
 * The core error handler.  An instance can be configured with multiple adapters
 * which will then all be logged to.
 *
 * It can also be configured with a default Logger instance which allows
 * logging using the static class methods, e.g. Logger.log(message);
 */
export class Logger {
  /**
   * The default adapter used when static methods are called.
   *
   * @property {Logger}
   */
  static _default = Logger.factory()

  _adapters = []

  constructor(adapters) {
    if (!adapters) {
      throw new Error('Adapters must be provided to the Logger.')
    }
    this._adapters = adapters
  }

  // TODO: implement factory to create an appropriate error handler
  static factory(config = { type: 'console' }) {
    const configs = Array.isArray(config) ? config : [config]

    const adapters = configs.map(_config => {
      let adapter

      switch (_config.type) {
        case 'winston':
          adapter = WinstonLogAdapter.factory(_config)
          break
        case 'post':
          adapter = new SimpleLogAdapter(new PostLogger(_config))
          break
        case 'console':
          adapter = ConsoleLogAdapter.factory(_config)
          break
        default:
          throw new Error(`Unknown error handler type "${config.type}"`)
      }

      return levelFilter(adapter, _config.level || 'warn')
    })

    return new Logger(adapters)
  }

  get adapters() {
    return this._adapters
  }

  _call(method, message, meta = {}) {
    this.adapters.map(adapter => adapter[method].call(adapter, message, meta))
  }

  log(level, msg, meta = {}) {
    this._call(level, msg, meta)
  }

  debug(msg, meta = {}) {
    this._call('debug', msg, meta)
  }

  info(msg, meta = {}) {
    this._call('info', msg, meta)
  }

  warn(msg, meta = {}) {
    this._call('warn', msg, meta)
  }

  error(msg, meta = {}) {
    this._call('error', msg, meta)
  }

  static set default(handler) {
    if (!handler) {
      throw new Error('Invalid handler passed to set default handler.')
    }
    this._default = handler
  }

  static get default() {
    return this._default
  }

  static log(level, msg, meta = {}) {
    this.default.log(level, msg, meta)
  }

  static debug(msg, meta = {}) {
    this.default.debug(msg, meta)
  }

  static info(msg, meta = {}) {
    this.default.info(msg, meta)
  }

  static warn(msg, meta = {}) {
    this.default.warn(msg, meta)
  }

  static error(msg, meta = {}) {
    this.default.error(msg, meta)
  }
}

/**
 * Attach the given error handler to the browser, handling otherwise uncaught
 * exceptions as well as unhandled promise rejections.
 *
 * @param {Logger} handler The error handler to use.
 * @param {Object} window The browser window object.
 *
 * @return {undefined} Nothing
 */
export function attachToBrowser(handler, win) {
  /* eslint-disable no-param-reassign */
  win.onerror = function onLogger(message, source, lineno, colno, error) {
    // Disregard suspect error messages entirely; unfortunately error is often missing
    if (!(message && source && lineno && colno)) return

    // "global code" = not in our code, extension or someting
    if (error && /^global code@/m.test(error.stack || '')) return

    // "Script error." is from third party scripts w/out CORS headers.
    // Lack of a source also indicates something we probably don't care about
    const level = (!source || /^Script error\.?$/.test(message)) ? 'warn' : 'error'
    handler.log(
      level,
      `Uncaught client exception: ${message}`,
      { source, lineno, colno, error: plainError(error) || 'MISSING' },
    )
  }
  win.onunhandledrejection = function onUnhandledRejectionHandler(event) {
    const { reason, promise } = event
    handler.warn(
      `Unhandled client promise rejection: ${reason.message || 'No message'}`,
      { reason: plainError(reason), promise, event }
    )
  }
  /* eslint-enable no-param-reassign */
}

/**
 * Attach the given error handler to node, handling otherwise uncaught
 * exceptions as well as unhandled promise rejections.
 *
 * @param {Logger} handler The error handler to use.
 * @param {Object} proc The node process object.
 *
 * @return {undefined} Nothing
 */
export function attachToNode(handler, proc) {
  proc.on('uncaughtException', (error) => {
    handler.error(`Uncaught server exception: ${error.message}`, { error })
    // Process must be monitored and restarted in this case
    // TODO: proper fix for this, see https://github.com/winstonsrc/winston/issues/228
    setTimeout(() => proc.exit(-1), 1000)
  })
  // Note: not supported on 0.12.x
  proc.on('unhandledRejection', (reason, promise) => {
    handler.warn(
      `Unhandled server promise rejection: ${reason.message || 'No message'}`,
      { reason: plainError(reason), promise }
    )
  })
}
