import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes, reset } from 'redux-form'
import { types } from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  warnForm,
  formSchemaValidator,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSection,
  FormSubmit,
  labelifyFormField,
} from '../presentational/formComponents'
import { getUserData } from '../../redux/modules/user'

const FORM_NAME = 'updateUser'
const schema = schemafy(
  schemaItem('phoneNumber', 'Your Phone Number', types.phone.required()),
)
const fields = schemaFields(schema)
const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(fields, schemaRules(schema))

const initialState = {
  submitted: false,
}

class UpdateUserForm extends Component {
  static propTypes = {
    ...propTypes,
    sendForm: PropTypes.func.isRequired,
    sendMeta: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sendMeta: {},
  }

  constructor() {
    super()

    this.state = { ...initialState }
  }

  componentWillUnmount() {
    this.setState(initialState)
  }

  render() {
    const {
      fields: { phoneNumber },
      submitting,
      sendForm,
      handleSubmit,
      error,
      dispatch,
      sendMeta,
      handleClose,
    } = this.props

    const onSubmit = data => sendForm({ ...data }, sendMeta)
      .then(() => {
        this.setState({ submitted: true })
        return dispatch(reset(FORM_NAME))
      })
    if (this.state.submitted) {
      return (
        <div className="form_fields_section">
          <div className="inner centered_text">
            <p>Thanks, we've updated your details.</p>
            <p>
              <button
                type="button"
                className="btn large"
                onClick={handleClose}
              >
                Close
              </button>
            </p>
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {warnForm(error)}
        {!phoneNumber.value && (
          <div className="form_notice info">
            Please provide a phone number.
          </div>
        )}
        <FormSection>
          <FormField
            field={phoneNumber}
            placeholder="e.g. 04xx xxx xxx"
            className="one_half"
            required
          />
        </FormSection>
        <FormSubmit submitting={submitting} label="Update Details" />
      </form>
    )
  }
}

const ReduxUpdateUserForm = reduxForm(
  {
    form: FORM_NAME,
    fields,
    validate,
  },
  state => ({ initialValues: { ...initialValues, ...getUserData(state.user) } }),
)(UpdateUserForm)

export default ReduxUpdateUserForm
