import React from 'react'
import { Route, IndexRoute, IndexRedirect, Redirect } from 'react-router'
import flagged from '@raywhite/helpers-utils/lib/helpers/flags'

import AboutPage from './components/container/AboutPage.jsx'
import AccountContainer from './components/container/AccountContainer.jsx'
import AgentPage from './components/container/AgentPage.jsx'
import CaseStudyContentArchive from './components/container/CaseStudyContentArchive.jsx'
import CaseStudyContentPage from './components/container/CaseStudyContentPage.jsx'
import ConnectedOfficePage from './components/container/ConnectedOfficePage.jsx'
import ContentPage from './components/container/ContentPage.jsx'
import EventsPageContainer from './components/container/EventsPageContainer.jsx'
import HomeLoansContainer from './components/container/HomeLoansContainer.jsx'
import HomePage from './components/container/HomePage.jsx'
import ListingPageContainer from './components/container/ListingPageContainer.jsx'
import ListingsFilter from './components/container/ListingsFilter.jsx'
import NewsContentArchive from './components/container/NewsContentArchive.jsx'
import NewsContentPage from './components/container/NewsContentPage.jsx'
import ServicePageContainer from './components/container/ServicePageContainer.jsx'
import TeamPage from './components/container/TeamPage.jsx'
import App from './components/App.jsx'
import { withStaticProps } from './components/hocs/providers'

const BuyServicePage = withStaticProps('BuyServicePage', { category: 'buy' })(ServicePageContainer)
const RentServicePage = withStaticProps('RentServicePage', { category: 'rent' })(ServicePageContainer)
const SellServicePage = withStaticProps('SellServicePage', { category: 'sell' })(ServicePageContainer)
const PropertyManagementServicePage = withStaticProps('PropertyManagementServicePage', { category: 'property-management' })(ServicePageContainer)

const AuctionsPage = withStaticProps('AuctionsPage', {
  eventType: 'auctionDate',
})(EventsPageContainer)
const InspectionsPage = withStaticProps('InspectionsPage', {
  eventType: 'inspectionDate',
  badgeField: [],
})(EventsPageContainer)

const getRoutes = (state) => {
  const useServicePages = flagged('servicePages', state)
  const { theme } = state.config

  return (
    <Route path="/" component={App}>
      <IndexRoute
        component={HomePage}
      />
      <Route path="properties">
        <IndexRoute component={ListingsFilter} />
        <Redirect from=":slug/private-auctions" to=":slug/auctions" />
        <Redirect from=":slug/online-auctions" to=":slug/auctions" />
        <Route
          path=":slug/auctions"
          component={AuctionsPage}
        />
        <Route
          path=":slug/open-for-inspection"
          component={InspectionsPage}
        />
        <Route
          path=":typeSlug/:region/:suburbPostcode/:category/:listingId"
          component={ListingPageContainer}
        />
        <Route path=":typeSlug" component={ListingsFilter} />
      </Route>
      <Route path="agents">
        <IndexRedirect to="/about#team" />
        <Route path=":name/:memberId" component={AgentPage} />
      </Route>
      <Route path="teams/:name/:teamId" component={TeamPage} />
      <Route path="news">
        <IndexRoute component={NewsContentArchive} />
        <Route
          path="*"
          component={NewsContentPage}
        />
      </Route>
      {theme === 'commercial' && (
        <Route path="case-studies">
          <IndexRoute component={CaseStudyContentArchive} />
          <Route
            path="*"
            component={CaseStudyContentPage}
          />
        </Route>
      )}
      <Route path="account">
        <IndexRoute component={AccountContainer} />
      </Route>
      {useServicePages && (
        <Route path="buy">
          <IndexRoute component={BuyServicePage} />
          <Route path=":slug" component={BuyServicePage} />
        </Route>
      )}
      {useServicePages && (
        <Route path="rent">
          <IndexRoute component={RentServicePage} />
          <Route path=":slug" component={RentServicePage} />
        </Route>
      )}
      {useServicePages && (
        <Route path="lease">
          <IndexRoute component={RentServicePage} />
          <Route path=":slug" component={RentServicePage} />
        </Route>
      )}
      {useServicePages && (
        <Route path="sell">
          <IndexRoute component={SellServicePage} />
          <Route path=":slug" component={SellServicePage} />
        </Route>
      )}
      {useServicePages && (
        <Route path="property-management">
          <IndexRoute component={PropertyManagementServicePage} />
          <Route path=":slug" component={PropertyManagementServicePage} />
        </Route>
      )}
      <Route path="about">
        <IndexRoute component={AboutPage} />
        <Route path=":name/:orgId" component={ConnectedOfficePage} />
      </Route>
      <Route path="home-loans/loanmarket" component={HomeLoansContainer} />
      <Route
        path="*"
        component={ContentPage}
      />
    </Route>
  )
}

export default getRoutes
