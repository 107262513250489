// @flow
import React, { Component } from 'react'
import { pluralize } from '@raywhite/data-utils/lib/data/listing/utils'

type Props = {
  listings: Object,
  sort: Object,
  hideFilters: boolean,
  filterCount: number,
  showMap: boolean,
  timeframe: string,
  activateFilter: () => void,
  resetFilters: () => void,
  activateTimeFrame: () => void,
};

export default class FilterStatusRow extends Component<Props> {
  render() {
    const {
      listings,
      sort,
      hideFilters,
      filterCount,
      showMap,
      timeframe,
      activateFilter,
      resetFilters,
      activateTimeFrame,
    } = this.props

    return (
      <div className="listings_map_meta inner_lg tbl">
        <div className="listings_map_count tbc middle">
          {listings.loaded && (
            <span>
              {listings.items.length > 0
                ? `Showing 1-${listings.items.length} of ${listings.totalResults} results`
                : 'No properties found'}
              {!hideFilters && ([
                ' with ',
                <span
                  key="filtercount"
                  className={filterCount ? 'filter_count_active' : 'filter_count_inactive'}
                  onClick={showMap ? activateFilter : undefined}
                  data-ev-on="click"
                  data-ev-category="Filter Panel"
                  data-ev-action="Open Filter"
                >
                  {filterCount
                    ? `${filterCount} ${pluralize(filterCount, 'filter', 'filters')}`
                    : 'no filters'}
                </span>,
                <span
                  key="filterclear"
                  className={filterCount ? 'filter_count_clear_active' : 'filter_count_clear_inactive'}
                  onClick={resetFilters}
                  data-ev-on="click"
                  data-ev-category="Filter"
                  data-ev-action="Clear Filter"
                >
                  &times;
                </span>,
                !!timeframe && (
                  <span
                    key="timeframe"
                    className="filter_timeframe_toggle"
                    onClick={showMap ? activateTimeFrame : undefined}
                    data-ev-on="click"
                    data-ev-category="Filter Panel"
                    data-ev-action="Open Filter Date Range"
                  >
                    {`from ${timeframe}`}
                  </span>
                ),
              ])}
            </span>
          )}
        </div>
        <sort.component
          className="listings_map_sort tbc middle show_charlie"
          options={sort.options}
          defaultValue={sort.defaultValue}
        />
      </div>
    )
  }
}
