// @flow

import React from 'react'

import type { ClearingSaleItem } from '@raywhite/data-client/lib/modules/listings'

import { ungarble } from '@raywhite/helpers-utils/lib/helpers/sanitization'

type Props = {
  clearingSaleItems: Array<ClearingSaleItem>,
};

const categorise = clearingSaleItems => {
  const seen = {}
  return clearingSaleItems
    .sort((a, b) => a.id - b.id)
    .reduce((cats, { category, description }) => {
      const name = category.replace(/:$/, '')
      const cat = seen[name] || { name, items: [] }
      if (!seen[name]) {
        seen[name] = cat
        cats.push(cat)
      }
      // Note: relies on ungarble to listify and clean this up
      cat.items.push(`* ${description.replace(/^[*\s]+/, '')}`)
      return cats
    }, [])
}

const ListingClearingMetadata = ({ clearingSaleItems }: Props) => (
  <div className="pdp_clearing_items">
    {categorise(clearingSaleItems).map(({ name, items }) => (
      <div key={name} className="clearing_sale_item">
        <p className="muted">
          {name}
          :
        </p>
        <div
          dangerouslySetInnerHTML={
            ungarble(
              items.length === 1 && !/[\r\n]/.test(items[0].trim())
                ? items[0].replace(/^[*\s]+/, '')
                : items.join('\n')
            )
          }
        />
      </div>
    ))}
  </div>
)

export default ListingClearingMetadata
