import React from 'react'
import PropTypes from 'prop-types'

import { Layer } from '.'

const BuildingShapeLayer = ({ minZoom }) => (
  <Layer
    id="3d-buildings"
    sourceId="composite"
    sourceLayer="building"
    filter={['==', 'extrude', 'true']}
    type="fill-extrusion"
    minZoom={minZoom}
    paint={{
      'fill-extrusion-color': '#aaa',
      // use an 'interpolate' expression to add a smooth transition effect to the
      // buildings as the user zooms in
      'fill-extrusion-height': [
        'interpolate', ['linear'], ['zoom'],
        minZoom, 0,
        minZoom + 0.05, ['get', 'height'],
      ],
      'fill-extrusion-base': [
        'interpolate', ['linear'], ['zoom'],
        minZoom, 0,
        (minZoom + 0.05), ['get', 'min_height'],
      ],
      'fill-extrusion-opacity': 0.6,
    }}
  />
)
BuildingShapeLayer.propTypes = {
  minZoom: PropTypes.number,
}
BuildingShapeLayer.defaultProps = {
  minZoom: 13,
}

export default BuildingShapeLayer
