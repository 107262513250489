import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const withLink = (Component) => {
  const wrappedComponent = ({ isLink, isStatic, url, ...rest }) => (
    isStatic || !isLink
      ? <Component {...rest} />
      : <Link to={url}><Component {...rest} /></Link>
  )

  wrappedComponent.propTypes = {
    isLink: PropTypes.bool,
    isStatic: PropTypes.bool,
    url: PropTypes.string.isRequired,
  }
  wrappedComponent.defaultProps = {
    isLink: false,
    isStatic: false,
  }

  return wrappedComponent
}

withLink.propTypes = {
  Component: PropTypes.element.isRequired,
}

export default withLink
