// @flow

import createClient from '@raywhite/lmg-client'
import typeof fetchType from 'isomorphic-fetch'

export type Broker = {|
  brokerId: number,
  firstName: string,
  lastName: string,
  company: string,
  title: string,
  phone: string,
  phoneInternational: ?string,
  email: string,
  website: string,
  image: ?string,
  mobile: ?string,
  mobileInternational: ?string,
  abn: ?string,
  fsg: ?string,
  calculatorUrls?: {
    repayments?: string,
    borrow?: string,
    stampDuty?: string,
  },
|}

export const cleanWebsite = (site: ?string, countryCode: 'au' | 'nz') => {
  if (site && typeof site === 'string') {
    const trimmed = site.trim()
    if (/^https?:/.test(trimmed)) return trimmed
    // NOTE(@elyobo): http used on the assumption that it will be supported and redirect
    // to https if possible, but https may not be supported if we direct straight there
    if (/^[^/]+\.(co\.nz|com\.au|com)($|\/)/.test(trimmed)) return `http://${trimmed}`
  }

  return countryCode === 'au' ? 'https://loanmarket.com.au' : 'https://loanmarket.co.nz'
}

export const NO_BROKER_LINKS = Object.freeze({
  repayments: undefined,
  borrow: undefined,
  stampDuty: undefined,
})

export const buildBrokerLinks = (
  countryCode: 'au' | 'nz',
  website: ?string
) => {
  if (!website) return NO_BROKER_LINKS

  switch (countryCode) {
    case 'au': {
      if (!/^https?:\/\/broker\.loanmarket\.com\.au\/([a-z0-9-]+)\/?$/.test(website)) return NO_BROKER_LINKS
      const base = website.replace(/\/+$/, '')
      return {
        repayments: `${base}/calculators-tools/loan-repayment-calculator/`,
        borrow: `${base}/calculators-tools/borrowing-power-calculator/`,
        stampDuty: `${base}/calculators-tools/stamp-duty-calculator/`,
      }
    }
    case 'nz': {
      const [, slug] = website.match(/^https?:\/\/(?:adviser\.)?loanmarket\.co\.nz\/([a-z0-9-]+)\/?$/) || []
      if (!slug) return NO_BROKER_LINKS
      const base = `https://adviser.loanmarket.co.nz/${slug}`
      return {
        repayments: `${base}/tools-help/loan-repayment-calculator/`,
        borrow: `${base}/tools-help/borrowing-power-calculator/`,
        stampDuty: undefined,
      }
    }
    default:
      return NO_BROKER_LINKS
  }
}

const mapBroker = countryCode => data => {
  if (!data) return undefined
  const {
    data: {
      attributes: details,
      id,
    },
    included,
  } = data
  const adviser = included.find(item => item.type === 'advisers')?.attributes
  const org = included.find(item => item.type === 'organisations')?.attributes
  if (
    // $FlowFixMe
    adviser?.status.toLowerCase() !== 'active'
    // $FlowFixMe
    || org?.status.toLowerCase() !== 'active'
    || !adviser.isBrokerWebPublic
  ) return undefined

  const website = cleanWebsite(adviser.website || org.website, countryCode)

  return {
    brokerId: +id,
    firstName: details.preferredName || details.firstName,
    lastName: details.lastName,
    // NOTE(@elyobo): Requested by LMG to show LM details here
    company: countryCode === 'au' ? 'Loan Market' : 'NZFSG LTD',
    title: countryCode === 'au' ? 'Mortgage Broker' : 'Mortgage Adviser',
    // company: org.officeDisplayName || org.tradingName || org.name,
    phone: details.businessPhone === details.mobilePhone ? undefined : details.businessPhone,
    phoneInternational: undefined, // MISSING
    email: details.email,
    website,
    image: (
      adviser.profilePhotoHalfBody
      || adviser.profilePhotoHeadShot
      || adviser.profilePhotoFullBody
    ),
    mobile: details.mobilePhone,
    mobileInternational: undefined, // MISSING
    // NOTE(@elyobo): Requested by LMG to show LM details here
    abn: countryCode === 'au' ? '89 105 230 019' : undefined,
    fsg: countryCode === 'nz' ? 'FSP286965' : undefined,
    // abn: org.businessNumber,
    calculatorUrls: buildBrokerLinks(countryCode, website),
  }
}

type Params = {
  countryCode: 'nz' | 'au',
  ids: Array<number>,
};

export type LoanMarketClient = {
  getBrokers: (params: Params) => Promise<*>,
}
;export const getClient = (
  fetch: fetchType,
  clientId: string,
  clientSecret: string,
): LoanMarketClient => {
  const client = createClient({ fetch, clientId, clientSecret })

  async function getBrokers(params: Params) {
    const { countryCode, ids } = params
    const requests = ids.map(id => (
      client.adviserDetails(id).get({
        include: ['adviser', 'adviser.organisation'].join(','),
      })
    ))

    // $FlowFixMe
    const results = await Promise.allSettled(requests)
    return results
      .map(result => result.value)
      .map(mapBroker(countryCode))
      .filter(Boolean)
  }

  return { getBrokers }
}

export const getBrokers = async (params: Params) => {
  const res = await fetch('/api/brokers/_search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  })
  const data = await res.json()

  if (!res.ok) {
    throw new Error(data.message || `${res.status} ${res.statusText}`)
  }
  return data
}
