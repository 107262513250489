import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

const baseMeta = {
  category: 'Outbound',
  action: 'Follow',
}

const names = {
  facebook: 'Facebook',
  googlePlus: 'Google+',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  pinterest: 'Pinterest',
  twitter: 'Twitter',
  youtube: 'Youtube',
}

const meta = {
  facebook: eventMeta({ ...baseMeta, label: 'Facebook' }),
  googlePlus: eventMeta({ ...baseMeta, label: 'Google+' }),
  instagram: eventMeta({ ...baseMeta, label: 'Instagram' }),
  linkedin: eventMeta({ ...baseMeta, label: 'LinkedIn' }),
  pinterest: eventMeta({ ...baseMeta, label: 'Pinterest' }),
  twitter: eventMeta({ ...baseMeta, label: 'Twitter' }),
  youtube: eventMeta({ ...baseMeta, label: 'Youtube' }),
}

const types = Object.keys(names)

export function hasSocial(social) {
  let len = types.length
  while (len) {
    if (social[types[len - 1]]) return true
    len--
  }
  return false
}

export default class SocialIcons extends Component {
  render() {
    const { social, className } = this.props

    if (!hasSocial(social)) return null

    const _social = types.reduce((p, k) => {
      const v = this.props.social[k]
      if (v) p.push([k, v])
      return p
    }, [])

    return (
      <ul className={className || 'social circle'}>
        {_social.map(item => {
          const [name, href] = item
          return (
            <li key={`${href}:${name}`} className={`social_${name.toLowerCase()}`}>
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                {...meta[name]}
              >
                <span>{names[name]}</span>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}

SocialIcons.propTypes = {
  social: PropTypes.object.isRequired,
  className: PropTypes.string,
}
