import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  warn,
  domOnlyProps,
} from '@raywhite/functionalities-utils/lib/functionalities/form'

// eslint-disable-next-line no-unused-vars
const noConnectedProps = ({ store, storeSubscription, ...rest }) => rest

export const FormSectionHeading = ({ children }) => (
  <p className="form_section_heading delta">{children}</p>
)
FormSectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
}

export const FormSection = ({ children, className }) => (
  <div className={classNames('form_fields_section', className)}>
    <div className="form_section">
      {children}
    </div>
  </div>
)
FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export const FormRow = ({ children }) => (
  <div className="form_row">
    {children}
  </div>
)
FormRow.propTypes = {
  children: PropTypes.node.isRequired,
}

// Standard <input> element
export const FormInput = ({ type, name, field = {}, ...attrs }) => (
  <input
    id={name || field.name}
    {...domOnlyProps(field)}
    {...attrs}
    type={type}
  />
)
FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  field: PropTypes.object,
  name: PropTypes.string,
}
FormInput.defaultProps = {
  type: 'text',
}

const normalizeOptions = options => {
  if (!Array.isArray(options)) {
    // Assume object
    return Object.entries(options).map(([label, value]) => ({ label, value: `${value}` }))
  }

  return options.map(val => ({
    value: `${val.value !== undefined ? val.value : val}`,
    label: val.label !== undefined ? val.label : val,
    attrs: val.attrs || {},
  }))
}

// Standard <textarea> element
export const FormTextArea = ({ field, ...attrs }) => (
  <textarea
    id={field.name}
    {...domOnlyProps(field)}
    {...attrs}
  />
)
FormTextArea.propTypes = {
  field: PropTypes.object.isRequired,
}

export const FormRadio = (
  { field = {}, name, onChange, value: fvalue, options = [], ...attrs }
) => (
  <div
    id={name || field.name}
    {...domOnlyProps(field)}
    {...noConnectedProps(attrs)}
    className={classNames('radio_group', attrs.className)}
  >
    {normalizeOptions(options).map(({ value, label, attrs: _attrs }) => (
      <div key={value} className="radio_group_option">
        <input
          {..._attrs}
          type="radio"
          id={`${name || field.name}_${value}`}
          name={field.name}
          value={value}
          checked={value === `${fvalue}`}
          onChange={onChange || field.onChange}
        />
        <label
          key={value}
          htmlFor={`${name || field.name}_${value}`}
        >
          {label}
        </label>
      </div>
    ))}
  </div>
)
FormRadio.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

// Standard <select> element
export const FormSelect = ({ field = {}, name, onChange, options = [], ...attrs }) => (
  <select
    id={name || field.name}
    name={name}
    onChange={onChange}
    {...domOnlyProps(field)}
    {...noConnectedProps(attrs)}
  >
    {normalizeOptions(options).map(({ value, label, attrs: _attrs }) => (
      <option
        key={value}
        value={value}
        {..._attrs}
      >
        {label}
      </option>
    ))}
  </select>
)
FormSelect.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export const FormDescriptor = ({ children }) => (
  <div className="form_descriptor">
    <small>{children}</small>
  </div>
)
FormDescriptor.propTypes = {
  children: PropTypes.node.isRequired,
}

export const FormField = ({
  label,
  field,
  children,
  required,
  className,
  description,
  input: Input = FormInput,
  ...rest
}) => (
  <div
    className={classNames('form_field', className, { error: field.touched && field.error })}
  >
    {!!label && (
      <label
        htmlFor={field.name}
        className={required ? 'required' : undefined}
      >
        {label}
      </label>
    )}
    {children}
    {!children && (
      <Input {...rest} field={field} />
    )}
    {!!description && (
      <FormDescriptor>{description}</FormDescriptor>
    )}
    {warn(field)}
  </div>
)
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  children: PropTypes.node,
  description: PropTypes.node,
  input: PropTypes.func,
  required: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
FormField.defaultProps = {
  required: false,
}

/* eslint-disable react/prop-types */
export const labelifyFormField = labels => props => (
  <FormField label={labels[props.field.name]} {...props} />
)
/* eslint-enable react/prop-types */

export const FormSubmit = ({ submitting, label }) => (
  <div className="form_section form_footer_section">
    <div className={`form_field submit_field ${submitting ? 'loading' : ''}`}>
      <input type="submit" value={label} disabled={submitting} />
    </div>
  </div>
)
FormSubmit.propTypes = {
  submitting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}
