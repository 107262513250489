// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shallowEqual from 'shallowequal'

import type { Auction, Inspection, Tender } from '@raywhite/data-client/lib/modules/listings'
import { elementRect } from '@raywhite/browser-utils/lib/browser/browser'

import { createTime, createTimeRange, startOfDay } from '@raywhite/helpers-utils/lib/helpers/time'

import type { Time, TimeRange } from '@raywhite/helpers-utils/lib/helpers/time'

import { inferEventsOffset } from '../../utils/events'

type EventsProp = {
  inspections: ?Array<Inspection>,
  auction: ?Auction,
  available: ?string,
  tender: ?Tender,
  setSaleDate: ?string,
};

type FinalEvent = {
  type: string,
  key: string,
  time: Time,
};

type AuctionEvent = FinalEvent & {
  location?: string,
};

type InspectionEvent = {
  type: string,
  key: string,
  time: TimeRange,
};

type ParseEventsResponse = {
  inspections: Array<InspectionEvent>,
  auction: ?AuctionEvent,
  available: ?FinalEvent,
  tender: ?FinalEvent,
  setSaleDate: ?FinalEvent,
};

type Props = {
  events: EventsProp,
  mayRequestInspection: boolean,
  showInspectionIcon: boolean,
  inspectionPrompt?: any,
  actions: Array<any>,
};

type State = {
  lastEvents: ?EventsProp,
  listIndex: number,
  showAllInspections: boolean,
  inspections: Array<FinalEvent>,
  auction: ?AuctionEvent,
  available: ?FinalEvent,
  setSaleDate: ?FinalEvent,
  tender: ?FinalEvent,
};

const INSPECTION = 'INSPECTION'
const AUCTION = 'AUCTION'
const AVAILABLE = 'AVAILABLE'
const TENDER = 'TENDER'
const SETSALE = 'SETSALE'

const cleanLocation = location => {
  if (location && /on(?:-| +)?site/i.test(location)) {
    return ''
  }

  return location
}

// Filter out inspections with duplicate start times
const dedup = inspections => {
  if (!inspections) return []

  const seen = {}
  return inspections.filter(({ start }) => {
    if (seen[start]) return false
    seen[start] = true
    return true
  })
}

const parseEvents = ({
  auction,
  available,
  inspections,
  tender,
  setSaleDate,
}: EventsProp): ParseEventsResponse => ({
  inspections: dedup(inspections).map(inspection => {
    const { start, finish } = inspection
    return {
      type: INSPECTION,
      key: start,
      time: createTimeRange(start, finish),
    }
  }),
  auction: auction ? {
    type: AUCTION,
    key: auction.date,
    time: createTime(auction.at),
    location: cleanLocation(auction.location),
  } : null,
  available: available ? {
    type: AVAILABLE,
    key: available,
    time: createTime(available),
  } : null,
  tender: tender ? {
    type: TENDER,
    key: tender.date,
    time: createTime(tender.at),
  } : null,
  setSaleDate: setSaleDate ? {
    type: SETSALE,
    key: setSaleDate,
    time: createTime(setSaleDate),
  } : null,
})

const buildEventState = (events: EventsProp) => {
  const {
    inspections: _inspections,
    available: _available,
    auction: _auction,
    tender: _tender,
    setSaleDate: _setSaleDate,
  } = parseEvents(events)

  const offset = inferEventsOffset(events)
  // Calculate the local start of day, but then un-offset it because the
  // parsed times discard the offset
  const today = startOfDay(+new Date() + offset)

  const inspections: Array<InspectionEvent> = _inspections
    .filter(event => (today <= event.time.start.ms))
  const available = _available && today <= _available.time.ms ? _available : undefined
  const auction = _auction && today <= _auction.time.ms ? _auction : undefined
  const tender = _tender && today <= _tender.time.ms ? _tender : undefined
  const setSaleDate = _setSaleDate && today <= _setSaleDate.time.ms ? _setSaleDate : undefined

  return {
    auction,
    available,
    inspections,
    setSaleDate,
    tender,
  }
}

const InspectionItem = event => {
  const {
    day,
    date,
    month,
    start: {
      time,
      meridiem,
    },
    end: {
      time: _time,
      meridiem: _meridiem,
    },
  } = event.time

  return (
    <div className="event_item">
      <div className="event_heading_wrap">
        <span className="event_type hide_charlie">Inspection:</span>
        <span className="event_heading">
          {day.slice(0, 3)}
          <span className="trunc">{day.slice(3)}</span>
        </span>
      </div>
      <div className="event_date_wrap">
        <span className="event_date">{date}</span>
        <span className="event_month">
          {month.slice(0, 3)}
          <span className="trunc">{month.slice(3)}</span>
        </span>
      </div>
      <div className="event_meta_wrap">
        <span className="event_time">
          {time}
          {meridiem}
          <span className="trunc">
&nbsp;-&nbsp;
            {_time}
            {_meridiem}
          </span>
        </span>
      </div>
    </div>
  )
}

const SaleItem = (event) => {
  const {
    heading,
    time: {
      day,
      date,
      month,
      time,
      meridiem,
    },
    showTime,
    showDay,
    showLocation,
  } = event

  // Midnight, nothing happens at midnight, probably an error
  const midnight = time === '12:00' && meridiem === 'am'

  return (
    <div className="event_item">
      <div className="event_heading_wrap">
        <span className="event_heading">{heading}</span>
      </div>
      <div className="event_date_wrap">
        <span className="event_date">{date}</span>
        <span className="event_month">
          {month.slice(0, 3)}
          <span className="trunc">{month.slice(3)}</span>
        </span>
        {showTime && (
          <span className="event_time hide_charlie">{midnight ? '–' : `${time}${meridiem}`}</span>
        )}
      </div>
      <div>
        {showTime && (
          <div className="event_meta_wrap show_charlie" key="event_time">
            <span className="event_time">{midnight ? '–' : `${time}${meridiem}`}</span>
          </div>
        )}
        {!showTime && showDay && (
          <div className="event_meta_wrap show_charlie" key="tender_day">
            <span className="event_time">{day}</span>
          </div>
        )}
        {showLocation && (
          <div className="event_meta_wrap hide_charlie" key="event_location">
            <span className="event_location">{event.location || 'On Site'}</span>
          </div>
        )}
      </div>
    </div>
  )
}
SaleItem.defaultProps = {
  showTime: false,
  showDay: true,
  showLocation: false,
}

export default class EventBox extends Component<Props, State> {
  props: Props

  state: State

  list: ?HTMLUListElement

  static defaultProps = {
    showInspectionIcon: true,
  }

  static propTypes = {
    events: PropTypes.object.isRequired,
    mayRequestInspection: PropTypes.bool.isRequired,
    actions: PropTypes.array.isRequired,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { events } = props
    if (shallowEqual(state.lastEvents, events)) return null

    return {
      lastEvents: events,
      ...buildEventState(events),
    }
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      listIndex: 0,
      showAllInspections: false,
      lastEvents: props.events,
      ...buildEventState(props.events),
    }
  }

  setList = (el: ?HTMLUListElement) => {
    this.list = el
  }

  maxListIndex() {
    if (!this.list) return 0

    const { list, state: { inspections: { length } } } = this
    const displayed = list.children.length
      ? Math.ceil(elementRect(list).width / elementRect(list.children[0]).width)
      : length

    return Math.max(0, length - displayed)
  }

  listLeft = () => {
    this.setState(state => ({ listIndex: Math.max(0, state.listIndex - 1) }))
  }

  listRight = () => {
    this.setState(state => ({
      listIndex: Math.min(this.maxListIndex(), state.listIndex + 1),
    }))
  }

  listTransform() {
    if (!this.list || !this.list.children.length || !this.state.listIndex) return undefined

    const { width } = elementRect(this.list.children[0])
    const amount = this.state.listIndex * width
    return `translateX(-${amount}px)`
  }

  showAllInspections = () => {
    this.setState({ showAllInspections: true })
  }

  render() {
    const {
      mayRequestInspection,
      actions,
      showInspectionIcon,
      inspectionPrompt: _inspectionPrompt,
    } = this.props

    const inspectionPrompt = _inspectionPrompt || (
      <p>
        Are you interested in inspecting this property?
        <br />
        Get in touch to request an inspection.
      </p>
    )

    const { inspections, auction, available, tender, setSaleDate } = this.state

    const hasContent = (
      actions.length
      || inspections.length
      || auction
      || available
      || mayRequestInspection
      || tender
      || setSaleDate
    )
    if (!hasContent) return null

    return (
      <div className="pdp_events">
        {!!(inspections.length || auction || tender || setSaleDate || available) && (
          <h3 className="pdp_events_heading show_charlie centered_text foxtrot">
            {(
              [
                inspections.length && 'Inspection',
                auction && 'Auction',
                available && 'Available',
                tender && 'Tender',
                setSaleDate && 'Deadline Sale',
              ]
                .filter(x => x)
                .join(' and ')
                .toLowerCase()
                .replace(/^([a-z])/, l => l.toUpperCase())
            )}
            {' '}
            details
          </h3>
        )}
        {!!(inspections.length || auction || available || tender || setSaleDate) && (
          <div
            className={classNames({
              event_cards: true,
              no_inspections: !inspections.length,
              no_final_event: !(tender || available || auction || setSaleDate)
            })}
          >
            {!!inspections.length && (
              <div
                className={`event_list_wrap inspections ${inspections.length >= 5 ? 'overflow' : ''}`}
                data-displayed-inspections={Math.min(4, inspections.length)}
              >
                <ul
                  className="event_list"
                  ref={this.setList}
                  style={{ transform: this.listTransform() }}
                >
                  {inspections.map((inspection, index) => (
                    <li
                      className={(index < 2 || this.state.showAllInspections) ? undefined : 'mobile_more_inspection'}
                      key={inspection.key}
                    >
                      <InspectionItem {...inspection} />
                    </li>
                  ))}
                  {(inspections.length > 2 && !this.state.showAllInspections) && (
                    <li className="centered_text mobile_more_inspections_link">
                      <span
                        className="anchor"
                        onClick={this.showAllInspections}
                      >
                        {`+${inspections.length - 2} more inspections`}
                      </span>
                    </li>
                  )}
                </ul>
                {inspections.length >= 4 && (
                  <div className="event_list_nav">
                    <span
                      className={classNames('prev', { hide: !this.state.listIndex })}
                      onClick={this.listLeft}
                    />
                    <span
                      className={classNames('next', { hide: this.state.listIndex === this.maxListIndex() })}
                      onClick={this.listRight}
                    />
                  </div>
                )}
              </div>
            )}
            {!!auction && (
              <div className="event_list_wrap final_event auction">
                <ul className="event_list">
                  <li>
                    <SaleItem
                      heading="Auction"
                      {...auction}
                      showTime
                      showLocation
                    />
                  </li>
                </ul>
              </div>
            )}
            {!!available && (
              <div className="event_list_wrap final_event available">
                <ul className="event_list">
                  <li>
                    <SaleItem heading="Available" {...available} />
                  </li>
                </ul>
              </div>
            )}
            {!!tender && (
              <div className="event_list_wrap final_event tender">
                <ul className="event_list">
                  <li>
                    <SaleItem
                      heading="Tender Sale"
                      {...tender}
                      showTime
                    />
                  </li>
                </ul>
              </div>
            )}
            {!!setSaleDate && (
              <div className="event_list_wrap final_event deadline">
                <ul className="event_list">
                  <li>
                    <SaleItem heading="Deadline Sale" {...setSaleDate} />
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        {!!auction && (
          <div className="event_location_full show_charlie">
            <p>
              Auction location: {auction.location || 'On Site'}
            </p>
          </div>
        )}
        {mayRequestInspection && !available && !inspections.length && !auction && !tender && (
          <div className="event_notes centered_text inner_sm">
            {showInspectionIcon && <div className="pdp_event_inspection_icon"></div>}
            {inspectionPrompt}
          </div>
        )}
        {((mayRequestInspection && !inspections.length && (auction || tender)) || !!actions.length)
        && (
          <div className="event_actions centered_text">
            {(mayRequestInspection && !inspections.length && auction || tender) && (
              inspectionPrompt
            )}
            {actions}
          </div>
        )}
      </div>
    )
  }
}
