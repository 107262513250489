import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import * as Joi from 'joi'
import {
  emailOrNumber,
  types,
} from '@raywhite/helpers-utils/lib/helpers/validation'
import {
  formSchemaValidator,
  stockFormEmail,
  schemafy,
  schemaItem,
  schemaFields,
  schemaLabels,
  schemaRules,
  schemaInitialValues,
} from '@raywhite/functionalities-utils/lib/functionalities/form'
import {
  FormSectionHeading,
  FormSection,
  FormSelect,
  FormTextArea,
  FormSubmit,
  FormRow,
  labelifyFormField,
} from '../../presentational/formComponents'
import ContentForm from './ContentForm.jsx'

const FORM_NAME = 'sellAppraisal'
const selectOptions = ['0', '1', '2', '3', '4', '5+']
const alts = Joi.string().only(selectOptions).required()
const schema = schemafy(
  schemaItem('name', 'Your Full Name', types.fullName.required()),
  schemaItem('email', 'Your Email', types.email.allow('').empty('')),
  schemaItem('number', 'Your Phone Number', types.phone.allow('').empty('')),
  schemaItem('message', 'Additional Message', types.freeText.allow('')),
  schemaItem('street', 'Street Address', types.freeText.required()),
  schemaItem('suburb', 'Suburb and Postcode', types.freeText.required()),
  schemaItem('beds', 'Bedrooms', alts, '2'),
  schemaItem('baths', 'Bathrooms', alts, '1'),
  schemaItem('cars', 'Car Spaces', alts, '1'),
  schemaItem('details', 'Additional Details', types.freeText.allow('')),
)
export const fields = schemaFields(schema)
export const labels = schemaLabels(schema)
const FormField = labelifyFormField(labels)
const initialValues = schemaInitialValues(schema)
export const validate = formSchemaValidator(
  fields, emailOrNumber.concat(schemaRules(schema))
)
export const process = (values, params = {}) => {
  const { send, prefix, referrer } = params

  const ref = referrer || 'your office website'
  return stockFormEmail(
    send,
    'Sale Appraisal Request',
    fields,
    labels,
    values,
    {
      prefix: prefix || `A sale appraisal request has been received from ${ref}.`,
      referrer,
    }
  )
}

const SellAppraisalForm = props => {
  const {
    fields: { name, email, number, message, street, suburb, beds, baths, cars, details },
    submitting,
    sendForm,
    handleSubmit,
    error,
    dispatch,
    showCloseButton,
    handleClose,
  } = props

  return (
    <ContentForm
      dispatch={dispatch}
      sendForm={sendForm}
      formName={FORM_NAME}
      handleSubmit={handleSubmit}
      error={error}
      showCloseButton={showCloseButton}
      handleClose={handleClose}
    >
      <FormSectionHeading>Your Contact Details</FormSectionHeading>
      <FormSection>
        <FormField field={name} placeholder="e.g. Joe Bloggs" required />
        <FormRow>
          <FormField
            field={number}
            placeholder="e.g. 04xx xxx xxx"
            required={!email.value}
            className="one_half"
          />
          <FormField
            field={email}
            placeholder="e.g. j.bloggs@gmail.com"
            required={!number.value}
            className="one_half"
          />
        </FormRow>
        <FormField field={message} input={FormTextArea} />
      </FormSection>
      <FormSectionHeading>Property Details</FormSectionHeading>
      <FormSection>
        <FormField field={street} placeholder="e.g. 123 Example Street" required />
        <FormField field={suburb} placeholder="City, Suburb, Postcode" required />
        <FormRow>
          <FormField
            field={beds}
            input={FormSelect}
            options={selectOptions}
            className="one_third select_form_field"
            required
          />
          <FormField
            field={baths}
            input={FormSelect}
            options={selectOptions}
            className="one_third select_form_field"
            required
          />
          <FormField
            field={cars}
            input={FormSelect}
            options={selectOptions}
            className="one_third select_form_field"
            required
          />
        </FormRow>
        <FormField field={details} input={FormTextArea} />
      </FormSection>
      <FormSubmit submitting={submitting} label="Request an Appraisal" />
    </ContentForm>
  )
}

SellAppraisalForm.propTypes = {
  ...propTypes,
  sendForm: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default reduxForm({
  form: FORM_NAME,
  fields,
  validate,
  initialValues,
})(SellAppraisalForm)
