// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { stringify } from 'query-string'
import { debounce } from '@raywhite/browser-utils/lib/browser/deferredSubscription'
import {
  getTypeSlug,
  getListingUrl,
} from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'
import { buildSearchCategories } from '@raywhite/data-utils/lib/data/meta/categories'

import withSiteMetadata from '../hocs/withSiteMetadata'
import withContext from '../hocs/withContext'
import HomePageSearch from '../presentational/HomePageSearch.jsx'
import { fetchListings } from '../../redux/modules/listings'

import type { Category, LinkDetails } from '../presentational/HomePageSearch.jsx'

type Props = {
  router: {
    push: string => void,
  },
  siteFocus: string,
  siteMetadata: {
    loaded: boolean,
    suburbs?: { [string]: Array<Object> },
    typeKey: Object,
  },
  _window: any,
  organisationIds: Array<number>,
  theme: 'commercial' | 'residential',
};

type State = {
  propertyType: ?Object,
  searching: boolean,
  keywords: string,
  options: Array<LinkDetails>,
  categories: Array<Category>,
};

// Note: from MDN - https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions
const escapeRegExp = string => (
  // $& means the whole matched string
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
)

const highlightMatch = (details, keywords) => {
  const position = details.toLowerCase().indexOf(keywords.toLowerCase())

  // Keywords cannot be matched exactly, skip highlighting
  if (position === -1) return details

  return [
    <span key="before">{details.substring(0, position)}</span>,
    <strong key="match">{details.substring(position, position + keywords.length)}</strong>,
    <span key="after">{details.substring(position + keywords.length)}</span>,
  ]
}

function buildTypeLinks(params, keywords) {
  const { typeCode, statusCode, subTypeCode, categoryCode, suburbs, total } = params || {}
  if (!(typeCode && statusCode)) return []
  const std = categoryCode
    ? { category: categoryCode }
    : {}

  const links = []
  const path = `/properties/${getTypeSlug(statusCode, typeCode, subTypeCode)}`
  const allLink = {
    className: 'home_all_link',
    label: 'all properties',
    path: `${path}?${stringify(std)}`,
    count: total,
    eventLabel: 'All Properties',
  }

  const filter = keywords ? new RegExp(`\\b${escapeRegExp(keywords)}`, 'i') : false
  const matchedSuburbs = filter
    ? suburbs.filter(({ name }) => filter.test(name))
    : suburbs

  links.push(...matchedSuburbs.slice(0, 4).map(suburb => ({
    className: 'home_suburb_link',
    label: `in ${suburb.name}`,
    path: `${path}?${stringify({ ...std, suburbPostCode: suburb.name })}`,
    count: suburb.total,
    eventLabel: 'Suburb',
  })))

  // Don't show keywords if it's just a number or MyD ID
  if (keywords && !/^([a-zA-Z]{3})?[0-9]+$/.test(keywords)) {
    links.push({
      className: 'home_keyword_link',
      label: `matching "${keywords}"`,
      path: `${path}?${stringify({ ...std, keywords })}`,
      eventLabel: 'Keywords',
    })
  }

  if (keywords) {
    // Add "all" link after suburbs if keywords, as the search takes priority
    links.push(allLink)
  } else {
    // If no search, prioritise the "all" link
    links.unshift(allLink)
  }

  return links
}

class HomePageSearchContainer extends Component<Props, State> {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    getListings: PropTypes.func.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    const { siteFocus, siteMetadata, theme } = props

    if (siteMetadata === state.lastSiteMetadata) return null
    if (!siteMetadata.loaded) return null

    return {
      lastSiteMetadata: siteMetadata,
      categories: buildSearchCategories(
        siteMetadata.typeKey,
        theme === 'commercial' ? ['COM'] : [],
        (
          (siteFocus === 'propertyManagement' && ['REN'])
          || (siteFocus === 'sales' && ['SAL'])
          || (siteFocus === 'commercialSales' && ['COMSAL'])
          || (siteFocus === 'commercialPropertyManagement' && ['COMLSE'])
          || []
        )
      ),
    }
  }

  state = {
    propertyType: undefined,
    searching: false,
    keywords: '',
    options: [],
    categories: [],
  }

  componentDidUpdate(prevProps, prevState) {
    const { keywords, propertyType } = prevState
    const { state } = this
    if (
      state.keywords
      && (keywords !== state.keywords || propertyType !== state.propertyType)
    ) {
      this.addAddressLinks(state.keywords, state.propertyType)
    }
  }

  handleClick = (index = 0) => {
    const { _window: { dataLayer } = {} } = this.props
    const { options } = this.state
    const option = options[index] || options[0]

    if (option) {
      if (dataLayer) {
        // Track event
        dataLayer.push({
          event: 'rwCustom',
          rwCustomData: {
            category: 'Home Search',
            action: 'Accept Suggestion',
            label: option.eventLabel,
          },
        })
      }
      this.props.router.push(option.path)
    }
  }

  // Check if the given keywords are searchable and add links if found
  addAddressLinks = debounce(async (keywords, { typeCode, statusCode, subTypeCode }) => {
    // Don't bother searching for short keywords
    if (!keywords || keywords.length < 3) {
      this.setState({ searching: false })
      return
    }
    this.setState({ searching: true })

    const params = {
      size: 10,
      organisationId: this.props.organisationIds,
      typeCode,
      statusCode,
      // Note: type codes are specific, e.g. either SAL or LSE, but we're always
      // OK with BOTH types coming so always include them
      subTypeCode: subTypeCode
        ? { in: [subTypeCode, 'BOTH'] }
        : undefined,
    }
    const { store: { dispatch }, getListings } = this.context

    // Suggest fields to use, add in ID if it looks like an ID query
    const fields = ['address']
    if (/^([a-zA-Z]{3}[0-9]+|[0-9]{3,})$/.test(keywords)) fields.push('id')

    const queries = Promise.all(fields.map(field => (
      dispatch(fetchListings(
        getListings,
        { ...params, suggest: { [field]: keywords } },
        null
      ))
        .then(res => ({ ...res, field }))
    )))

    // Combine results
    const options = (await queries).reduce((links, res) => {
      const { totalResults, items, field } = res
      if (!totalResults) return links

      // eslint-disable-next-line no-nested-ternary
      const prefixField = field === 'id'
        ? (/^[0-9]+$/.test(keywords) ? 'id' : 'sourceId')
        : undefined

      // Build links for the found listings
      const _links = items
        // Discard those without street level address details
        .filter(listing => listing.address.streetName)
        .map(listing => {
          const streetAddress = stringifyAddress(listing.address, { withLocality: true })
          const details = prefixField
            ? `${listing[prefixField]} - ${streetAddress}`
            : streetAddress

          return {
            className: 'home_listing_link',
            path: getListingUrl(listing),
            label: highlightMatch(details, keywords),
            eventLabel: 'Listing',
          }
        })
      if (!_links.length) return links

      return [..._links, ...links]
    }, [])

    // Skip updating state if we haven't actually changed anything
    if (!options.length) {
      this.setState({ searching: false })
      return
    }

    // Inject listing links at the start of the links
    this.setState(state => ({
      options: [...options.slice(0, 5), ...state.options],
      searching: false,
    }))
  }, 300)

  // Set the searched keywords
  setKeywords = keywords => {
    if (keywords === this.state.keywords) return
    this.setState(state => ({
      keywords,
      options: buildTypeLinks(state.propertyType, keywords),
    }))
  }

  // Set the selected property type
  setPropertyType = propertyType => {
    if (propertyType === this.state.propertyType) return
    this.setState(state => ({
      propertyType,
      options: buildTypeLinks(propertyType, state.keywords),
    }))
  }

  render() {
    const {
      theme,
      siteMetadata: {
        typeKey: {
          total = 0,
        } = {},
      },
    } = this.props
    const { keywords, options, categories, searching } = this.state
    if (!total) return null

    return (
      <HomePageSearch
        className={theme}
        searching={searching}
        onClick={this.handleClick}
        categories={categories}
        options={options}
        keywords={keywords}
        setKeywords={this.setKeywords}
        setPropertyType={this.setPropertyType}
      />
    )
  }
}

function mapStateToProps(state) {
  const {
    siteFocus,
  } = state.config.options || {}

  return { siteFocus }
}

export default compose(
  // $FlowFixMe
  connect(mapStateToProps),
  withSiteMetadata,
  withRouter,
  withContext('_window'),
)(HomePageSearchContainer)
