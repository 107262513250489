import { connect } from 'react-redux'
import ConnectedOfficePage from './ConnectedOfficePage.jsx'

function mapStateToProps(state, props) {
  return {
    params: {
      ...props.params,
      orgId: state.config.primaryOrganisationId,
    },
    showAwards: state.config.displayOrganisationIds.length === 1,
    emailOverride: state.config.options.defaultEmail,
    content: state.config.options.about,
    contactOrganisationIds: state.config.displayOrganisationIds.filter(
      id => id !== state.config.primaryOrganisationId
    ),
  }
}

export default connect(mapStateToProps)(ConnectedOfficePage)
