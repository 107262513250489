import { DEFAULT_LOADABLE, makeMappedReducer } from '../utils'
import { getRewrites, checkWpRewrites } from '../../utils/wordpress/rewrites'
import { Logger } from '../../logging'

const LOADING_MENU = 'RW_OFFICE/CONTENT/LOADING_MENU'
const LOADED_MENU = 'RW_OFFICE/CONTENT/LOADED_MENU'
const DEFAULT_MENU = Object.freeze({ ...DEFAULT_LOADABLE, menus: [] })

const defaultState = { ...DEFAULT_MENU }

function rewritePaths(items, rewrites, redirections) {
  return items
    .map(item => {
      const _path = item.path.replace(/\/*$/, '/')
      if (rewrites[_path] === false) return false

      // Check site specific WP rewrites after global rewrites
      const path = checkWpRewrites(
        rewrites[_path] || _path,
        redirections,
        Logger
      ) || rewrites[_path] || item.path
      const children = rewritePaths(item.children, rewrites, redirections)

      return { ...item, path, children }
    })
    .filter(x => !!x)
}

export function getMenu(state) {
  return state || DEFAULT_MENU
}

function loadingMenu() {
  return { type: LOADING_MENU }
}

function loadedMenu(menus, { useServicePages = false, error = false, redirections = [] } = {}) {
  const rewrites = getRewrites(useServicePages)
  const rewritten = Object.keys(menus).reduce((result, name) => {
    // eslint-disable-next-line no-param-reassign
    result[name] = rewritePaths(menus[name], rewrites, redirections)
    return result
  }, {})

  return { type: LOADED_MENU, menus: rewritten, error }
}

function reduceLoadingMenu(state) {
  return { ...state, loading: true }
}

function reduceLoadedMenu(state, { menus, error }) {
  return { ...state, loaded: true, loading: false, menus, error }
}

const reducer = makeMappedReducer({
  [LOADING_MENU]: reduceLoadingMenu,
  [LOADED_MENU]: reduceLoadedMenu,
}, defaultState)
export default reducer

export function loadMenu(api, { useServicePages = false, redirections = [] }) {
  return function dispatchLoadMenu(dispatch, getState) {
    const menu = getMenu(getState().menu)

    // Loading, wait
    if (menu.loading) {
      return Promise.resolve({})
    }

    if (menu.loaded) {
      // Already loaded, return immediately
      return Promise.resolve(menu)
    }

    dispatch(loadingMenu())

    return api()
      .then(menus => dispatch(loadedMenu(menus, { useServicePages, redirections })))
      .catch(error => dispatch(loadedMenu(false, { error, useServicePages, redirections })))
      .then(() => getMenu(getState().menu))
  }
}
