import React, { Component } from 'react'
import ErrorPage from './ErrorPage.jsx'

export default class NotFoundPage extends Component {
  render() {
    return (
      <ErrorPage message="404 - Page Not Found">
        <p className="error_message">
          Sorry the page you're looking for could not be found.
          Please use the menu to locate the page or return to the homepage.
        </p>
      </ErrorPage>
    )
  }
}
