import React, { Component } from 'react'

import PropTypes from 'prop-types'

export default class LoadMore extends Component {
  constructor() {
    super()
    this.state = {
      page: 1,
    }
  }

  componentDidMount() {
    this.props.load(this.state.page)
  }

  componentDidUpdate() {
    this.props.load(this.state.page)
  }

  loadMore = () => {
    this.setState(state => ({ page: state.page + 1 }), () => {
      this.props.load(this.state.page)
    })
  }

  render() {
    const {
      loadMoreLabel,
      loadedCount,
      totalCount,
      loading,
      buttonProps,
    } = this.props

    // Assume we can load more if we don't know how many there are yet
    if (totalCount !== undefined && loadedCount >= totalCount) {
      return null
    }

    if (this.props.children) {
      return React.Children.only(this.props.children)
    }

    return (
      <div className="load_more">
        <div className="inner">
          <div className={`load_more_btn_wrap ${loading ? 'loading' : ''}`}>
            <button
              type="button"
              className="btn no_arrow"
              onClick={this.loadMore}
              data-ev-value={this.state.page + 1}
              {...buttonProps}
            >
              {loadMoreLabel}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

LoadMore.propTypes = {
  load: PropTypes.func.isRequired,
  loadMoreLabel: PropTypes.string.isRequired,
  loadedCount: PropTypes.number.isRequired,
  // Total is not available before loading any data
  totalCount: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  buttonProps: PropTypes.object,
}
LoadMore.defaultProps = {
  loadMoreLabel: 'Load More',
  loading: false,
  loadedCount: 0,
}
