// @flow

import React, { Component } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import { eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

import Loader from './Loader.jsx'

type Param = {
  inspectionDate: { gte: string },
  size: number,
  sort: string,
  typeCode: Array<string>,
}
type Category = {
  component: Function,
  label: string,
  links: Array<{ label: string, url: string}>,
  params: Param,
};

type Props = {
  categories: Array<Category>,
  loaded: boolean,
}

const tabMeta = eventMeta({
  category: "What's On",
  action: 'View',
})

export default class WhatsOnBox extends Component<Props, { label: ?string }> {
  constructor(props: Props) {
    super(props)
    this.state = { label: props.categories[0].label }
  }

  handleToggle = (label: string) => {
    this.setState({ label })
  }

  render() {
    const currentLabel = this.state.label
    const { categories, loaded } = this.props
    const current = (categories.filter(category => category.label === currentLabel))[0]
    const { component: Cards, links = [] } = current || {}

    if (!loaded) return <Loader />
    if (!categories.length) return null

    return (
      <section className="home_whatson_wrap">
        <div className="inner_lg">
          <div className="switcher_wrap small">
            <div className="switcher">
              {categories.map(({ label }) => (
                <span
                  key={label}
                  onClick={() => this.handleToggle(label)}
                  className={classNames('mini', { active: label === currentLabel })}
                  {...tabMeta}
                  data-ev-label={label}
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="home_whatson_cards">
          <div className="inner_lg home_whatson_cards_wrap">
            {Cards && (
              <Cards
                className="upcomingevent_list_wrap proplist"
                cardOptions={{
                  addressComponent: 'h4',
                  badgeField: ['underOffer', 'inspection', 'keyAuction'],
                }}
              />
            )}
          </div>
        </div>
        <div className="home_more_wrap inner">
          {links.map(({ url, label }) => (
            <Link
              key={label}
              to={url}
              className="btn"
            >
              {label}
            </Link>
          ))}
        </div>
      </section>
    )
  }
}
