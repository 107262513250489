import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  getResizeOptions,
  stringifyResizerParams,
  createStandardSrcSet,
  calculateImageDimensions,
} from '@raywhite/media-utils/lib/media/images.js'
import {
  createId,
  subscribe,
  unsubscribe,
} from '@raywhite/browser-utils/lib/browser/deferredSubscription.js'

export const DEFERRED = 'IMAGE_LOADING_DEFERRED'
export const TRIGGERED = 'IMAGE_LOADING_TRIGGERED'
export const COMPLETE = 'IMAGE_LOADING_COMPLETE'

// Default params to pass through to the resizing service.
const DEFAULT_IMAGE_ATTRS = {
  maxwidth: 2560,
  maxheight: 2560,
  format: 'jpg',
  quality: 90,
  scale: 'down',
}

const defaultListingImage = process.env.WEBPACKED
  ? require('../../../assets/icons/dist/default_listing_image.svg')
  : ''

const imageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
})

export default class DeferredImage extends Component {
  static propTypes = {
    image: imageShape.isRequired,
    deferred: PropTypes.bool.isRequired,
    responsive: PropTypes.bool.isRequired,
    galleryItem: PropTypes.bool,
    attrs: PropTypes.object,
    style: PropTypes.object,
    sizes: PropTypes.string,
    immediate: PropTypes.bool.isRequired,
    alt: PropTypes.string,
    external: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    deferred: true,
    responsive: true,
    immediate: false,
    alt: '',
    external: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.image.url === state.lastUrl) return null
    return {
      hasError: false,
      lastUrl: props.image.url,
    }
  }

  constructor(props) {
    super()

    const { deferred } = props

    this.state = {
      id: createId(),
      constant: deferred ? DEFERRED : TRIGGERED,
      showLoader: !deferred,
      hasError: false,
      lastUrl: props.image.url,
    }
  }

  componentDidMount() {
    const { deferred = true } = this.props
    if (deferred) subscribe(this.state.id, this.toggleLoading)
  }

  componentWillUnmount() {
    unsubscribe(this.state.id)
    if (clearTimeout && this.timeout) clearTimeout(this.timeout)
  }

  toggleLoading = () => {
    this.setState({
      constant: TRIGGERED,
      showLoader: true,
    })
  }

  toggleLoader = () => {
    this.setState({ showLoader: false })
  }

  toggleDisplay = () => {
    if (this.state.constant === COMPLETE) return
    this.setState({
      constant: COMPLETE,
    })

    // Continue showing the loader until the image is faded in
    if (setTimeout) {
      this.timeout = setTimeout(this.toggleLoader, 220)
    }
  }

  render() {
    const {
      image,
      sizes: _sizes,
      responsive,
      galleryItem,
      attrs = {},
      immediate,
      alt,
      external,
      ...rest
    } = this.props
    const { hasError } = this.state

    // Deferred is not a dom prop
    delete rest.deferred
    delete rest.showLoader

    const { id, constant } = this.state
    const _attrs = { ...DEFAULT_IMAGE_ATTRS, ...attrs }
    const { maxWidth, maxHeight, ratio } = hasError
      ? calculateImageDimensions({ url: defaultListingImage, width: 800, height: 450 }, _attrs)
      : calculateImageDimensions(image, _attrs)

    // Contstruct the default resolution image source.
    // eslint-disable-next-line no-nested-ternary
    const src = hasError
      ? defaultListingImage
      : (external ? image.url : `${image.url}?${stringifyResizerParams(_attrs)}`)

    // Construct the standard srcSet
    const srcSet = (hasError || external)
      ? undefined
      : createStandardSrcSet(getResizeOptions(_attrs), maxWidth, maxHeight, ratio)
        .map(({ descriptor, ...params }) => (
          `${image.url}?${stringifyResizerParams(params)} ${descriptor}`
        )).join(', ') || undefined
    const sizes = external
      ? undefined
      : (_sizes || `(min-width: ${maxWidth}px) ${maxWidth}px, 100vw`)

    // Construct image with webp
    const srcSetWebp = (hasError || external)
      ? undefined
      : createStandardSrcSet(getResizeOptions(_attrs), maxWidth, maxHeight, ratio)
        .map(({ descriptor, ...params }) => (
          `${image.url}?${stringifyResizerParams({ ...params, format: 'webp' })} ${descriptor}`
        )).join(', ') || undefined

    /**
     * Render image with responsive wrapper
     * @see https://www.codecaptain.io/blog/web-development/responsive-images-and-preventing-page-reflow/474
     */
    const style = {
      paddingBottom: `${Math.round(maxHeight / maxWidth * 100000000) / 1000000}%`,
      backgroundColor: '#EFEFEF',
      ...this.props.style,
    }
    const img = (
      <picture>
        {!!(srcSetWebp && sizes) && (
          <source
            srcSet={srcSetWebp}
            sizes={sizes}
            type="image/webp"
            media="(min-width: 1px)"
          />
        )}
        <noscript>
          <img
            className="image_deferred image_fadein"
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            alt={alt}
            role={alt ? undefined : 'presentation'}
            {...rest}
          />
        </noscript>
        {constant !== DEFERRED && (
          <img
            className={classNames('image_deferred', 'hide_no_js', {
              image_fadein: (immediate || constant === COMPLETE),
            })}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            alt={alt}
            role={alt ? undefined : 'presentation'}
            onLoad={this.toggleDisplay}
            ref={el => {
              if (!el || this.state.constant === COMPLETE) return
              if (el.complete) this.toggleDisplay()
            }}
            onError={() => {
              this.setState({ hasError: true })
              this.toggleLoader()
            }}
            {...rest}
          />
        )}
      </picture>
    )

    if (galleryItem) {
      return (
        <div
          className={classNames('gallery_item_wrapper', {
            loading: this.state.showLoader,
            image_loaded: constant === COMPLETE,
          })}
          id={id}
        >
          {img}
        </div>
      )
    }

    if (!responsive) return img

    return (
      <div
        className={classNames('responsive_image_wrapper', {
          loading: this.state.showLoader,
          image_loaded: constant === COMPLETE,
        })}
        style={style}
        id={id}
      >
        {img}
      </div>
    )
  }
}
