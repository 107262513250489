import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import {
  getSearch,
  getListing,
  fetchListing,
} from '@raywhite/redux/lib/listing'
import { loadOrganisations } from '@raywhite/redux/lib/org'
import flagged from '@raywhite/helpers-utils/lib/helpers/flags'
import {
  webSite as webSiteSchema,
} from '@raywhite/helpers-utils/lib/helpers/structuredData'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { timestampToDate } from '@raywhite/helpers-utils/lib/helpers/time'
import { getListingUrl, listingLinkMeta } from '@raywhite/data-utils/lib/data/listing/listings'
import { stringifyAddress } from '@raywhite/data-utils/lib/data/listing/formatting'
import { getStructuredData } from '@raywhite/data-utils/lib/data/member/office'
import { buildRecentTransactions, buildWhatsOnCategories } from '../../utils/data/meta/categories'

import { fetchListings } from '../../redux/modules/listings'
import NewsContentSummary from './NewsContentSummary.jsx'
import withSiteMetadata from '../hocs/withSiteMetadata'
import HomePageHeader from '../presentational/HomePageHeader.jsx'
import withOrgs from '../hocs/withOrgs'
import withSearch from '../hocs/withSearch'
import WhatsOnBox from '../presentational/WhatsOnBox.jsx'
import RecentTransactionBox from '../presentational/RecentTransactionBox.jsx'
import withContext from '../hocs/withContext'
import { OfficeOpenGraph } from '../presentational/OfficePage.jsx'
import JsonLD from '../presentational/JsonLD.jsx'
import TwitterSummaryCard from '../presentational/TwitterSummaryCard.jsx'
import { getCdnImage } from '../../utils/media/renderers'
import { loadOfficeSiteMetadata } from '../../redux/modules/meta'
import { loadMenu } from '../../redux/modules/menu'
import { setShowingModal } from '../../redux/modules/app'
import siteFocusConfig from '../../siteFocusConfig'


const getImage = (listing, image) => {
  if (listing && !listing.notFound) {
    return listing.loaded && listing.images[0]
  }

  return image
}

// eslint-disable-next-line max-len
const defaultBlurb = 'Ray White brings you unparalleled technology, dedication and a real passion for customer service, so you can be sure whether you list, sell, rent or buy with us - you’re getting real estate experts with a local feel.'

function load(dispatch, getListings, listingId, image, headerParams, organisationIds) {
  const maybeDoSearch = listing => {
    if (listing && listing.loaded && !listing.notFound && listing.images.length) {
      // Listing is loaded and has an image, all we need
      return Promise.resolve()
    }

    if (image && image.url) {
      // An image was specified, all we need
      return Promise.resolve()
    }

    return dispatch(fetchListings(
      getListings, { ...headerParams, organisationId: organisationIds }, 1
    ))
  }

  return listingId
    ? dispatch(fetchListing(getListings, listingId)).then(maybeDoSearch)
    : maybeDoSearch()
}

const buildStateFromMetadata = (props) => {
  const {
    siteMetadata,
    siteMetadata: { loaded, typeKey } = {},
    siteFocus,
    eventsSince,
  } = props

  if (!loaded) return { siteMetadata, transactions: undefined, categories: [] }
  const transactions = buildRecentTransactions(
    typeKey, siteFocusConfig[siteFocus], withSearch
  ).component
  const categories = buildWhatsOnCategories(
    typeKey, eventsSince, siteFocusConfig[siteFocus], withSearch
  )
  return { siteMetadata, transactions, categories }
}

export class HomePage extends Component {
  static propTypes = {
    primaryOrg: PropTypes.object,
    siteMetadata: PropTypes.object.isRequired,
    listingId: PropTypes.number,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    superscript: PropTypes.string,
    image: PropTypes.object,
    headerParams: PropTypes.object,
    videoUrl: PropTypes.string,
    listing: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    search: PropTypes.object.isRequired,
    getListings: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    baseUrl: PropTypes.string.isRequired,
    orgs: PropTypes.object.isRequired,
    displayOrgs: PropTypes.array.isRequired,
    organisationIds: PropTypes.array.isRequired,
    eventsSince: PropTypes.string.isRequired,
    blurb: PropTypes.string,
    getMenus: PropTypes.func,
    siteFocus: PropTypes.oneOf([
      'residential',
      'sales',
      'propertyManagement',
      'rural',
      'commercial',
      'commercialSales',
      'commercialPropertyManagement',
      'business',
    ]).isRequired,
    siteTitle: PropTypes.string,
    theme: PropTypes.oneOf(['commercial', 'residential']).isRequired,
    showAppraisalModal: PropTypes.func.isRequired,
    showRentalAppraisalModal: PropTypes.func.isRequired,
    _window: PropTypes.object,
  }

  static defaultProps = {
    siteFocus: 'sales',
  }

  static fetchData(dispatch, params, env) {
    const {
      getOrganisations,
      getOrganisationsMetadata,
      organisationIds,
      displayOrganisationIds,
      getMenus,
      getListings,
      getState,
      redirections,
      requestTime,
      options: {
        header: { listingId, image } = {},
        siteFocus = 'residential',
      } = {},
    } = env

    const siteConfig = siteFocusConfig[siteFocus]
    const headerParams = {
      ...siteConfig.searchParams,
      typeCode: siteConfig.headerTypeCode,
    }

    const eventsSince = timestampToDate(requestTime)

    return unlazyAll([
      dispatch(loadMenu(getMenus, {
        useServicePages: flagged('servicePages', getState()),
        redirections,
      })),
      dispatch(loadOrganisations(getOrganisations, displayOrganisationIds)),
      dispatch(loadOfficeSiteMetadata(getOrganisationsMetadata, organisationIds))
        .then(result => {
          const categories = buildWhatsOnCategories(
            result.typeKey, eventsSince, siteFocusConfig[siteFocus], withSearch,
          )
          const transaction = buildRecentTransactions(
            result.typeKey, siteFocusConfig[siteFocus], withSearch,
          )
          return unlazyAll([
            categories.length && dispatch(fetchListings(
              getListings, { ...categories[0].params, organisationId: organisationIds }, 1
            )),
            dispatch(fetchListings(
              getListings, { ...transaction.params, organisationId: organisationIds }, 1
            )),
          ])
        }),
      load(dispatch, getListings, listingId, image, headerParams, organisationIds),
    ])
  }

  static getDerivedStateFromProps(props, state) {
    if (state.siteMetadata === props.siteMetadata) return null
    return buildStateFromMetadata(props)
  }

  constructor(props) {
    super(props)
    this.state = buildStateFromMetadata(props)
  }

  componentDidMount() {
    this.load()
  }

  getValidListing() {
    const { listing, search, image } = this.props

    // If no listing ID, then skip direct listing load checks
    if (listing && listing.id) {
      if (!listing.loaded) {
        // Wait
        return listing
      }

      if (!listing.notFound && listing.images.length) {
        // OK, has an image
        return listing
      }
    }

    if (image && image.url) {
      // No need to load anything if we have an image, use that
      return false
    }

    if (!search.loaded) {
      // Wait
      return search
    }

    for (const item of search.items) {
      if (item.images.length) {
        return item
      }
    }

    return false
  }

  load() {
    const { listing, getListings, dispatch, image, headerParams, organisationIds } = this.props
    load(dispatch, getListings, listing && listing.id, image, headerParams, organisationIds)
  }

  render() {
    const {
      primaryOrg,
      orgs,
      displayOrgs,
      siteMetadata: { loaded },
      baseUrl,
      superscript,
      heading,
      subheading,
      blurb,
      siteFocus,
      siteTitle,
      videoUrl,
      organisationIds,
      theme,
      showAppraisalModal,
      showRentalAppraisalModal,
      _window,
    } = this.props

    // Show appropriate recent transactions depending on site focus
    // or none if no matching listings were found
    const { transactions: Transactions, categories } = this.state
    const listing = this.getValidListing()
    const image = getImage(listing, this.props.image)
    const { countryCode = 'AU' } = primaryOrg || {}

    const imageSrc = (
      (this.props.image && this.props.image.url && getCdnImage(this.props.image.url))
      || primaryOrg.imageStaff
      || primaryOrg.imageExterior
      || primaryOrg.imageReception
      || (image && image.url && getCdnImage(image.url))
    )

    const structuredData = webSiteSchema({
      name: siteTitle || primaryOrg.fullName || 'Ray White',
      headline: heading,
      description: blurb,
      image: imageSrc || undefined,
      url: baseUrl,
      keywords: [
        'ray white',
        'real estate',
      ].concat(Object.values(orgs.entities).map(
        org => org.loaded && org.name && org.name.toLowerCase()
      )),
    })

    return (
      <div className="home_wrap centered_text">
        <Helmet>
          <meta name="description" content={structuredData.description} />
        </Helmet>
        <JsonLD key="website">{structuredData}</JsonLD>
        {displayOrgs
          .filter(org => org.loaded && !org.error && !org.notFound)
          .map(org => (
            <JsonLD
              key={org.organisationId}
            >
              {(
                getStructuredData(
                  org,
                  org === primaryOrg,
                  baseUrl,
                  { imageOverride: imageSrc },
                )
              )}
            </JsonLD>
          ))}
        <TwitterSummaryCard
          type="summary"
          site={primaryOrg.twitter}
          title={structuredData.name}
          description={structuredData.description}
          image={imageSrc || undefined}
        />
        {primaryOrg.loaded && (
          <OfficeOpenGraph
            isPrimary
            office={primaryOrg}
            baseUrl={baseUrl}
            image={imageSrc}
          />
        )}
        <HomePageHeader
          appraisalLabel={siteFocusConfig[siteFocus].appraisalLabel}
          countryCode={countryCode}
          heading={heading}
          subheading={subheading}
          superscript={superscript}
          videoUrl={videoUrl}
          image={image}
          linkUrl={listing && listing.loaded ? getListingUrl(listing) : undefined}
          linkTitle={listing && listing.loaded
            ? stringifyAddress(listing.address, { withLocality: true })
            : undefined}
          linkProps={listing && listing.loaded ? listingLinkMeta(listing) : undefined}
          organisationIds={organisationIds}
          theme={theme}
          showAppraisalModal={siteFocus === 'propertyMangement' ? showRentalAppraisalModal : showAppraisalModal}
          showAppraisalCTA={siteFocusConfig[siteFocus].showAppraisalCTA}
          _window={_window}
        />
        {!!categories.length && <WhatsOnBox categories={categories} loaded={loaded} />}
        <section className="home_about_wrap dark_bg centered_text">
          <div className="home_about inner">
            <div className="tbl">
              <div className="tbc middle">
                <span className="muted mini preheading">About</span>
                <h3 className="charlie">
                  <Link to="/about">
                    {siteTitle || primaryOrg.fullName || 'Ray White'}
                  </Link>
                </h3>
                <p>{blurb}</p>
              </div>
              <div className="tbc middle">
                <p>
                  <Link to="/about#team" className="btn">
                    Meet The Team
                  </Link>
                </p>
                <Link to="/about" className="anchor mini">
                  Learn more about us
                </Link>
              </div>
            </div>
          </div>
        </section>
        {Transactions
          && (
          <RecentTransactionBox
            siteConfig={siteFocusConfig[siteFocus]}
            Transactions={Transactions}
            cardOptions={{
              badgeField: 'status',
              addressComponent: 'h4',
            }}
          />
          )}
        <section className="home_content">
          <span className="muted mini preheading">Up to Date</span>
          <h3 className="charlie">Latest News</h3>
          <NewsContentSummary
            className="home_content_wrap"
            contentSearchCount={2}
            morePath="/news"
            moreLabel="See more news"
            listClassName="inner_lg"
            cardOptions={{
              showImage: false,
              showDate: true,
              headerClassName: 'centered_text',
              excerptLength: 145,
              excerptStrict: false,
              titleComponent: 'h4',
            }}
          />
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { primaryOrg = {}, organisationIds } = props
  const eventsSince = timestampToDate(state.config.requestTime)

  const {
    header = {},
    office: { blurb } = {},
    siteFocus,
    siteTitle,
  } = state.config.options || {}

  const {
    listingId,
    heading,
    subheading,
    superscript,
    image,
    videoUrl,
  } = header

  // Find the most recently updated sold/for sale listing and show that
  const listing = listingId ? getListing(state.listings, listingId) : false

  // Build params for header image filter
  const siteConfig = siteFocusConfig[siteFocus]
  const headerParams = {
    ...siteConfig.searchParams,
    typeCode: siteConfig.headerTypeCode,
  }

  const search = getSearch(
    state.listings, { ...headerParams, organisationId: organisationIds }, 1
  )

  return {
    heading,
    blurb: blurb || defaultBlurb,
    subheading,
    superscript: superscript || primaryOrg.name || '',
    listing,
    search,
    headerParams,
    image,
    videoUrl,
    baseUrl: state.config.baseUrl,
    siteFocus,
    siteTitle,
    eventsSince,
    theme: state.config.theme,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showAppraisalModal: () => dispatch(setShowingModal('appraisal')),
    showRentalAppraisalModal: () => dispatch(setShowingModal('rentalAppraisal')),
  }
}

export default compose(
  withContext('getListings', 'getMenus', '_window'),
  withSiteMetadata,
  withOrgs,
  connect(mapStateToProps, mapDispatchToProps),
)(HomePage)
