import React from 'react'

import PropTypes from 'prop-types'

/**
 * Renders title and content markup, generally for use with a modal form.
 */
const ModalInner = ({ title, subtitle, children, className }) => (
  <div className={className}>
    <div className="modal_header dark_bg">
      {!!subtitle && (
        <span className="mini muted">
          {subtitle}
        </span>
      )}
      <h2 className="delta">{title}</h2>
    </div>
    <div className="modal_content">
      {children}
    </div>
  </div>
)

ModalInner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ModalInner
