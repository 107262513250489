import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './redux/modules'
import { filterAgents } from './redux/middleware'

const middleware = process.env.NODE_ENV !== 'production'
  ? [require('redux-immutable-state-invariant')(), thunk, filterAgents] // eslint-disable-line global-require
  : [thunk, filterAgents]

export default function configureStore(initialState, extension) {
  return createStore(
    reducer,
    initialState,
    extension
      ? compose(applyMiddleware(...middleware), extension)
      : applyMiddleware(...middleware)
  )
}
