import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ClientOnly from '../../container/ClientOnly.jsx'
import { mapboxToken } from '../../../constants'

import { mapboxFactory } from '.'
// import BuildingShapeLayer from './BuildingShapeLayer.jsx'

const ReactMapboxGlMap = mapboxFactory({ accessToken: mapboxToken })

export default class Map extends Component {
  static propTypes = {
    children: PropTypes.node,
    style: PropTypes.object.isRequired,
    containerStyle: PropTypes.object.isRequired,
    mapUser: PropTypes.string.isRequired,
    mapId: PropTypes.string.isRequired,
    maxZoom: PropTypes.number,
    zoom: PropTypes.number,
    pitch: PropTypes.number,
    bearing: PropTypes.number,
    center: PropTypes.arrayOf(PropTypes.number),
    movingMethod: PropTypes.string,
    renderMap: PropTypes.bool,
    identifier: PropTypes.string.isRequired,
    fitBounds: PropTypes.array,
    fitBoundsOptions: PropTypes.object,
    enforce: PropTypes.bool,
  }

  static defaultProps = {
    style: {
      width: '100%',
      height: '100%',
    },
    containerStyle: {},
    mapUser: 'mapbox',
    mapId: 'light-v10',
    maxZoom: 19,
    movingMethod: 'jumpTo',
    renderMap: true,
    enforce: false,
    pitch: 0,
    bearing: 0,
  }

  constructor(props) {
    super(props)

    this.state = {
      map: null,
      zoom: props.zoom || 12,
      pitch: props.pitch || 0,
      bearing: props.bearing || 0,
      center: props.center || [0, 0],
    }
  }

  componentDidUpdate(prevProps) {
    const { map } = this.state
    if (!map) return

    // Check that the size makes sense if we've updated
    map.resize()

    const {
      identifier,
      zoom,
      pitch,
      bearing,
      center,
      fitBounds,
      fitBoundsOptions,
    } = this.props

    // No need to force updates unless the map identifier changes
    if (prevProps.identifier === identifier) return

    // These rely on recordPosition below below to sync
    if (prevProps.center !== center) map.setCenter(center)
    if (prevProps.zoom !== zoom) map.setZoom(zoom)
    if (prevProps.bearing !== bearing) map.setBearing(bearing)
    if (prevProps.pitch !== pitch) map.setPitch(pitch)
    if (fitBounds) map.fitBounds(fitBounds, fitBoundsOptions)
  }

  recordPosition = (map) => {
    const zoom = map.getZoom()
    const pitch = map.getPitch()
    const bearing = map.getBearing()
    const { lat, lng } = map.getCenter()
    if ((
      zoom !== this.state.zoom
      || pitch !== this.state.pitch
      || bearing !== this.state.bearing
      || lng !== this.state.center[0]
      || lat !== this.state.center[1]
    )) {
      this.setState({
        map,
        zoom,
        pitch,
        bearing,
        center: [lng, lat],
      })
    }
  }

  onRender = (map) => {
    // Nothing to be done if we already have the map instance
    if (this.state.map) return

    const { fitBounds, fitBoundsOptions } = this.props

    // Trigger resize on first render - created off screen so initial
    // container dimensions are incorrect
    map.resize()

    // Stash map + current zoom/center points in state
    const zoom = map.getZoom()
    const pitch = map.getPitch()
    const bearing = map.getBearing()
    const { lat, lng } = map.getCenter()
    const { center } = this.state
    this.setState({
      map,
      zoom,
      pitch,
      bearing,
      center: (lng === center[0] && lat === center[1])
        ? center
        : [lng, lat]
      ,
    })

    // Trigger a fit bounds recalculation, also wrong before the resize
    if (fitBounds) {
      // There is some delay in the resize taking effect, urggh
      setTimeout(() => map.fitBounds(fitBounds, fitBoundsOptions), 1000)
    }
  }

  render() {
    const {
      children,
      containerStyle,
      style,
      mapId,
      renderMap,
      fitBounds,
      fitBoundsOptions,
      zoom: defaultZoom,
      center: defaultCenter,
      pitch: defaultPitch,
      bearing: defaultBearing,
      enforce,
      ...rest
    } = this.props
    const {
      zoom,
      pitch,
      bearing,
      center,
    } = this.state

    return (
      <div
        className="mapbox-map"
        style={containerStyle}
      >
        <ClientOnly
          placeholder={<div className="mapbox-map-placeholder" />}
        >
          {renderMap && (
            <ReactMapboxGlMap
              style={`mapbox://styles/mapbox/${mapId}`}
              containerStyle={style}
              {...rest}
              fitBounds={fitBounds}
              fitBoundsOptions={fitBoundsOptions}
              zoom={[enforce ? defaultZoom : zoom]}
              pitch={[enforce ? defaultPitch : pitch]}
              bearing={[enforce ? defaultBearing : bearing]}
              center={enforce ? defaultCenter : center}
              onMoveEnd={this.recordPosition}
              onPitchEnd={this.recordPosition}
              onRotateEnd={this.recordPosition}
              onRender={this.state.map ? undefined : this.onRender}
              onError={() => {
                // Discard errors for now
              }}
            >
              {children}
            </ReactMapboxGlMap>
          )}
        </ClientOnly>
      </div>
    )
  }
}
