import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import withContext from '../hocs/withContext'

const widgetId = listingId => `lm-widget-${listingId}`

export class LoanMarketWidget extends Component {
  static propTypes = {
    listingId: PropTypes.number.isRequired,
    aoEndpoint: PropTypes.string.isRequired,
    price: PropTypes.number,
    _window: PropTypes.object,
  }

  static defaultProps = {
    fallbackPrice: 500000,
  }

  componentDidMount() {
    const {
      _window,
      listingId,
      aoEndpoint,
      price,
    } = this.props

    if (!_window) return

    const {
      document,
      aoDataLayer,
    } = _window

    if (!aoDataLayer) {
      // Inject loader script
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.innerHTML = `
          (function(w,d,s,l,i) { w[l]=w[l]||[]; w[l].push({'start': new Date().getTime()});
            var f=d.getElementsByTagName(s)[0], j=d.createElement(s); j.async=true;
            j.src='https://${aoEndpoint}/ao-injector.remote.js'; j.setAttribute('data-ref', i);
            j.setAttribute('data-layer', l); j.id='ao-data-layer-injector'; f.parentNode.insertBefore(j,f);
          })(window,document,'script','aoDataLayer','RayWhiteCalc');
      `
      document.querySelector('head').appendChild(script)
    }

    _window.aoDataLayer.push({
      widget: {
        type: 'calculator',
        target: widgetId(listingId),
        utm_campaign: 'raywhitecalculator',
        utm_source: 'raywhite',
        utm_medium: 'referral',
        transparent: true,
      },
      calculator: {
        listingId,
        purchasePrice: price,
        themeColor: '#333',
      },
    })
  }

  render() {
    return <div id={widgetId(this.props.listingId)} />
  }
}

const mapStateToProps = state => ({
  aoEndpoint: state.config.loanMarket.aoEndpoint,
})

export default compose(
  withContext('_window'),
  connect(mapStateToProps),
)(LoanMarketWidget)
