// @flow
import React from 'react'
import queryString from 'query-string'
import { telLink, eventMeta } from '@raywhite/helpers-utils/lib/helpers/link'

import type { LoadableBroker } from '../../types'

const linkMeta = eventMeta({
  category: 'Outbound',
  action: 'Click',
  label: 'LoanMarket',
})

const formatCompany = ({ company, abn, fsg }: LoadableBroker) => [
  company,
  abn && `ABN ${abn}`,
  fsg,
].filter(Boolean).join(' | ')

const BrokerCard = ({ broker, utmTerm }: { broker: LoadableBroker, utmTerm: string }) => {
  const utms = queryString.stringify({
    utm_source: 'raywhite',
    utm_medium: 'referral',
    utm_campaign: 'officewebsite',
    utm_term: utmTerm,
    utm_content: 'brokercard',
  })

  return (
    <div className="lm_agent">
      <div className="agent_card">
        {broker.image ? (
          <a
            href={`${broker.website}?${utms}`}
            target="_blank"
            rel="noopener noreferrer"
            {...linkMeta}
          >
            <div className="agent_card_image">
              <img
                src={broker.image}
                alt={`${broker.firstName} ${broker.lastName}`}
              />
            </div>
          </a>
        ) : (
          <div className="agent_card_image placeholder" />
        )}
        <div className="agent_card_contact_details_wrap">
          <div className="agent_card_contact_details">
            <h4 className="echo">
              <a
                href={`${broker.website}?${utms}`}
                target="_blank"
                rel="noopener noreferrer"
                {...linkMeta}
              >
                {broker.firstName}
                {' '}
                {broker.lastName}
              </a>
            </h4>
            <span
              className="agent_card_position mini muted"
            >
              {formatCompany(broker)}
            </span>
            <ul>
              {broker.mobile && (
                <li className="contact_link_mobile">
                  <span>
                    {telLink(broker.mobileInternational || broker.mobile, {
                      label: broker.mobile,
                      'data-ev-label': `${broker.firstName} ${broker.lastName}`,
                    })}
                  </span>
                </li>
              )}
              {broker.phone && (
                <li className="contact_link_landline">
                  <span>
                    {telLink(broker.phoneInternational || broker.phone, {
                      label: broker.phone,
                      'data-ev-label': `${broker.firstName} ${broker.lastName}`,
                    })}
                  </span>
                </li>
              )}
              <li className="contact_link_profile">
                <span>
                  <a
                    href={`${broker.website}?${utms}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...linkMeta}
                  >
                    {broker.website.replace(/^https?:\/\/(www\.)?/, '').replace(/\/$/, '')}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrokerCard
