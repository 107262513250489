import EventEmitter from 'events'
import { ONE_HOUR } from '@raywhite/helpers-utils/lib/helpers/time'

/* Default expiry of 30 seconds; having a default is consistent with other
 * implementations, but set one that makes sense instead!
 */
const DEFAULT_OPTIONS = Object.freeze({
  expire: 30000,
  jitter: true,
  staleAllowance: ONE_HOUR,
  tags: [],
})

// From MDN
export function getJitter(min, max) {
  const _min = Math.ceil(min)
  const _max = Math.floor(max)
  return Math.floor(Math.random() * (_max - _min)) + _min
}

/**
 * Pack data into a standard cache item format.  This means that all cache items
 * will be objects (so drivers must be able to serialize them) with a value and
 * an expiry time.
 *
 * Field names are kept short to consume a little less space in the cache.
 */
export function pack(value, expiry) {
  return {
    v: value, // cached value
    e: expiry ? expiry + new Date().getTime() : expiry, // expiry time
  }
}

/**
 * Check if the data is expired.
 */
export function isExpired({ e }, allowance = 0) {
  return e ? e + allowance <= new Date().getTime() : false
}

/**
 * Provides a wrapper around core cache libs to provide generic functionality,
 * e.g. TTL jitter, fetch() wrappers, stale reuse, tagged record expiry.
 *
 * Wrapped caches need to support a reasonably simple API
 *   .set(key, value, options) => Promise
 *   .get(key) => Promise
 *   .getTags(tags) => Promise
 *   .invalidateTags(tags) => Promise
 *
 * @param {cache} cache A cache object support get and set.
 *
 * @todo Add support for cache composition, e.g. a fast local memory cache
 *       and more expensive shared network cache.  Writes must go to both,
 *       while reads should try a local cache first. MAY NOT BE WORTH IT.
 */
export default function getCache(cache, defaults = DEFAULT_OPTIONS) {
  // An emitter is used so that we can listen for and log errors
  const emitter = new EventEmitter()
  const locks = {}

  function emit(name, ...args) {
    if (name === 'error' && !emitter.listeners(name).length) return

    emitter.emit(name, ...args)
  }

  async function tagKey(key, tags) {
    const values = await cache.getTags(tags)
    const tagged = tags.sort().map(tag => `${tag}#${values[tag]}`).join('/')
    return `${key}:${tagged}`
  }

  async function set(key, val, options = defaults) {
    const _options = { ...defaults, ...options }
    const jitter = (_options.expire && _options.jitter)
      ? getJitter(1, _options.expire / 10)
      : 0
    const expire = _options.expire ? _options.expire + jitter : false
    const _key = _options.tags.length ? await tagKey(key, _options.tags) : key

    try {
      return await cache.set(_key, pack(val, expire), {
        expire: expire ? expire + _options.staleAllowance : false,
      })
    } catch (error) {
      emit('error', error)
      throw error
    }
  }

  async function get(key, options = {}) {
    try {
      const tags = options.tags || defaults.tags || []
      const _key = tags.length ? await tagKey(key, tags) : key
      const result = await cache.get(_key)
      if (result === null) return result
      if (!isExpired(result)) return result.v
      // todo: maybe delete it?
      return null
    } catch (error) {
      emit('error', error)
      throw error
    }
  }

  async function fetch(key, func, options = defaults) {
    const _options = { ...defaults, ...options }
    const _key = _options.tags.length ? await tagKey(key, _options.tags) : key

    let result
    try {
      result = await cache.get(_key)
    } catch (error) {
      // Swallow error, we still want to call func()
      emit('error', error)
    }

    // Return immediately if the results are final
    if (result && !isExpired(result)) return result.v

    const haveStaleResult = result && !isExpired(result, _options.staleAllowance)

    // If we have a stale result, flag it to be unstale a little longer
    const updateStale = Promise.resolve(
      !haveStaleResult || set(key, result.v, { ...options, expire: 5000 })
    )

    // If a generator is already running for this key, return it
    if (locks[_key]) return locks[_key]

    // We need to regenerate the result, one way or another
    const generator = locks[_key] = updateStale
      .catch(error => {
        emit('error', error)
      })
      .then(() => Promise.resolve(func()))
      .then(async value => {
        // Cache result, we wait to avoid other processes regenerating if we return early
        await set(key, value, options).catch(error => {
          // Swallow error, we still want to return value()
          emit('error', error)
        })

        // Clean up the lock when we're done
        delete locks[_key]

        return value
      })
      .catch(error => {
        // Clean up lock on error
        delete locks[_key]

        throw error
      })

    // Use the stale result if we have it
    return haveStaleResult ? result.v : generator
  }

  function disconnect() {
    // async noop, no unleashing zalgo
    return Promise.resolve()
  }

  function invalidateTags(tags) {
    return Promise.resolve(cache.invalidateTags(tags))
  }

  // Add listeners for cache events if supported, ensuring that we always check for errors
  if (cache.eventNames) {
    const events = cache.eventNames()
    if (events.indexOf('error') === -1) events.push('error')
    events.forEach(event => {
      cache.on(event, emit.bind(emitter, event))
    })
  }

  return Object.assign(
    emitter,
    {
      get,
      set,
      fetch,
      invalidateTags,
      diconnect: cache.disconnect || disconnect,
    }
  )
}
