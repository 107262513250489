import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  getSearch,
  getListing,
  fetchListing,
  keystringify,
} from '@raywhite/redux/lib/listing'
import {
  getMember,
  loadMembers,
} from '@raywhite/redux/lib/member'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import { unlazyAll } from '@raywhite/helpers-utils/lib/helpers/async'
import { timestampToDate } from '@raywhite/helpers-utils/lib/helpers/time'
import { submitForm } from '@raywhite/functionalities-utils/lib/functionalities/form'

import { fetchListings } from '../../redux/modules/listings'
import {
  loadContentForPath,
  loadContentTypes,
  getContentForPath,
  getContentType,
} from '../../redux/modules/content'
import NotFoundPagePage from '../presentational/NotFoundPage.jsx'
import ErrorPage from '../presentational/ErrorPage.jsx'
import RawContentItem from '../presentational/RawContentItem.jsx'
import ContentItem from '../presentational/ContentItem.jsx'
import NewsContentItem from '../presentational/NewsContentItem.jsx'
import CaseStudyContentItem from '../presentational/CaseStudyContentItem.jsx'
import withContext from '../hocs/withContext'
import Loader from '../presentational/Loader.jsx'
import LoadMoreListingList from '../presentational/LoadMoreListingList.jsx'
import { processContentSearch, getContentForm } from '../../utils/wordpress/content'

class ContentPage extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    getContentForPath: PropTypes.func.isRequired,
    getContentTypes: PropTypes.func.isRequired,
    isCommercial: PropTypes.bool.isRequired,
    itemOptions: PropTypes.object,
    search: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    searchListings: PropTypes.object,
    getListings: PropTypes.func.isRequired,
    formId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    fetch: PropTypes.func.isRequired,
    type: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    primaryDomain: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    primaryOrganisationId: PropTypes.number.isRequired,
    office: PropTypes.object,
    _window: PropTypes.object,
    itemComponent: PropTypes.oneOf([
      ContentItem,
      NewsContentItem,
      CaseStudyContentItem,
      RawContentItem,
    ]).isRequired,
    agents: PropTypes.object.isRequired,
    listings: PropTypes.object.isRequired,
    organisationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    getMembers: PropTypes.func.isRequired,
  }

  static defaultProps = {
    itemComponent: ContentItem,
  }

  componentDidMount() {
    this.loadContent()
  }

  componentDidUpdate() {
    this.loadContent()
  }

  sendForm = data => {
    const { type, id } = this.props.content
    const { formId } = this.props
    const form = getContentForm(formId)
    return submitForm(
      this.props.fetch,
      this.props._window && this.props._window.dataLayer,
      form.label || `content-form-${type}`,
      `/api/contact/content/${type}/${id}`,
      data,
      'Unable to send form',
      'Site',
    )
  }

  loadListings = page => {
    const {
      search,
      getListings,
      dispatch,
    } = this.props

    dispatch(fetchListings(getListings, search, page))
  }

  loadListing = listingId => {
    const { dispatch, getListings } = this.props
    dispatch(fetchListing(getListings, listingId))
  }

  loadAgent = memberId => {
    const { dispatch, getMembers, organisationIds } = this.props
    dispatch(loadMembers(getMembers, organisationIds, [memberId]))
  }

  loadContent() {
    const {
      dispatch,
      getContentTypes,
      getContentForPath: getContent,
      path,
      getOrganisations,
      primaryOrganisationId,
    } = this.props

    this.constructor.fetchData(
      dispatch,
      { splat: path },
      {
        getContentTypes,
        getContentForPath: getContent,
        getOrganisations,
        primaryOrganisationId,
      },
    )
  }

  render() {
    const {
      content,
      isCommercial,
      itemOptions,
      search,
      searchListings,
      formId,
      type,
      primaryDomain,
      protocol,
      baseUrl,
      office: { twitter } = {},
      itemComponent: Item,
      agents,
      listings,
      _window,
    } = this.props

    const { component: ContentForm } = getContentForm(formId)

    if (content.notFound) {
      return <NotFoundPagePage />
    }

    if (content.error) {
      return <ErrorPage error={content.error} />
    }

    if (!content.loaded) {
      return <Loader />
    }

    const hasContent = !!(ContentForm || (content.content && content.content.rendered))

    return (
      <Item
        {...itemOptions}
        _window={_window}
        content={content}
        isCommercial={isCommercial}
        typeName={type ? type.name : undefined}
        typeSlug={type ? type.slug : undefined}
        primaryDomain={primaryDomain}
        protocol={protocol}
        baseUrl={baseUrl}
        twitter={twitter}
        className={hasContent ? undefined : 'no_content'}
        agents={agents}
        listings={listings}
        loadAgents={this.loadAgent}
        loadListing={this.loadListing}
      >
        {ContentForm && (
          <div className="inner_sm">
            <hr />
            <ContentForm
              sendForm={this.sendForm}
            />
          </div>
        )}
        {!!searchListings && (
          <div className="inner_lg contentpg_proplist">
            <div className="contentpg_proplist_header centered_text">
              <span className="mini muted">What's On</span>
              <h3 className="charlie">Current Listings</h3>
            </div>
            <LoadMoreListingList
              key={keystringify(search)}
              listings={searchListings}
              load={this.loadListings}
              loadMoreLabel="Load more properties"
            />
          </div>
        )}
      </Item>
    )
  }
}

ContentPage.fetchData = (dispatch, { splat }, params) => {
  const {
    getContentTypes,
    getContentForPath: getContent,
    getOrganisations,
    primaryOrganisationId,
    getState,
    response,
  } = params

  return unlazyAll([
    dispatch(loadContentForPath(getContent, splat)),
    dispatch(loadContentTypes(getContentTypes)),
    dispatch(loadOrganisations(getOrganisations, [primaryOrganisationId])),
  ]).then(() => {
    // Only runs server side
    if (!response) return
    const content = getContentForPath(getState().content, splat)
    if (content.notFound) response.status(404)
  })
}

function mapStateToProps(state, props) {
  const { splat: path } = props.params
  const isCommercial = state.config.theme === 'commercial'
  const content = getContentForPath(state.content, path)
  const eventsSince = timestampToDate(state.config.requestTime)
  const search = content.listings
    ? processContentSearch(content.listings, eventsSince)
    : false
  const formId = content.form ? content.form.formId : false
  const searchListings = content.listings
    ? getSearch(state.listings, search, -1)
    : undefined
  const type = content.type
    && !~['page', 'post'].indexOf(content.type)
    && getContentType(state.content, content.type)

  // Pull down agent/listing info for videos if required
  const videos = content.videos || []
  const agents = videos.reduce((result, { memberId }) => {
    if (!memberId) return result
    // eslint-disable-next-line no-param-reassign
    result[memberId] = getMember(state.members, memberId)
    return result
  }, {})
  const listings = videos.reduce((result, { listingId }) => {
    if (!listingId) return result
    // eslint-disable-next-line no-param-reassign
    result[listingId] = getListing(state.listings, listingId)
    return result
  }, {})

  return {
    content,
    isCommercial,
    path,
    search,
    searchListings,
    formId,
    type,
    primaryDomain: state.config.primaryDomain,
    protocol: state.config.protocol,
    baseUrl: state.config.baseUrl,
    primaryOrganisationId: state.config.primaryOrganisationId,
    organisationIds: state.config.organisationIds,
    office: getOrganisation(state.orgs, state.config.primaryOrganisationId),
    agents,
    listings,
    itemComponent: content.raw ? RawContentItem : props.itemComponent,
  }
}

export default compose(
  withContext(
    'getListings',
    'fetch',
    'getOrganisations',
    '_window',
    'getContentTypes',
    'getContentForPath',
    'getMembers',
    'getListings',
  ),
  connect(mapStateToProps),
)(ContentPage)
