import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getSiteMetadata } from '@raywhite/redux/lib/meta'
import hoistStatics from 'hoist-non-react-statics'

import { loadOfficeSiteMetadata } from '../../redux/modules/meta'
import { getDisplayName } from './providers'

export default function withSiteMetadata(Wrapee) {
  class Wrapper extends Component {
    componentDidMount() {
      this.load()
    }

    load() {
      const { dispatch, organisationIds, getOrganisationsMetadata } = this.props
      const getMetadata = (
        getOrganisationsMetadata || this.context.getOrganisationsMetadata
      )
      dispatch(loadOfficeSiteMetadata(getMetadata, organisationIds))
    }

    render() {
      return <Wrapee {...this.props._props} />
    }
  }

  // Component is `connect`ed in order to implement shouldComponentUpdate.
  function mapStateToProps(state, props) {
    const {
      config: {
        organisationIds,
      },
      meta,
    } = state

    return {
      organisationIds,
      _props: { ...props, siteMetadata: getSiteMetadata(meta) },
    }
  }

  Wrapper.displayName = `withSiteMetadata(${getDisplayName(Wrapee)})`

  Wrapper.contextTypes = {
    getOrganisationsMetadata: PropTypes.func,
  }

  Wrapper.propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisationIds: PropTypes.array,
    _props: PropTypes.object.isRequired,
    getOrganisationsMetadata: PropTypes.func,
  }

  return connect(mapStateToProps)(hoistStatics(Wrapper, Wrapee))
}
