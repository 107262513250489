import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getOrganisation, loadOrganisations } from '@raywhite/redux/lib/org'
import OfficePage from '../presentational/OfficePage.jsx'
import NotFoundPage from '../presentational/NotFoundPage.jsx'
import withContext from '../hocs/withContext'
import { setShowingModal } from '../../redux/modules/app'

class ConnectedOfficePage extends Component {
  static propTypes = {
    isValidId: PropTypes.bool.isRequired,
    organisationId: PropTypes.number.isRequired,
    primaryOrganisationId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    org: PropTypes.object.isRequired,
    contactOrganisations: PropTypes.arrayOf(PropTypes.object).isRequired,
    baseUrl: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    content: PropTypes.string,
    siteTitle: PropTypes.string,
    showEnquiryModal: PropTypes.func.isRequired,
    emailOverride: PropTypes.string,
    showAwards: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    showAwards: true,
  }

  static fetchData = (
    dispatch,
    { orgId },
    { getOrganisations, primaryOrganisationId, organisationIds, response }
  ) => {
    const organisationId = orgId ? +orgId : primaryOrganisationId

    // Skip loading if the organisationId is not in our valid ID list
    if (organisationIds.indexOf(organisationId) === -1) {
      if (response) response.status(404)
      return undefined
    }

    return dispatch(loadOrganisations(getOrganisations, [organisationId]))
      .then(orgs => {
        const org = orgs.entities[organisationId]
        if (org) return
        if (response) response.status(404)
      })
  }

  componentDidMount() {
    if (!this.props.isValidId) {
      // Don't try to load IDs that are not valid for this site
      return
    }

    const { getOrganisations, organisationId, dispatch, primaryOrganisationId } = this.props

    this.constructor.fetchData(
      dispatch,
      { orgId: organisationId },
      { getOrganisations, primaryOrganisationId, organisationIds: [organisationId] }
    )
  }

  render() {
    if (!this.props.isValidId) {
      return <NotFoundPage />
    }

    const {
      org,
      baseUrl,
      isPrimary,
      content,
      contactOrganisations,
      siteTitle,
      showEnquiryModal,
      showAwards,
    } = this.props

    return (
      <OfficePage
        org={org}
        baseUrl={baseUrl}
        showAwards={showAwards}
        isPrimary={isPrimary}
        content={content}
        contactOrganisations={contactOrganisations}
        siteTitle={siteTitle}
        showEnquiryModal={showEnquiryModal}
      />
    )
  }
}

function mapStateToProps(state, props) {
  const organisationId = props.params.orgId
    ? parseInt(props.params.orgId, 10)
    : state.config.primaryOrganisationId

  const org = getOrganisation(state.orgs, organisationId)
  const isValidId = state.config.organisationIds.indexOf(organisationId) !== -1
  const isPrimary = organisationId === state.config.primaryOrganisationId
  const { siteTitle } = state.config.options
  const contactOrganisationIds = state.config.displayOrganisationIds.filter(
    id => id !== organisationId
  )

  return {
    organisationId,
    org: props.emailOverride ? { ...org, email: props.emailOverride } : org,
    isValidId,
    isPrimary,
    primaryOrganisationId: state.config.primaryOrganisationId,
    baseUrl: state.config.baseUrl,
    contactOrganisations: contactOrganisationIds.map(
      id => getOrganisation(state.orgs, id)
    ),
    siteTitle,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showEnquiryModal: () => dispatch(setShowingModal('enquiry')),
    dispatch,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContext('getOrganisations'),
)(ConnectedOfficePage)
