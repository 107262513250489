// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { testimonialShape } from '../../types'

type Testimonial = Object;

// TODO: String function... move to utils.
const ensureQuotes = str => {
  if (!str) return undefined
  const _str = str.trim()
  return (/^"[\s\S]*"$/.test(_str)) ? _str : `"${_str}"`
}

const agentTestimonial = (testimonial: Testimonial, i: number) => {
  const { detail, person } = testimonial
  return (
    <li key={i}>
      <blockquote>
        <p>
          {ensureQuotes(detail)}
        </p>
        {person && (
          <cite>{person}</cite>
        )}
      </blockquote>
    </li>
  )
}

type Props = {
    testimonials: Array<Testimonial>,
};

const AgentTestimonialList = ({ testimonials }: Props) => (
  <div className="agent_section_reviews inner_sm">
    <ul className="reviews_list">
      {testimonials.filter(t => t.detail).map(agentTestimonial)}
    </ul>
  </div>
)

AgentTestimonialList.propTypes = {
  testimonials: PropTypes.arrayOf(testimonialShape).isRequired,
}

export default AgentTestimonialList
