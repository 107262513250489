// @flow

import React, { Component } from 'react'
import { Link } from 'react-router'

import type { Node } from 'react'

type Props = {
  message: string,
  children?: Node,
  linkLabel?: string,
  linkUrl?: string,
};

export default class ErrorPage extends Component<Props> {
  static defaultProps = {
    message: '500 - Error',
    linkLabel: 'Return to homepage',
    linkUrl: '/',
  }

  render() {
    const { message, children, linkLabel, linkUrl } = this.props
    return (
      <div className="dark_bg error_page_wrap">
        <article className="centered_text inner">
          <h1 className="charlie">{message}</h1>
          {children}
          {!!(linkLabel && linkUrl) && (
            <Link
              className="btn large"
              to={linkUrl}
            >
              {linkLabel}
            </Link>
          )}
        </article>
      </div>
    )
  }
}
