// @flow

import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router'
import {
  agentUrl,
  getContactInfo,
  getMemberTitle,
} from '@raywhite/data-utils/lib/data/member/agent'
import { telLink, emailLink } from '@raywhite/helpers-utils/lib/helpers/link'
import { getAgentLicenceCode } from '@raywhite/helpers-utils/lib/helpers/rules'
import DeferredImage from './DeferredImage.jsx'

const resizeParams = {
  headshot: {
    width: 624,
    height: 624,
    scale: 'both',
  },
}

type Props = {
  member: Object,
  showContactDetails: boolean,
  isStatic: boolean,
  stateCode?: string,
  countryCode?: string,
  licenceCode?: string,
  showLicenceCode: boolean,
  showAsLink: boolean,
  organisationId?: number,
  organisationIds: Array<number>,
  organisations: Object,
  onClickEmail?: () => ?null,
};

export default class AgentCard extends Component<Props> {
  static defaultProps = {
    showContactDetails: true,
    isStatic: false,
    showLicenceCode: true,
    organisationIds: [],
    organisations: {},
    showAsLink: false,
  }

  render() {
    const {
      organisationId,
      organisationIds,
      organisations,
      member,
      member: {
        fullName,
        alternateName,
        email,
        titles,
        imageHeadshot: headshot,
        organisationIds: memberOrgIds,
      },
      countryCode,
      stateCode,
      licenceCode,
      isStatic,
      showContactDetails,
      showLicenceCode,
      showAsLink,
      onClickEmail,
    } = this.props

    const image = headshot
    const imageParams = image
      ? resizeParams.headshot
      : undefined

    const url = agentUrl(member)
    const linkify = isStatic || !showAsLink ? x => x : x => (
      <Link to={url}>{x}</Link>
    )

    const _licenceCode = showLicenceCode
      ? getAgentLicenceCode(countryCode, stateCode, licenceCode)
      : undefined

    const title = getMemberTitle(organisationIds, titles, memberOrgIds) || member.title
    const {
      mobilePhone: mobile,
      officePhone: landline,
      promoPhone: promo,
    } = getContactInfo(member, { orgId: organisationId })

    const org = member.organisationIds.reduce((found, id) => {
      if (found) return found
      return organisations[id]
    }, undefined)

    return (
      <div
        className={classNames('agent_card', {
          has_licence: _licenceCode && showContactDetails,
          has_office: org,
        })}
      >
        {image
          ? linkify(
            <div className="agent_card_image">
              <DeferredImage
                image={{ url: image, width: 624, height: 624 }}
                attrs={imageParams}
                alt={fullName}
              />
            </div>
          ) : linkify(
            <div className="agent_card_image">
              <picture className="agent_card_placeholder_image" />
            </div>
          )}
        <div className="agent_card_contact_details_wrap">
          <div className="agent_card_contact_details">
            <h4 className="echo">
              {linkify(fullName)}
              {alternateName && (
                <span className="muted altName">
                  {alternateName}
                </span>
              )}
            </h4>
            <span className="agent_card_position mini muted">{title}</span>
            {showContactDetails && (
              <ul>
                {(
                  [
                    promo && (
                      <li className="contact_link_promo" key="promo">
                        <span>
                          {isStatic ? promo : telLink(promo, { 'data-ev-label': fullName })}
                        </span>
                      </li>
                    ),
                    mobile && (
                      <li className="contact_link_mobile" key="mobile">
                        <span>
                          {isStatic ? mobile : telLink(mobile, { 'data-ev-label': fullName })}
                        </span>
                      </li>
                    ),
                    landline && (
                      <li className="contact_link_landline" key="landline">
                        <span>
                          {isStatic ? landline : telLink(landline, { 'data-ev-label': fullName })}
                        </span>
                      </li>
                    ),
                  ]
                    .filter(Boolean)
                  // Only show the first one
                    .shift()
                )}
                {email && (
                  <li className="contact_link_email">
                    <span className={!isStatic ? 'clickable' : undefined}>
                      {(
                        (isStatic && email)
                        || (!onClickEmail && emailLink(email, { 'data-ev-label': fullName }))
                        || (
                          <span
                            onClick={onClickEmail}
                            tabIndex="0"
                            role="button"
                            data-ev-on="click"
                            data-ev-category="Agent Card Email"
                            data-ev-action="Click"
                            data-ev-label={fullName}
                          >
                            {email}
                          </span>
                        )
                      )}
                    </span>
                  </li>
                )}
                {!!org && (
                  <li className="agent_card_office">
                    <small className="muted">{org.fullName}</small>
                  </li>
                )}
                {_licenceCode && (
                  <li className="agent_card_licence">
                    <small className="muted">{_licenceCode}</small>
                  </li>
                )}
              </ul>
            )}
            {!!(!showContactDetails && org) && (
              <ul>
                <li className="agent_card_office">
                  <small className="muted">{org.fullName}</small>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    )
  }
}
